const getSyncfusionTranslations = () => {
	return {
		es: {
			calendar: {
				today: 'Hoy'
			},
			datepicker: {
				today: 'Avui'
			},
			schedule: {
				day: 'Día',
				week: 'Semana',
				workWeek: 'Semana laboral',
				month: 'Mes',
				year: 'Año',
				agenda: 'Agenda',
				weekAgenda: 'Agenda semanal',
				workWeekAgenda: 'Agenda de trabajo semanal',
				monthAgenda: 'Agenda Mensual',
				today: 'Hoy',
				noEvents: 'No hay eventos',
				emptyContainer: 'No hay eventos programados para este día.',
				allDay: 'Todo el día',
				start: 'Inicio',
				end: 'Fin',
				more: 'más',
				close: 'Cerrar',
				cancel: 'Cancelar',
				noTitle: '(Sin título)',
				delete: 'Eliminar',
				deleteEvent: 'Eliminar Evento',
				deleteMultipleEvent: 'Eliminar Multiples Eventos',
				selectedItems: 'Items seleccionados',
				deleteSeries: 'Eliminar series',
				edit: 'Editar',
				editSeries: 'Editar Series',
				editEvent: 'Editar Eventos',
				createEvent: 'Crear',
				subject: 'Tema',
				addTitle: 'Añadir título',
				moreDetails: 'Más detalles',
				save: 'Guardar',
				editContent: 'Do you want to edit only this event or entire series?',
				deleteRecurrenceContent: 'Do you want to delete only this event or entire series?',
				deleteContent: 'Are you sure you want to delete this event?',
				deleteMultipleContent: 'Are you sure you want to delete the selected events?',
				newEvent: 'New Event',
				title: 'Title',
				location: 'Location',
				description: 'Description',
				timezone: 'Timezone',
				startTimezone: 'Start Timezone',
				endTimezone: 'End Timezone',
				repeat: 'Repeat',
				saveButton: 'Save',
				cancelButton: 'Cancel',
				deleteButton: 'Delete',
				recurrence: 'Recurrence',
				wrongPattern: 'The recurrence pattern is not valid.',
				seriesChangeAlert:
					'The changes made to specific instances of this series will be cancelled and those events will match the series again.',
				createError:
					'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
				recurrenceDateValidation:
					'Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.',
				sameDayAlert: 'Two occurrences of the same event cannot occur on the same day.',
				editRecurrence: 'Edit Recurrence',
				repeats: 'Repeats',
				alert: 'Alert',
				startEndError: 'The selected end date occurs before the start date.',
				invalidDateError: 'The entered date value is invalid.',
				ok: 'Ok',
				occurrence: 'Occurrence',
				series: 'Series',
				previous: 'Previous',
				next: 'Next',
				timelineDay: 'Timeline Day',
				timelineWeek: 'Timeline Week',
				timelineWorkWeek: 'Timeline Work Week',
				timelineMonth: 'Timeline Month',
				expandAllDaySection: 'Expandir',
				collapseAllDaySection: 'Collapse'
			},
			colorpicker: {
				Apply: 'Aplicar',
				Cancel: 'Cancelar',
				ModeSwitcher: 'Cambiar modo'
			},
			grid: {
				EmptyRecord: 'Vacío',
				EditOperationAlert: 'No hay registros seleccionados para la operación de edición',
				DeleteOperationAlert: 'No hay registros seleccionados para la operación de eliminación',
				GroupDropArea: 'Arrastre el encabezado de una columna aquí para agrupar su columna',
				Add: 'Añadir',
				Edit: 'Editar',
				Cancel: 'Cancelar',
				Update: 'Guardar',
				Delete: 'Eliminar',
				Save: 'Guardar',
				SaveButton: 'Guardar',
				OKButton: 'Vale',
				CancelButton: 'Cancelar',
				Item: 'Item',
				Items: 'Items',
				UnGroup: 'Haga clic aquí para desagrupar',
				True: 'Activo',
				False: 'Inactivo'
			},
			formValidator: {
				required: 'Campo requerido'
			},
			pager: {
				pagerInfo: '{0} de {1} páginas ({2} páginas)',
				nextPagerTooltip: 'Ir al siguiente Pager',
				previousPagerTooltip: 'Ir a Pager anterior',
				currentPageInfo: '{0} de {1} páginas',
				firstPageTooltip: 'Primera página',
				lastPageTooltip: 'Última página',
				nextPageTooltip: 'Siguiente',
				previousPageTooltip: 'Anterior',
				totalItemsInfo: '({0} Total)'
			}
		},
		ca: {
			calendar: {
				today: 'Avui'
			},
			schedule: {
				day: 'Dia',
				week: 'Setmana',
				year: 'Any',
				workWeek: 'Work Week',
				month: 'Mes',
				agenda: 'Agenda',
				weekAgenda: 'Agenda semanal',
				workWeekAgenda: 'Work Week Agenda',
				monthAgenda: 'Month Agenda',
				today: 'Avui',
				noEvents: 'No events',
				emptyContainer: 'There are no events scheduled on this day.',
				allDay: 'All day',
				start: 'Start',
				end: 'End',
				more: 'more',
				close: 'Close',
				cancel: 'Cancel',
				noTitle: '(No Title)',
				delete: 'Delete',
				deleteEvent: 'Delete Event',
				deleteMultipleEvent: 'Delete Multiple Events',
				selectedItems: 'Items selected',
				deleteSeries: 'Delete Series',
				edit: 'Edit',
				editSeries: 'Edit Series',
				editEvent: 'Edit Event',
				createEvent: 'Create',
				subject: 'Subject',
				addTitle: 'Add title',
				moreDetails: 'More Details',
				save: 'Save',
				editContent: 'Do you want to edit only this event or entire series?',
				deleteRecurrenceContent: 'Do you want to delete only this event or entire series?',
				deleteContent: 'Are you sure you want to delete this event?',
				deleteMultipleContent: 'Are you sure you want to delete the selected events?',
				newEvent: 'New Event',
				title: 'Title',
				location: 'Location',
				description: 'Description',
				timezone: 'Timezone',
				startTimezone: 'Start Timezone',
				endTimezone: 'End Timezone',
				repeat: 'Repeat',
				saveButton: 'Save',
				cancelButton: 'Cancel',
				deleteButton: 'Delete',
				recurrence: 'Recurrence',
				wrongPattern: 'The recurrence pattern is not valid.',
				seriesChangeAlert:
					'The changes made to specific instances of this series will be cancelled and those events will match the series again.',
				createError:
					'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
				recurrenceDateValidation:
					'Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.',
				sameDayAlert: 'Two occurrences of the same event cannot occur on the same day.',
				editRecurrence: 'Edit Recurrence',
				repeats: 'Repeats',
				alert: 'Alert',
				startEndError: 'The selected end date occurs before the start date.',
				invalidDateError: 'The entered date value is invalid.',
				ok: 'Ok',
				occurrence: 'Occurrence',
				series: 'Series',
				previous: 'Previous',
				next: 'Next',
				timelineDay: 'Timeline Day',
				timelineWeek: 'Timeline Week',
				timelineWorkWeek: 'Timeline Work Week',
				timelineMonth: 'Timeline Month',
				expandAllDaySection: 'Expand',
				collapseAllDaySection: 'Collapse'
			},
			colorpicker: {
				Apply: 'Aplicar',
				Cancel: 'Cancel·lar',
				ModeSwitcher: 'Canviar mode'
			},
			grid: {
				EmptyRecord: 'Buit',
				EditOperationAlert: "No hi ha registres seleccionats per a l'operació d'edició",
				DeleteOperationAlert: "No hi ha registres seleccionats per a loperació d'eliminació",
				GroupDropArea: "Arrossegueu la capçalera d'una columna aquí per agrupar la seva columna",
				Add: 'Afegir',
				Edit: 'Editar',
				Cancel: 'Cancel·lar',
				Update: 'Guardar',
				Delete: 'Eliminar',
				Save: 'Guardar',
				SaveButton: 'Guardar',
				OKButton: 'Vale',
				CancelButton: 'Cancel·lar',
				Item: 'Article',
				Items: 'Articles',
				UnGroup: 'Fes clic aquí per desagrupar',
				True: 'Actiu',
				False: 'Inactiu'
			},
			formValidator: {
				required: 'Camp requerit'
			},
			pager: {
				pagerInfo: '{0} de {1} pàgines ({2} pàgines)',
				nextPagerTooltip: 'Anar al següent Pager',
				previousPagerTooltip: 'Anar a Pager anterior',
				currentPageInfo: '{0} de {1} páginas',
				firstPageTooltip: 'Primera página',
				lastPageTooltip: 'Última página',
				nextPageTooltip: 'Seguent',
				previousPageTooltip: 'Anterior',
				totalItemsInfo: '({0} Total)'
			}
		}
	};
};

export { getSyncfusionTranslations };
