import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { startRequest } from '@backoffice/actions/apiCall';
import { Input, Button, Combo } from '@backoffice/components/FormComponents/index';
import { openModal, closeModal } from '../../../actions/popup';
import { validateEmail } from '../../../common/validations/common';
import { showFixedLabel } from '../../../actions/fixedLabel';
import PopUpGenericDelete from '../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { getRolesParams, getUserByIdParams, postUserParams, putUserParams, deleteUserParams } from './UserDetailCalls';
import './UserDetail.scss';
import { ROUTE } from '../../../common/constants/routes';

const UserDetail = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [ userId, setUserId ] = useState(id);

	const [ email, setEmail ] = useState('');
	const [ emailError, setEmailError ] = useState(false);
	const [ name, setName ] = useState('');
	const [ surnames, setSurnames ] = useState('');
	const [ role, setRole ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ roleOptions, setRoleOptions ] = useState([]);

	useEffect(() => {
		getRoles();
	}, []);

	const getRoles = () => {
		dispatch(startRequest(getRolesParams(getRolesSuccess)));
	};

	const getRolesSuccess = (response) => {
		setRoleOptions(response);
		if (userId !== '0') {
			getUserById();
		}
	};

	const getUserById = () => {
		dispatch(startRequest(getUserByIdParams(userId, getUserByIdSuccess)));
	};

	const getUserByIdSuccess = (response) => {
		setName(response.name);
		setSurnames(response.surnames);
		setEmail(response.email);
		setRole(response.userRoles[0]);
		setPhone(response.phone);
	};

	const getUserForm = () => {
		return {
			id: userId,
			email: email,
			name: name,
			surnames: surnames,
			phone: phone,
			rolId: role !== '' ? role.id : ''
		};
	};

	const validateForm = () => {
		let formOK = true;
		if (email === '' || emailError || name === '' || surnames === '' || role === '') {
			formOK = false;
		}
		return !formOK;
	};

	const postUser = () => {
		let user = getUserForm();
		if (userId === '0') {
			dispatch(
				startRequest(
					postUserParams(
						user,
						(response) => postUserSuccess('add', response),
						(response) => postUserError('add', response)
					)
				)
			);
		} else {
			dispatch(
				startRequest(
					putUserParams(
						user,
						(response) => postUserSuccess('edit', response),
						(response) => postUserError('edit', response)
					)
				)
			);
		}
	};

	const postUserSuccess = (action, response) => {
		history.push(ROUTE.USERS);
		if (action === 'add') {
			dispatch(showFixedLabel('UserAddSuccess', 'success'));
		} else {
			dispatch(showFixedLabel('UserEditSuccess', 'success'));
		}
	};

	const postUserError = (action, response) => {
		dispatch(showFixedLabel(response.data.errors[0].errorMessageKey, 'error'));
	};

	const openDeleteUsers = (action, user) => {
		const optionValues = {
			id: 'delete-users-' + user.id + '-' + Math.random(),
			dataTestId: 'delete-user',
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteUsers(user)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteUsers = (user) => {
		dispatch(startRequest(deleteUserParams({ id: user.id }, deleteUsersSuccess)));
	};

	const deleteUsersSuccess = (response) => {
		dispatch(showFixedLabel('UserDeleteSuccess', 'success'));
		history.push(ROUTE.USERS);
	};

	return (
		<div className="userdetail">
			<div className="return">
				<div className="col-lg-1">
					<span className='sr-only' aria-labelledby='returnButton'>Volver atrás</span>
					<span className="go-back" id='returnButton' onClick={() => history.goBack()} role="button">
						<span className="material-icons-round" data-testid={'btn-goback'} id={'btn-goback'}>
							arrow_back
						</span>
						<div className="go-back__text">{t('GoBack')}</div>
					</span>
				</div>
			</div>
			<h2 className="title">{t('UserDetail')}</h2>

			<div className="userdetail-form">
				<div className="row">
					<div className="col-lg-3">
						<Input
							dataTestID="txtName"
							inputName="txtName"
							inputType="text"
							inputLabel={t('Name')}
							inputID="user-name"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setName(val);
							}}
							value={name}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtSurnames"
							inputName="txtSurnames"
							inputType="text"
							inputLabel={t('Surnames')}
							inputID="user-firstsurname"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setSurnames(val);
							}}
							value={surnames}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtEmail"
							inputName="txtEmail"
							inputType="text"
							inputLabel={t('Email')}
							inputID="user-email"
							isReadOnly={userId !== '0'}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setEmail(val);
								setEmailError(validateEmail(val).error);
							}}
							value={email}
							isError={emailError}
							errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3" hidden={role.name !== 'Operator'}>
						<Input
							dataTestID="txtPhone"
							inputName="txtPhone"
							inputType="text"
							isReadOnly={false}
							inputLabel={t('Phone')}
							inputID="consumer-phone"
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhone(val);
							}}
							value={phone}
							isRequired={false}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3">
						<Combo
							id="cmbRol"
							dataTestID="cmbRol"
							comboLabel={t('Rol')}
							inputValue={role}
							defaultInputValue=""
							isSearchable={false}
							getOptionValue={(option) => option['id']}
							getOptionLabel={(option) => t(option['name'])}
							options={roleOptions}
							isMultiple={false}
							isRequired={true}
							handleChange={(e) => {
								setRole(e);
							}}
						/>
					</div>
				</div>
				<div className="btn-end">
					{userId !== '0' ? (
						<div className="col-lg-3 mb-3">
							<Button
								dataTestID="btnConfirmarDeleteAceptar"
								btnText={t('Delete')}
								btnType="button"
								btnClass="btn-base_danger mr-2"
								onHandleBtnClick={() => {
									openDeleteUsers('delete', getUserForm());
								}}
							/>
						</div>
					) : (
						''
					)}
					<div className="col-lg-3 mb-3">
						<Button
							dataTestID="btnSaveAddUser"
							btnText={t('SaveChanges')}
							btnType="button"
							btnClass="btn-base_normal"
							isDisabled={validateForm()}
							onHandleBtnClick={() => postUser()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default UserDetail;
