import axios from 'axios';
import { GetCookie_token, GetCookie_culture } from '@backoffice/common/utils/cookiesManager';
import { getEnvApiUrl, ApiVersion } from './config';
import qs from 'qs';

const APIBASEURL = getEnvApiUrl();
function getHeaders(contentType) {
	const token = GetCookie_token() ? 'Bearer ' + GetCookie_token() : '';
	const culture = GetCookie_culture() ? GetCookie_culture() : 'es';
	return {
		ApplicationName: 'Web_BackOffice',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': contentType ? contentType : 'application/json',
		Authorization: token,
		Culture: culture
	};
}

const apiCall = (options, props) => {
	const { showDialog, hideDialog, endRequest, showFixedLabel } = props;
	if (options.endpoint !== undefined) {
		if (options.hideSpinner === undefined || !options.hideSpinner) {
			showDialog();
		}
		if (options.contentType === 'multipart/form-data') {
			var form_data = new FormData();
			for (var key in options.data) {
				form_data.append(key, options.data[key]);
			}
			options.data = form_data;
		}
		if (window.location.hostname === 'https://dev-madridexcelente-api.azurewebsites.net' || window.location.hostname === 'localhost') {
			console.log(options);
		}
		axios({
			headers: getHeaders(options.contentType),
			method: options.method || 'GET',
			url: options.url ? options.url : `${APIBASEURL}${ApiVersion}` + options.endpoint,
			data: options.data,
			params: options.params,
			paramsSerializer: function(params) {
				return qs.stringify(params, { arrayFormat: 'repeat' });
			}
		})
			.then(function(response) {
				if (window.location.hostname === 'https://dev-madridexcelente-api.azurewebsites.net' || window.location.hostname === 'localhost') {
					console.log(response);
				}
				if (options.onSuccess) {
					options.onSuccess(response);
				}
			})
			.then(function() {
				if (options.onThen) {
					options.onThen();
				}
			})
			.catch(function(error) {
				if (window.location.hostname === 'https://dev-madridexcelente-api.azurewebsites.net' || window.location.hostname === 'localhost') {
					console.log(error);
				}
				let data;
				if (!error.response) {
					data = error.data;
				} else if (error.response.status === 401) {
					// toastError('Usuario no autorizado', 'Información sensible, usuario restringido.');
					// toastError('Unauthorized', 'SensitiveInformation.');
				} else {
					data = error.response.data;
				}
				if (data != null) {
					if (data.errors) {
						showFixedLabel(
							data.errors[Object.keys(data.errors)[0]].errorMessageKey
								? data.errors[Object.keys(data.errors)[0]].errorMessageKey
								: data.errors[Object.keys(data.errors)[0]],
							'error'
						);
						// data.errors.forEach((error) => {
						// 	const { errorMessageKey } = error;
						// 	if (options.hideFixedLabel === undefined || !options.hideFixedLabel) {
						// 		showFixedLabel(errorMessageKey, 'error');
						// 	}
						// });
					}
				}
				if (options.onError) {
					options.onError(error.response);
				}
			})
			.finally(function(response, error) {
				if (options.onFinally) {
					options.onFinally(response, error);
				}
				if (options.hideSpinner === undefined || !options.hideSpinner) {
					hideDialog();
				}
			});
	}
};

export { apiCall, getHeaders, APIBASEURL };
