import React from 'react';
import footerLogo from '../../assets/logos/endesa.png';
import './Footer.scss';

const Footer = ({showLogo = true}) => {

	return (
		<footer className="footer">
			<div className="footer-wrapper">
				<div className="footer-logo-wrapper">
					<span className={`footer-slogan${!showLogo ? ' hide-logo' : ''}`}>Con el apoyo de </span>
					<img className={`footer-logo${!showLogo ? ' hide-logo' : ''}`} alt='logo de Endesa' src={footerLogo} />
				</div>
				<div className="footer-links">
					<a href="https://madridexcelente.com/aviso-legal/" target="_blank" rel="noreferrer">Aviso legal</a>
					<a href="https://madridexcelente.com/politica-de-privacidad/" target="_blank" rel="noreferrer">Política privacidad</a>
					<a href="https://madridexcelente.com/politica-de-cookies/" target="_blank" rel="noreferrer">Cookies</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
