const getAutoEvaluationDataParams = ( id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetAutoEvaluationData/${id}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};
const getUserLoggedParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetUserLogged',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postSaveAutoevaluationData = (data, SuccessFn, ErrorFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Request/SaveAutoevaluationData',
		onError: (response) => {
			ErrorFn(response.data);
		},
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};
const postSendAutoevaluationData = (data, SuccessFn, ErrorFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Request/SendAutoevaluationData',
		onError: (response) => {
			ErrorFn(response.data);
		},
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};

export {
    getAutoEvaluationDataParams,
	getUserLoggedParams,
	postSaveAutoevaluationData,
	postSendAutoevaluationData
}