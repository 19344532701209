import moment from 'moment';
import { GetCookie_formatDate } from './cookiesManager';

const createURL = (file) => {
	let el = URL.createObjectURL(file);
	URL.revokeObjectURL(file);
	return el;
};

const getMediaType = (file) => {
	let mediaType = 1;
	var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
	var fileExtension = file.name.match(patternFileExtension);
	switch (fileExtension[1]) {
		case 'jpg':
		case 'jpeg':
		case 'gif':
		case 'bmp':
		case 'png':
			mediaType = 'Image';
			break;
		case 'm4v':
		case 'avi':
		case 'mpg':
		case 'mp4':
			mediaType = 'Video';
			break;

		default:
			mediaType = 'Other';
			break;
	}
	return mediaType;
};

const getMediaExtension = (file) => {
	var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
	var fileExtension = file.name.match(patternFileExtension);
	return fileExtension[1];
};

const isWeekend = (date) => {
	let format = GetCookie_formatDate();
	if (date !== null && date !== '') {
		let parsedDate = moment(date, format);
		let day = parsedDate.day();
		if (day === 6 || day === 0) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

const parsePrice = (price) => {
	let parsedPrice = parseFloat(price).toFixed(2);
	return parsedPrice.toString().replace('.', ',');
};

const parseDate = (date) => {
	let format = GetCookie_formatDate();
	let newDate = '';
	if (date !== '') {
		newDate = moment(date, 'YYYY-MM-DD').format(format);
	}
	return newDate;
};

const parseTime = (time) => {
	let newTime = '';
	if (time !== '') {
		newTime = moment(time).format('HH:mm');
	}
	return newTime;
};

const parseDateBE = (date) => {
	let format = GetCookie_formatDate();

	let newDate = '';
	if (date !== '') {
		newDate = moment(date, format).format('YYYY-MM-DD');
	}
	return newDate;
};

const parsePriceCurrency = (price) => {
	let priceParsed = '';
	const formatter = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 2
	});

	priceParsed = formatter.format(price);
	return priceParsed;
};

const parsePriceCurrencyNoDecimal = (price) => {
	let priceParsed = '';
	const formatter = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 0
	});

	priceParsed = formatter.format(price);
	return priceParsed;
};

const validateIban = (IBAN) => {
	//Se pasa a Mayusculas
	IBAN = IBAN.toUpperCase();
	//Se quita los blancos de principio y final.
	IBAN = IBAN.trim();
	IBAN = IBAN.replace(/\s/g, ''); //Y se quita los espacios en blanco dentro de la cadena

	var letra1, letra2, num1, num2;
	var isbanaux;
	var numeroSustitucion;
	//La longitud debe ser siempre de 24 caracteres
	if (IBAN.length != 24) {
		return false;
	}

	// Se coge las primeras dos letras y se pasan a números
	letra1 = IBAN.substring(0, 1);
	letra2 = IBAN.substring(1, 2);
	num1 = getnumIBAN(letra1);
	num2 = getnumIBAN(letra2);
	//Se sustituye las letras por números.
	isbanaux = String(num1) + String(num2) + IBAN.substring(2);
	// Se mueve los 6 primeros caracteres al final de la cadena.
	isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

	//Se calcula el resto, llamando a la función modulo97, definida más abajo
	let resto = modulo97(isbanaux);
	if (resto == 1) {
		return true;
	} else {
		return false;
	}
};

function modulo97(iban) {
	var parts = Math.ceil(iban.length / 7);
	var remainer = '';

	for (var i = 1; i <= parts; i++) {
		remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
	}

	return remainer;
}

const getnumIBAN = (letra) => {
	let ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	return ls_letras.search(letra) + 10;
};

const orderComboByText = ( options ) => {	
	return options.sort(compare)
}

const compare = ( a, b ) => {
	if ( a.label < b.label ){
	  return -1;
	}
	if ( a.label > b.label ){
	  return 1;
	}
	return 0;
}

export {
	createURL,
	getMediaType,
	getMediaExtension,
	isWeekend,
	parsePrice,
	parsePriceCurrencyNoDecimal,
	parseTime,
	parseDate,
	parseDateBE,
	validateIban,
	parsePriceCurrency,
	orderComboByText
};
