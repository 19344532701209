const postForgotPasswordParams = (data, funcSucc, funcErr) => {
    const options = {
        method: 'POST',
        data: data,
        endpoint: '/Account/ForgotPassword',
        onSuccess: (response) => {funcSucc(response.data.data)},
        onError: (error) => {funcErr(error)},
    };
    return options;
}

export {postForgotPasswordParams};