import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeModal } from '@backoffice/actions/popup';
import { Button } from '../FormComponents';

import './PopUpGenericDelete.scss';

const PopUpGenericDelete = ({
	id,
	deleteTitle,
	deleteMessage,
	handleButtonDelete,
	handleButtonCancel,
	buttonDeleteClass,
	buttonDeleteName
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<Fragment>
			<div id={'body' + id} className="row">
				<div className="col-12">
					<p className="title"> {t(deleteMessage)}</p>
				</div>
			</div>

			<div className="modal-delete_footer">
				<div className="row">
					<div className="col-12 btn-group-row d-flex justify-content-end">
						<Button
							dataTestID="btnConfirmarDeleteCancelar"
							btnText={t('Cancel')}
							btnType="button"
							btnClass="btn-base_outline-danger"
							onHandleBtnClick={() => {
								handleButtonCancel();
								//dispatch(closeModal());
							}}
						/>
						<Button
							dataTestID="btnConfirmarDeleteAceptar"
							btnText={buttonDeleteName ? buttonDeleteName : t('Delete')}
							btnType="button"
							btnClass={buttonDeleteClass ? buttonDeleteClass : 'btn-base_danger'}
							onHandleBtnClick={() => {
								handleButtonDelete();
								dispatch(closeModal());
							}}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PopUpGenericDelete;
