import { ROUTE } from '../common/constants/routes';
import Login from '../views/Pages/Login';
import Logout from '../views/Pages/Logout';
import ResetPassword from '../views/Pages/ResetPassword';
import Users from '../views/Layout/Users';
import Companies from '../views/Layout/Companies';
import UserDetail from '../views/Layout/UserDetail';
import Request from '../views/Pages/Request';
import RequestDetail from '../views/Layout/RequestDetail';
import SelfAssessment from '../views/Layout/SelfAssessment';
import RequestRecieved from '../views/Pages/RequestReceived/RequestReceived';
import AuditorCompanies from '../views/Layout/AuditorCompanies';
import ImprovementPlans from '../views/Layout/ImprovementPlans';
import Questionnaires from '../views/Layout/Questionnaires';
import QuestionnairesDetail from '../views/Layout/Questionnaires/QuestionnairesDetail';
import CompaniesInfo from '../views/Layout/CompaniesInfo';
import CompaniesInfoDetail from '../views/Layout/CompaniesInfo/CompaniesInfoDetail';

const publicRoutes = [];
publicRoutes[ROUTE.ROOT] = Login;
publicRoutes[ROUTE.RESETPASSWORD] = ResetPassword;
publicRoutes[ROUTE.LOGIN] = Login;
publicRoutes[ROUTE.LOGOUT] = Logout;
publicRoutes[ROUTE.REQUEST] = Request;
publicRoutes[ROUTE.REQUESTID] = Request; //TODO Private!
publicRoutes[ROUTE.SELFASSESSMENTID] = SelfAssessment; //TODO Private!
publicRoutes[ROUTE.REQUESTRECIEVED] = RequestRecieved;

const privateRoutes = [];
privateRoutes[ROUTE.USERS] = Users;
privateRoutes[ROUTE.USERDETAIL] = UserDetail;
privateRoutes[ROUTE.COMPANIES] = Companies;
privateRoutes[ROUTE.REQUESTDETAIL] = RequestDetail;
privateRoutes[ROUTE.SOLICITUDES] = Companies;
privateRoutes[ROUTE.SOLICITUDESID] = RequestDetail;
privateRoutes[ROUTE.AUDITORCOMPANIES] = AuditorCompanies;
privateRoutes[ROUTE.IMPROVEMENTPLANS] = ImprovementPlans;
privateRoutes[ROUTE.QUESTIONNAIRES] = Questionnaires;
privateRoutes[ROUTE.QUESTIONNAIRESDETAIL] = QuestionnairesDetail;
privateRoutes[ROUTE.COMPANIESINFO] = CompaniesInfo;
privateRoutes[ROUTE.COMPANIESINFODETAIL] = CompaniesInfoDetail;
//privateRoutes[ROUTE.SELFASSESSMENT] = SelfAssessment;

export { publicRoutes, privateRoutes };
