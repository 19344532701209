import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../../actions/popup';
import { startRequest } from '@backoffice/actions/apiCall';
//import { getAllRolesExceptCompany,  } from '../UsersCalls/UsersCalls'
import {
    putApproveRequestByID,
    putRejectRequestByID,
    putAcceptBudgetByID,
    putRejectBudgetByID,
    putValidateQuestionnaireByID,
    putRejectQuestionnaireByID,
    putApproveEvaluationByID,
    putApproveRenewalRequest,
    putRejectRenewalRequest,
    getDeregisterCompanyType,
    deleteDeactivateCompany,
    putChangeRequestTypeToRenewal,
} from '../RequestDetailCalls/RequestDetailCalls'
import Button from '../../../../components/FormComponents/Button';
import './RequestDetailModal.scss';
import Combo from '../../../../components/FormComponents/Combo';

export default function RequestDetailModal({requestId, action, handleSubmit, showFixedLabel, isPrincipalCompany, requestType}) {
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const [showError, setshowError] = useState({
        state: false,
        message: "",
        type: ""
    })

    const [numImprovementPlansOptions, setNumImprovementPlansOptions] = useState([
        {
            id: '1',
            label: 'Un plan',
            value: 'Un plan'
        },
        {
            id: '2',
            label: 'Dos planes',
            value: 'Dos planes'
        },
    ])
    const [numImprovementPlansSelected, setNumImprovementPlansSelected] = useState(numImprovementPlansOptions[0])

    const [deregisterCompanyOptions, setDeregisterCompanyOptions] = useState()
    const [deregisterCompanySelected, setDeregisterCompanySelected] = useState()
    
    useEffect(() => {
        action === 'DeregisterCompany' && getDeregisterCompanyOptions()
    }, [action])

    const getDeregisterCompanyOptions = () => {
        dispatch(startRequest(getDeregisterCompanyType(getDeregisterCompanyTypeSuccess)))
    }

    const getDeregisterCompanyTypeSuccess = (data) => {
        let options = [];
        data.forEach((el) => {
            if(el.id != "98240a48-96c7-4d5a-9abf-1b1110a2099f" && el.id != "2ed45da7-5b24-4f61-b194-5ba0e168455b") {
                options.push({
                    id: el.id,
                    label: el.name,
                    value: el.name
                })
            }
        })

        setDeregisterCompanyOptions(options);
    }

    const postAuthorize = (e) => {
        e.preventDefault()
        switch (action) {
            case 'ApproveRequest':
                dispatch(startRequest(putApproveRequestByID(requestId, putApproveRequestByIDSuccess, handleError)))
                break;
            case 'RejectRequest':
                dispatch(startRequest(putRejectRequestByID(requestId, putRejectRequestByIDSuccess, handleError)))
                break;
            case 'AcceptBudget':
                dispatch(startRequest(putAcceptBudgetByID(requestId, putAcceptBudgetByIDSuccess, handleError)))
                break;
            case 'RejectBudget':
                dispatch(startRequest(putRejectBudgetByID(requestId, putRejectBudgetByIDSuccess, handleError)))
                break;
            case 'ValidateQuestionnaire':
                dispatch(startRequest(putValidateQuestionnaireByID(requestId, putValidateQuestionnaireByIDSuccess, handleError)))
                break;
            case 'RejectQuestionnaire':
                dispatch(startRequest(putRejectQuestionnaireByID(requestId, putRejectQuestionnaireByIDSuccess, handleError)))
                break;
            case 'ApproveEvaluation':
                dispatch(startRequest(putApproveEvaluationByID(requestId, parseInt(numImprovementPlansSelected.id), putApproveEvaluationByIDSuccess, handleError)))
                break;
            case 'ApproveRenewal':
                handleApproveRenewal()
                break;
            case 'RejectRenewal':
                dispatch(startRequest(putRejectRenewalRequest(requestId, putRejectRenewalRequestSuccess, handleError)))
                break;
            case 'DeregisterCompany':
                handleDeactivateCompany()
                break;
            case 'ChangeToRenewal':
                dispatch(startRequest(putChangeRequestTypeToRenewal(requestId, putChangeRequestTypeToRenewalSuccess, handleError)))
                break;
            default:
                break;
        }
    }
    const handleApproveRenewal = () => {
        if(!requestId.numEmployees || requestId.numEmployees <= 0) {
            setshowError({
                state: true,
                message: 'Debes introducir un número de empleados válido'
            })
        } else if(!requestId.revenueVolume || requestId.revenueVolume <= 0) {
            setshowError({
                state: true,
                message: 'Debes introducir un volumen de ingresos válido. No se admiten letras, símbolos ni decimales.'
            })
        } else {
            dispatch(startRequest(putApproveRenewalRequest(requestId, putApproveRenewalRequestSuccess, handleError)))
        }
    }
    const handleDeactivateCompany = () => {
        if(!deregisterCompanySelected) {
            setshowError({
                state: true,
                message: 'Debes seleccionar una opción'
            })
        } else {
            const data = {
                companyId: requestId,
                deregisterCompanyTypeId: deregisterCompanySelected.id
            }
            dispatch(startRequest(deleteDeactivateCompany(data, deleteDeactivateCompanySuccess, handleError)))
        }
    }
    const handleError = (data) => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t(data.errors[0].errorMessageKey), 'error'));
    }
    const deleteDeactivateCompanySuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('DeregisterCompanySuccess'), 'success'));
        handleSubmit()
    }
    const putApproveRequestByIDSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('ApproveRequestSuccess'), 'success'));
        handleSubmit()
    }
    const putRejectRequestByIDSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('RejectRequestSuccess'), 'success'));
        handleSubmit()
    }
    const putAcceptBudgetByIDSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('AcceptBudgetSuccess'), 'success'));
        handleSubmit()
    }
    const putRejectBudgetByIDSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('RejectBudgetSuccess'), 'success'));
        handleSubmit()
    }
    const putValidateQuestionnaireByIDSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('ValidateQuestionnaireSuccess'), 'success'));
        handleSubmit()
    }
    const putRejectQuestionnaireByIDSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('RejectQuestionnaireSuccess'), 'success'));
        handleSubmit()
    }
    const putApproveEvaluationByIDSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('ApproveEvaluationSuccess'), 'success'));
        handleSubmit()
    }
    const putApproveRenewalRequestSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('ApproveRenewalSuccess'), 'success'));
        handleSubmit()
    }
    const putRejectRenewalRequestSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('RejectRenewalSuccess'), 'success'));
        handleSubmit()
    }    
    const putChangeRequestTypeToRenewalSuccess = () => {
        dispatch(closeModal())
        dispatch(showFixedLabel(t('ChangeRequestTypeToRenewalSuccess'), 'success'));
        handleSubmit()
    }

    return(
        <div className='request-detail-modal'>
            <form className='form-request-detail-modal'>
                {
                    action === 'AcceptBudget' 
                        ? isPrincipalCompany
                                ? <>
                                    <div style={{textAlign: 'center'}}>
                                        Si estás conforme con el presupuesto, activaremos el siguiente paso en el proceso de obtención del sello Madrid Excelente: el cuestionario de autoevaluación.
                                    </div>
                                    {
                                        requestType === 2
                                            ? <div className="text-danger text-center font-italic mt-2" dangerouslySetInnerHTML={{ __html: `Recuerda que debe estar finalizado <strong>10 días</strong> antes de la visita de evaluación.`}}></div>
                                            : <div className="text-danger text-center font-italic mt-2" dangerouslySetInnerHTML={{ __html: `Recuerda que debe estar finalizado <strong>10 días</strong> antes de la visita de evaluación y también que tienes que seleccionar a la entidad evaluadora.`}}></div>
                                    }                                    
                                </>
                                : <div style={{textAlign: 'center'}}>
                                    {
                                        requestType === 2
                                            ? 'Si estás conforme con el presupuesto, continuarás con el proceso de renovación del sello Madrid Excelente.'
                                            : 'Si estás conforme con el presupuesto, continuarás con el proceso de obtención del sello Madrid Excelente.'
                                    }
                                </div>
                        : null
                }
                {
                    action === 'RejectBudget' ? (
                        <div style={{textAlign: 'center'}}>
                            ¿Hay algo que no te convence? Puedes ponerte en contacto con madridexcelente@madridexcelente.com para contarnos tus dudas, o finalizar aquí el proceso de obtención del sello Madrid Excelente.
                        </div>
                    ) : null
                }
                {
                    action === 'ApproveRenewal' ? (
                        <div style={{textAlign: 'center'}}>
                            Gracias por seguir confiando en Madrid Excelente. Te avisaremos cuando esté disponible tu presupuesto.
                        </div>
                    ) : null
                }
                {
                    action === 'RejectRenewal' ? (
                        <>                        
                            <div style={{textAlign: 'center'}}>
                                ¿Deseas cancelar la renovación del sello Madrid Excelente?
                            </div>
                            <div className="text-danger text-center font-italic mt-2" dangerouslySetInnerHTML={{ __html: `Deberás retirar el distintivo de todos los materiales (físicos y digitales) relacionados con tu organización.`}}></div>
                        </>
                    ) : null
                }
                {
                    action === 'ApproveEvaluation' ? (
                        <div className="combo-modal-center">
                            <Combo
                                id="numImprovementePlans"
                                dataTestID="numImprovementePlans"
                                inputValue={numImprovementPlansSelected}
                                options={numImprovementPlansOptions}
                                isSearchable={false}
                                comboLabel="Número de planes de mejora"
                                handleChange={(e) => {
                                    setshowError({
                                        ...showError,
                                        state: false
                                    })
                                    setNumImprovementPlansSelected(e)
                                }}
                                isMultiple={false}
                                isRequired={false}
                                comboPlaceholder={t('Seleccionar')}
                            />
                        </div>
                    ) : null
                }
                {
                    action === 'DeregisterCompany' ? (
                        <div className="combo-modal-center">
                            <Combo
                                id="deregisterCompanyCombo"
                                dataTestID="deregisterCompanyCombo"
                                defaultInputValue=""
                                options={deregisterCompanyOptions}
                                isSearchable={false}
                                isClearable={true}
                                handleChange={(e) => {
                                    setshowError({
                                        ...showError,
                                        state: false
                                    })
                                    setDeregisterCompanySelected(e)
                                }}
                                inputValue={deregisterCompanySelected}
                                // comboLabel="Causa de la desactivación"
                                isMultiple={false}
                                comboPlaceholder={t('Seleccionar')}
                                isDisabled={false}
                            />
                        </div>
                    ) : null
                }
                {
                    action === 'ChangeToRenewal' ? (
                        <>                        
                            <div style={{textAlign: 'center'}}>
                                ¿Deseas cambiar la solicitud a renovación?
                            </div>
                            <div className="text-danger text-center font-italic mt-2" dangerouslySetInnerHTML={{ __html: `Esta acción no se puede deshacer.`}}></div>
                        </>
                    ) : null
                }
                {
                    showError.state &&
                        <div className="text-danger" style={{textAlign: 'center'}}>{showError.message}</div>
                }
                <div className='btn-submit-container row mt-4'>
                    <div className='btn-submit-wrapper col-lg-12 d-flex justify-content-center'>
                        <Button
                            btnText="Confirmar"
                            btnType="submit"
                            isDisabled={false}
                            btnClass="btn-base_normal "
                            dataTestID="btnAuthorize"
                            id='btnAuthorize'
                            onHandleBtnClick={(e) => postAuthorize(e)}
                        />
                        <Button
                            dataTestID="btnCancel"
                            btnText={t('Cancel')}
                            btnType="button"
                            btnClass="btn-base_outline ml-3"
                            onHandleBtnClick={() => {
                                dispatch(closeModal());
                            }}
                        />                        
                    </div>
                </div>
            </form>
        </div>
    )
}








