import React from 'react'
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { useTranslation } from 'react-i18next';
import { parseDate } from '../../../../common/utils/parseUtils';

const CompaniesInfoGrid = ({
    companiesData,
    grid,
    pageSettings,
    actionsTemplate,
	companySizeType,
	companyDataSectorTypology
}) => {

    const {t} = useTranslation();

	const dateTemplate = (rowData) => {
		let date = ''
		if( rowData.currentCompanyInfoHistory ) {
			date = parseDate(rowData.currentCompanyInfoHistory.creationDate)
		}
		return (
			<span>{date}</span>
		)
	}

	const sizeTemplate = (rowData) => {
		let name = '';
		if( rowData.currentCompanyInfoHistory ) {
			name = companySizeType.filter(el => el.id === rowData.currentCompanyInfoHistory.companySizeTypeId)[0].label
		}

		return (
			<span>{name}</span>
		)
	}

	const sectorTemplate = (rowData) => {
		let name = '';
		if( rowData.currentCompanyInfoHistory ) {
			name = companyDataSectorTypology.filter(el => el.id === rowData.currentCompanyInfoHistory.sectorTypeId)[0].label
		}

		return (
			<span>{name}</span>
		)
	}
	
	const publicTemplate = (rowData) => {
		return (
			rowData.currentCompanyInfoHistory
				? rowData.currentCompanyInfoHistory.isPublicCompany
					? <span>{t('Public')}</span>
					: <span>{t('Private')}</span>
				: <span></span>
		)
	}

    return (
        <div className='request-grid row'>
			<div className="col-md-12">
				<div className='grid-wrapper' style={{ flex: "1", width: "100%", height: "100%" }}>
					<GridComponent
						className="gridTable"
						dataSource={companiesData}
						width="100%"
						toolbar={false}
						ref={grid}
						allowExcelExport={true}
						allowPaging={true}
						enableAdaptiveUI={true}
						allowSorting={true}
						rowRenderingMode={'Vertical'}
						pageSettings={pageSettings}
						allowResizing={true}
						locale="es"
					>
						<ColumnsDirective>
							<ColumnDirective field="id" width="0" visible={false} />
							<ColumnDirective 
								field="infoData.socialName" 
								headerText={t('Company')}  
								textAlign="Left"
								width="120" 
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.creationDate" 
								headerText={t('DataDate')}  
								textAlign="Left"
								width="80" 
								template={dateTemplate}
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.numEmployees" 
								headerText={t('Employees')}  
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.incomeQuantity" 
								headerText={t('Revenue')}  
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.companySizeTypeId" 
								headerText={t('Size')}  
								textAlign="Left"
								// width="120" 
								template={sizeTemplate}
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.sectorTypeId" 
								headerText={t('CompanySector')}  
								textAlign="Left"
								width="80" 
								template={sectorTemplate}
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.town" 
								headerText={t('Town')}  
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.region" 
								headerText={t('Province')}  
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="currentCompanyInfoHistory.isPublicCompany" 
								headerText={t('Public/Private')}  
								textAlign="Left"
								width="80" 
								template={publicTemplate}
								visible={true}
							/>
							<ColumnDirective
								field="actions"
								headerText=''
								template={actionsTemplate}
								textAlign="Right"
								width="50"
							/>
						</ColumnsDirective>
						<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
					</GridComponent>
					{/* <div className="export-excel-wrapper">
						<div className="col-lg-12">
							<Button
								btnText={t('ExportExcel')}
								btnType="button"
								btnClass="btn-base_normal export-excel-button"
								onHandleBtnClick={() => {
									grid.current.excelExport();
								}}
							/>
						</div>
					</div> */}
				</div>
			</div>
		</div>
    )
}

export default CompaniesInfoGrid