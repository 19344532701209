const getRequestDetailByID = (id, funcSucc) => {
    const options = {
        method: 'GET',
        endpoint: `/Request/Detail/${id}`,
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const getUserLoggedParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetUserLogged',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAllAuditorCompaniesParams = ( SuccessFn) => {
    const options = {
        method: 'GET',
		endpoint: '/Company/GetAllAuditorCompanies',
		onSuccess: (response) => {
            SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAuditorUsersByAuditorCompanyIdParams = ( id, SuccessFn) => {
    const options = {
        method: 'GET',
        endpoint: `/UserManagement/GetAuditorUsersByAuditorCompanyId/${id}`,
        onSuccess: (response) => {
            SuccessFn(response.data.data);
        }
    };
    return options;
};

const putApproveRequestByID = (id, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/ApproveRequest/${id}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}
const putRejectRequestByID = (id, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/RejectRequest/${id}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const putAcceptBudgetByID = (id, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/AcceptBudget/${id}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}
const putRejectBudgetByID = (id, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/RejectBudget/${id}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const putValidateQuestionnaireByID = (id, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/ValidateQuestionnaire/${id}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}
const putRejectQuestionnaireByID = (id, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/RejectQuestionnaire/${id}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}
const putApproveEvaluationByID = (id, numImprovementPlans, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/ApproveEvaluation/${id}/${numImprovementPlans}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}
const putApproveRenewalRequest = (data, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        data: data,
        endpoint: `/Request/ApproveRenewalRequest/`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}
const putRejectRenewalRequest = (id, funcSucc, funcError) => {
    const options = {
        method: 'PUT',
        endpoint: `/Request/RejectRenewalRequest/${id}`,
        onError: (response) => {
			funcError(response.data);
		},
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

//Files
const postBudgetMediaParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/Request/UploadBudgetMedia',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};
const postContractsMediaParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/Request/UploadContractsMedia',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};
const postSealMediaParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/Request/UploadSealMedia',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};
const postEvaluationMediaParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/Request/UploadEvaluationMedia',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};

const postEvaluationPuntuationsMediaParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/Request/UploadEvaluationPuntuationsMedia',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};

const postSaveDetail = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Request/SaveDetail',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};

const getContractStatusTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/ContractsStatusType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getDeregisterCompanyType = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/DeregisterCompanyType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteDeactivateCompany = (data, SuccessFn) => {
	const options = {
		method: 'DELETE',
        data: data,
		endpoint: '/Company/DeactivateCompany',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postReactivateCompany = (id, SuccessFn) => {
	const options = {
		method: 'POST',
		endpoint: `/Company/ReactivateCompany/${id}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const putChangeRequestTypeToRenewal = (id, SuccessFn) => {
	const options = {
		method: 'PUT',
		endpoint: `/Request/ChangeRequestTypeToRenewal/${id}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export{
    getRequestDetailByID,
    getUserLoggedParams,
    getAllAuditorCompaniesParams,
    getAuditorUsersByAuditorCompanyIdParams,
    putApproveRequestByID,
    putRejectRequestByID,
    putAcceptBudgetByID,
    putRejectBudgetByID,
    putValidateQuestionnaireByID,
    putRejectQuestionnaireByID,
    putApproveEvaluationByID,
    postBudgetMediaParams,
    postContractsMediaParams,
    postEvaluationMediaParams,
    postEvaluationPuntuationsMediaParams,
    postSealMediaParams,
    postSaveDetail,
    getContractStatusTypeParams,
    putApproveRenewalRequest,
    putRejectRenewalRequest,
    getDeregisterCompanyType,
    deleteDeactivateCompany,
    postReactivateCompany,
    putChangeRequestTypeToRenewal
}

