const getAllUsers = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetAllUsers',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postUserManagementParams = (data, funcSucc, funcError) => {
	const options = {
		method: 'POST',
		endpoint: '/UserManagement/CreateUser',
		data,
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
		onError: (response) => {
			funcError(response.data);
		}
	};
	return options;
};

const putUserManagementParams = (data, funcSucc, funcError) => {
	const options = {
		method: 'PUT',
		endpoint: '/UserManagement',
		data,
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
		onError: (response) => {
			funcError(response.data);
		}
	};
	return options;
};

const getAllRoles = ( SuccessFn ) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetAllRoles',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAllRolesExceptCompany = ( SuccessFn ) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetAllRolesExceptCompany',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAllAuditorCompanies = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Company/GetAllAuditorCompanies',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteUserParams = (params, funcSucc) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/UserManagement',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		}
	};
	return options;
};

const postResentEmailParams = (data, funcSucc) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Account/ResendConfirmEmail',
		onSuccess: (response) => { funcSucc(response.data.data) }
	};
	return options;
}

const getUserCompaniesParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagementCompany/GetUserCompanies',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAllUserCompanies = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagementCompany/GetAllUsers',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteUserCompanyParams = (userId, companyId, funcSucc) => {
	const options = {
		method: 'DELETE',
		endpoint: `/UserManagementCompany/DeleteUserCompany/${userId}/${companyId}`,
		onSuccess: (response) => {
			funcSucc(response.data.data);
		}
	};
	return options;
};

const postUserManagementCompanyParams = (data, funcSucc, funcError) => {
	const options = {
		method: 'POST',
		endpoint: '/UserManagementCompany/CreateUser',
		data,
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
		onError: (response) => {
			funcError(response.data);
		}
	};
	return options;
};

const putUserManagementCompanyParams = (data, funcSucc, funcError) => {
	const options = {
		method: 'PUT',
		endpoint: '/UserManagementCompany',
		data,
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
		onError: (response) => {
			funcError(response.data);
		}
	};
	return options;
};

const postUploadUserAuditorCVParams = (data, funcSucc, funcError) => {
	const options = {
		method: 'POST',
		endpoint: '/UserManagement/UploadUserAuditorCV',
		contentType: 'multipart/form-data',
		data,
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
		onError: (response) => {
			funcError(response.data);
		}
	};
	return options;
};

export { 
	getAllUsers, 
	getAllRoles, 
	postUserManagementParams, 
	putUserManagementParams,
	getAllAuditorCompanies,
	deleteUserParams,
	getAllRolesExceptCompany,
	getAllUserCompanies,
	postResentEmailParams,
	deleteUserCompanyParams,
	postUserManagementCompanyParams,
	putUserManagementCompanyParams,
	getUserCompaniesParams,
	postUploadUserAuditorCVParams
};