import React, { useRef, useState } from "react";
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import CookiesConfigModal from "./CookiesConfigModal/CookiesConfigModal";
import { AcceptCookiesAnalytics, DeleteCookiesAnalytics } from "../../common/utils/cookiesManager";
import { COOKIES_CONSTANTS } from "../../common/constants/cookies";
import './CookiesConsent.scss';

const CookiesConsent = () => {

    const ref = useRef();
    const [configModalOpen, setConfigModalOpen] = useState(false);
    const [switchAnalytic, setSwitchAnalytic] = useState(true);
    const [switchMarketing, setSwitchMarketing] = useState(true);

    const handleConfigModalOpen = () => {
        setConfigModalOpen(true);
    };
    
    const handleConfigModalClose = () => {
        ref.current.props.onAccept();
        Cookies.set(COOKIES_CONSTANTS.CONSENT_COOKIES, true, { expires: 365 });
        Cookies.set(COOKIES_CONSTANTS.CONSENT_COOKIES_ANALYTICS, switchAnalytic, { expires: 365 });
        Cookies.set(COOKIES_CONSTANTS.CONSENT_COOKIES_MARKETING, switchMarketing, { expires: 365 });
        if(!switchAnalytic) {
            DeleteCookiesAnalytics('Analytics')
        } else {
            AcceptCookiesAnalytics('Analytics')
        }
        
        if(!switchMarketing) {
            DeleteCookiesAnalytics('Marketing')
        }else {
            AcceptCookiesAnalytics('Marketing')
        }
        setConfigModalOpen(false);
    };

    return (
        <>        
            <CookieConsent
                    ref={ref}
                    location='bottom'
                    buttonText='Aceptar todo'
                    enableDeclineButton
                    declineButtonText='Configurar cookies'
                    setDeclineCookie={false}
                    children={<div>Modal</div>}
                    containerClasses="cookie-consent-container"
                    buttonWrapperClasses="cookie-consent-buttons"
                    contentClasses="cookie-consent-content"
                    declineButtonClasses="btn-decline-cookie-consent"
                    buttonClasses="btn-accept-cookie-consent"
                    onAccept={() => {
                        Cookies.set(COOKIES_CONSTANTS.CONSENT_COOKIES_ANALYTICS, switchAnalytic, { expires: 365 });
                        Cookies.set(COOKIES_CONSTANTS.CONSENT_COOKIES_MARKETING, switchMarketing, { expires: 365 });
                        if(!switchAnalytic) {
                            DeleteCookiesAnalytics('Analytics')
                        } else {
                            AcceptCookiesAnalytics('Analytics')
                        }
                        
                        if(!switchMarketing) {
                            DeleteCookiesAnalytics('Marketing')
                        }else {
                            AcceptCookiesAnalytics('Marketing')
                        }
                    }}
                    onDecline={handleConfigModalOpen}
                    overlay
                >
                    Usamos cookies en nuestro sitio web para brindarle la experiencia más relevante recordando sus preferencias y visitas repetidas. Al hacer clic en "Aceptar todo", acepta el uso de TODAS las cookies. Sin embargo, puede visitar "Configuración de cookies" para proporcionar un consentimiento controlado.
            </CookieConsent>

            {
                configModalOpen &&
                    <CookiesConfigModal 
                        switchAnalytic={switchAnalytic}
                        setSwitchAnalytic={setSwitchAnalytic}
                        switchMarketing={switchMarketing}
                        setSwitchMarketing={setSwitchMarketing}
                        onClose={handleConfigModalClose} 
                    />
            }
        </>
    );
};

export default CookiesConsent;