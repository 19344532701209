import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { startRequest } from '@backoffice/actions/apiCall';
import { getCompanySizeTypeParams, postCompaniesInfoParams } from './CompaniesInfoCalls.js/CompaniesInfoCalls';
import { ROUTE } from '../../../common/constants/routes';
import CompaniesInfoGrid from './CompaniesInfoGrid/CompaniesInfoGrid';
import { Button, Combo, InputAutoSuggest } from '../../../components/FormComponents';
import { getSectorTypeParams } from '../../Pages/Request/RequestCalls/RequestCalls';
import { MUNICIPIOS } from '../../../common/constants/municipios';
import { PROVINCIAS } from '../../../common/constants/provincias';
import './CompaniesInfo.scss';
import { orderComboByText } from '../../../common/utils/parseUtils';
import { getClientCompaniesParams } from '../Companies/CompaniesCalls/CompaniesCalls';

const CompaniesInfo = () => {

    const pageSettings = { pageSize: 10 };
	const grid = useRef(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState(true)
	const [companiesData, setCompaniesData] = useState([])

	//Como companyId
	const [companiesOptions, setCompaniesOptions] = useState([])
	const [companyComboSelected, setCompanyComboSelected] = useState()

	// Combo company size
	const [companySizeType, setCompanySizeType] = useState([])
	const [companySizeSelectedType, setCompanySizeSelectedType] = useState(null)

	// Combo sector type
	const [companyDataSectorTypology, setCompanyDataSectorTypology] = useState([])
	const [companyDataSectorTypologySelected, setCompanyDataSectorTypologySelected] = useState(null)

	// Combo public/private
	const [companyPublicSelected, setCompanyPublicSelected] = useState(null)
	
	// Autosuggest towns
	const [autoSuggestTown, setAutoSuggestTown] = useState('')

	// Autosuggest province
	const [autoSuggestProvince, setAutoSuggestProvince] = useState('')

	const optionsPublic = [
		{
			id: 1,
			value: true,
			label: t('Public')
		},
		{
			id: 2,
			value: false,
			label: t('Private')
		}
	]

	useEffect(() => {
		getCompanySizeType()
		getClientCompanies()
	}, [])

	useEffect(() => {
		getCompaniesInfo()
	}, [
		companyComboSelected,
		companySizeSelectedType,
		companyDataSectorTypologySelected,
		companyPublicSelected,
		autoSuggestTown,
		autoSuggestProvince
	])

	const getCompaniesInfo = () => {
		const filtersInit = {
			filters: {
				companyId: companyComboSelected ? companyComboSelected.id : '',
				companySizeTypeId: companySizeSelectedType ? companySizeSelectedType.id : '',
				sectorTypeId: companyDataSectorTypologySelected ? companyDataSectorTypologySelected.id : '',
				town: autoSuggestTown ? autoSuggestTown : '',
				region: autoSuggestProvince ? autoSuggestProvince : ''
			}
		};

		if( companyPublicSelected ) {
			filtersInit.filters.isPublicCompany = companyPublicSelected.value;
		}

        dispatch(startRequest(postCompaniesInfoParams(filtersInit, getCompaniesInfoSuccess)))
    }

	const getCompaniesInfoSuccess = (data) => {
		setCompaniesData(data);
	}

	const getCompanySizeType = () => {
		dispatch(startRequest(getCompanySizeTypeParams(getCompanySizeTypeParamsSuccess)))
	}

	const getClientCompanies = () => {
		dispatch(startRequest(getClientCompaniesParams(getClientCompaniesParamsSuccess)))
	}

	const getClientCompaniesParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name,
				isCompanyPrincipal: el.isCompanyPrincipal
            });
        });
		options = orderComboByText(options);
		setCompaniesOptions(options)
	}

	const getCompanySizeTypeParamsSuccess = (data) => {
		let options = [];

		
        data.forEach((el) => {
			let label = "";
			switch (el.name) {
				case "Grupo 1":
					label = `${el.name}: < 10 empleados y ≤ 2 millones`
					break;

				case "Grupo 2":
					label = `${el.name}: < 50 empleados y/o < 10 millones`
					break;

				case "Grupo 3":
					label = `${el.name}: < 250 empleados y/o < 50 millones`
					break;

				case "Grupo 4":
					label = `${el.name}: ≥ 250 empleados y ≥ 50 millones`
					break;

				case "Grupo 5":
					label = `${el.name}: > 500 empleados y/o > 200 millones`
					break;

				case "Grupo 6":
					label = `${el.name}: > 5000 empleados y/o > 1.000 millones`
					break;
			
				default:
					break;
			}

			options.push({
                id: el.id,
                value: el.name,
                label: label
            });
        });
		setCompanySizeType(options)
		getSectorType()
	}

	const getSectorType = () => {
		dispatch(startRequest(getSectorTypeParams(getSectorTypeParamsSuccess)))
	}

	const getSectorTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name,
                description: el.description
            });
        });
        setCompanyDataSectorTypology(options)
		setIsLoading(false);
	}

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					role='navigation'
					aria-hidden={true}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() => handleRedirectFromGrid(rowData)}
					type="button"
				>
					mode_edit
				</span>
			</div>
		);
	};

	const handleRedirectFromGrid = (data) => {
		history.push({
			pathname: ROUTE.COMPANIESINFODETAIL.replace(':id', data.id)
		})
	}

	const onHandleChangeCompanySize = (e) => {
		setCompanySizeSelectedType(e)
	}

	const onHandleChangeCompanySector = (e) => {
		setCompanyDataSectorTypologySelected(e)
	}

	const onHandleChangeCompanyPublic = (e) => {
		setCompanyPublicSelected(e)
	}

	const onHandleChangeCompanyTown = (e) => {
		setAutoSuggestTown(e)
	}

	const onHandleChangeCompanyProvince = (e) => {
		setAutoSuggestProvince(e)
	}

	const resetFilters = () => {
		setCompanyComboSelected([])
		setCompanySizeSelectedType(null)
		setCompanyDataSectorTypologySelected(null)
		setCompanyPublicSelected(null)
		setAutoSuggestTown('')
		setAutoSuggestProvince('')
	}

    return (
        <div className='companies-info'>
			<div className='companies-info-filters'>
				<Combo 
					id="Entity"
					data-testid="Entity"
					defaultInputValue=""
					options={companiesOptions}
					isSearchable={true}
					isClearable={true}
					className="comboBox"
					handleChange={(e) => {
						setCompanyComboSelected(e)
					}}
					inputValue={companyComboSelected}
					isMultiple={false}
					comboLabel={t('Company')}
					comboPlaceholder={t('Company')}
				/>

				<Combo 
					id="CompanySize"
					data-testid="CompanySize"
					defaultInputValue=""
					options={companySizeType}
					isSearchable={false}
					isClearable={true}
					className="comboBox"
					handleChange={onHandleChangeCompanySize}
					inputValue={companySizeSelectedType}
					isMultiple={false}
					comboLabel={t('Size')}
					comboPlaceholder={t('Size')}
				/> 

				<Combo 
					id="CompanySector"
					data-testid="CompanySector"
					defaultInputValue=""
					options={companyDataSectorTypology}
					isSearchable={false}
					isClearable={true}
					className="comboBox"
					handleChange={onHandleChangeCompanySector}
					inputValue={companyDataSectorTypologySelected}
					isMultiple={false}
					comboLabel={t('CompanySector')}
					comboPlaceholder={t('CompanySector')}
				/> 

				<Combo 
					id="CompanyPublic"
					data-testid="CompanyPublic"
					defaultInputValue=""
					options={optionsPublic}
					isSearchable={false}
					isClearable={true}
					className="comboBox"
					handleChange={onHandleChangeCompanyPublic}
					inputValue={companyPublicSelected}
					isMultiple={false}
					comboLabel={t('Public/Private')}
					comboPlaceholder={t('Public/Private')}
				/>

				<InputAutoSuggest 
					inputLabel={t('Town')}
					defaultValue={autoSuggestTown}
					placeholderInput={t('Town')}
					onHandleChange={(value) => onHandleChangeCompanyTown(value)}
					filterFunction={(inputValue) => MUNICIPIOS.filter((el) => el.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))}
				/>

				<InputAutoSuggest 
					inputLabel={t('Province')}
					defaultValue={autoSuggestProvince}
					placeholderInput={t('Province')}
					onHandleChange={(value) => onHandleChangeCompanyProvince(value)}
					filterFunction={(inputValue) => PROVINCIAS.filter((el) => el.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))}
				/>

				<Button
					dataTestID="btnResetFilters"
					btnText={t('ResetFilters')}
					btnType="button"
					btnClass={'btn-base_normal'}
					onHandleBtnClick={resetFilters}
				/>
			</div>
			
			{
				!isLoading &&
					<CompaniesInfoGrid 
						companiesData={companiesData}
						grid={grid}
						pageSettings={pageSettings}
						actionsTemplate={actionsTemplate}
						companySizeType={companySizeType}
						companyDataSectorTypology={companyDataSectorTypology}
					/>
			}
		</div>
    )
}

export default CompaniesInfo