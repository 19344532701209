import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Logo from '../../../../assets/logos/me_azul2.png';
import './QuestionnairesDetail.scss';
import Radio from '../../../../components/FormComponents/RadioButton/Radio';
import Textarea from '../../../../components/FormComponents/Textarea';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import { ROUTE } from '../../../../common/constants/routes';
import { startRequest } from '@backoffice/actions/apiCall';
import FixedLabel from '../../../../shared/FixedLabel/FixedLabel';
import { getQuestionnaireDetailsParams, getUserLoggedParams } from '../QuestionnairesCalls/QuestionnairesCalls';

const QuestionnairesDetail = (props) => {
    const history = useHistory()
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const [requestId, setRequestId] = useState('')
    const [userLogedName, setUserLogedName] = useState('')
    const [asideTop, setAsideTop] = useState(190)
    const [asideHeight, setAsideHeight] = useState(188)
    const [rol, setRol] = useState('')

    const [selfAssessmentData, setSelfAssessmentData] = useState({})


    useEffect(() => {
        if(history.location.state){
            setRequestId(history.location.state.id)
        }else{
            //get id from params
            const params = window.location.pathname
            setRequestId(params.split('/')[2])
        }
        //#1
        getUserLoged();
    }, [])

    const getUserLoged = () =>{
        dispatch(startRequest(getUserLoggedParams(getUserLoggedParamsSuccess)))
	}

    const getUserLoggedParamsSuccess = (data) => {
        setRol(data.userRoles[0].name)
        //setRol('Auditor')
        setUserLogedName(data.name)
    }

    useEffect(() => {
        if(requestId !== '') {
            //#2
            getSelfAssessment()
        }
    }, [requestId])

    const [loadOk, setLoadOk] = useState(false)

    const getSelfAssessment = () => {
        if(requestId !== ''){
            dispatch(startRequest(getQuestionnaireDetailsParams(requestId, getQuestionnaireDetailsParamsSuccess)))
        } else {
            dispatch(showFixedLabel(t('GenericSelfAssessmentError'), 'error'))
        }
    }

    const getQuestionnaireDetailsParamsSuccess = (data) => {
        setSelfAssessmentData(data)
        setLoadOk(true)
    }

    const calcHeight = (sections, navLi, aside) => {
        let current = "";
        setAsideTop(190)
        let control = 0;
        //TODO Refactor foreach
        sections.forEach((section, index) => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.clientHeight;
            
            if (index === 0 && control === 0) {
                //introduction
                if (parseInt(Math.ceil(window.scrollY))  <= sectionHeight + sectionTop){
                    current = section.getAttribute("id");
                    if(aside.querySelector('.menu-list__item.active')){
                        aside.querySelector('.menu-list__item.active').scrollIntoView();
                    }
                    control = 1;
                }
            } else if(control === 0) {
                if (parseInt(Math.ceil(window.scrollY + 2)) >= sectionTop && parseInt(Math.ceil(window.scrollY + 2)) < sectionTop + sectionHeight){
                    current = section.getAttribute("id");
                    if(aside.querySelector('.menu-list__item.active')){
                        aside.querySelector('.menu-list__item.active').scrollIntoView();
                    }
                    control = 1;
                }
            }
        });

        if(window.scrollY > 80){
            setAsideTop(15)
            setAsideHeight(72)
        }
        
        navLi.forEach((li) => {
            li.classList.remove("active");
            if (li.classList.contains(current)) {
                li.classList.add("active");
            }
        });
    }

    //UI Effects
    useEffect(() => {
        if(loadOk) {
            const sections = document.querySelectorAll(".selfassessment-content .anchor-container");
            const navLi = document.querySelectorAll(".menu-list.ul .menu-list__item");
            const aside = document.querySelector('aside')
            window.addEventListener("scroll", () => calcHeight(sections, navLi, aside));
        }

        return () => {
            const sections = document.querySelectorAll(".selfassessment-content .anchor-container");
            const navLi = document.querySelectorAll(".menu-list.ul .menu-list__item");
            const aside = document.querySelector('aside')
            window.removeEventListener("scroll", () => calcHeight(sections, navLi, aside))
        }
    }, [loadOk])
    
    return (
        <div className='questionnaire-detail'>
            <FixedLabel parentType="general" />
            <form className='selfassessment-form'>
                <div className='selfassessment__heading'>
                    <div className='heading-logo-container '>
                        <h1 className='logo-wrapper'>
                            <img className='logo-img' src={Logo} alt="logo madrid excelente" />
                        </h1>
                        <span className='sr-only' aria-labelledby='returnButton'>Volver atrás</span>
                         <button className='return-wrapper'
                            id='returnButton'
                            role='navigation'
                            onClick={() => {
                                history.push({
                                    pathname: ROUTE.QUESTIONNAIRES,
                                })
                            }}
                        >
                            <span className='material-icons' aria-hidden={true}>arrow_back</span>
                            <span className='return-link'>
                                {t('GoBack')}
                            </span>
                        </button>
                    </div>
                    <div className='user-container'>
                        <div className='help-wrapper' >
                            <a className='return-wrapper' href='https://www.madridexcelente.com/' role='button'>
                                <span className='material-icons' aria-hidden={true}>arrow_back</span> 
                                <span className='return-link'>
                                    Volver a la web de Madrid Excelente
                                </span>
                            </a>
                        </div> 
                        <div className='user-wrapper'>
                            <span className='user-text'>{userLogedName}</span>
                        </div>
                    </div>
                </div>
                <div className='flex-container'>
                    <aside className='selfassessment-aside'
                        style={{
                            top: `${asideTop}px`,
                            height: `calc(100vh - ${asideHeight}px)`
                        }}
                    >
                        <div className='aside-content'>
                            <div className='menu-wrapper'>
                                <div className='menu-list ul'>
                                        {
                                            selfAssessmentData.asides ?  selfAssessmentData.asides.map((itemAside, indexSecondLevel) => (
                                                    <div key={indexSecondLevel} className={`menu-list__item ${itemAside.titleAnchorId}`}>
                                                        <div className='item-wrapper'>
                                                            <a href={`#${itemAside.titleAnchorId}`} className='item-wrapper__title' aria-labelledby={itemAside.titleAsideId}>{itemAside.title}</a>
                                                            {
                                                                itemAside.subasides.map((subasideItem, indexThirdLevel) => (
                                                                    <div key={indexThirdLevel} className='item-wrapper__content'>
                                                                        <a href={`#${subasideItem.subAnchorId}`} className='sub-title'>{subasideItem.subtitle}</a>
                                                                        <div className='points-container'>
                                                                            <div className='answer-rate'>{subasideItem.totalAnswered.split('/')[1]}</div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                )

                                            ) : null
                                        }

                                </div>
                            </div>
                        </div>
                    </aside>
                    <section className='selfassessment-content'>
                        <div className='row-container'>
                            <div className='selfassessment-wrapper'>
                                <div className='1 selfassessment-wrapper__item'>
                                    {
                                        selfAssessmentData.contents ? selfAssessmentData.contents.map((itemContent, indexSecondLevel) => (
                                            <div id={itemContent.titleAsideAnchorId} key={indexSecondLevel} className='2 questions-wrapper anchor-container'>
                                                <div className='block-item'>
                                                    <div className='title-wrapper'>
                                                        <h3 id={itemContent.titleAsideId} className="title-3">{itemContent.titleAside}</h3>
                                                    </div>
                                                    {
                                                        itemContent.subasidesContent.map((questions, indexThirdLevel) => (
                                                            <div className='3' key={indexThirdLevel}>
                                                                <div className='area-title-wrapper'>
                                                                    <h4 id={questions.subtitleAnchorId} className="title-4">
                                                                        {questions.subtitle}
                                                                    </h4>
                                                                </div>
                                                                <div className='content-wrapper'>
                                                                    {
                                                                        questions.subasidesDetail.map((subasideDetail, indexFourthLevel) => (
                                                                            <div key={indexFourthLevel} className="4">
                                                                                <div className='content-title'>
                                                                                    <h5 className='title-5'>{subasideDetail.title}</h5>
                                                                                </div>
                                                                                <div className='questions-wrapper'>
                                                                                     <div className='radios-wrapper'>
                                                                                        {
                                                                                            subasideDetail.questions.map((question, indexFiveLevel) => (
                                                                                                <fieldset className={`5 questions ${question.answerType ? 'area-question' : ''}`} key={indexFiveLevel}>
                                                                                                    <div className='questions-heading'>
                                                                                                        <div className='content-title'>
                                                                                                            <label 
                                                                                                                role='heading' 
                                                                                                                aria-level='6' 
                                                                                                                className='title-6'
                                                                                                                dangerouslySetInnerHTML={{ __html: question.questionTitle}}
                                                                                                            />
                                                                                                        </div>
                                                                                                     </div>
                                                                                                    {
                                                                                                        question.answerType === 0 ? question.answers.map((answer, indexAnswer) => (
                                                                                                            <div key={indexAnswer} className='6 radio-container'>
                                                                                                                <div className='company-column'>
                                                                                                                    <div className={`points`}>
                                                                                                                        {answer.rate}
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        question.answerType === 0 ? (
                                                                                                                            <Radio
                                                                                                                                id={answer.id}
                                                                                                                                label={answer.title}
                                                                                                                                name={question.titleName}
                                                                                                                                value={answer.title}
                                                                                                                                isDisabled={true}
                                                                                                                                handleChecked={false}
                                                                                                                            />

                                                                                                                        ) : null
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {/* <div className='auditor-column'>
                                                                                                                    <Radio
                                                                                                                        id={answer.id + 'Auditor'}
                                                                                                                        label={' '}
                                                                                                                        name={question.titleName + 'Auditor'}
                                                                                                                        value={false}
                                                                                                                        isDisabled={true}
                                                                                                                        classList={'hideLabel'}
                                                                                                                        handleChecked={false}
                                                                                                                        handleChange={() => {}}
                                                                                                                        //dataId={questions.subtitleAnchorId}
                                                                                                                    />
                                                                                                                    <div className={`points`}>
                                                                                                                        {answer.rate}
                                                                                                                    </div>
                                                                                                                </div> */}
                                                                                                            </div>

                                                                                                        )) : (
                                                                                                            <div className='form-input-group input-group'>
                                                                                                                <div className="form-wrapper">
                                                                                                                    <Textarea
                                                                                                                        inputLabel={question.questionTitle}
                                                                                                                        //id="notAllOrganizationDescription"
                                                                                                                        className="textarea-description"
                                                                                                                        isDisabled={true}
                                                                                                                        onHandleChange={() => {}}
                                                                                                                        inputValue={question.otherAnswer}
                                                                                                                        max={500}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className='radio-container'>
                                                                                                                {/* {
                                                                                                                    question.answers.map((answer, indexAnswer) => (
                                                                                                                        <div key={indexAnswer} className='auditor-column'>
                                                                                                                            <Radio
                                                                                                                                id={answer.id + 'Auditor'}
                                                                                                                                label={' '}
                                                                                                                                name={question.titleName + 'Auditor'}
                                                                                                                                value={false}
                                                                                                                                isDisabled={true}
                                                                                                                                classList={'hideLabel'}
                                                                                                                                handleChecked={false}
                                                                                                                                handleChange={() => {}}
                                                                                                                            />
                                                                                                                            <div className={`points`}>
                                                                                                                                {answer.rate}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    ))
                                                                                                                } */}
                                                                                                            </div> 
                                                                                                        </div>

                                                                                                        )
                                                                                                    }

                                                                                                </fieldset>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )) : null
                                    }
                                </div>
                            </div>
                        </div>                                              
                    </section>
                </div>
            </form>
        </div>
    )
}

export default QuestionnairesDetail;