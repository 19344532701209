import { L10n } from '@syncfusion/ej2-base';
import { getSyncfusionTranslations } from './SyncfusionTranslations';
import { GetCookie_culture } from '../common/utils/cookiesManager';
import { loadCldr, setCulture } from '@backoffice/modules/@syncfusion/ej2-base';
import * as ej2Base from '@syncfusion/ej2-base';

const loadLanguage = () => {
	ej2Base.L10n.load(getSyncfusionTranslations());
	L10n.load(getSyncfusionTranslations());
	let cookieCulture = GetCookie_culture();
	if (cookieCulture === 'es') {
		ej2Base.loadCldr(
			require('cldr-data/supplemental/numberingSystems.json'),
			require('cldr-data/main/es/ca-gregorian.json'),
			require('cldr-data/main/es/ca-generic.json'),
			require('cldr-data/main/es/numbers.json'),
			require('cldr-data/main/es/timeZoneNames.json'),
			require('cldr-data/supplemental/weekData.json')
		);
		loadCldr(
			require('cldr-data/supplemental/numberingSystems.json'),
			require('cldr-data/main/es/ca-gregorian.json'),
			require('cldr-data/main/es/ca-generic.json'),
			require('cldr-data/main/es/numbers.json'),
			require('cldr-data/main/es/timeZoneNames.json'),
			require('cldr-data/supplemental/weekData.json')
		);
		ej2Base.setCulture('es');
		setCulture('es');
		return 'es';
	} else if (cookieCulture === 'en') {
		ej2Base.loadCldr(
			require('cldr-data/supplemental/numberingSystems.json'),
			require('cldr-data/main/en/ca-gregorian.json'),
			require('cldr-data/main/en/ca-generic.json'),
			require('cldr-data/main/en/numbers.json'),
			require('cldr-data/main/en/timeZoneNames.json'),
			require('cldr-data/supplemental/weekData.json')
		);
		loadCldr(
			require('cldr-data/supplemental/numberingSystems.json'),
			require('cldr-data/main/en/ca-gregorian.json'),
			require('cldr-data/main/en/ca-generic.json'),
			require('cldr-data/main/en/numbers.json'),
			require('cldr-data/main/en/timeZoneNames.json'),
			require('cldr-data/supplemental/weekData.json')
		);
		ej2Base.setCulture('en');
		setCulture('en');
		return 'en';
	} else if (cookieCulture === 'ca') {
		ej2Base.loadCldr(
			require('cldr-data/supplemental/numberingSystems.json'),
			require('cldr-data/main/ca/ca-gregorian.json'),
			require('cldr-data/main/ca/numbers.json'),
			require('cldr-data/main/ca/timeZoneNames.json'),
			require('cldr-data/main/ca/ca-generic.json'),
			require('cldr-data/supplemental/weekData.json')
		);
		loadCldr(
			require('cldr-data/supplemental/numberingSystems.json'),
			require('cldr-data/main/ca/ca-gregorian.json'),
			require('cldr-data/main/ca/numbers.json'),
			require('cldr-data/main/ca/timeZoneNames.json'),
			require('cldr-data/main/ca/ca-generic.json'),
			require('cldr-data/supplemental/weekData.json')
		);
		ej2Base.setCulture('ca');
		setCulture('ca');
		return 'ca';
	}
};

export { loadLanguage };
