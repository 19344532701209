const getUsersParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetManagementUsers',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getRolesParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetUserManagerRoles',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getUserByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postUserParams = (data, SuccessFn, ErrorFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/UserManagement/CreateUser',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
		onError: (response) => {
			ErrorFn(response);
		}
	};
	return options;
};

const putUserParams = (data, SuccessFn, ErrorFn) => {
	const options = {
		method: 'PUT',
		data,
		endpoint: '/UserManagement',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
		onError: (response) => {
			ErrorFn(response);
		}
	};
	return options;
};

const deleteUserParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/UserManagement',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getRolesParams, getUsersParams, getUserByIdParams, postUserParams, putUserParams, deleteUserParams };
