const UPDATE_PROFILE = 'UPDATE_PROFILE';
const RESET_PROFILE = 'RESET_PROFILE';

const defaultConst = {
	UPDATE_PROFILE,
	RESET_PROFILE
};

export default defaultConst;

export const updateProfile = (profile) => ({
	type: UPDATE_PROFILE,
	profile: profile
});

export const resetProfile = () => ({
	type: RESET_PROFILE
});
