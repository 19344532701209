import profile from '../../actions/profile';
import { SESSION_STORAGE_CONSTANTS } from '../../common/constants/sessionStorage';
import { SaveSessionStorage, GetSessionStorage } from '@backoffice/common/utils/storageManager';
/* Redux state init */
const defaultProfile = {
	imgUrl: '',
	name: '',
	firstSurname: '',
	secondSurname: '',
	email: ''
};

const initialState = {
	profile: GetSessionStorage(SESSION_STORAGE_CONSTANTS.PROFILE)
		? GetSessionStorage(SESSION_STORAGE_CONSTANTS.PROFILE)
		: defaultProfile
};

export default function profileReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case profile.UPDATE_PROFILE:
				SaveSessionStorage(SESSION_STORAGE_CONSTANTS.PROFILE, action.profile);
				return {
					...state,
					profile: action.profile
				};
			case profile.RESET_PROFILE:
				return {
					...state,
					profile: defaultProfile
				};
			default:
				return { ...state };
		}
	}
	return state;
}
