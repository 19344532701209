import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { startRequest } from "../../../../actions/apiCall";
import { createAdditionalInfoDocumentParams, createAdditionalInfoNotesParams, deleteAdditionalInfoDocumentParams, deleteAdditionalInfoNotesParams, getAdditionalInfoParams, updateAdditionalInfoDocumentParams, updateAdditionalInfoNotesParams } from "./CompaniesAdditionalDataCalls/CompaniesAdditionalDataCalls";
import './CompaniesAdditionalData.scss';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { useTranslation } from "react-i18next";
import { Button, Input } from "../../../../components/FormComponents";
import DocumentCompaniesModal from "./DocumentCompaniesModal/DocumentCompaniesModal";
import { showFixedLabel } from "../../../../actions/fixedLabel";
import { closeModal, openModal } from "../../../../actions/popup";
import { parseDate } from "../../../../common/utils/parseUtils";
import PopUpGenericDelete from "../../../../components/PopUpGenericDelete/PopUpGenericDelete";
import NoteCompaniesModal from "./NoteCompaniesModal";

const CompaniesAdditionalData = memo(function CompaniesAdditionalData({ companyId }) {

    const [documents, setDocuments] = useState([])
    const [allDocuments, setAllDocuments] = useState([])
    const [documentsSearch, setDocumentsSearch] = useState('')
    const [notes, setNotes] = useState([])
    const [allNotes, setAllNotes] = useState([])
    const [notesSearch, setNotesSearch] = useState('')
    
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const gridDocument = useRef(null);
    const gridNotes = useRef(null);
    const pageSettings = { pageSize: 10 };

    useEffect(() => {
        getAdditionalInfo();
    }, [])

    const getAdditionalInfo = () => {
        dispatch(startRequest(getAdditionalInfoParams(companyId, getAdditionalInfoParamsSuccess)))
    }    

    const getAdditionalInfoParamsSuccess = (response) => {
        setDocuments(response.documents);
        setAllDocuments(response.documents);
        setNotes(response.notes);
        setAllNotes(response.notes);
    }

    const GetDocumentsBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				element.about.toLowerCase().includes(searching.toLowerCase())				
			);
		});
		return result;
	};

    const filterDocuments = () => {
		if (documentsSearch === '') {
			setDocuments(allDocuments);
		}
		const newData = GetDocumentsBySearch(allDocuments, documentsSearch);
		if (newData === '') {
			setDocuments(allDocuments);
		}
		setDocuments(newData);
	};

    const resetFiltersDocuments = () => {
		setDocuments(allDocuments);
		setDocumentsSearch('');
	}

    const GetNotesBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				element.about.toLowerCase().includes(searching.toLowerCase())				
			);
		});
		return result;
	};

    const filterNotes = () => {
		if (notesSearch === '') {
			setNotes(allNotes);
		}
		const newData = GetNotesBySearch(allNotes, notesSearch);
		if (newData === '') {
			setNotes(allNotes);
		}
		setNotes(newData);
	};

    const resetFiltersNotes = () => {
		setNotes(allNotes);
		setNotesSearch('');
	}

    const dateTemplate = (rowData) => {
        return (
            <span>{parseDate(rowData.date)}</span>
        )
    }

    const openModalNote = (rowData) => {
        const optionValues = {
            id: 'noteInfoCompanyModal',
            dataTestId: 'noteInfoCompanyModal',
            cssClass: 'modal-header__transparent',
            title: rowData.about
        };
    
        const options = {
            ...optionValues,
            body: (
                <span>{rowData.notes}</span>
            )
        };
        dispatch(openModal(options));
    }

    const actionsTemplate = (rowData, type) => {
		return (
			<div className="action-template">
                {
                    type === "documents"
                        ? <span className='file-wrapper'>
                            <a title={rowData.name} href={rowData.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                        </span>    
                        : <span className="material-icons-round" onClick={() => openModalNote(rowData)}>visibility</span>
                }
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() => {
                        documentCompanyModalPopUp(type, rowData.id, "edit", rowData)
                    }}
					role="button"
					type="button"
				>
					mode_edit
				</span>
				<span
					className="material-icons-round"
					id={`btnDeleteStandar_${rowData.index}`}
					data-testid={`btnDeleteStandar_${rowData.index}`}
					onClick={() => {
						openDeleteDocument('delete', rowData, type);
					}}
					role="button"
					type="button"
				>
					delete
				</span>
			</div>
		);
	};    

    const documentCompanyModalPopUp = (type, documentId, action, data) => {
        const optionValues = {
            id: 'documentCompanyModal',
            dataTestId: 'documentCompanyModal',
            cssClass: 'modal-header__transparent',
            title: action === 'edit' 
                    ? type === 'documents' 
                        ? t('EditDocument') 
                        : t('EditNote') 
                    : type === 'documents' 
                        ? t('AddDocument') 
                        : t('AddNote')
        };
    
        const options = {
            ...optionValues,
            body: (
                type === 'documents' 
                    ? <DocumentCompaniesModal 
                        data={data}
                        documentId={documentId}
                        action={action}
                        companyId={companyId}
                        handleSubmit={handleOnSubmitDocument}
                        showFixedLabel={showFixedLabel}
                    />
                    : <NoteCompaniesModal
                        data={data}
                        documentId={documentId}
                        action={action}
                        companyId={companyId}
                        handleSubmit={handleOnSubmitNote}
                        showFixedLabel={showFixedLabel}
                    />
            )
        };
        dispatch(openModal(options));
    }

    const handleOnSubmitDocument = (action, data) => {
        if (action === 'edit') {
            dispatch(startRequest(updateAdditionalInfoDocumentParams(data, createAdditionalInfoDocumentParamsSuccess))); 
        }
        if (action === 'add') {
            dispatch(startRequest(createAdditionalInfoDocumentParams(data, createAdditionalInfoDocumentParamsSuccess)));
        }
    }

    const createAdditionalInfoDocumentParamsSuccess = (response) => {
        getAdditionalInfo();
    }

    const handleOnSubmitNote = (action, data) => {
        if (action === 'edit') {
            dispatch(startRequest(updateAdditionalInfoNotesParams(data, createAdditionalInfoDocumentParamsSuccess))); 
        }
        if (action === 'add') {
            dispatch(startRequest(createAdditionalInfoNotesParams(data, createAdditionalInfoDocumentParamsSuccess)));
        }
    }

    const openDeleteDocument = (action, rowData, type) => {
		const optionValues = {
			id: 'delete-document-' + rowData.id + '-' + Math.random(),
			dataTestId: 'delete-document',
			cssClass: 'modal-header__danger',
			title: type === 'documents' ? t('RemoveDocument') : t('RemoveNote')
		};
		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					user={rowData}
					deleteMessage={`¿Seguro que quieres eliminar ${ type === 'documents' ? 'el documento' : 'la nota'} ${rowData.about}?`}
					handleButtonDelete={() => { type === 'documents' ? deleteDocument(rowData) : deleteNote(rowData) }}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};
		dispatch(openModal(options));
	};

    const deleteDocument = (rowData) => {
		dispatch(startRequest(deleteAdditionalInfoDocumentParams(companyId, rowData.id, deleteAdditionalInfoDocumentParamsSuccess)));
	};

    const deleteAdditionalInfoDocumentParamsSuccess = (response) => {
		dispatch(showFixedLabel('Documento borrado correctamente', 'success'));
		getAdditionalInfo();
	};

    const deleteNote = (rowData) => {
		dispatch(startRequest(deleteAdditionalInfoNotesParams(companyId, rowData.id, deleteAdditionalInfoNotesParamsSuccess)));
	};

    const deleteAdditionalInfoNotesParamsSuccess = (response) => {
		dispatch(showFixedLabel('Nota borrada correctamente', 'success'));
		getAdditionalInfo();
	};

    return (
        <div className="additional-info flex-container">
            <div className="document-wrapper">
                <div className="title">{t('Documents')}</div>
                <div className="filters-container row">
                    <div className="col-lg-12">
                        <div className='fields-search-wrapper'>
                            <div className='form-input-group select-group'>
                                <Input
                                    dataTestID="txtDocumentSearch"
                                    inputName="txtDocumentSearch"
                                    inputPlaceholder={t('Search')}
                                    inputType="text"
                                    value={documentsSearch}
                                    inputOutline={true}
                                    icon={'search'}
                                    iconLeft={true}
                                    isRequired={false}
                                    onHandleChange={(e) => setDocumentsSearch(e.target.value)}
                                    onHandleKeyUp={filterDocuments}
                                />
                            </div>
                        </div>
                        <div className='fields-search-wrapper'>
                            <div className='form-input-group btn-group'>
                                <Button
                                    btnText={t('ResetFilters')}
                                    btnType="button"
                                    btnClass="btn-base_normal "
                                    id="resetDocumentsFilters"
                                    dataTestID="resetDocumentsFilters"
                                    onHandleBtnClick={() => resetFiltersDocuments()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='add-documents-wrapper col-lg-12'>
                        <Button
                            btnText={t('AddDocument')}
                            btnType="button"
                            btnClass="btn-base_normal "
                            dataTestID="addDocumentBtn"
                            id="addDocumentBtn"
                            onHandleBtnClick={() => {
                                documentCompanyModalPopUp('documents', null, 'add')
                            }}
                        />
                    </div>
                </div>
                <GridComponent
                    className="gridTable"
                    dataSource={documents}
                    width="100%"
                    toolbar={false}
                    ref={gridDocument}
                    //allowExcelExport={true}
                    allowPaging={true}
                    enableAdaptiveUI={true}
                    allowSorting={true}
                    rowRenderingMode={'Vertical'}
                    pageSettings={pageSettings}
                    allowResizing={true}
                    locale="es"
                    sortSettings={
                        {
                            columns:[{
                                field: 'date',
                                direction: 'Descending'
                            }]
                        }
                    }
                >
                    <ColumnsDirective>
                        <ColumnDirective field="id" width="0" visible={false} />
                        <ColumnDirective 
                            field="about" 
                            headerText={t('Name')}
                            textAlign="Left"
                            width="80" 
                        />
                        <ColumnDirective 
                            field="date"
                            template={dateTemplate}
                            headerText={t('Date')}
                            textAlign="Left"
                            width='50'
                        />
                        <ColumnDirective 
                            field="name" 
                            headerText={t('Content')}
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field="actions"
                            headerText=''
                            template={(e) => actionsTemplate(e, 'documents')}
                            textAlign="Right"
                            width="50"
                        />
                    </ColumnsDirective>
                    <Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
                </GridComponent>
            </div>
            <div className="notes-wrapper">                
                <div className="title">{t('Notes')}</div>
                <div className="filters-container row">
                    <div className="col-lg-12">
                        <div className='fields-search-wrapper'>
                            <div className='form-input-group select-group'>
                                <Input
                                    dataTestID="txtNotesSearch"
                                    inputName="txtNotesSearch"
                                    inputPlaceholder={t('Search')}
                                    inputType="text"
                                    value={notesSearch}
                                    inputOutline={true}
                                    icon={'search'}
                                    iconLeft={true}
                                    isRequired={false}
                                    onHandleChange={(e) => setNotesSearch(e.target.value)}
                                    onHandleKeyUp={filterNotes}
                                />
                            </div>
                        </div>
                        <div className='fields-search-wrapper'>
                            <div className='form-input-group btn-group'>
                                <Button
                                    btnText={t('ResetFilters')}
                                    btnType="button"
                                    btnClass="btn-base_normal "
                                    id="resetNotesFilters"
                                    dataTestID="resetNotesFilters"
                                    onHandleBtnClick={() => resetFiltersNotes()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='add-notes-wrapper col-lg-12'>
                        <Button
                            btnText={t('AddNote')}
                            btnType="button"
                            btnClass="btn-base_normal "
                            dataTestID="addNotesBtn"
                            id="addNotesBtn"
                            onHandleBtnClick={() => {
                                documentCompanyModalPopUp('notes', null, 'add')
                            }}
                        />
                    </div>
                </div>
                <GridComponent
                    className="gridTable"
                    dataSource={notes}
                    width="100%"
                    toolbar={false}
                    ref={gridNotes}
                    //allowExcelExport={true}
                    allowPaging={true}
                    enableAdaptiveUI={true}
                    allowSorting={true}
                    rowRenderingMode={'Vertical'}
                    pageSettings={pageSettings}
                    allowResizing={true}
                    locale="es"
                    sortSettings={
                        {
                            columns:[{
                                field: 'date',
                                direction: 'Descending'
                            }]
                        }
                    }
                >
                    <ColumnsDirective>
                        <ColumnDirective field="id" width="0" visible={false} />
                        <ColumnDirective 
                            field="about" 
                            headerText={t('Name')}
                            textAlign="Left"
                            width="80" 
                        />
                        <ColumnDirective 
                            field="date"
                            headerText={t('Date')}
                            template={dateTemplate}
                            textAlign="Left"
                            width='50'
                        />
                        <ColumnDirective 
                            field="notes"
                            headerText={t('Content')}
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field="actions"
                            headerText=''
                            template={(e) => actionsTemplate(e, 'notes')}
                            textAlign="Right"
                            width="50"
                        />
                    </ColumnsDirective>
                    <Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
                </GridComponent>
            </div>
        </div>
    );
});

export default CompaniesAdditionalData;
