import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { Input, Button, Combo } from '@backoffice/components/FormComponents/index';
import { startRequest } from '@backoffice/actions/apiCall';
import { openModal, closeModal } from '../../../actions/popup';
import { showFixedLabel } from '../../../actions/fixedLabel';
import PopUpGenericDelete from '../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { ROUTE } from '../../../common/constants/routes';
import { getAllUsers, getAllRoles, deleteUserParams, getAllUserCompanies, deleteUserCompanyParams, postUploadUserAuditorCVParams, postResentEmailParams } from './UsersCalls/UsersCalls';
import ResponsiveGridWrapper from '../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { GetSessionStorage } from '../../../common/utils/storageManager';
import UserModal from './UserModal/UserModal';
import './Users.scss';
import HeaderPages from '../../../shared/HeaderPages';
import UserCVModal from './UserCVModal';
import { Checkbox } from '../../../components/FormComponents';
import checkIsPrincipalAnyCompany from '../../../common/utils/checkIsPrincipal';

const Users = () => {
	const pageSettings = { pageSize: 10 };
	const grid = useRef(null);
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [search, setSearch] = useState('');
	const [users, setUsers] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [showExportExcelBtn, setShowExportExcelBtn] = useState(false)
	const [userLoged, setUserLoged] = useState('')	
	const [isSuperAdmin, setIsSuperAdmin] = useState(true)
	const [checkCoordinator, setCheckCoordinator] = useState(false)
	const [showCheckCoordinator, setShowCheckCoordinator] = useState(false)

	const [rolTypeOptions, setRolTypeOptions] = useState([])
	const [rolTypeComboSelected, setRolTypeComboSelected] = useState()

	const [principalTypeOptions, setPrincipalTypeOptions] = useState([
		{
			id: t('Principal'),
			label: t('Principal'),
			value: true
		},
		{
			id: t('Normal'),
			label: t('Normal'),
			value: false
		}
	])
	const [principalTypeComboSelected, setPrincipalTypeComboSelected] = useState()

	useEffect(() => {
		getUserLoged();
	}, []);
	
	const getUserLoged = () =>{
		const userLoged = GetSessionStorage('PROFILE_')
		setUserLoged(userLoged);
	}

	useEffect(() => {
		userLoged && getData();
	}, [userLoged]);	

	const getData = () => {
		if( userLoged.userRoles[0].name === 'Admin' ) {
			getUsers();
			getRoles();
		} else if( userLoged.userRoles[0].name === 'Company' && checkIsPrincipalAnyCompany(userLoged) ) {
			getUsersCompanyPrincipal();
		}
	}

	const getUsers = () => {
		dispatch(startRequest(getAllUsers(getAllUsersSuccess)));
	};

	const getAllUsersSuccess = (response) => {
		setAllUsers(response);
		setUsers(response);
	};

	const getUsersCompanyPrincipal = () => {
		dispatch(startRequest(getAllUserCompanies(getAllUserCompaniesSuccess)));
	};

	const getAllUserCompaniesSuccess = (response) => {
		setAllUsers(response);
		setUsers(response);
	}

	const getRoles = () => {
		dispatch(startRequest(getAllRoles(getAllRolesSuccess)))
	}

	const getAllRolesSuccess = (response) => {
		let options = [];
        response.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: t(el.name)
            });
        });
		setRolTypeOptions(options)
	}	

	const filterUsers = () => {
		//reset filter combos
		//setUserTypeComboSelected([])
		userLoged.userRoles[0].name === 'Admin' && setRolTypeComboSelected([]);

		if (search === '') {
			setUsers(allUsers);
		}
		const newData = GetDataBySearch(allUsers, search);
		if (newData === '') {
			setUsers(allUsers);
		}
		setUsers(newData);
	};

	const GetDataBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				element.name.toLowerCase().includes(searching.toLowerCase()) ||
				element.surnames.toLowerCase().includes(searching.toLowerCase()) ||
				element.email.toLowerCase().includes(searching.toLowerCase()) 				
			);
		});
		return result;
	};

	const handleRolTypeChange = (e) => {
		//Reset other combo
		//setUserTypeComboSelected([])
		setRolTypeComboSelected(e)
		setSearch('')
		if (e.value === '') {
			setUsers(allUsers);
		}		

		if(e.value === 'Auditor') {
			setShowCheckCoordinator(true);
		} else {
			setShowCheckCoordinator(false);
		}
		//const newData = GetDataBySearch(allUsers, e.value);
		const newData = getRolesDataBySearch(allUsers, e.value)
		if (newData === '') {
			setUsers(allUsers);
		}
		setUsers(newData);
	}

	const getRolesDataBySearch = (data = [], searching = '') => {
		//console.log(data, searching);
		const result = data.filter((element) => {
			return (
				element.userRoles[0].name.toLowerCase().includes(searching.toLowerCase())	
			);
		});
		return result;
	}

	const handlePrincipalTypeChange = (e) => {
		setPrincipalTypeComboSelected(e)
		setSearch('')
		if (e.value === '') {
			setUsers(allUsers);
		}

		const newData = getPrincipalDataBySearch(allUsers, e.value)
		if (newData === '') {
			setUsers(allUsers);
		}
		setUsers(newData);
	}

	const getPrincipalDataBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				checkIsPrincipalAnyCompany(element) === searching	
			);
		});
		return result;
	}

	const handleCheckCoordinator = (value) => {
		setCheckCoordinator(value)

		const newData = getCoordinatorDataBySearch(allUsers, value);
		if (newData === '') {
			setUsers(allUsers);
		}
		setUsers(newData);
	}

	const getCoordinatorDataBySearch = (data = [], searching = false) => {
		const result = data.filter((element) => {
			return (
				element.userData.auditorUserData.isCoordinator === searching	
			);
		});
		return result;
	}

	const resetFilters = () => {
		//setUserTypeComboSelected([])
		setRolTypeComboSelected([])
		setShowCheckCoordinator(false)
		setCheckCoordinator(false)
		setPrincipalTypeComboSelected([])
		setSearch('')
		setUsers(allUsers)
	}

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				{
					rowData.userRoles[0].name === 'Auditor'
						? <span
							className="material-icons-round"
							id={`btnUploadCVStandar_${rowData.index}`}
							data-testid={`btnUploadCVStandar_${rowData.index}`}
							onClick={() => {
								openUploadCVUsers(rowData, !rowData.userData.auditorUserData.auditorCV ? 'add' : 'edit');
							}}
							role="button"
							type="button"
						>
							library_books
						</span>
						: null
				}
				{
					!rowData.emailConfirmed
						? <span
							className="material-icons-round"
							id={`btnResendEmail_${rowData.index}`}
							data-testid={`btnResendEmail_${rowData.index}`}
							onClick={() => resendEmail(rowData.email)}
							role="button"
							type="button"
						>
							outbox
						</span>
						: null
				}
				{
					checkIsPrincipalAnyCompany(rowData) && rowData.email != userLoged.email
						? null
						: <span
							className="material-icons-round"
							data-testid={`btnEditStandar_${rowData.index}`}
							id={`btnEditStandar_${rowData.index}`}
							onClick={() => userModalPopUp(rowData, "edit")}
							role="button"
							type="button"
						>
							mode_edit
						</span>
				}
				{
					rowData.userRoles[0].name === 'Company' && checkIsPrincipalAnyCompany(rowData) && rowData.email === userLoged.email
						? null
						: <span
							className="material-icons-round"
							id={`btnDeleteStandar_${rowData.index}`}
							data-testid={`btnDeleteStandar_${rowData.index}`}
							onClick={() => {
								openDeleteUsers('delete', rowData);
							}}
							role="button"
							type="button"
						>
							delete
						</span>
				}
			</div>
		);
	};

	const resendEmail = (email) => {
		const resendEmail = { email };
		if (email) {
			dispatch(startRequest(postResentEmailParams(resendEmail, postResentEmailParamsSuccess)));
		}
	};

	const postResentEmailParamsSuccess = (data) => 
    { 
        if (data)
        {
            dispatch(showFixedLabel('El email de confirmación se ha enviado correctamente', 'success'));
        }
        else
        {
            dispatch(showFixedLabel('No se ha enviado el email de confirmación', 'success'));
        }
    };

	const userModalPopUp = (user, action) => {
        const optionValues = {
            id: 'userModal',
            dataTestId: 'userModal',
            cssClass: 'modal-header__transparent',
            title: action === 'edit' ? t('EditUser') : t('AddUser')
        };
    
        const options = {
            ...optionValues,
            body: (
                <UserModal 
					user={user}
					action={action}
					userRol={userLoged.userRoles[0].name}
					handleSubmit={userLoged.userRoles[0].name === 'Admin' ? getUsers : getUsersCompanyPrincipal }
					showFixedLabel={showFixedLabel}
					isUserCompany={userLoged && userLoged.userRoles[0].name === 'Company' ? true : false}
				/>
            )
        };
        dispatch(openModal(options));
    }

	const openUploadCVUsers = (user, action) => {
        const optionValues = {
            id: 'userCVModal',
            dataTestId: 'userCVModal',
            cssClass: 'modal-header__transparent',
            title: action === 'edit' ? t('UpdateCV') : t('AddCV')
        };
    
        const options = {
            ...optionValues,
            body: (
                <UserCVModal 
					user={user}
					action={action}
					uploadFile={uploadFile}
					handleOnClickCloseModal={handleOnClickCloseModal}
				/>
            )
        };
        dispatch(openModal(options));
	}

	const uploadFile = (e, user) => {
		let data = { File: e.target.files[0], AuditorUserId: user.id };
		dispatch(closeModal());
		dispatch(startRequest(postUploadUserAuditorCVParams(data, postUploadUserAuditorCVParamsSuccess, postUploadUserAuditorCVParamsError)))
	}

	const handleOnClickCloseModal = () => {
		dispatch(closeModal());
	}

	const postUploadUserAuditorCVParamsSuccess = () => {
		resetFilters();
		getUsers();
		dispatch(showFixedLabel('Se ha subido el CV correctamente', 'success'));
	}

	const postUploadUserAuditorCVParamsError = () => {
		dispatch(showFixedLabel('Se ha producido un error al subir el CV', 'error'));
	}

	const openDeleteUsers = (action, user) => {
		if(user.userRoles[0].name === "Company" && userLoged.userRoles[0].name === 'Admin'){
			dispatch(showFixedLabel('No se pueden eliminar usuarios con el rol de empresa', 'error'));
			return
		}
		const optionValues = {
			id: 'delete-users-' + user.id + '-' + Math.random(),
			dataTestId: 'delete-user',
			cssClass: 'modal-header__danger',
			title: t('DeleteUser')
		};
		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					user={user}
					deleteMessage={`¿Seguro que quieres eliminar al usuario ${user.fullName}?`}
					handleButtonDelete={() => deleteUsers(user)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};
		dispatch(openModal(options));
	};

	const deleteUsers = (user) => {
		if(userLoged.userRoles[0].name === 'Company') {
			dispatch(startRequest(deleteUserCompanyParams(user.id, user.userData.companyUserData.companiesInfo[0].companyId , deleteUsersSuccess)));
		} else {
			dispatch(startRequest(deleteUserParams({ id: user.id }, deleteUsersSuccess)));
		}
	};

	const deleteUsersSuccess = (response) => {
		dispatch(showFixedLabel('UserDeleteSuccess', 'success'));
		if(userLoged.userRoles[0].name === 'Company') {
			getUsersCompanyPrincipal();
		} else {
			getUsers();
		}
	};

	const principalTemplate = (rowData) => {
		let spanReturn = '';
		if( checkIsPrincipalAnyCompany(rowData) && rowData.email === userLoged.email ) {
			spanReturn = 'star';
		}
		return (
			<span className="material-icons icon-star" aria-hidden={true}>{spanReturn}</span>
		)
	}

	return (
		<div className="users">
			<HeaderPages 
				title={t('UsersConfiguration')}
				userLogedName={userLoged.name}
			/>
			<div className="filters-container row">
				<div className="col-lg-12">
					<div className='free-search-wrapper'>
						<Input
							dataTestID="txtSearch"
							inputName="txtSearch"
							inputPlaceholder={t('Search')}
							inputType="text"
							value={search}
							inputOutline={true}
							icon={'search'}
							iconLeft={true}
							isRequired={false}
							onHandleChange={(e) => setSearch(e.target.value)}
							onHandleKeyUp={filterUsers}
						/>

					</div>
				</div>								
				<div className="col-lg-12">

					{
						userLoged && userLoged.userRoles[0].name === 'Admin' &&
							<div className='fields-search-wrapper'>
								<div className='form-input-group select-group'>
									<Combo 
										id="RoleId"
										data-testid="RoleId"
										defaultInputValue=""
										options={rolTypeOptions}
										isSearchable={false}
										isClearable={true}
										className="comboBox"
										handleChange={(e) => handleRolTypeChange(e)}
										inputValue={rolTypeComboSelected}
										isMultiple={false}
										comboLabel={t('Rol')}
										comboPlaceholder={t('Rol')}
									/> 
								</div>								
							</div>
					}
					{
						userLoged && userLoged.userRoles[0].name === 'Company' &&
							<div className='fields-search-wrapper'>
								<div className='form-input-group select-group'>
									<Combo 
										id="PrincipalId"
										data-testid="PrincipalId"
										defaultInputValue=""
										options={principalTypeOptions}
										isSearchable={false}
										isClearable={true}
										className="comboBox"
										handleChange={(e) => handlePrincipalTypeChange(e)}
										inputValue={principalTypeComboSelected}
										isMultiple={false}
										comboLabel={t('UserType')}
										comboPlaceholder={t('UserType')}
									/> 
								</div>
							</div>
					}
					<div className='fields-search-wrapper'>
						<div className='form-input-group btn-group'>
							<Button
								btnText={t('ResetFilters')}
								btnType="button"
								btnClass="btn-base_normal "
								id="resetFilters"
								dataTestID="resetFilters"
								onHandleBtnClick={() => resetFilters()}
							/>
						</div>
					</div>
				</div>
				{
					showCheckCoordinator &&
						<div className="col-lg-12">
							<div className='fields-search-wrapper'>
								<Checkbox
									inputID={'checkCoordinator'}
									dataTestID={'checkCoordinator'}
									inputName={'checkCoordinator'}
									inputLabel={t('Coordinator')}
									inputChecked={checkCoordinator}
									onHandleChange={(e) => {
										handleCheckCoordinator(e.target.checked)
									}}
									withLink={false}
								/>
							</div>
						</div>
				}
			</div>
			<div className='row'>
				<div className='add-user-wrapper col-lg-12'>
					<Button
						btnText={t('AddUser')}
						btnType="button"
						btnClass="btn-base_normal "
						dataTestID="addUserBtn"
						id="addUserBtn"
						onHandleBtnClick={() => userModalPopUp(null, 'add')}
					/>
				</div>
			</div>

			<div className='users-grid row'>
				<div className="col-md-12">
					{/* <ResponsiveGridWrapper> */}
					<div className='grid-wrapper'>
						{
							userLoged &&
								<>
									<GridComponent
										className="gridTable"
										dataSource={users}
										width="100%"
										toolbar={false}
										ref={grid}
										//allowExcelExport={true}
										allowPaging={true}
										enableAdaptiveUI={true}
										allowSorting={true}
										rowRenderingMode={'Vertical'}
										pageSettings={pageSettings}
										allowResizing={true}
									>
										<ColumnsDirective>
											<ColumnDirective field="id" width="0" visible={false} />
											<ColumnDirective
												field="userData"
												headerText={t('Company')}
												textAlign="Left"
												valueAccessor={(field, data, column) => {
													if(data.userRoles[0].name === 'Auditor') return t(data.userData.auditorUserData.companyName)
													else if(data.userRoles[0].name === 'Company') return t(data.userData.companyUserData.companiesInfo[0].companyName)
													else return
												}}
												width="120"
											/>
											<ColumnDirective field="name" headerText={t('Name')} textAlign="Left" />
											<ColumnDirective field="surnames" headerText={t('Surnames')} textAlign="Left" />
											<ColumnDirective field="phoneNumber" headerText={t('Phone')} textAlign="Left" width='100' />
											<ColumnDirective field="email" headerText={t('Email')} textAlign="Left" />
											{ 
												userLoged.userRoles[0].name === 'Admin'
													? <ColumnDirective
														field="userRoles"
														headerText={t('Rol')}
														textAlign="Left"
														valueAccessor={(field, data, column) => {
															return t(data['userRoles'][0].name);
														}}
														width="120"
													/>
													: <ColumnDirective
														field="userRoles"
														headerText={t('')}
														textAlign="Left"
														template={principalTemplate}
														width={30}
													/>
											}
											<ColumnDirective
												field="actions"
												headerText={''}
												template={actionsTemplate}
												textAlign="Right"
												width="100"
											/>
										</ColumnsDirective>
										<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
									</GridComponent>
								
									{
										showExportExcelBtn ? (
											<div className="export-excel-wrapper">
												<div className="col-lg-12">
													<Button
														btnText={t('ExportExcel')}
														btnType="button"
														btnClass="btn-base_normal export-excel-button"
														dataTestID="export-excel-button"
														onHandleBtnClick={() => {
															grid.current.excelExport();
														}}
													/>
												</div>
											</div>

										) : null
									}
								</>
						}
					{/* </ResponsiveGridWrapper> */}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Users;
