import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/FormComponents';
import './RequestImprovementPlans.scss';

const RequestImprovementPlans = ({userRol, formValues, colorStatesImprovementPlans, handleOnClickImprovementPlans}) => {

    const { t } = useTranslation();

    const buttonDraw = (isFill = false, plan = {}) => {
        return (
            <div key={plan.id} className="btn-improvement-plan-wrapper">
                {
                    isFill && <span>{`${ userRol !== 'Company' ? plan.isCompleted ? `${t('Completed')} - ` : `${t('NotCompleted')} - ` : '' }${plan.name}`}</span>
                }
                <Button
                    icon={ userRol !== 'Company' || plan.isCompleted ? 'visibility' : 'create'}
                    btnText={ userRol !== 'Company' || plan.isCompleted ? t('See') : userRol === 'Company' && isFill ? t('Edit') : t('New')}
                    btnType="button"
                    btnClass="btn-base_normal"
                    dataTestID="DeclineRequestBtn"
                    id="DeclineRequestBtn"
                    onHandleBtnClick={() => handleOnClickImprovementPlans(plan.id)}                            
                />
            </div>
        )
    }

    const buttonDrawOptions = () => {
        return (
            <>
                {
                    formValues.ImprovementPlans.map((plan) => (
                        buttonDraw(true, plan)
                    ))
                }
                {
                    userRol === 'Company' && formValues.ImprovementPlans.length === 1 && formValues.IndicatedImprovementPlans === 2 && buttonDraw(false)
                }
            </>
        )
    }

    return (
        <>
            <div className='request-wrapper'>
                <div className='title-wrapper'>
                    <h2 className='title'>{`${t("ImprovementPlansLong")} (${formValues.ImprovementPlans.length}/${formValues.IndicatedImprovementPlans})`}</h2>
                    <div className='state'>
                        <span
                            className='material-icons-round'
                            style={{
                                color: colorStatesImprovementPlans(formValues.ImprovementPlanStatusId)
                            }}
                            aria-hidden={true}
                        >{formValues.ImprovementPlanStatusName === 'Pendiente' ? 'cancel' : 'check_circle'}</span>
                        <span>{formValues.ImprovementPlanStatusName}</span>
                    </div>

                </div>
            </div>
            <div className='content-wrapper'>
                <div className='actions btn-group'>
                    <div className='btn-wrapper-improvement-plans'>
                        {
                            formValues.ImprovementPlans.length > 0
                                ? buttonDrawOptions()
                                : userRol === 'Company' && buttonDraw()
                        }
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestImprovementPlans;