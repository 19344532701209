import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from './style';

import './Loading.scss';

const Loading = () => {
    const loadingReducer = useSelector((state) => state.loading);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (loadingReducer.show === 0) {
            setShowSpinner(false);
        } else if (showSpinner === false) {
            setShowSpinner(true);
        }
    }, [loadingReducer.show, showSpinner]);

    return (
        <div
            data-testid='spinner'
            className={showSpinner ? 'spinner-container' : ''}
        >
            <Loader loading={showSpinner.toString()}/>
        </div>
    );
};

export default Loading;
