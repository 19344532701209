import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../actions/popup';
import { Button } from '../../../../components/FormComponents';
import './QuestionnaireAddModal.scss';

const QuestionnaireAddModal = ({handleSubmit, showFixedLabel}) => {
  
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const postDocumentForm = (e) => {
        e.preventDefault();

        if(e.target.files[0] === 0 ) {
            dispatch(closeModal())
            dispatch(showFixedLabel(t('EmptyUpload'), 'error'))
        } else {
            dispatch(closeModal())
            handleSubmit(e.target.files[0])            
        }
    }

    return(
        <div className='questionnaire-add-modal'>
            <form className='form-document-edit'>
                <div className='btn-submit-container row mt-4'>
                    <div className={`btn-submit-wrapper col-lg-12 d-flex`}>
                        <div className='d-flex justify-content-end'>
                            <Button
                                dataTestID="btnCancel"
                                btnText={t('Cancel')}
                                btnType="button"
                                btnClass="btn-base_outline ml-5"
                                onHandleBtnClick={() => {
                                    dispatch(closeModal());
                                }}
                            />

                            <div className="button-upload ml-3">
                                <label 
                                    htmlFor="btnUploadFile" 
                                    role={'button'}
                                    className={'btn-base_normal btn-base_outline'}
                                >
                                    <i className="material-icons-round">{ 'upload'}</i>
                                    <span>{t('AddQuestionnaire')}</span> 
                                
                                </label>
                                <input
                                    id="btnUploadFile"
                                    data-testid="btnUploadFile"
                                    type="file"
                                    onChange={(e) => postDocumentForm(e)}
                                    name="btnUploadFile"
                                    accept=".xlsx"
                                    className="inputfile"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default QuestionnaireAddModal;