export const MUNICIPIOS = [
  {
    "parent_code": "01",
    "code": "3",
    "label": "Agurain/Salvatierra"
  },
  {
    "parent_code": "01",
    "code": "4",
    "label": "Alegría-Dulantzi"
  },
  {
    "parent_code": "01",
    "code": "9",
    "label": "Amurrio"
  },
  {
    "parent_code": "01",
    "code": "3",
    "label": "Añana"
  },
  {
    "parent_code": "01",
    "code": "5",
    "label": "Aramaio"
  },
  {
    "parent_code": "01",
    "code": "6",
    "label": "Armiñón"
  },
  {
    "parent_code": "01",
    "code": "6",
    "label": "Arraia-Maeztu"
  },
  {
    "parent_code": "01",
    "code": "8",
    "label": "Arratzua-Ubarrundia"
  },
  {
    "parent_code": "01",
    "code": "0",
    "label": "Artziniega"
  },
  {
    "parent_code": "01",
    "code": "1",
    "label": "Asparrena"
  },
  {
    "parent_code": "01",
    "code": "5",
    "label": "Ayala/Aiara"
  },
  {
    "parent_code": "01",
    "code": "2",
    "label": "Baños de Ebro/Mañueta"
  },
  {
    "parent_code": "01",
    "code": "3",
    "label": "Barrundia"
  },
  {
    "parent_code": "01",
    "code": "8",
    "label": "Berantevilla"
  },
  {
    "parent_code": "01",
    "code": "4",
    "label": "Bernedo"
  },
  {
    "parent_code": "01",
    "code": "0",
    "label": "Campezo/Kanpezu"
  },
  {
    "parent_code": "01",
    "code": "0",
    "label": "Elburgo/Burgelu"
  },
  {
    "parent_code": "01",
    "code": "5",
    "label": "Elciego"
  },
  {
    "parent_code": "01",
    "code": "1",
    "label": "Elvillar/Bilar"
  },
  {
    "parent_code": "01",
    "code": "4",
    "label": "Erriberabeitia"
  },
  {
    "parent_code": "01",
    "code": "8",
    "label": "Erriberagoitia/Ribera Alta"
  },
  {
    "parent_code": "01",
    "code": "5",
    "label": "Harana/Valle de Arana"
  },
  {
    "parent_code": "01",
    "code": "5",
    "label": "Iruña Oka/Iruña de Oca"
  },
  {
    "parent_code": "01",
    "code": "8",
    "label": "Iruraiz-Gauna"
  },
  {
    "parent_code": "01",
    "code": "9",
    "label": "Kripan"
  },
  {
    "parent_code": "01",
    "code": "3",
    "label": "Kuartango"
  },
  {
    "parent_code": "01",
    "code": "4",
    "label": "Labastida/Bastida"
  },
  {
    "parent_code": "01",
    "code": "1",
    "label": "Lagrán"
  },
  {
    "parent_code": "01",
    "code": "8",
    "label": "Laguardia"
  },
  {
    "parent_code": "01",
    "code": "3",
    "label": "Lanciego/Lantziego"
  },
  {
    "parent_code": "01",
    "code": "0",
    "label": "Lantarón"
  },
  {
    "parent_code": "01",
    "code": "9",
    "label": "Lapuebla de Labarca"
  },
  {
    "parent_code": "01",
    "code": "0",
    "label": "Laudio/Llodio"
  },
  {
    "parent_code": "01",
    "code": "7",
    "label": "Legutio"
  },
  {
    "parent_code": "01",
    "code": "4",
    "label": "Leza"
  },
  {
    "parent_code": "01",
    "code": "5",
    "label": "Moreda de Álava/Moreda Araba"
  },
  {
    "parent_code": "01",
    "code": "6",
    "label": "Navaridas"
  },
  {
    "parent_code": "01",
    "code": "1",
    "label": "Okondo"
  },
  {
    "parent_code": "01",
    "code": "7",
    "label": "Oyón-Oion"
  },
  {
    "parent_code": "01",
    "code": "2",
    "label": "Peñacerrada-Urizaharra"
  },
  {
    "parent_code": "01",
    "code": "8",
    "label": "Samaniego"
  },
  {
    "parent_code": "01",
    "code": "4",
    "label": "San Millán/Donemiliaga"
  },
  {
    "parent_code": "01",
    "code": "9",
    "label": "Urkabustaiz"
  },
  {
    "parent_code": "01",
    "code": "2",
    "label": "Valdegovía/Gaubea"
  },
  {
    "parent_code": "01",
    "code": "1",
    "label": "Villabuena de Álava/Eskuernaga"
  },
  {
    "parent_code": "01",
    "code": "0",
    "label": "Vitoria-Gasteiz"
  },
  {
    "parent_code": "01",
    "code": "4",
    "label": "Yécora/Iekora"
  },
  {
    "parent_code": "01",
    "code": "1",
    "label": "Zalduondo"
  },
  {
    "parent_code": "01",
    "code": "6",
    "label": "Zambrana"
  },
  {
    "parent_code": "01",
    "code": "6",
    "label": "Zigoitia"
  },
  {
    "parent_code": "01",
    "code": "2",
    "label": "Zuia"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Abengibre"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Alatoz"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Albacete"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Albatana"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Alborea"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Alcadozo"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Alcalá del Júcar"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Alcaraz"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Almansa"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Alpera"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Ayna"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Balazote"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Ballestero, El"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Balsa de Ves"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Barrax"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Bienservida"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Bogarra"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Bonete"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Bonillo, El"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Carcelén"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Casas de Juan Núñez"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Casas de Lázaro"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Casas de Ves"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Casas-Ibáñez"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Caudete"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Cenizate"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Chinchilla de Monte-Aragón"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Corral-Rubio"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Cotillas"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Elche de la Sierra"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Férez"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Fuensanta"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Fuente-Álamo"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Fuentealbilla"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Gineta, La"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Golosalvo"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Hellín"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Herrera, La"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Higueruela"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Hoya-Gonzalo"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Jorquera"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Letur"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Lezuza"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Liétor"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Madrigueras"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Mahora"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Masegoso"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Minaya"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Molinicos"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Montalvos"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Montealegre del Castillo"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Motilleja"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Munera"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Navas de Jorquera"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Nerpio"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Ontur"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Ossa de Montiel"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Paterna del Madera"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Peñas de San Pedro"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Peñascosa"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Pétrola"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Povedilla"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Pozo Cañada"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Pozohondo"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Pozo-Lorente"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Pozuelo"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Recueja, La"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Riópar"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Robledo"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Roda, La"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Salobre"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "San Pedro"
  },
  {
    "parent_code": "02",
    "code": "9",
    "label": "Socovos"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Tarazona de la Mancha"
  },
  {
    "parent_code": "02",
    "code": "0",
    "label": "Tobarra"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Valdeganga"
  },
  {
    "parent_code": "02",
    "code": "6",
    "label": "Vianos"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Villa de Ves"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Villalgordo del Júcar"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Villamalea"
  },
  {
    "parent_code": "02",
    "code": "5",
    "label": "Villapalacios"
  },
  {
    "parent_code": "02",
    "code": "2",
    "label": "Villarrobledo"
  },
  {
    "parent_code": "02",
    "code": "7",
    "label": "Villatoya"
  },
  {
    "parent_code": "02",
    "code": "3",
    "label": "Villavaliente"
  },
  {
    "parent_code": "02",
    "code": "8",
    "label": "Villaverde de Guadalimar"
  },
  {
    "parent_code": "02",
    "code": "1",
    "label": "Viveros"
  },
  {
    "parent_code": "02",
    "code": "4",
    "label": "Yeste"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Agost"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Agres"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Aigües"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Albatera"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Alcalalí"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Alcocer de Planes"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Alcoleja"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Alcoy/Alcoi"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Alfafara"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Alfàs del Pi, l'"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Algorfa"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Algueña"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Alicante/Alacant"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Almoradí"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Almudaina"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Alqueria d'Asnar, l'"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Altea"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Aspe"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Atzúbia, l'"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Balones"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Banyeres de Mariola"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Benasau"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Beneixama"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Benejúzar"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Benferri"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Beniarbeig"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Beniardá"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Beniarrés"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Benidoleig"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Benidorm"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Benifallim"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Benifato"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Benigembla"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Benijófar"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Benilloba"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Benillup"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Benimantell"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Benimarfull"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Benimassot"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Benimeli"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Benissa"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Benitachell/Poble Nou de Benitatxell, el"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Biar"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Bigastro"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Bolulla"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Busot"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Callosa de Segura"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Callosa d'en Sarrià"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Calp"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Campello, el"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Campo de Mirra/Camp de Mirra, el"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Cañada"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Castalla"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Castell de Castells"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Castell de Guadalest, el"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Catral"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Cocentaina"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Confrides"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Cox"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Crevillent"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Daya Nueva"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Daya Vieja"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Dénia"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Dolores"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Elche/Elx"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Elda"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Facheca"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Famorca"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Finestrat"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Fondó de les Neus, el/Hondón de las Nieves"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Formentera del Segura"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Gaianes"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Gata de Gorgos"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Gorga"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Granja de Rocamora"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Guardamar del Segura"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Hondón de los Frailes"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Ibi"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Jacarilla"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Jávea/Xàbia"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Jijona/Xixona"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Llíber"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Lorcha/Orxa, l'"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Millena"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Monforte del Cid"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Monóvar/Monòver"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Montesinos, Los"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Murla"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Muro de Alcoy"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Mutxamel"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Novelda"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Nucia, la"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Ondara"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Onil"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Orba"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Orihuela"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Orxeta"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Parcent"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Pedreguer"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Pego"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Penàguila"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Petrer"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Pilar de la Horadada"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Pinós, el/Pinoso"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Planes"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Poblets, els"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Polop"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Quatretondeta"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Rafal"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Ràfol d'Almúnia, el"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Redován"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Relleu"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Rojales"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Romana, la"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Sagra"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Salinas"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "San Fulgencio"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "San Isidro"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "San Miguel de Salinas"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "San Vicente del Raspeig/Sant Vicent del Raspeig"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Sanet y Negrals"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Sant Joan d'Alacant"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Santa Pola"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Sax"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Sella"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Senija"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Tàrbena"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Teulada"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Tibi"
  },
  {
    "parent_code": "03",
    "code": "1",
    "label": "Tollos"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Tormos"
  },
  {
    "parent_code": "03",
    "code": "3",
    "label": "Torremanzanas/Torre de les Maçanes, la"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Torrevieja"
  },
  {
    "parent_code": "03",
    "code": "4",
    "label": "Vall d'Alcalà, la"
  },
  {
    "parent_code": "03",
    "code": "0",
    "label": "Vall de Gallinera"
  },
  {
    "parent_code": "03",
    "code": "6",
    "label": "Vall de Laguar, la"
  },
  {
    "parent_code": "03",
    "code": "7",
    "label": "Vall d'Ebo, la"
  },
  {
    "parent_code": "03",
    "code": "2",
    "label": "Verger, el"
  },
  {
    "parent_code": "03",
    "code": "5",
    "label": "Villajoyosa/Vila Joiosa, la"
  },
  {
    "parent_code": "03",
    "code": "9",
    "label": "Villena"
  },
  {
    "parent_code": "03",
    "code": "8",
    "label": "Xaló"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Abla"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Abrucena"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Adra"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Albanchez"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Alboloduy"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Albox"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Alcolea"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Alcóntar"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Alcudia de Monteagud"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Alhabia"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Alhama de Almería"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Alicún"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Almería"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Almócita"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Alsodux"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Antas"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Arboleas"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Armuña de Almanzora"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Bacares"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Balanegra"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Bayárcal"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Bayarque"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Bédar"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Beires"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Benahadux"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Benitagla"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Benizalón"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Bentarique"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Berja"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Canjáyar"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Cantoria"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Carboneras"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Castro de Filabres"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Chercos"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Chirivel"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Cóbdar"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Cuevas del Almanzora"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Dalías"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Ejido, El"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Enix"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Felix"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Fines"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Fiñana"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Fondón"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Gádor"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Gallardos, Los"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Garrucha"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Gérgal"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Huécija"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Huércal de Almería"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Huércal-Overa"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Íllar"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Instinción"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Laroya"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Láujar de Andarax"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Líjar"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Lubrín"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Lucainena de las Torres"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Lúcar"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Macael"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "María"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Mojácar"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Mojonera, La"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Nacimiento"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Níjar"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Ohanes"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Olula de Castro"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Olula del Río"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Oria"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Padules"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Partaloa"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Paterna del Río"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Pechina"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Pulpí"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Purchena"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Rágol"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Rioja"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Roquetas de Mar"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Santa Cruz de Marchena"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Santa Fe de Mondújar"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Senés"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Serón"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Sierro"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Somontín"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Sorbas"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Suflí"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Tabernas"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Taberno"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Tahal"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Terque"
  },
  {
    "parent_code": "04",
    "code": "6",
    "label": "Tíjola"
  },
  {
    "parent_code": "04",
    "code": "1",
    "label": "Tres Villas, Las"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Turre"
  },
  {
    "parent_code": "04",
    "code": "7",
    "label": "Turrillas"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Uleila del Campo"
  },
  {
    "parent_code": "04",
    "code": "3",
    "label": "Urrácal"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Velefique"
  },
  {
    "parent_code": "04",
    "code": "5",
    "label": "Vélez-Blanco"
  },
  {
    "parent_code": "04",
    "code": "8",
    "label": "Vélez-Rubio"
  },
  {
    "parent_code": "04",
    "code": "2",
    "label": "Vera"
  },
  {
    "parent_code": "04",
    "code": "9",
    "label": "Viator"
  },
  {
    "parent_code": "04",
    "code": "4",
    "label": "Vícar"
  },
  {
    "parent_code": "04",
    "code": "0",
    "label": "Zurgena"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Adanero"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Adrada, La"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Albornos"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Aldeanueva de Santa Cruz"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Aldeaseca"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Aldehuela, La"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Amavida"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Arenal, El"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Arenas de San Pedro"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Arevalillo"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Arévalo"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Aveinte"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Avellaneda"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Ávila"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Barco de Ávila, El"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Barraco, El"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Barromán"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Becedas"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Becedillas"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Bercial de Zapardiel"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Berlanas, Las"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Bernuy-Zapardiel"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Berrocalejo de Aragona"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Blascomillán"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Blasconuño de Matacabras"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Blascosancho"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Bohodón, El"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Bohoyo"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Bonilla de la Sierra"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Brabos"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Bularros"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Burgohondo"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Cabezas de Alambre"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Cabezas del Pozo"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Cabezas del Villar"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Cabizuela"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Canales"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Candeleda"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Cantiveros"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Cardeñosa"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Carrera, La"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Casas del Puerto"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Casasola"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Casavieja"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Casillas"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Castellanos de Zapardiel"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Cebreros"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Cepeda la Mora"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Chamartín"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Cillán"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Cisla"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Colilla, La"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Collado de Contreras"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Collado del Mirón"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Constanzana"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Crespos"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Cuevas del Valle"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Diego del Carpio"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Donjimeno"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Donvidas"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Espinosa de los Caballeros"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Flores de Ávila"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Fontiveros"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Fresnedilla"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Fresno, El"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Fuente el Saúz"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Fuentes de Año"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Gallegos de Altamiros"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Gallegos de Sobrinos"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Garganta del Villar"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Gavilanes"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Gemuño"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Gil García"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Gilbuena"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Gimialcón"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Gotarrendura"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Grandes y San Martín"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Guisando"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Gutierre-Muñoz"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Hernansancho"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Herradón de Pinares"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Herreros de Suso"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Higuera de las Dueñas"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Hija de Dios, La"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Horcajada, La"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Horcajo de las Torres"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Hornillo, El"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Hoyo de Pinares, El"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Hoyocasero"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Hoyorredondo"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Hoyos de Miguel Muñoz"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Hoyos del Collado"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Hoyos del Espino"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Hurtumpascual"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Junciana"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Langa"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Lanzahíta"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Llanos de Tormes, Los"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Losar del Barco, El"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Madrigal de las Altas Torres"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Maello"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Malpartida de Corneja"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Mamblas"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Mancera de Arriba"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Manjabálago y Ortigosa de Rioalmar"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Marlín"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Martiherrero"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Martínez"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Mediana de Voltoya"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Medinilla"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Mengamuñoz"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Mesegar de Corneja"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Mijares"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Mingorría"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Mirón, El"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Mironcillo"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Mirueña de los Infanzones"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Mombeltrán"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Monsalupe"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Moraleja de Matacabras"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Muñana"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Muñico"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Muñogalindo"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Muñogrande"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Muñomer del Peco"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Muñopepe"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Muñosancho"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Muñotello"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Narrillos del Álamo"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Narrillos del Rebollar"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Narros de Saldueña"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Narros del Castillo"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Narros del Puerto"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Nava de Arévalo"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Nava del Barco"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Navacepedilla de Corneja"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Navadijos"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Navaescurial"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Navahondilla"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Navalacruz"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Navalmoral"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Navalonguilla"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Navalosa"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Navalperal de Pinares"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Navalperal de Tormes"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Navaluenga"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Navaquesera"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Navarredonda de Gredos"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Navarredondilla"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Navarrevisca"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Navas del Marqués, Las"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Navatalgordo"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Navatejares"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Neila de San Miguel"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Niharra"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Ojos-Albos"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Orbita"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Oso, El"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Padiernos"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Pajares de Adaja"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Palacios de Goda"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Papatrigo"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Parral, El"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Pascualcobo"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Pedro Bernardo"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Pedro-Rodríguez"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Peguerinos"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Peñalba de Ávila"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Piedrahíta"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Piedralaves"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Poveda"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Poyales del Hoyo"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Pozanco"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Pradosegar"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Puerto Castilla"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Rasueros"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Riocabado"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Riofrío"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Rivilla de Barajas"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Salobral"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Salvadiós"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "San Bartolomé de Béjar"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "San Bartolomé de Corneja"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "San Bartolomé de Pinares"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "San Esteban de los Patos"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "San Esteban de Zapardiel"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "San Esteban del Valle"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "San García de Ingelmos"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "San Juan de Gredos"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "San Juan de la Encinilla"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "San Juan de la Nava"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "San Juan del Molinillo"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "San Juan del Olmo"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "San Lorenzo de Tormes"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "San Martín de la Vega del Alberche"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "San Martín del Pimpollar"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "San Miguel de Corneja"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "San Miguel de Serrezuela"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "San Pascual"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "San Pedro del Arroyo"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "San Vicente de Arévalo"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Sanchidrián"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Sanchorreja"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Santa Cruz de Pinares"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Santa Cruz del Valle"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Santa María de los Caballeros"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Santa María del Arroyo"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Santa María del Berrocal"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Santa María del Cubillo"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Santa María del Tiétar"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Santiago del Collado"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Santiago del Tormes"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Santo Domingo de las Posadas"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Santo Tomé de Zabarcos"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Serrada, La"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Serranillos"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Sigeres"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Sinlabajos"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Solana de Ávila"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Solana de Rioalmar"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Solosancho"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Sotalbo"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Sotillo de la Adrada"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Tiemblo, El"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Tiñosillos"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Tolbaños"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Tormellas"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Tornadizos de Ávila"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Torre, La"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Tórtoles"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Umbrías"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Vadillo de la Sierra"
  },
  {
    "parent_code": "05",
    "code": "5",
    "label": "Valdecasa"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Vega de Santa María"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Velayos"
  },
  {
    "parent_code": "05",
    "code": "2",
    "label": "Villaflor"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Villafranca de la Sierra"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Villanueva de Ávila"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Villanueva de Gómez"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Villanueva del Aceral"
  },
  {
    "parent_code": "05",
    "code": "1",
    "label": "Villanueva del Campillo"
  },
  {
    "parent_code": "05",
    "code": "8",
    "label": "Villar de Corneja"
  },
  {
    "parent_code": "05",
    "code": "3",
    "label": "Villarejo del Valle"
  },
  {
    "parent_code": "05",
    "code": "9",
    "label": "Villatoro"
  },
  {
    "parent_code": "05",
    "code": "4",
    "label": "Viñegra de Moraña"
  },
  {
    "parent_code": "05",
    "code": "7",
    "label": "Vita"
  },
  {
    "parent_code": "05",
    "code": "0",
    "label": "Zapardiel de la Cañada"
  },
  {
    "parent_code": "05",
    "code": "6",
    "label": "Zapardiel de la Ribera"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Acedera"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Aceuchal"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Ahillones"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Alange"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Albuera, La"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Alburquerque"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Alconchel"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Alconera"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Aljucén"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Almendral"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Almendralejo"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Arroyo de San Serván"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Atalaya"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Azuaga"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Badajoz"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Barcarrota"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Baterno"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Benquerencia de la Serena"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Berlanga"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Bienvenida"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Bodonal de la Sierra"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Burguillos del Cerro"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Cabeza del Buey"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Cabeza la Vaca"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Calamonte"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Calera de León"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Calzadilla de los Barros"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Campanario"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Campillo de Llerena"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Capilla"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Carmonita"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Carrascalejo, El"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Casas de Don Pedro"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Casas de Reina"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Castilblanco"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Castuera"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Cheles"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Codosera, La"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Cordobilla de Lácara"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Coronada, La"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Corte de Peleas"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Cristina"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Don Álvaro"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Don Benito"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Entrín Bajo"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Esparragalejo"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Esparragosa de la Serena"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Esparragosa de Lares"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Feria"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Fregenal de la Sierra"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Fuenlabrada de los Montes"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Fuente de Cantos"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Fuente del Arco"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Fuente del Maestre"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Fuentes de León"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Garbayuela"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Garlitos"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Garrovilla, La"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Granja de Torrehermosa"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Guadiana del Caudillo"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Guareña"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Haba, La"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Helechosa de los Montes"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Herrera del Duque"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Higuera de la Serena"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Higuera de Llerena"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Higuera de Vargas"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Higuera la Real"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Hinojosa del Valle"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Hornachos"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Jerez de los Caballeros"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Lapa, La"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Llera"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Llerena"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Lobón"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Magacela"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Maguilla"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Malcocinado"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Malpartida de la Serena"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Manchita"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Medellín"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Medina de las Torres"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Mengabril"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Mérida"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Mirandilla"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Monesterio"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Montemolín"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Monterrubio de la Serena"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Montijo"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Morera, La"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Nava de Santiago, La"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Navalvillar de Pela"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Nogales"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Oliva de la Frontera"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Oliva de Mérida"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Olivenza"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Orellana de la Sierra"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Orellana la Vieja"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Palomas"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Parra, La"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Peñalsordo"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Peraleda del Zaucejo"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Puebla de Alcocer"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Puebla de la Calzada"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Puebla de la Reina"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Puebla de Obando"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Puebla de Sancho Pérez"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Puebla del Maestre"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Puebla del Prior"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Pueblonuevo del Guadiana"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Quintana de la Serena"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Reina"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Rena"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Retamal de Llerena"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Ribera del Fresno"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Risco"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Roca de la Sierra, La"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Salvaleón"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Salvatierra de los Barros"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "San Pedro de Mérida"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "San Vicente de Alcántara"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Sancti-Spíritus"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Santa Amalia"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Santa Marta"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Santos de Maimona, Los"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Segura de León"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Siruela"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Solana de los Barros"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Talarrubias"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Talavera la Real"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Táliga"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Tamurejo"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Torre de Miguel Sesmero"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Torremayor"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Torremejía"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Trasierra"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Trujillanos"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Usagre"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Valdecaballeros"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Valdelacalzada"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Valdetorres"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Valencia de las Torres"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Valencia del Mombuey"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Valencia del Ventoso"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Valle de la Serena"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Valle de Matamoros"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Valle de Santa Ana"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Valverde de Burguillos"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Valverde de Leganés"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Valverde de Llerena"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Valverde de Mérida"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Villafranca de los Barros"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Villagarcía de la Torre"
  },
  {
    "parent_code": "06",
    "code": "4",
    "label": "Villagonzalo"
  },
  {
    "parent_code": "06",
    "code": "9",
    "label": "Villalba de los Barros"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Villanueva de la Serena"
  },
  {
    "parent_code": "06",
    "code": "0",
    "label": "Villanueva del Fresno"
  },
  {
    "parent_code": "06",
    "code": "6",
    "label": "Villar de Rena"
  },
  {
    "parent_code": "06",
    "code": "3",
    "label": "Villar del Rey"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Villarta de los Montes"
  },
  {
    "parent_code": "06",
    "code": "8",
    "label": "Zafra"
  },
  {
    "parent_code": "06",
    "code": "1",
    "label": "Zahínos"
  },
  {
    "parent_code": "06",
    "code": "5",
    "label": "Zalamea de la Serena"
  },
  {
    "parent_code": "06",
    "code": "7",
    "label": "Zarza, La"
  },
  {
    "parent_code": "06",
    "code": "2",
    "label": "Zarza-Capilla"
  },
  {
    "parent_code": "07",
    "code": "7",
    "label": "Alaior"
  },
  {
    "parent_code": "07",
    "code": "2",
    "label": "Alaró"
  },
  {
    "parent_code": "07",
    "code": "3",
    "label": "Alcúdia"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Algaida"
  },
  {
    "parent_code": "07",
    "code": "1",
    "label": "Andratx"
  },
  {
    "parent_code": "07",
    "code": "3",
    "label": "Ariany"
  },
  {
    "parent_code": "07",
    "code": "4",
    "label": "Artà"
  },
  {
    "parent_code": "07",
    "code": "0",
    "label": "Banyalbufar"
  },
  {
    "parent_code": "07",
    "code": "6",
    "label": "Binissalem"
  },
  {
    "parent_code": "07",
    "code": "9",
    "label": "Búger"
  },
  {
    "parent_code": "07",
    "code": "3",
    "label": "Bunyola"
  },
  {
    "parent_code": "07",
    "code": "0",
    "label": "Calvià"
  },
  {
    "parent_code": "07",
    "code": "5",
    "label": "Campanet"
  },
  {
    "parent_code": "07",
    "code": "1",
    "label": "Campos"
  },
  {
    "parent_code": "07",
    "code": "6",
    "label": "Capdepera"
  },
  {
    "parent_code": "07",
    "code": "5",
    "label": "Castell, Es"
  },
  {
    "parent_code": "07",
    "code": "9",
    "label": "Ciutadella de Menorca"
  },
  {
    "parent_code": "07",
    "code": "2",
    "label": "Consell"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Costitx"
  },
  {
    "parent_code": "07",
    "code": "4",
    "label": "Deià"
  },
  {
    "parent_code": "07",
    "code": "0",
    "label": "Eivissa"
  },
  {
    "parent_code": "07",
    "code": "7",
    "label": "Escorca"
  },
  {
    "parent_code": "07",
    "code": "1",
    "label": "Esporles"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Estellencs"
  },
  {
    "parent_code": "07",
    "code": "3",
    "label": "Felanitx"
  },
  {
    "parent_code": "07",
    "code": "9",
    "label": "Ferreries"
  },
  {
    "parent_code": "07",
    "code": "4",
    "label": "Formentera"
  },
  {
    "parent_code": "07",
    "code": "7",
    "label": "Fornalutx"
  },
  {
    "parent_code": "07",
    "code": "6",
    "label": "Inca"
  },
  {
    "parent_code": "07",
    "code": "2",
    "label": "Lloret de Vistalegre"
  },
  {
    "parent_code": "07",
    "code": "5",
    "label": "Lloseta"
  },
  {
    "parent_code": "07",
    "code": "9",
    "label": "Llubí"
  },
  {
    "parent_code": "07",
    "code": "6",
    "label": "Llucmajor"
  },
  {
    "parent_code": "07",
    "code": "7",
    "label": "Manacor"
  },
  {
    "parent_code": "07",
    "code": "2",
    "label": "Mancor de la Vall"
  },
  {
    "parent_code": "07",
    "code": "1",
    "label": "Maó-Mahón"
  },
  {
    "parent_code": "07",
    "code": "5",
    "label": "Maria de la Salut"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Marratxí"
  },
  {
    "parent_code": "07",
    "code": "4",
    "label": "Mercadal, Es"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Migjorn Gran, Es"
  },
  {
    "parent_code": "07",
    "code": "0",
    "label": "Montuïri"
  },
  {
    "parent_code": "07",
    "code": "3",
    "label": "Muro"
  },
  {
    "parent_code": "07",
    "code": "7",
    "label": "Palma "
  },
  {
    "parent_code": "07",
    "code": "4",
    "label": "Petra"
  },
  {
    "parent_code": "07",
    "code": "0",
    "label": "Pobla, Sa"
  },
  {
    "parent_code": "07",
    "code": "9",
    "label": "Pollença"
  },
  {
    "parent_code": "07",
    "code": "5",
    "label": "Porreres"
  },
  {
    "parent_code": "07",
    "code": "3",
    "label": "Puigpunyent"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Salines, Ses"
  },
  {
    "parent_code": "07",
    "code": "6",
    "label": "Sant Antoni de Portmany"
  },
  {
    "parent_code": "07",
    "code": "1",
    "label": "Sant Joan"
  },
  {
    "parent_code": "07",
    "code": "4",
    "label": "Sant Joan de Labritja"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Sant Josep de sa Talaia"
  },
  {
    "parent_code": "07",
    "code": "1",
    "label": "Sant Llorenç des Cardassar"
  },
  {
    "parent_code": "07",
    "code": "6",
    "label": "Sant Lluís"
  },
  {
    "parent_code": "07",
    "code": "2",
    "label": "Santa Eugènia"
  },
  {
    "parent_code": "07",
    "code": "7",
    "label": "Santa Eulària des Riu"
  },
  {
    "parent_code": "07",
    "code": "0",
    "label": "Santa Margalida"
  },
  {
    "parent_code": "07",
    "code": "3",
    "label": "Santa María del Camí"
  },
  {
    "parent_code": "07",
    "code": "9",
    "label": "Santanyí"
  },
  {
    "parent_code": "07",
    "code": "5",
    "label": "Selva"
  },
  {
    "parent_code": "07",
    "code": "2",
    "label": "Sencelles"
  },
  {
    "parent_code": "07",
    "code": "2",
    "label": "Sineu"
  },
  {
    "parent_code": "07",
    "code": "9",
    "label": "Sóller"
  },
  {
    "parent_code": "07",
    "code": "4",
    "label": "Son Servera"
  },
  {
    "parent_code": "07",
    "code": "0",
    "label": "Valldemossa"
  },
  {
    "parent_code": "07",
    "code": "8",
    "label": "Vilafranca de Bonany"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Abrera"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Aguilar de Segarra"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Aiguafreda"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Alella"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Alpens"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Ametlla del Vallès, L'"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Arenys de Mar"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Arenys de Munt"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Argençola"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Argentona"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Artés"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Avià"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Avinyó"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Avinyonet del Penedès"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Badalona"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Badia del Vallès"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Bagà"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Balenyà"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Balsareny"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Barberà del Vallès"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Barcelona"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Begues"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Bellprat"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Berga"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Bigues i Riells"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Borredà"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Bruc, El"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Brull, El"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Cabanyes, Les"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Cabrera d'Anoia"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Cabrera de Mar"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Cabrils"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Calaf"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Calders"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Caldes de Montbui"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Caldes d'Estrac"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Calella"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Calldetenes"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Callús"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Calonge de Segarra"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Campins"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Canet de Mar"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Canovelles"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Cànoves i Samalús"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Canyelles"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Capellades"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Capolat"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Cardedeu"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Cardona"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Carme"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Casserres"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Castell de l'Areny"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Castellar de n'Hug"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Castellar del Riu"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Castellar del Vallès"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Castellbell i el Vilar"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Castellbisbal"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Castellcir"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Castelldefels"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Castellet i la Gornal"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Castellfollit de Riubregós"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Castellfollit del Boix"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Castellgalí"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Castellnou de Bages"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Castellolí"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Castellterçol"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Castellví de la Marca"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Castellví de Rosanes"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Centelles"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Cercs"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Cerdanyola del Vallès"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Cervelló"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Collbató"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Collsuspina"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Copons"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Corbera de Llobregat"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Cornellà de Llobregat"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Cubelles"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Dosrius"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Esparreguera"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Esplugues de Llobregat"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Espunyola, L'"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Esquirol, L'"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Estany, L'"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Figaró-Montmany"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Fígols"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Fogars de la Selva"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Fogars de Montclús"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Folgueroles"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Fonollosa"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Font-rubí"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Franqueses del Vallès, Les"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Gaià"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Gallifa"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Garriga, La"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Gavà"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Gelida"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Gironella"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Gisclareny"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Granada, La"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Granera"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Granollers"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Gualba"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Guardiola de Berguedà"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Gurb"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Hospitalet de Llobregat, L'"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Hostalets de Pierola, Els"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Igualada"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Jorba"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Llacuna, La"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Llagosta, La"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Lliçà d'Amunt"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Lliçà de Vall"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Llinars del Vallès"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Lluçà"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Malgrat de Mar"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Malla"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Manlleu"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Manresa"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Marganell"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Martorell"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Martorelles"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Masies de Roda, Les"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Masies de Voltregà, Les"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Masnou, El"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Masquefa"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Matadepera"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Mataró"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Mediona"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Moià"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Molins de Rei"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Mollet del Vallès"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Monistrol de Calders"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Monistrol de Montserrat"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Montcada i Reixac"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Montclar"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Montesquiu"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Montgat"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Montmajor"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Montmaneu"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Montmeló"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Montornès del Vallès"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Montseny"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Muntanyola"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Mura"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Navarcles"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Navàs"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Nou de Berguedà, La"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Òdena"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Olèrdola"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Olesa de Bonesvalls"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Olesa de Montserrat"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Olivella"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Olost"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Olvan"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Orís"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Oristà"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Orpí"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Òrrius"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Pacs del Penedès"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Palafolls"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Palau-solità i Plegamans"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Pallejà"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Palma de Cervelló, La"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Papiol, El"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Parets del Vallès"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Perafita"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Piera"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Pineda de Mar"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Pla del Penedès, El"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Pobla de Claramunt, La"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Pobla de Lillet, La"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Polinyà"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Pont de Vilomara i Rocafort, El"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Pontons"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Prat de Llobregat, El"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Prats de Lluçanès"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Prats de Rei, Els"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Premià de Dalt"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Premià de Mar"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Puigdàlber"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Puig-reig"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Pujalt"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Quar, La"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Rajadell"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Rellinars"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Ripollet"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Roca del Vallès, La"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Roda de Ter"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Rubí"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Rubió"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Rupit i Pruit"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sabadell"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sagàs"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Saldes"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sallent"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sant Adrià de Besòs"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Sant Agustí de Lluçanès"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Sant Andreu de la Barca"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Sant Andreu de Llavaneres"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Sant Antoni de Vilamajor"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Sant Bartomeu del Grau"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Sant Boi de Llobregat"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Sant Boi de Lluçanès"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Sant Cebrià de Vallalta"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Sant Celoni"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Sant Climent de Llobregat"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Sant Cugat del Vallès"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sant Cugat Sesgarrigues"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sant Esteve de Palautordera"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Sant Esteve Sesrovires"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Sant Feliu de Codines"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sant Feliu de Llobregat"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Sant Feliu Sasserra"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Sant Fost de Campsentelles"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Sant Fruitós de Bages"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Sant Hipòlit de Voltregà"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Sant Iscle de Vallalta"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Sant Jaume de Frontanyà"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sant Joan de Vilatorrada"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Sant Joan Despí"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Sant Julià de Cerdanyola"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Sant Julià de Vilatorta"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Sant Just Desvern"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Sant Llorenç d'Hortons"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Sant Llorenç Savall"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Sant Martí d'Albars"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sant Martí de Centelles"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sant Martí de Tous"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Sant Martí Sarroca"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Sant Martí Sesgueioles"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Sant Mateu de Bages"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Sant Pere de Ribes"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Sant Pere de Riudebitlles"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Sant Pere de Torelló"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Sant Pere de Vilamajor"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Sant Pere Sallavinera"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Sant Pol de Mar"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Sant Quintí de Mediona"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sant Quirze de Besora"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sant Quirze del Vallès"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Sant Quirze Safaja"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Sant Sadurní d'Anoia"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sant Sadurní d'Osormort"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Sant Salvador de Guardiola"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Sant Vicenç de Castellet"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Sant Vicenç de Montalt"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Sant Vicenç de Torelló"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sant Vicenç dels Horts"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Santa Cecília de Voltregà"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Santa Coloma de Cervelló"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Santa Coloma de Gramenet"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Santa Eugènia de Berga"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Santa Eulàlia de Riuprimer"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Santa Eulàlia de Ronçana"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Santa Fe del Penedès"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Santa Margarida de Montbui"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Santa Margarida i els Monjos"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Santa Maria de Besora"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Santa Maria de Martorelles"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Santa Maria de Merlès"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Santa Maria de Miralles"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Santa Maria de Palautordera"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Santa Maria d'Oló"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Santa Perpètua de Mogoda"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Santa Susanna"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Santpedor"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Sentmenat"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Seva"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Sitges"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Sobremunt"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Sora"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Subirats"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Súria"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Tagamanent"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Talamanca"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Taradell"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Tavèrnoles"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Tavertet"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Teià"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Terrassa"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Tiana"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Tona"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Tordera"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Torelló"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Torre de Claramunt, La"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Torrelavit"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Torrelles de Foix"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Torrelles de Llobregat"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Ullastrell"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Vacarisses"
  },
  {
    "parent_code": "08",
    "code": "2",
    "label": "Vallbona d'Anoia"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Vallcebre"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Vallgorguina"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Vallirana"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Vallromanes"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Veciana"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Vic"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Vilada"
  },
  {
    "parent_code": "08",
    "code": "5",
    "label": "Viladecans"
  },
  {
    "parent_code": "08",
    "code": "8",
    "label": "Viladecavalls"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Vilafranca del Penedès"
  },
  {
    "parent_code": "08",
    "code": "7",
    "label": "Vilalba Sasserra"
  },
  {
    "parent_code": "08",
    "code": "6",
    "label": "Vilanova de Sau"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Vilanova del Camí"
  },
  {
    "parent_code": "08",
    "code": "4",
    "label": "Vilanova del Vallès"
  },
  {
    "parent_code": "08",
    "code": "3",
    "label": "Vilanova i la Geltrú"
  },
  {
    "parent_code": "08",
    "code": "0",
    "label": "Vilassar de Dalt"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Vilassar de Mar"
  },
  {
    "parent_code": "08",
    "code": "1",
    "label": "Vilobí del Penedès"
  },
  {
    "parent_code": "08",
    "code": "9",
    "label": "Viver i Serrateix"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Abajas"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Adrada de Haza"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Aguas Cándidas"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Aguilar de Bureba"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Albillos"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Alcocero de Mola"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Alfoz de Bricia"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Alfoz de Quintanadueñas"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Alfoz de Santa Gadea"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Altable"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Altos, Los"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Ameyugo"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Anguix"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Aranda de Duero"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Arandilla"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Arauzo de Miel"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Arauzo de Salce"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Arauzo de Torre"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Arcos"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Arenillas de Riopisuerga"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Arija"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Arlanzón"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Arraya de Oca"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Atapuerca"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Ausines, Los"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Avellanosa de Muñó"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Bahabón de Esgueva"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Balbases, Los"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Baños de Valdearados"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Bañuelos de Bureba"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Barbadillo de Herreros"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Barbadillo del Mercado"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Barbadillo del Pez"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Barrio de Muñó"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Barrios de Bureba, Los"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Barrios de Colina"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Basconcillos del Tozo"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Bascuñana"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Belbimbre"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Belorado"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Berberana"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Berlangas de Roa"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Berzosa de Bureba"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Bozoó"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Brazacorta"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Briviesca"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Bugedo"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Buniel"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Burgos"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Busto de Bureba"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Cabañes de Esgueva"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Cabezón de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Caleruega"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Campillo de Aranda"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Campolara"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Canicosa de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Cantabrana"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Carazo"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Carcedo de Bureba"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Carcedo de Burgos"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Cardeñadijo"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Cardeñajimeno"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Cardeñuela Riopico"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Carrias"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Cascajares de Bureba"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Cascajares de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Castellanos de Castro"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Castil de Peones"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Castildelgado"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Castrillo de la Reina"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Castrillo de la Vega"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Castrillo de Riopisuerga"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Castrillo del Val"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Castrillo Mota de Judíos"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Castrojeriz"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Cavia"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Cayuela"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Cebrecos"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Celada del Camino"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Cerezo de Río Tirón"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Cerratón de Juarros"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Ciadoncha"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Cillaperlata"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Cilleruelo de Abajo"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Cilleruelo de Arriba"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Ciruelos de Cervera"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Cogollos"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Condado de Treviño"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Contreras"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Coruña del Conde"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Covarrubias"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Cubillo del Campo"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Cubo de Bureba"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Cueva de Roa, La"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Cuevas de San Clemente"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Encío"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Espinosa de Cervera"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Espinosa de los Monteros"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Espinosa del Camino"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Estépar"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Fontioso"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Frandovínez"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Fresneda de la Sierra Tirón"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Fresneña"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Fresnillo de las Dueñas"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Fresno de Río Tirón"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Fresno de Rodilla"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Frías"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Fuentebureba"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Fuentecén"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Fuentelcésped"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Fuentelisendo"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Fuentemolinos"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Fuentenebro"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Fuentespina"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Galbarros"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Gallega, La"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Grijalba"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Grisaleña"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Gumiel de Izán"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Gumiel de Mercado"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Hacinas"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Haza"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Hontanas"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Hontangas"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Hontoria de la Cantera"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Hontoria de Valdearados"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Hontoria del Pinar"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Hormazas, Las"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Hornillos del Camino"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Horra, La"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Hortigüela"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Hoyales de Roa"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Huérmeces"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Huerta de Arriba"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Huerta de Rey"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Humada"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Hurones"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Ibeas de Juarros"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Ibrillos"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Iglesiarrubia"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Iglesias"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Isar"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Itero del Castillo"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Jaramillo de la Fuente"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Jaramillo Quemado"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Junta de Traslaloma"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Junta de Villalba de Losa"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Jurisdicción de Lara"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Jurisdicción de San Zadornil"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Lerma"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Llano de Bureba"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Madrigal del Monte"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Madrigalejo del Monte"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Mahamud"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Mambrilla de Castrejón"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Mambrillas de Lara"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Mamolar"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Manciles"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Mazuela"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Mecerreyes"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Medina de Pomar"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Melgar de Fernamental"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Merindad de Cuesta-Urria"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Merindad de Montija"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Merindad de Río Ubierna"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Merindad de Sotoscueva"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Merindad de Valdeporres"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Merindad de Valdivielso"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Milagros"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Miranda de Ebro"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Miraveche"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Modúbar de la Emparedada"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Monasterio de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Monasterio de Rodilla"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Moncalvillo"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Monterrubio de la Demanda"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Montorio"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Moradillo de Roa"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Nava de Roa"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Navas de Bureba"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Nebreda"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Neila"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Olmedillo de Roa"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Olmillos de Muñó"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Oña"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Oquillas"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Orbaneja Riopico"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Padilla de Abajo"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Padilla de Arriba"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Padrones de Bureba"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Palacios de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Palacios de Riopisuerga"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Palazuelos de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Palazuelos de Muñó"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Pampliega"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Pancorbo"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Pardilla"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Partido de la Sierra en Tobalina"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Pedrosa de Duero"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Pedrosa de Río Úrbel"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Pedrosa del Páramo"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Pedrosa del Príncipe"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Peñaranda de Duero"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Peral de Arlanza"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Piérnigas"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Pineda de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Pineda Trasmonte"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Pinilla de los Barruecos"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Pinilla de los Moros"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Pinilla Trasmonte"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Poza de la Sal"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Prádanos de Bureba"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Pradoluengo"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Presencio"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Puebla de Arganzón, La"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Puentedura"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Quemada"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Quintana del Pidio"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Quintanabureba"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Quintanaélez"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Quintanaortuño"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Quintanapalla"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Quintanar de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Quintanavides"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Quintanilla de la Mata"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Quintanilla del Agua y Tordueles"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Quintanilla del Coco"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Quintanilla San García"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Quintanilla Vivar"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Quintanillas, Las"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Rabanera del Pinar"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Rábanos"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Rabé de las Calzadas"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Rebolledo de la Torre"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Redecilla del Camino"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Redecilla del Campo"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Regumiel de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Reinoso"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Retuerta"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Revilla del Campo"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Revilla Vallejera"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Revilla y Ahedo, La"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Revillarruz"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Rezmondo"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Riocavado de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Roa"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Rojas"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Royuela de Río Franco"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Rubena"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Rublacedo de Abajo"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Rucandio"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Salas de Bureba"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Salas de los Infantes"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Saldaña de Burgos"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Salinillas de Bureba"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "San Adrián de Juarros"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "San Juan del Monte"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "San Mamés de Burgos"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "San Martín de Rubiales"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "San Millán de Lara"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "San Vicente del Valle"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Santa Cecilia"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Santa Cruz de la Salceda"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Santa Cruz del Valle Urbión"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Santa Gadea del Cid"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Santa Inés"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Santa María del Campo"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Santa María del Invierno"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Santa María del Mercadillo"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Santa María Rivarredonda"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Santa Olalla de Bureba"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Santibáñez de Esgueva"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Santibáñez del Val"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Santo Domingo de Silos"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Sargentes de la Lora"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Sarracín"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Sasamón"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Sequera de Haza, La"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Solarana"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Sordillos"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Sotillo de la Ribera"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Sotragero"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Sotresgudo"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Susinos del Páramo"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Tamarón"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Tardajos"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Tejada"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Terradillos de Esgueva"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Tinieblas de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Tobar"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Tordómar"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Torrecilla del Monte"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Torregalindo"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Torrelara"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Torrepadre"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Torresandino"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Tórtoles de Esgueva"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Tosantos"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Trespaderne"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Tubilla del Agua"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Tubilla del Lago"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Úrbel del Castillo"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Vadocondes"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Valdeande"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Valdezate"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Valdorros"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Vallarta de Bureba"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Valle de las Navas"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Valle de Losa"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Valle de Manzanedo"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Valle de Mena"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Valle de Oca"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Valle de Santibáñez"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Valle de Sedano"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Valle de Tobalina"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Valle de Valdebezana"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Valle de Valdelaguna"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Valle de Valdelucio"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Valle de Zamanzas"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Vallejera"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Valles de Palenzuela"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Valluércanes"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Valmala"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Vid de Bureba, La"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Vid y Barrios, La"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Vileña"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Villadiego"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Villaescusa de Roa"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Villaescusa la Sombría"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Villaespasa"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Villafranca Montes de Oca"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Villafruela"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Villagalijo"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Villagonzalo Pedernales"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Villahoz"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Villalba de Duero"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Villalbilla de Burgos"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Villalbilla de Gumiel"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Villaldemiro"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Villalmanzo"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Villamayor de los Montes"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Villamayor de Treviño"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Villambistia"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Villamedianilla"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Villamiel de la Sierra"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Villangómez"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Villanueva de Argaño"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Villanueva de Carazo"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Villanueva de Gumiel"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Villanueva de Teba"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Villaquirán de la Puebla"
  },
  {
    "parent_code": "09",
    "code": "8",
    "label": "Villaquirán de los Infantes"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Villarcayo de Merindad de Castilla la Vieja"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Villariezo"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Villasandino"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Villasur de Herreros"
  },
  {
    "parent_code": "09",
    "code": "0",
    "label": "Villatuelda"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Villaverde del Monte"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Villaverde-Mogina"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Villayerno Morquillas"
  },
  {
    "parent_code": "09",
    "code": "7",
    "label": "Villazopeque"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Villegas"
  },
  {
    "parent_code": "09",
    "code": "4",
    "label": "Villoruebo"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Viloria de Rioja"
  },
  {
    "parent_code": "09",
    "code": "2",
    "label": "Vilviestre del Pinar"
  },
  {
    "parent_code": "09",
    "code": "6",
    "label": "Vizcaínos"
  },
  {
    "parent_code": "09",
    "code": "3",
    "label": "Zael"
  },
  {
    "parent_code": "09",
    "code": "5",
    "label": "Zarzosa de Río Pisuerga"
  },
  {
    "parent_code": "09",
    "code": "1",
    "label": "Zazuar"
  },
  {
    "parent_code": "09",
    "code": "9",
    "label": "Zuñeda"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Abadía"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Abertura"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Acebo"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Acehúche"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Aceituna"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Ahigal"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Alagón del Río"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Albalá"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Alcántara"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Alcollarín"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Alcuéscar"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Aldea del Cano"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Aldea del Obispo, La"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Aldeacentenera"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Aldeanueva de la Vera"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Aldeanueva del Camino"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Aldehuela de Jerte"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Alía"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Aliseda"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Almaraz"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Almoharín"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Arroyo de la Luz"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Arroyomolinos"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Arroyomolinos de la Vera"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Baños de Montemayor"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Barrado"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Belvís de Monroy"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Benquerencia"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Berrocalejo"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Berzocana"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Bohonal de Ibor"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Botija"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Brozas"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Cabañas del Castillo"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Cabezabellosa"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Cabezuela del Valle"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Cabrero"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Cáceres"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Cachorrilla"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Cadalso"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Calzadilla"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Caminomorisco"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Campillo de Deleitosa"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Campo Lugar"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Cañamero"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Cañaveral"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Carbajo"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Carcaboso"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Carrascalejo"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Casar de Cáceres"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Casar de Palomero"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Casares de las Hurdes"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Casas de Don Antonio"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Casas de Don Gómez"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Casas de Millán"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Casas de Miravete"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Casas del Castañar"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Casas del Monte"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Casatejada"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Casillas de Coria"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Castañar de Ibor"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Ceclavín"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Cedillo"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Cerezo"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Cilleros"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Collado de la Vera"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Conquista de la Sierra"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Coria"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Cuacos de Yuste"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Cumbre, La"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Deleitosa"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Descargamaría"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Eljas"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Escurial"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Fresnedoso de Ibor"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Galisteo"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Garciaz"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Garganta la Olla"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Garganta, La"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Gargantilla"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Gargüera"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Garrovillas de Alconétar"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Garvín"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Gata"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Gordo, El"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Granja, La"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Guadalupe"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Guijo de Coria"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Guijo de Galisteo"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Guijo de Granadilla"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Guijo de Santa Bárbara"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Herguijuela"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Hernán-Pérez"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Herrera de Alcántara"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Herreruela"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Hervás"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Higuera"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Hinojal"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Holguera"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Hoyos"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Huélaga"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Ibahernando"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Jaraicejo"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Jaraíz de la Vera"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Jarandilla de la Vera"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Jarilla"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Jerte"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Ladrillar"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Logrosán"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Losar de la Vera"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Madrigal de la Vera"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Madrigalejo"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Madroñera"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Majadas"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Malpartida de Cáceres"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Malpartida de Plasencia"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Marchagaz"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Mata de Alcántara"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Membrío"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Mesas de Ibor"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Miajadas"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Millanes"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Mirabel"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Mohedas de Granadilla"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Monroy"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Montánchez"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Montehermoso"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Moraleja"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Morcillo"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Navaconcejo"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Navalmoral de la Mata"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Navalvillar de Ibor"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Navas del Madroño"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Navezuelas"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Nuñomoral"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Oliva de Plasencia"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Palomero"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Pasarón de la Vera"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Pedroso de Acim"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Peraleda de la Mata"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Peraleda de San Román"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Perales del Puerto"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Pescueza"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Pesga, La"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Piedras Albas"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Pinofranqueado"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Piornal"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Plasencia"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Plasenzuela"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Portaje"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Portezuelo"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Pozuelo de Zarzón"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Pueblonuevo de Miramontes"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Puerto de Santa Cruz"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Rebollar"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Riolobos"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Robledillo de Gata"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Robledillo de la Vera"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Robledillo de Trujillo"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Robledollano"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Romangordo"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Rosalejo"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Ruanes"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Salorino"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Salvatierra de Santiago"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "San Martín de Trevejo"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Santa Ana"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Santa Cruz de la Sierra"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Santa Cruz de Paniagua"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Santa Marta de Magasca"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Santiago de Alcántara"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Santiago del Campo"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Santibáñez el Alto"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Santibáñez el Bajo"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Saucedilla"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Segura de Toro"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Serradilla"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Serrejón"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Sierra de Fuentes"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Talaván"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Talaveruela de la Vera"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Talayuela"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Tejeda de Tiétar"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Tiétar"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Toril"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Tornavacas"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Torno, El"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Torre de Don Miguel"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Torre de Santa María"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Torrecilla de los Ángeles"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Torrecillas de la Tiesa"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Torrejón el Rubio"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Torrejoncillo"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Torremenga"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Torremocha"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Torreorgaz"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Torrequemada"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Trujillo"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Valdastillas"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Valdecañas de Tajo"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Valdefuentes"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Valdehúncar"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Valdelacasa de Tajo"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Valdemorales"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Valdeobispo"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Valencia de Alcántara"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Valverde de la Vera"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Valverde del Fresno"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Vegaviana"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Viandar de la Vera"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Villa del Campo"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Villa del Rey"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Villamesías"
  },
  {
    "parent_code": "10",
    "code": "4",
    "label": "Villamiel"
  },
  {
    "parent_code": "10",
    "code": "1",
    "label": "Villanueva de la Sierra"
  },
  {
    "parent_code": "10",
    "code": "6",
    "label": "Villanueva de la Vera"
  },
  {
    "parent_code": "10",
    "code": "7",
    "label": "Villar de Plasencia"
  },
  {
    "parent_code": "10",
    "code": "2",
    "label": "Villar del Pedroso"
  },
  {
    "parent_code": "10",
    "code": "0",
    "label": "Villasbuenas de Gata"
  },
  {
    "parent_code": "10",
    "code": "3",
    "label": "Zarza de Granadilla"
  },
  {
    "parent_code": "10",
    "code": "9",
    "label": "Zarza de Montánchez"
  },
  {
    "parent_code": "10",
    "code": "5",
    "label": "Zarza la Mayor"
  },
  {
    "parent_code": "10",
    "code": "8",
    "label": "Zorita"
  },
  {
    "parent_code": "11",
    "code": "2",
    "label": "Alcalá de los Gazules"
  },
  {
    "parent_code": "11",
    "code": "7",
    "label": "Alcalá del Valle"
  },
  {
    "parent_code": "11",
    "code": "3",
    "label": "Algar"
  },
  {
    "parent_code": "11",
    "code": "8",
    "label": "Algeciras"
  },
  {
    "parent_code": "11",
    "code": "1",
    "label": "Algodonales"
  },
  {
    "parent_code": "11",
    "code": "4",
    "label": "Arcos de la Frontera"
  },
  {
    "parent_code": "11",
    "code": "0",
    "label": "Barbate"
  },
  {
    "parent_code": "11",
    "code": "6",
    "label": "Barrios, Los"
  },
  {
    "parent_code": "11",
    "code": "3",
    "label": "Benalup-Casas Viejas"
  },
  {
    "parent_code": "11",
    "code": "9",
    "label": "Benaocaz"
  },
  {
    "parent_code": "11",
    "code": "3",
    "label": "Bornos"
  },
  {
    "parent_code": "11",
    "code": "0",
    "label": "Bosque, El"
  },
  {
    "parent_code": "11",
    "code": "5",
    "label": "Cádiz"
  },
  {
    "parent_code": "11",
    "code": "1",
    "label": "Castellar de la Frontera"
  },
  {
    "parent_code": "11",
    "code": "9",
    "label": "Chiclana de la Frontera"
  },
  {
    "parent_code": "11",
    "code": "2",
    "label": "Chipiona"
  },
  {
    "parent_code": "11",
    "code": "6",
    "label": "Conil de la Frontera"
  },
  {
    "parent_code": "11",
    "code": "8",
    "label": "Espera"
  },
  {
    "parent_code": "11",
    "code": "4",
    "label": "Gastor, El"
  },
  {
    "parent_code": "11",
    "code": "7",
    "label": "Grazalema"
  },
  {
    "parent_code": "11",
    "code": "1",
    "label": "Jerez de la Frontera"
  },
  {
    "parent_code": "11",
    "code": "8",
    "label": "Jimena de la Frontera"
  },
  {
    "parent_code": "11",
    "code": "3",
    "label": "Línea de la Concepción, La"
  },
  {
    "parent_code": "11",
    "code": "9",
    "label": "Medina Sidonia"
  },
  {
    "parent_code": "11",
    "code": "4",
    "label": "Olvera"
  },
  {
    "parent_code": "11",
    "code": "7",
    "label": "Paterna de Rivera"
  },
  {
    "parent_code": "11",
    "code": "0",
    "label": "Prado del Rey"
  },
  {
    "parent_code": "11",
    "code": "6",
    "label": "Puerto de Santa María, El"
  },
  {
    "parent_code": "11",
    "code": "2",
    "label": "Puerto Real"
  },
  {
    "parent_code": "11",
    "code": "5",
    "label": "Puerto Serrano"
  },
  {
    "parent_code": "11",
    "code": "9",
    "label": "Rota"
  },
  {
    "parent_code": "11",
    "code": "6",
    "label": "San Fernando"
  },
  {
    "parent_code": "11",
    "code": "8",
    "label": "San José del Valle"
  },
  {
    "parent_code": "11",
    "code": "4",
    "label": "San Martín del Tesorillo"
  },
  {
    "parent_code": "11",
    "code": "7",
    "label": "San Roque"
  },
  {
    "parent_code": "11",
    "code": "1",
    "label": "Sanlúcar de Barrameda"
  },
  {
    "parent_code": "11",
    "code": "2",
    "label": "Setenil de las Bodegas"
  },
  {
    "parent_code": "11",
    "code": "5",
    "label": "Tarifa"
  },
  {
    "parent_code": "11",
    "code": "8",
    "label": "Torre Alháquime"
  },
  {
    "parent_code": "11",
    "code": "4",
    "label": "Trebujena"
  },
  {
    "parent_code": "11",
    "code": "0",
    "label": "Ubrique"
  },
  {
    "parent_code": "11",
    "code": "3",
    "label": "Vejer de la Frontera"
  },
  {
    "parent_code": "11",
    "code": "7",
    "label": "Villaluenga del Rosario"
  },
  {
    "parent_code": "11",
    "code": "4",
    "label": "Villamartín"
  },
  {
    "parent_code": "11",
    "code": "9",
    "label": "Zahara"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Aín"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Albocàsser"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Alcalà de Xivert"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Alcora, l'"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Alcudia de Veo"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Alfondeguilla"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Algimia de Almonacid"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Almassora"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Almedíjar"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Almenara"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Alqueries, les/Alquerías del Niño Perdido"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Altura"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Arañuel"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Ares del Maestrat"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Argelita"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Artana"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Atzeneta del Maestrat"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Ayódar"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Azuébar"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Barracas"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Bejís"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Benafer"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Benafigos"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Benassal"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Benicarló"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Benicasim/Benicàssim"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Benlloc"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Betxí"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Borriana/Burriana"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Borriol"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Cabanes"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Càlig"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Canet lo Roig"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Castell de Cabres"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Castellfort"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Castellnovo"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Castellón de la Plana/Castelló de la Plana"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Castillo de Villamalefa"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Catí"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Caudiel"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Cervera del Maestre"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Chilches/Xilxes"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Chodos/Xodos"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Chóvar"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Cinctorres"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Cirat"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Cortes de Arenoso"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Costur"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Coves de Vinromà, les"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Culla"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Eslida"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Espadilla"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Fanzara"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Figueroles"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Forcall"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Fuente la Reina"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Fuentes de Ayódar"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Gaibiel"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Geldo"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Herbés"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Higueras"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Jana, la"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Jérica"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Llosa, la"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Llucena/Lucena del Cid"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Ludiente"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Mata de Morella, la"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Matet"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Moncofa"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Montán"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Montanejos"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Morella"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Navajas"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Nules"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Olocau del Rey"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Onda"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Oropesa del Mar/Orpesa"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Palanques"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Pavías"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Peníscola/Peñíscola"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Pina de Montalgrao"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Pobla de Benifassà, la"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Pobla Tornesa, la"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Portell de Morella"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Puebla de Arenoso"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Ribesalbes"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Rossell"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Sacañet"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Salzadella, la"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "San Rafael del Río"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Sant Joan de Moró"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Sant Jordi/San Jorge"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Sant Mateu"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Santa Magdalena de Pulpis"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Segorbe"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Serratella, la"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Sierra Engarcerán"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Soneja"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Sot de Ferrer"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Sueras/Suera"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Tales"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Teresa"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Tírig"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Todolella"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Toga"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Torás"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Toro, El"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Torralba del Pinar"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Torre d'En Besora, la"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Torre d'en Doménec, la"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Torreblanca"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Torrechiva"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Traiguera"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Useras/Useres, les"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Vall d'Alba"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Vall de Almonacid"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Vall d'Uixó, la"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Vallat"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Vallibona"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Vilafamés"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Vilafranca/Villafranca del Cid"
  },
  {
    "parent_code": "12",
    "code": "5",
    "label": "Vilanova d'Alcolea"
  },
  {
    "parent_code": "12",
    "code": "6",
    "label": "Vilar de Canes"
  },
  {
    "parent_code": "12",
    "code": "9",
    "label": "Vila-real"
  },
  {
    "parent_code": "12",
    "code": "2",
    "label": "Vilavella, la"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Villahermosa del Río"
  },
  {
    "parent_code": "12",
    "code": "0",
    "label": "Villamalur"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Villanueva de Viver"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Villores"
  },
  {
    "parent_code": "12",
    "code": "4",
    "label": "Vinaròs"
  },
  {
    "parent_code": "12",
    "code": "7",
    "label": "Vistabella del Maestrat"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Viver"
  },
  {
    "parent_code": "12",
    "code": "1",
    "label": "Xert"
  },
  {
    "parent_code": "12",
    "code": "8",
    "label": "Zorita del Maestrazgo"
  },
  {
    "parent_code": "12",
    "code": "3",
    "label": "Zucaina"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Abenójar"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Agudo"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Alamillo"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Albaladejo"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Alcázar de San Juan"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Alcoba"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Alcolea de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Alcubillas"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Aldea del Rey"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Alhambra"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Almadén"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Almadenejos"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Almagro"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Almedina"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Almodóvar del Campo"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Almuradiel"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Anchuras"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Arenales de San Gregorio"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Arenas de San Juan"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Argamasilla de Alba"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Argamasilla de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Arroba de los Montes"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Ballesteros de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Bolaños de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Brazatortas"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Cabezarados"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Cabezarrubias del Puerto"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Calzada de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Campo de Criptana"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Cañada de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Caracuel de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Carrión de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Carrizosa"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Castellar de Santiago"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Chillón"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Ciudad Real"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Corral de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Cortijos, Los"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Cózar"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Daimiel"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Fernán Caballero"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Fontanarejo"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Fuencaliente"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Fuenllana"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Fuente el Fresno"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Granátula de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Guadalmez"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Herencia"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Hinojosas de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Horcajo de los Montes"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Labores, Las"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Llanos del Caudillo"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Luciana"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Malagón"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Manzanares"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Membrilla"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Mestanza"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Miguelturra"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Montiel"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Moral de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Navalpino"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Navas de Estena"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Pedro Muñoz"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Picón"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Piedrabuena"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Poblete"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Porzuna"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Pozuelo de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Pozuelos de Calatrava, Los"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Puebla de Don Rodrigo"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Puebla del Príncipe"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Puerto Lápice"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Puertollano"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Retuerta del Bullaque"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Robledo, El"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Ruidera"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Saceruela"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "San Carlos del Valle"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "San Lorenzo de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Santa Cruz de los Cáñamos"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Santa Cruz de Mudela"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Socuéllamos"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Solana del Pino"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Solana, La"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Terrinches"
  },
  {
    "parent_code": "13",
    "code": "1",
    "label": "Tomelloso"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Torralba de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Torre de Juan Abad"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Torrenueva"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Valdemanco del Esteras"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Valdepeñas"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Valenzuela de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Villahermosa"
  },
  {
    "parent_code": "13",
    "code": "7",
    "label": "Villamanrique"
  },
  {
    "parent_code": "13",
    "code": "4",
    "label": "Villamayor de Calatrava"
  },
  {
    "parent_code": "13",
    "code": "9",
    "label": "Villanueva de la Fuente"
  },
  {
    "parent_code": "13",
    "code": "5",
    "label": "Villanueva de los Infantes"
  },
  {
    "parent_code": "13",
    "code": "0",
    "label": "Villanueva de San Carlos"
  },
  {
    "parent_code": "13",
    "code": "3",
    "label": "Villar del Pozo"
  },
  {
    "parent_code": "13",
    "code": "6",
    "label": "Villarrubia de los Ojos"
  },
  {
    "parent_code": "13",
    "code": "2",
    "label": "Villarta de San Juan"
  },
  {
    "parent_code": "13",
    "code": "8",
    "label": "Viso del Marqués"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Adamuz"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Aguilar de la Frontera"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Alcaracejos"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Almedinilla"
  },
  {
    "parent_code": "14",
    "code": "7",
    "label": "Almodóvar del Río"
  },
  {
    "parent_code": "14",
    "code": "0",
    "label": "Añora"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Baena"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Belalcázar"
  },
  {
    "parent_code": "14",
    "code": "5",
    "label": "Belmez"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Benamejí"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Blázquez, Los"
  },
  {
    "parent_code": "14",
    "code": "1",
    "label": "Bujalance"
  },
  {
    "parent_code": "14",
    "code": "7",
    "label": "Cabra"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Cañete de las Torres"
  },
  {
    "parent_code": "14",
    "code": "5",
    "label": "Carcabuey"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Cardeña"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Carlota, La"
  },
  {
    "parent_code": "14",
    "code": "0",
    "label": "Carpio, El"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Castro del Río"
  },
  {
    "parent_code": "14",
    "code": "7",
    "label": "Conquista"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Córdoba"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Doña Mencía"
  },
  {
    "parent_code": "14",
    "code": "5",
    "label": "Dos Torres"
  },
  {
    "parent_code": "14",
    "code": "0",
    "label": "Encinas Reales"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Espejo"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Espiel"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Fernán-Núñez"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Fuente Carreteros"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Fuente la Lancha"
  },
  {
    "parent_code": "14",
    "code": "1",
    "label": "Fuente Obejuna"
  },
  {
    "parent_code": "14",
    "code": "5",
    "label": "Fuente Palmera"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Fuente-Tójar"
  },
  {
    "parent_code": "14",
    "code": "7",
    "label": "Granjuela, La"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Guadalcázar"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Guijarrosa, La"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Guijo, El"
  },
  {
    "parent_code": "14",
    "code": "1",
    "label": "Hinojosa del Duque"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Hornachuelos"
  },
  {
    "parent_code": "14",
    "code": "0",
    "label": "Iznájar"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Lucena"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Luque"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Montalbán de Córdoba"
  },
  {
    "parent_code": "14",
    "code": "0",
    "label": "Montemayor"
  },
  {
    "parent_code": "14",
    "code": "5",
    "label": "Montilla"
  },
  {
    "parent_code": "14",
    "code": "1",
    "label": "Montoro"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Monturque"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Moriles"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Nueva Carteya"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Obejo"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Palenciana"
  },
  {
    "parent_code": "14",
    "code": "7",
    "label": "Palma del Río"
  },
  {
    "parent_code": "14",
    "code": "0",
    "label": "Pedro Abad"
  },
  {
    "parent_code": "14",
    "code": "7",
    "label": "Pedroche"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Peñarroya-Pueblonuevo"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Posadas"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Pozoblanco"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Priego de Córdoba"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Puente Genil"
  },
  {
    "parent_code": "14",
    "code": "5",
    "label": "Rambla, La"
  },
  {
    "parent_code": "14",
    "code": "1",
    "label": "Rute"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "San Sebastián de los Ballesteros"
  },
  {
    "parent_code": "14",
    "code": "5",
    "label": "Santa Eufemia"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Santaella"
  },
  {
    "parent_code": "14",
    "code": "0",
    "label": "Torrecampo"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Valenzuela"
  },
  {
    "parent_code": "14",
    "code": "1",
    "label": "Valsequillo"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Victoria, La"
  },
  {
    "parent_code": "14",
    "code": "7",
    "label": "Villa del Río"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Villafranca de Córdoba"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Villaharta"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Villanueva de Córdoba"
  },
  {
    "parent_code": "14",
    "code": "6",
    "label": "Villanueva del Duque"
  },
  {
    "parent_code": "14",
    "code": "3",
    "label": "Villanueva del Rey"
  },
  {
    "parent_code": "14",
    "code": "8",
    "label": "Villaralto"
  },
  {
    "parent_code": "14",
    "code": "4",
    "label": "Villaviciosa de Córdoba"
  },
  {
    "parent_code": "14",
    "code": "9",
    "label": "Viso, El"
  },
  {
    "parent_code": "14",
    "code": "2",
    "label": "Zuheros"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Abegondo"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Ames"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Aranga"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Ares"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Arteixo"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Arzúa"
  },
  {
    "parent_code": "15",
    "code": "9",
    "label": "Baña, A"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Bergondo"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Betanzos"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Boimorto"
  },
  {
    "parent_code": "15",
    "code": "9",
    "label": "Boiro"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Boqueixón"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Brión"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Cabana de Bergantiños"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Cabanas"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Camariñas"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Cambre"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Capela, A"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Carballo"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Cariño"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Carnota"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Carral"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Cedeira"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Cee"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Cerceda"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Cerdido"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Coirós"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Corcubión"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Coristanco"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Coruña, A"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Culleredo"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Curtis"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Dodro"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Dumbría"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Fene"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Ferrol"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Fisterra"
  },
  {
    "parent_code": "15",
    "code": "9",
    "label": "Frades"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Irixoa"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Laracha, A"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Laxe"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Lousame"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Malpica de Bergantiños"
  },
  {
    "parent_code": "15",
    "code": "9",
    "label": "Mañón"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Mazaricos"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Melide"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Mesía"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Miño"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Moeche"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Monfero"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Mugardos"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Muros"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Muxía"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Narón"
  },
  {
    "parent_code": "15",
    "code": "9",
    "label": "Neda"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Negreira"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Noia"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Oleiros"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Ordes"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Oroso"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Ortigueira"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Outes"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Oza-Cesuras"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Paderne"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Padrón"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Pino, O"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Pobra do Caramiñal, A"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Ponteceso"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Pontedeume"
  },
  {
    "parent_code": "15",
    "code": "9",
    "label": "Pontes de García Rodríguez, As"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Porto do Son"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Rianxo"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Ribeira"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Rois"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Sada"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "San Sadurniño"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Santa Comba"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Santiago de Compostela"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Santiso"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Sobrado"
  },
  {
    "parent_code": "15",
    "code": "4",
    "label": "Somozas, As"
  },
  {
    "parent_code": "15",
    "code": "9",
    "label": "Teo"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Toques"
  },
  {
    "parent_code": "15",
    "code": "0",
    "label": "Tordoia"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Touro"
  },
  {
    "parent_code": "15",
    "code": "6",
    "label": "Trazo"
  },
  {
    "parent_code": "15",
    "code": "8",
    "label": "Val do Dubra"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Valdoviño"
  },
  {
    "parent_code": "15",
    "code": "1",
    "label": "Vedra"
  },
  {
    "parent_code": "15",
    "code": "2",
    "label": "Vilarmaior"
  },
  {
    "parent_code": "15",
    "code": "5",
    "label": "Vilasantar"
  },
  {
    "parent_code": "15",
    "code": "7",
    "label": "Vimianzo"
  },
  {
    "parent_code": "15",
    "code": "3",
    "label": "Zas"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Abia de la Obispalía"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Acebrón, El"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Alarcón"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Albaladejo del Cuende"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Albalate de las Nogueras"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Albendea"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Alberca de Záncara, La"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Alcalá de la Vega"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Alcantud"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Alcázar del Rey"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Alcohujate"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Alconchel de la Estrella"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Algarra"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Aliaguilla"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Almarcha, La"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Almendros"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Almodóvar del Pinar"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Almonacid del Marquesado"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Altarejos"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Arandilla del Arroyo"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Arcas"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Arcos de la Sierra"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Arguisuelas"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Arrancacepas"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Atalaya del Cañavate"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Barajas de Melo"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Barchín del Hoyo"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Bascuñana de San Pedro"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Beamud"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Belinchón"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Belmonte"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Belmontejo"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Beteta"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Boniches"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Buciegas"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Buenache de Alarcón"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Buenache de la Sierra"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Buendía"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Campillo de Altobuey"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Campillos-Paravientos"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Campillos-Sierra"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Campos del Paraíso"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Canalejas del Arroyo"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Cañada del Hoyo"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Cañada Juncosa"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Cañamares"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Cañavate, El"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Cañaveras"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Cañaveruelas"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Cañete"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Cañizares"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Carboneras de Guadazaón"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Cardenete"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Carrascosa"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Carrascosa de Haro"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Casas de Benítez"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Casas de Fernando Alonso"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Casas de Garcimolina"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Casas de Guijarro"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Casas de Haro"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Casas de los Pinos"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Casasimarro"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Castejón"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Castillejo de Iniesta"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Castillejo-Sierra"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Castillo de Garcimuñoz"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Castillo-Albaráñez"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Cervera del Llano"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Chillarón de Cuenca"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Chumillas"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Cierva, La"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Cuenca"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Cueva del Hierro"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Enguídanos"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Fresneda de Altarejos"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Fresneda de la Sierra"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Frontera, La"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Fuente de Pedro Naharro"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Fuentelespino de Haro"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Fuentelespino de Moya"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Fuentenava de Jábaga"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Fuentes"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Fuertescusa"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Gabaldón"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Garaballa"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Gascueña"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Graja de Campalbo"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Graja de Iniesta"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Henarejos"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Herrumblar, El"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Hinojosa, La"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Hinojosos, Los"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Hito, El"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Honrubia"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Hontanaya"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Hontecillas"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Horcajo de Santiago"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Huélamo"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Huelves"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Huérguina"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Huerta de la Obispalía"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Huerta del Marquesado"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Huete"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Iniesta"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Laguna del Marquesado"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Lagunaseca"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Landete"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Ledaña"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Leganiel"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Majadas, Las"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Mariana"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Masegosa"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Mesas, Las"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Minglanilla"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Mira"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Monreal del Llano"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Montalbanejo"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Montalbo"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Monteagudo de las Salinas"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Mota de Altarejos"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Mota del Cuervo"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Motilla del Palancar"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Moya"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Narboneta"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Olivares de Júcar"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Olmeda de la Cuesta"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Olmeda del Rey"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Olmedilla de Alarcón"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Olmedilla de Eliz"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Osa de la Vega"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Pajarón"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Pajaroncillo"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Palomares del Campo"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Palomera"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Paracuellos"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Paredes"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Parra de las Vegas, La"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Pedernoso, El"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Pedroñeras, Las"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Peral, El"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Peraleja, La"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Pesquera, La"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Picazo, El"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Pinarejo"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Pineda de Gigüela"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Piqueras del Castillo"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Portalrubio de Guadamejud"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Portilla"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Poyatos"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Pozoamargo"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Pozorrubielos de la Mancha"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Pozorrubio de Santiago"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Pozuelo, El"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Priego"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Provencio, El"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Puebla de Almenara"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Puebla del Salvador"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Quintanar del Rey"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Rada de Haro"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Reíllo"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Rozalén del Monte"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Saceda-Trasierra"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Saelices"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Salinas del Manzano"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Salmeroncillos"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Salvacañete"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "San Clemente"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "San Lorenzo de la Parrilla"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "San Martín de Boniches"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "San Pedro Palmiches"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Santa Cruz de Moya"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Santa María de los Llanos"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Santa María del Campo Rus"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Santa María del Val"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Sisante"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Solera de Gabaldón"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Sotorribas"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Talayuelas"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Tarancón"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Tébar"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Tejadillos"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Tinajas"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Torralba"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Torrejoncillo del Rey"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Torrubia del Campo"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Torrubia del Castillo"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Tragacete"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Tresjuncos"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Tribaldos"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Uclés"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Uña"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Valdecolmenas, Los"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Valdemeca"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Valdemorillo de la Sierra"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Valdemoro-Sierra"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Valdeolivas"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Valdetórtola"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Valeras, Las"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Valhermoso de la Fuente"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Valle de Altomira, El"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Valsalobre"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Valverde de Júcar"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Valverdejo"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Vara de Rey"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Vega del Codorno"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Vellisca"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Villaconejos de Trabaque"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Villaescusa de Haro"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Villagarcía del Llano"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Villalba de la Sierra"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Villalba del Rey"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Villalgordo del Marquesado"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Villalpardo"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Villamayor de Santiago"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Villanueva de Guadamejud"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Villanueva de la Jara"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Villar de Cañas"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Villar de Domingo García"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Villar de la Encina"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Villar de Olalla"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Villar del Humo"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Villar del Infantado"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Villar y Velasco"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Villarejo de Fuentes"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Villarejo de la Peñuela"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Villarejo-Periesteban"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Villares del Saz"
  },
  {
    "parent_code": "16",
    "code": "0",
    "label": "Villarrubio"
  },
  {
    "parent_code": "16",
    "code": "7",
    "label": "Villarta"
  },
  {
    "parent_code": "16",
    "code": "2",
    "label": "Villas de la Ventosa"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Villaverde y Pasaconsol"
  },
  {
    "parent_code": "16",
    "code": "3",
    "label": "Víllora"
  },
  {
    "parent_code": "16",
    "code": "6",
    "label": "Vindel"
  },
  {
    "parent_code": "16",
    "code": "9",
    "label": "Yémeda"
  },
  {
    "parent_code": "16",
    "code": "5",
    "label": "Zafra de Záncara"
  },
  {
    "parent_code": "16",
    "code": "1",
    "label": "Zafrilla"
  },
  {
    "parent_code": "16",
    "code": "4",
    "label": "Zarza de Tajo"
  },
  {
    "parent_code": "16",
    "code": "8",
    "label": "Zarzuela"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Agullana"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Aiguaviva"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Albanyà"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Albons"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Alp"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Amer"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Anglès"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Arbúcies"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Argelaguer"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Armentera, L'"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Avinyonet de Puigventós"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Banyoles"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Bàscara"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Begur"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Bellcaire d'Empordà"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Besalú"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Bescanó"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Beuda"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Bisbal d'Empordà, La"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Biure"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Blanes"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Boadella i les Escaules"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Bolvir"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Bordils"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Borrassà"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Breda"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Brunyola i Sant Martí Sapresa"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Cabanelles"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Cabanes"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Cadaqués"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Caldes de Malavella"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Calonge i Sant Antoni"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Camós"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Campdevànol"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Campelles"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Campllong"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Camprodon"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Canet d'Adri"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Cantallops"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Capmany"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Cassà de la Selva"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Castellfollit de la Roca"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Castelló d'Empúries"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Castell-Platja d'Aro"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Cellera de Ter, La"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Celrà"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Cervià de Ter"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Cistella"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Colera"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Colomers"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Corçà"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Cornellà del Terri"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Crespià"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Cruïlles, Monells i Sant Sadurní de l'Heura"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Darnius"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Das"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Escala, L'"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Espinelves"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Espolla"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Esponellà"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Far d'Empordà, El"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Figueres"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Flaçà"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Foixà"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Fontanals de Cerdanya"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Fontanilles"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Fontcoberta"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Forallac"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Fornells de la Selva"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Fortià"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Garrigàs"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Garrigoles"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Garriguella"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Ger"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Girona"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Gombrèn"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Gualta"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Guils de Cerdanya"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Hostalric"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Isòvol"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Jafre"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Jonquera, La"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Juià"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Lladó"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Llagostera"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Llambilles"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Llanars"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Llançà"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Llers"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Llívia"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Lloret de Mar"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Llosses, Les"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Maçanet de Cabrenys"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Maçanet de la Selva"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Madremanya"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Maià de Montcal"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Masarac"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Massanes"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Meranges"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Mieres"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Mollet de Peralada"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Molló"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Montagut i Oix"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Mont-ras"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Navata"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Ogassa"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Olot"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Ordis"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Osor"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Palafrugell"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Palamós"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Palau de Santa Eulàlia"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Palau-sator"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Palau-saverdera"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Palol de Revardit"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Pals"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Pardines"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Parlavà"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Pau"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Pedret i Marzà"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Pera, La"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Peralada"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Planes d'Hostoles, Les"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Planoles"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Pont de Molins"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Pontós"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Porqueres"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Port de la Selva, El"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Portbou"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Preses, Les"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Puigcerdà"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Quart"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Queralbs"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Rabós"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Regencós"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Ribes de Freser"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Riells i Viabrea"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Ripoll"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Riudarenes"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Riudaura"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Riudellots de la Selva"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Riumors"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Roses"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Rupià"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Sales de Llierca"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Salt"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Sant Andreu Salou"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Sant Aniol de Finestres"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Sant Climent Sescebes"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Sant Feliu de Buixalleu"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Sant Feliu de Guíxols"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Sant Feliu de Pallerols"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Sant Ferriol"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Sant Gregori"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Sant Hilari Sacalm"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Sant Jaume de Llierca"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Sant Joan de les Abadesses"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Sant Joan de Mollet"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Sant Joan les Fonts"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Sant Jordi Desvalls"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Sant Julià de Ramis"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Sant Julià del Llor i Bonmatí"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Sant Llorenç de la Muga"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Sant Martí de Llémena"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Sant Martí Vell"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Sant Miquel de Campmajor"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Sant Miquel de Fluvià"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Sant Mori"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Sant Pau de Segúries"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Sant Pere Pescador"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Santa Coloma de Farners"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Santa Cristina d'Aro"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Santa Llogaia d'Àlguema"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Santa Pau"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Sarrià de Ter"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Saus, Camallera i Llampaies"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Selva de Mar, La"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Serinyà"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Serra de Daró"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Setcases"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Sils"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Siurana"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Susqueda"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Tallada d'Empordà, La"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Terrades"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Torrent"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Torroella de Fluvià"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Torroella de Montgrí"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Tortellà"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Toses"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Tossa de Mar"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Ullà"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Ullastret"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Ultramort"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Urús"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Vajol, La"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Vall de Bianya, La"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Vall d'en Bas, La"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Vallfogona de Ripollès"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Vall-llobrega"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Ventalló"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Verges"
  },
  {
    "parent_code": "17",
    "code": "1",
    "label": "Vidrà"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Vidreres"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Vilabertran"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Vilablareix"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Viladamat"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Viladasens"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Vilademuls"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Viladrau"
  },
  {
    "parent_code": "17",
    "code": "4",
    "label": "Vilafant"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Vilajuïga"
  },
  {
    "parent_code": "17",
    "code": "0",
    "label": "Vilallonga de Ter"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Vilamacolum"
  },
  {
    "parent_code": "17",
    "code": "6",
    "label": "Vilamalla"
  },
  {
    "parent_code": "17",
    "code": "2",
    "label": "Vilamaniscle"
  },
  {
    "parent_code": "17",
    "code": "8",
    "label": "Vilanant"
  },
  {
    "parent_code": "17",
    "code": "5",
    "label": "Vila-sacra"
  },
  {
    "parent_code": "17",
    "code": "9",
    "label": "Vilaür"
  },
  {
    "parent_code": "17",
    "code": "3",
    "label": "Vilobí d'Onyar"
  },
  {
    "parent_code": "17",
    "code": "7",
    "label": "Vilopriu"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Agrón"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Alamedilla"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Albolote"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Albondón"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Albuñán"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Albuñol"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Albuñuelas"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Aldeire"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Alfacar"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Algarinejo"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Alhama de Granada"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Alhendín"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Alicún de Ortega"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Almegíjar"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Almuñécar"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Alpujarra de la Sierra"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Alquife"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Arenas del Rey"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Armilla"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Atarfe"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Baza"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Beas de Granada"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Beas de Guadix"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Benalúa"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Benalúa de las Villas"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Benamaurel"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Bérchules"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Bubión"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Busquístar"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Cacín"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Cádiar"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Cájar"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Calahorra, La"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Calicasas"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Campotéjar"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Caniles"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Cáñar"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Capileira"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Carataunas"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Cástaras"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Castilléjar"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Castril"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Cenes de la Vega"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Chauchina"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Chimeneas"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Churriana de la Vega"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Cijuela"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Cogollos de Guadix"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Cogollos de la Vega"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Colomera"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Cortes de Baza"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Cortes y Graena"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Cuevas del Campo"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Cúllar"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Cúllar Vega"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Darro"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Dehesas de Guadix"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Dehesas Viejas"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Deifontes"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Diezma"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Dílar"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Dólar"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Domingo Pérez de Granada"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Dúdar"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Dúrcal"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Escúzar"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Ferreira"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Fonelas"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Fornes"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Freila"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Fuente Vaqueros"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Gabias, Las"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Galera"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Gobernador"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Gójar"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Gor"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Gorafe"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Granada"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Guadahortuna"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Guadix"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Guájares, Los"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Gualchos"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Güéjar Sierra"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Güevéjar"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Huélago"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Huéneja"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Huéscar"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Huétor de Santillán"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Huétor Tájar"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Huétor Vega"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Íllora"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Ítrabo"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Iznalloz"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Játar"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Jayena"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Jérez del Marquesado"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Jete"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Jun"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Juviles"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Láchar"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Lanjarón"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Lanteira"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Lecrín"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Lentegí"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Lobras"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Loja"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Lugros"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Lújar"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Malahá, La"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Maracena"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Marchal"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Moclín"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Molvízar"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Monachil"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Montefrío"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Montejícar"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Montillana"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Moraleda de Zafayona"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Morelábor"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Motril"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Murtas"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Nevada"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Nigüelas"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Nívar"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Ogíjares"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Orce"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Órgiva"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Otívar"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Padul"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Pampaneira"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Pedro Martínez"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Peligros"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Peza, La"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Pinar, El"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Pinos Genil"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Pinos Puente"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Píñar"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Polícar"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Polopos"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Pórtugos"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Puebla de Don Fadrique"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Pulianas"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Purullena"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Quéntar"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Rubite"
  },
  {
    "parent_code": "18",
    "code": "0",
    "label": "Salar"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Salobreña"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Santa Cruz del Comercio"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Santa Fe"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Soportújar"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Sorvilán"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Taha, La"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Torre-Cardela"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Torrenueva Costa"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Torvizcón"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Trevélez"
  },
  {
    "parent_code": "18",
    "code": "8",
    "label": "Turón"
  },
  {
    "parent_code": "18",
    "code": "3",
    "label": "Ugíjar"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Valderrubio"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Valle del Zalabí"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Valle, El"
  },
  {
    "parent_code": "18",
    "code": "9",
    "label": "Válor"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Vegas del Genil"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Vélez de Benaudalla"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Ventas de Huelma"
  },
  {
    "parent_code": "18",
    "code": "4",
    "label": "Villa de Otura"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Villamena"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Villanueva de las Torres"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Villanueva Mesía"
  },
  {
    "parent_code": "18",
    "code": "5",
    "label": "Víznar"
  },
  {
    "parent_code": "18",
    "code": "1",
    "label": "Zafarraya"
  },
  {
    "parent_code": "18",
    "code": "6",
    "label": "Zagra"
  },
  {
    "parent_code": "18",
    "code": "7",
    "label": "Zubia, La"
  },
  {
    "parent_code": "18",
    "code": "2",
    "label": "Zújar"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Abánades"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Ablanque"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Adobes"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Alaminos"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Alarilla"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Albalate de Zorita"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Albares"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Albendiego"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Alcocer"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Alcolea de las Peñas"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Alcolea del Pinar"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Alcoroches"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Aldeanueva de Guadalajara"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Algar de Mesa"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Algora"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Alhóndiga"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Alique"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Almadrones"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Almoguera"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Almonacid de Zorita"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Alocén"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Alovera"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Alustante"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Angón"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Anguita"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Anquela del Ducado"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Anquela del Pedregal"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Aranzueque"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Arbancón"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Arbeteta"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Argecilla"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Armallones"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Armuña de Tajuña"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Arroyo de las Fraguas"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Atanzón"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Atienza"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Auñón"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Azuqueca de Henares"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Baides"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Baños de Tajo"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Bañuelos"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Barriopedro"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Berninches"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Bodera, La"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Brihuega"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Budia"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Bujalaro"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Bustares"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Cabanillas del Campo"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Campillo de Dueñas"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Campillo de Ranas"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Campisábalos"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Canredondo"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Cantalojas"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Cañizar"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Cardoso de la Sierra, El"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Casa de Uceda"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Casar, El"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Casas de San Galindo"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Caspueñas"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Castejón de Henares"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Castellar de la Muela"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Castilforte"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Castilnuevo"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Cendejas de Enmedio"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Cendejas de la Torre"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Centenera"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Checa"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Chequilla"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Chillarón del Rey"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Chiloeches"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Cifuentes"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Cincovillas"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Ciruelas"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Ciruelos del Pinar"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Cobeta"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Cogollor"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Cogolludo"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Condemios de Abajo"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Condemios de Arriba"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Congostrina"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Copernal"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Corduente"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Cubillo de Uceda, El"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Driebes"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Durón"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Embid"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Escamilla"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Escariche"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Escopete"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Espinosa de Henares"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Esplegares"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Establés"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Estriégana"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Fontanar"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Fuembellida"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Fuencemillán"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Fuentelahiguera de Albatages"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Fuentelencina"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Fuentelsaz"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Fuentelviejo"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Fuentenovilla"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Gajanejos"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Galápagos"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Galve de Sorbe"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Gascueña de Bornova"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Guadalajara"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Henche"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Heras de Ayuso"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Herrería"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Hiendelaencina"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Hijes"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Hita"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Hombrados"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Hontoba"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Horche"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Hortezuela de Océn"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Huerce, La"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Huérmeces del Cerro"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Huertahernando"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Hueva"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Humanes"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Illana"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Iniéstola"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Inviernas, Las"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Irueste"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Jadraque"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Jirueque"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Ledanca"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Loranca de Tajuña"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Lupiana"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Luzaga"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Luzón"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Majaelrayo"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Málaga del Fresno"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Malaguilla"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Mandayona"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Mantiel"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Maranchón"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Marchamalo"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Masegoso de Tajuña"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Matarrubia"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Matillas"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Mazarete"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Mazuecos"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Medranda"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Megina"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Membrillera"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Miedes de Atienza"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Mierla, La"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Millana"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Milmarcos"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Miñosa, La"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Mirabueno"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Miralrío"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Mochales"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Mohernando"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Molina de Aragón"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Monasterio"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Mondéjar"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Montarrón"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Moratilla de los Meleros"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Morenilla"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Muduex"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Navas de Jadraque, Las"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Negredo"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Ocentejo"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Olivar, El"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Olmeda de Cobeta"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Olmeda de Jadraque, La"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Ordial, El"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Orea"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Pálmaces de Jadraque"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Pardos"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Paredes de Sigüenza"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Pareja"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Pastrana"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Pedregal, El"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Peñalén"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Peñalver"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Peralejos de las Truchas"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Peralveche"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Pinilla de Jadraque"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Pinilla de Molina"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Pioz"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Piqueras"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Pobo de Dueñas, El"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Poveda de la Sierra"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Pozo de Almoguera"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Pozo de Guadalajara"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Prádena de Atienza"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Prados Redondos"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Puebla de Beleña"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Puebla de Valles"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Quer"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Rebollosa de Jadraque"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Recuenco, El"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Renera"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Retiendas"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Riba de Saelices"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Rillo de Gallo"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Riofrío del Llano"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Robledillo de Mohernando"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Robledo de Corpes"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Romanillos de Atienza"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Romanones"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Rueda de la Sierra"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Sacecorbo"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Sacedón"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Saelices de la Sal"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Salmerón"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "San Andrés del Congosto"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "San Andrés del Rey"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Santiuste"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Saúca"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Sayatón"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Selas"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Semillas"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Setiles"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Sienes"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Sigüenza"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Solanillos del Extremo"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Somolinos"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Sotillo, El"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Sotodosos"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Tamajón"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Taragudo"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Taravilla"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Tartanedo"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Tendilla"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Terzaga"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Tierzo"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Toba, La"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Tordellego"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Tordelrábano"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Tordesilos"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Torija"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Torre del Burgo"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Torrecuadrada de Molina"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Torrecuadradilla"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Torrejón del Rey"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Torremocha de Jadraque"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Torremocha del Campo"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Torremocha del Pinar"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Torremochuela"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Torrubia"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Tórtola de Henares"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Tortuera"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Tortuero"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Traíd"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Trijueque"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Trillo"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Uceda"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Ujados"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Utande"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Valdarachas"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Valdearenas"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Valdeavellano"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Valdeaveruelo"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Valdeconcha"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Valdegrudas"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Valdelcubo"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Valdenuño Fernández"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Valdepeñas de la Sierra"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Valderrebollo"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Valdesotos"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Valfermoso de Tajuña"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Valhermoso"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Valtablado del Río"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Valverde de los Arroyos"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Viana de Jadraque"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Villanueva de Alcorón"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Villanueva de Argecilla"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Villanueva de la Torre"
  },
  {
    "parent_code": "19",
    "code": "2",
    "label": "Villares de Jadraque"
  },
  {
    "parent_code": "19",
    "code": "7",
    "label": "Villaseca de Henares"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Villaseca de Uceda"
  },
  {
    "parent_code": "19",
    "code": "8",
    "label": "Villel de Mesa"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Viñuelas"
  },
  {
    "parent_code": "19",
    "code": "4",
    "label": "Yebes"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Yebra"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Yélamos de Abajo"
  },
  {
    "parent_code": "19",
    "code": "3",
    "label": "Yélamos de Arriba"
  },
  {
    "parent_code": "19",
    "code": "0",
    "label": "Yunquera de Henares"
  },
  {
    "parent_code": "19",
    "code": "5",
    "label": "Yunta, La"
  },
  {
    "parent_code": "19",
    "code": "1",
    "label": "Zaorejas"
  },
  {
    "parent_code": "19",
    "code": "6",
    "label": "Zarzuela de Jadraque"
  },
  {
    "parent_code": "19",
    "code": "9",
    "label": "Zorita de los Canes"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Abaltzisketa"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Aduna"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Aia"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Aizarnazabal"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Albiztur"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Alegia"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Alkiza"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Altzaga"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Altzo"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Amezketa"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Andoain"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Anoeta"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Antzuola"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Arama"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Aretxabaleta"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Arrasate/Mondragón"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Asteasu"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Astigarraga"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Ataun"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Azkoitia"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Azpeitia"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Baliarrain"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Beasain"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Beizama"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Belauntza"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Berastegi"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Bergara"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Berrobi"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Bidania-Goiatz"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Deba"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Donostia/San Sebastián"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Eibar"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Elduain"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Elgeta"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Elgoibar"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Errenteria"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Errezil"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Eskoriatza"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Ezkio-Itsaso"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Gabiria"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Gaintza"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Gaztelu"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Getaria"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Hernani"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Hernialde"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Hondarribia"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Ibarra"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Idiazabal"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Ikaztegieta"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Irun"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Irura"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Itsasondo"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Larraul"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Lasarte-Oria"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Lazkao"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Leaburu"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Legazpi"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Legorreta"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Leintz-Gatzaga"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Lezo"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Lizartza"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Mendaro"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Mutiloa"
  },
  {
    "parent_code": "20",
    "code": "4",
    "label": "Mutriku"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Oiartzun"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Olaberria"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Oñati"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Ordizia"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Orendain"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Orexa"
  },
  {
    "parent_code": "20",
    "code": "0",
    "label": "Orio"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Ormaiztegi"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Pasaia"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Segura"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Soraluze-Placencia de las Armas"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Tolosa"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Urnieta"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Urretxu"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Usurbil"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Villabona"
  },
  {
    "parent_code": "20",
    "code": "2",
    "label": "Zaldibia"
  },
  {
    "parent_code": "20",
    "code": "5",
    "label": "Zarautz"
  },
  {
    "parent_code": "20",
    "code": "8",
    "label": "Zegama"
  },
  {
    "parent_code": "20",
    "code": "1",
    "label": "Zerain"
  },
  {
    "parent_code": "20",
    "code": "7",
    "label": "Zestoa"
  },
  {
    "parent_code": "20",
    "code": "3",
    "label": "Zizurkil"
  },
  {
    "parent_code": "20",
    "code": "6",
    "label": "Zumaia"
  },
  {
    "parent_code": "20",
    "code": "9",
    "label": "Zumarraga"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Alájar"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Aljaraque"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Almendro, El"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Almonaster la Real"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "Almonte"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Alosno"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "Aracena"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Aroche"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Arroyomolinos de León"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Ayamonte"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "Beas"
  },
  {
    "parent_code": "21",
    "code": "3",
    "label": "Berrocal"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "Bollullos Par del Condado"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Bonares"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Cabezas Rubias"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Cala"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Calañas"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Campillo, El"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Campofrío"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "Cañaveral de León"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Cartaya"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Castaño del Robledo"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Cerro de Andévalo, El"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Chucena"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Corteconcepción"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Cortegana"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "Cortelazor"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Cumbres de Enmedio"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Cumbres de San Bartolomé"
  },
  {
    "parent_code": "21",
    "code": "3",
    "label": "Cumbres Mayores"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Encinasola"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "Escacena del Campo"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Fuenteheridos"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Galaroza"
  },
  {
    "parent_code": "21",
    "code": "3",
    "label": "Gibraleón"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Granada de Río-Tinto, La"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Granado, El"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "Higuera de la Sierra"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Hinojales"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Hinojos"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Huelva"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Isla Cristina"
  },
  {
    "parent_code": "21",
    "code": "3",
    "label": "Jabugo"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "Lepe"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Linares de la Sierra"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Lucena del Puerto"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Manzanilla"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Marines, Los"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "Minas de Riotinto"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Moguer"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "Nava, La"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Nerva"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Niebla"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Palma del Condado, La"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "Palos de la Frontera"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Paterna del Campo"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Paymogo"
  },
  {
    "parent_code": "21",
    "code": "3",
    "label": "Puebla de Guzmán"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Puerto Moral"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Punta Umbría"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Rociana del Condado"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Rosal de la Frontera"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "San Bartolomé de la Torre"
  },
  {
    "parent_code": "21",
    "code": "3",
    "label": "San Juan del Puerto"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "San Silvestre de Guzmán"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Sanlúcar de Guadiana"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Santa Ana la Real"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Santa Bárbara de Casa"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Santa Olalla del Cala"
  },
  {
    "parent_code": "21",
    "code": "8",
    "label": "Trigueros"
  },
  {
    "parent_code": "21",
    "code": "5",
    "label": "Valdelarco"
  },
  {
    "parent_code": "21",
    "code": "0",
    "label": "Valverde del Camino"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Villablanca"
  },
  {
    "parent_code": "21",
    "code": "1",
    "label": "Villalba del Alcor"
  },
  {
    "parent_code": "21",
    "code": "4",
    "label": "Villanueva de las Cruces"
  },
  {
    "parent_code": "21",
    "code": "7",
    "label": "Villanueva de los Castillejos"
  },
  {
    "parent_code": "21",
    "code": "3",
    "label": "Villarrasa"
  },
  {
    "parent_code": "21",
    "code": "9",
    "label": "Zalamea la Real"
  },
  {
    "parent_code": "21",
    "code": "6",
    "label": "Zarza-Perrunal, La"
  },
  {
    "parent_code": "21",
    "code": "2",
    "label": "Zufre"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Abiego"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Abizanda"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Adahuesca"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Agüero"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Aínsa-Sobrarbe"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Aisa"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Albalate de Cinca"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Albalatillo"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Albelda"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Albero Alto"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Albero Bajo"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Alberuela de Tubo"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Alcalá de Gurrea"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Alcalá del Obispo"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Alcampell"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Alcolea de Cinca"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Alcubierre"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Alerre"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Alfántega"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Almudévar"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Almunia de San Juan"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Almuniente"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Alquézar"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Altorricón"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Angüés"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Ansó"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Antillón"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Aragüés del Puerto"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Arén"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Argavieso"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Arguis"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Ayerbe"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Azanuy-Alins"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Azara"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Azlor"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Baélls"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Bailo"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Baldellou"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Ballobar"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Banastás"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Barbastro"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Barbués"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Barbuñales"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Bárcabo"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Belver de Cinca"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Benabarre"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Benasque"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Beranuy"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Berbegal"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Bielsa"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Bierge"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Biescas"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Binaced"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Binéfar"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Bisaurri"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Biscarrués"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Blecua y Torres"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Boltaña"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Bonansa"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Borau"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Broto"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Caldearenas"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Campo"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Camporrélls"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Canal de Berdún"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Candasnos"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Canfranc"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Capdesaso"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Capella"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Casbas de Huesca"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Castejón de Monegros"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Castejón de Sos"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Castejón del Puente"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Castelflorite"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Castiello de Jaca"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Castigaleu"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Castillazuelo"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Castillonroy"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Chalamera"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Chía"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Chimillas"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Colungo"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Esplús"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Estada"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Estadilla"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Estopiñán del Castillo"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Fago"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Fanlo"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Fiscal"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Fonz"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Foradada del Toscar"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Fraga"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Fueva, La"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Gistaín"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Grado, El"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Grañén"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Graus"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Gurrea de Gállego"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Hoz de Jaca"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Hoz y Costean"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Huerto"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Huesca"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Ibieca"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Igriés"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Ilche"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Isábena"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Jaca"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Jasa"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Labuerda"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Laluenga"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Lalueza"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Lanaja"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Laperdiguera"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Lascellas-Ponzano"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Lascuarre"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Laspaúles"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Laspuña"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Loarre"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Loporzano"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Loscorrales"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Lupiñén-Ortilla"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Monesma y Cajigar"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Monflorite-Lascasas"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Montanuy"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Monzón"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Naval"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Novales"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Nueno"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Olvena"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Ontiñena"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Osso de Cinca"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Palo"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Panticosa"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Peñalba"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Peñas de Riglos, Las"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Peralta de Alcofea"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Peralta de Calasanz"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Peraltilla"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Perarrúa"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Pertusa"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Piracés"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Plan"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Poleñino"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Pozán de Vero"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Puebla de Castro, La"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Puente de Montañana"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Puente la Reina de Jaca"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Puértolas"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Pueyo de Araguás, El"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Pueyo de Santa Cruz"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Quicena"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Robres"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Sabiñánigo"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Sahún"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Salas Altas"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Salas Bajas"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Salillas"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Sallent de Gállego"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "San Esteban de Litera"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "San Juan de Plan"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "San Miguel del Cinca"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Sangarrén"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Santa Cilia"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Santa Cruz de la Serós"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Santa María de Dulcis"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Santaliestra y San Quílez"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Sariñena"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Secastilla"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Seira"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Sena"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Senés de Alcubierre"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Sesa"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Sesué"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Siétamo"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Sopeira"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Sotonera, La"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Tamarite de Litera"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Tardienta"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Tella-Sin"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Tierz"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Tolva"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Torla-Ordesa"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Torralba de Aragón"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Torre la Ribera"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Torrente de Cinca"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Torres de Alcanadre"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Torres de Barbués"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Tramaced"
  },
  {
    "parent_code": "22",
    "code": "0",
    "label": "Valfarta"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Valle de Bardají"
  },
  {
    "parent_code": "22",
    "code": "6",
    "label": "Valle de Hecho"
  },
  {
    "parent_code": "22",
    "code": "1",
    "label": "Valle de Lierp"
  },
  {
    "parent_code": "22",
    "code": "4",
    "label": "Velilla de Cinca"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Vencillón"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Viacamp y Litera"
  },
  {
    "parent_code": "22",
    "code": "9",
    "label": "Vicién"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Villanova"
  },
  {
    "parent_code": "22",
    "code": "5",
    "label": "Villanúa"
  },
  {
    "parent_code": "22",
    "code": "2",
    "label": "Villanueva de Sigena"
  },
  {
    "parent_code": "22",
    "code": "7",
    "label": "Yebra de Basa"
  },
  {
    "parent_code": "22",
    "code": "3",
    "label": "Yésero"
  },
  {
    "parent_code": "22",
    "code": "8",
    "label": "Zaidín"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Albanchez de Mágina"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Alcalá la Real"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Alcaudete"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Aldeaquemada"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Andújar"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Arjona"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Arjonilla"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Arquillos"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Arroyo del Ojanco"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Baeza"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Bailén"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Baños de la Encina"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Beas de Segura"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Bedmar y Garcíez"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Begíjar"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Bélmez de la Moraleda"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Benatae"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Cabra del Santo Cristo"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Cambil"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Campillo de Arenas"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Canena"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Carboneros"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Cárcheles"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Carolina, La"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Castellar"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Castillo de Locubín"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Cazalilla"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Cazorla"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Chiclana de Segura"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Chilluévar"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Escañuela"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Espeluy"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Frailes"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Fuensanta de Martos"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Fuerte del Rey"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Génave"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Guardia de Jaén, La"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Guarromán"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Higuera de Calatrava"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Hinojares"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Hornos"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Huelma"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Huesa"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Ibros"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Iruela, La"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Iznatoraf"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Jabalquinto"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Jaén"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Jamilena"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Jimena"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Jódar"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Lahiguera"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Larva"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Linares"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Lopera"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Lupión"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Mancha Real"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Marmolejo"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Martos"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Mengíbar"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Montizón"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Navas de San Juan"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Noalejo"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Orcera"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Peal de Becerro"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Pegalajar"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Porcuna"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Pozo Alcón"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Puente de Génave"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Puerta de Segura, La"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Quesada"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Rus"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Sabiote"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Santa Elena"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Santiago de Calatrava"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Santiago-Pontones"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Santisteban del Puerto"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Santo Tomé"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Segura de la Sierra"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Siles"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Sorihuela del Guadalimar"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Torreblascopedro"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Torredelcampo"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Torredonjimeno"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Torreperogil"
  },
  {
    "parent_code": "23",
    "code": "5",
    "label": "Torres"
  },
  {
    "parent_code": "23",
    "code": "2",
    "label": "Torres de Albánchez"
  },
  {
    "parent_code": "23",
    "code": "7",
    "label": "Úbeda"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Valdepeñas de Jaén"
  },
  {
    "parent_code": "23",
    "code": "8",
    "label": "Vilches"
  },
  {
    "parent_code": "23",
    "code": "1",
    "label": "Villacarrillo"
  },
  {
    "parent_code": "23",
    "code": "4",
    "label": "Villanueva de la Reina"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Villanueva del Arzobispo"
  },
  {
    "parent_code": "23",
    "code": "6",
    "label": "Villardompardo"
  },
  {
    "parent_code": "23",
    "code": "9",
    "label": "Villares, Los"
  },
  {
    "parent_code": "23",
    "code": "0",
    "label": "Villarrodrigo"
  },
  {
    "parent_code": "23",
    "code": "3",
    "label": "Villatorres"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Acebedo"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Algadefe"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Alija del Infantado"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Almanza"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Antigua, La"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Ardón"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Arganza"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Astorga"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Balboa"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Bañeza, La"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Barjas"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Barrios de Luna, Los"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Bembibre"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Benavides"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Benuza"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Bercianos del Páramo"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Bercianos del Real Camino"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Berlanga del Bierzo"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Boca de Huérgano"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Boñar"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Borrenes"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Brazuelo"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Burgo Ranero, El"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Burón"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Bustillo del Páramo"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Cabañas Raras"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Cabreros del Río"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Cabrillanes"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Cacabelos"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Calzada del Coto"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Campazas"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Campo de Villavidel"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Camponaraya"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Candín"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Cármenes"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Carracedelo"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Carrizo"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Carrocera"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Carucedo"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Castilfalé"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Castrillo de Cabrera"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Castrillo de la Valduerna"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Castrocalbón"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Castrocontrigo"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Castropodame"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Castrotierra de Valmadrigal"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Cea"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Cebanico"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Cebrones del Río"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Chozas de Abajo"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Cimanes de la Vega"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Cimanes del Tejar"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Cistierna"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Congosto"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Corbillos de los Oteros"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Corullón"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Crémenes"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Cuadros"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Cubillas de los Oteros"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Cubillas de Rueda"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Cubillos del Sil"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Destriana"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Encinedo"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Ercina, La"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Escobar de Campos"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Fabero"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Folgoso de la Ribera"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Fresno de la Vega"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Fuentes de Carbajal"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Garrafe de Torío"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Gordaliza del Pino"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Gordoncillo"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Gradefes"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Grajal de Campos"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Gusendos de los Oteros"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Hospital de Órbigo"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Igüeña"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Izagre"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Joarilla de las Matas"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Laguna Dalga"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Laguna de Negrillos"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "León"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Llamas de la Ribera"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Lucillo"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Luyego"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Magaz de Cepeda"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Mansilla de las Mulas"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Mansilla Mayor"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Maraña"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Matadeón de los Oteros"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Matallana de Torío"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Matanza"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Molinaseca"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Murias de Paredes"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Noceda del Bierzo"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Oencia"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Omañas, Las"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Onzonilla"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Oseja de Sajambre"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Pajares de los Oteros"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Palacios de la Valduerna"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Palacios del Sil"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Páramo del Sil"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Peranzanes"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Pobladura de Pelayo García"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Pola de Gordón, La"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Ponferrada"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Posada de Valdeón"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Pozuelo del Páramo"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Prado de la Guzpeña"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Priaranza del Bierzo"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Prioro"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Puebla de Lillo"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Puente de Domingo Flórez"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Quintana del Castillo"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Quintana del Marco"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Quintana y Congosto"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Regueras de Arriba"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Reyero"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Riaño"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Riego de la Vega"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Riello"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Rioseco de Tapia"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Robla, La"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Roperuelos del Páramo"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Sabero"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Sahagún"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "San Adrián del Valle"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "San Andrés del Rabanedo"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "San Cristóbal de la Polantera"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "San Emiliano"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "San Esteban de Nogales"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "San Justo de la Vega"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "San Millán de los Caballeros"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "San Pedro Bercianos"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Sancedo"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Santa Colomba de Curueño"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Santa Colomba de Somoza"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Santa Cristina de Valmadrigal"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Santa Elena de Jamuz"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Santa María de la Isla"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Santa María de Ordás"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Santa María del Monte de Cea"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Santa María del Páramo"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Santa Marina del Rey"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Santas Martas"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Santiago Millas"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Santovenia de la Valdoncina"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Sariegos"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Sena de Luna"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Sobrado"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Soto de la Vega"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Soto y Amío"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Toral de los Guzmanes"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Toral de los Vados"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Toreno"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Torre del Bierzo"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Trabadelo"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Truchas"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Turcia"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Urdiales del Páramo"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Val de San Lorenzo"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Valdefresno"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Valdefuentes del Páramo"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Valdelugueros"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Valdemora"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Valdepiélago"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Valdepolo"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Valderas"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Valderrey"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Valderrueda"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Valdesamario"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Valdevimbre"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Valencia de Don Juan"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Vallecillo"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Valverde de la Virgen"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Valverde-Enrique"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Vecilla, La"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Vega de Espinareda"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Vega de Infanzones"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Vega de Valcarce"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Vegacervera"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Vegaquemada"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Vegas del Condado"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Villablino"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Villabraz"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Villadangos del Páramo"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Villademor de la Vega"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Villafranca del Bierzo"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Villagatón"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Villamandos"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Villamanín"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Villamañán"
  },
  {
    "parent_code": "24",
    "code": "3",
    "label": "Villamartín de Don Sancho"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Villamejil"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Villamol"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Villamontán de la Valduerna"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Villamoratiel de las Matas"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Villanueva de las Manzanas"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Villaobispo de Otero"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Villaornate y Castro"
  },
  {
    "parent_code": "24",
    "code": "0",
    "label": "Villaquejida"
  },
  {
    "parent_code": "24",
    "code": "5",
    "label": "Villaquilambre"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Villarejo de Órbigo"
  },
  {
    "parent_code": "24",
    "code": "6",
    "label": "Villares de Órbigo"
  },
  {
    "parent_code": "24",
    "code": "9",
    "label": "Villasabariego"
  },
  {
    "parent_code": "24",
    "code": "2",
    "label": "Villaselán"
  },
  {
    "parent_code": "24",
    "code": "8",
    "label": "Villaturiel"
  },
  {
    "parent_code": "24",
    "code": "4",
    "label": "Villazala"
  },
  {
    "parent_code": "24",
    "code": "7",
    "label": "Villazanzo de Valderaduey"
  },
  {
    "parent_code": "24",
    "code": "1",
    "label": "Zotes del Páramo"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Abella de la Conca"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Àger"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Agramunt"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Aitona"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Alamús, Els"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Alàs i Cerc"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Albagés, L'"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Albatàrrec"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Albesa"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Albi, L'"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Alcanó"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Alcarràs"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Alcoletge"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Alfarràs"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Alfés"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Algerri"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Alguaire"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Alins"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Almacelles"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Almatret"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Almenar"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Alòs de Balaguer"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Alpicat"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Alt Àneu"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Anglesola"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Arbeca"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Arres"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Arsèguel"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Artesa de Lleida"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Artesa de Segre"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Aspa"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Avellanes i Santa Linya, Les"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Baix Pallars"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Balaguer"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Barbens"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Baronia de Rialb, La"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Bassella"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Bausen"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Belianes"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Bellaguarda"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Bellcaire d'Urgell"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Bell-lloc d'Urgell"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Bellmunt d'Urgell"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Bellpuig"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Bellver de Cerdanya"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Bellvís"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Benavent de Segrià"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Biosca"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Bòrdes, Es"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Borges Blanques, Les"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Bossòst"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Bovera"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Cabanabona"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Cabó"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Camarasa"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Canejan"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Castell de Mur"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Castellar de la Ribera"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Castelldans"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Castellnou de Seana"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Castelló de Farfanya"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Castellserà"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Cava"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Cervera"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Cervià de les Garrigues"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Ciutadilla"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Clariana de Cardener"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Cogul, El"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Coll de Nargó"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Coma i la Pedra, La"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Conca de Dalt"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Corbins"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Cubells"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Espluga Calba, L'"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Espot"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Estamariu"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Estaràs"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Esterri d'Àneu"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Esterri de Cardós"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Farrera"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Fígols i Alinyà"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Floresta, La"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Fondarella"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Foradada"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Fuliola, La"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Fulleda"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Gavet de la Conca"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Gimenells i el Pla de la Font"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Golmés"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Gósol"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Granadella, La"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Granja d'Escarp, La"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Granyanella"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Granyena de les Garrigues"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Granyena de Segarra"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Guimerà"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Guingueta d'Àneu, La"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Guissona"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Guixers"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Isona i Conca Dellà"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Ivars de Noguera"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Ivars d'Urgell"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Ivorra"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Josa i Tuixén"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Juncosa"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Juneda"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Les"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Linyola"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Lladorre"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Lladurs"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Llardecans"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Llavorsí"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Lleida"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Lles de Cerdanya"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Llimiana"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Llobera"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Maials"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Maldà"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Massalcoreig"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Massoteres"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Menàrguens"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Miralcamp"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Mollerussa"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Molsosa, La"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Montellà i Martinet"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Montferrer i Castellbò"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Montgai"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Montoliu de Lleida"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Montoliu de Segarra"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Montornès de Segarra"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Nalec"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Naut Aran"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Navès"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Odèn"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Oliana"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Oliola"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Olius"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Oluges, Les"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Omellons, Els"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Omells de na Gaia, Els"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Organyà"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Os de Balaguer"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Ossó de Sió"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Palau d'Anglesola, El"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Penelles"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Peramola"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Pinell de Solsonès"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Pinós"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Plans de Sió, Els"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Poal, El"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Pobla de Cérvoles, La"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Pobla de Segur, La"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Pont de Bar, El"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Pont de Suert, El"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Ponts"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Portella, La"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Prats i Sansor"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Preixana"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Preixens"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Prullans"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Puiggròs"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Puigverd d'Agramunt"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Puigverd de Lleida"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Rialp"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Ribera d'Ondara"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Ribera d'Urgellet"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Riner"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Riu de Cerdanya"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Rosselló"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Salàs de Pallars"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Sanaüja"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Sant Esteve de la Sarga"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Sant Guim de Freixenet"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Sant Guim de la Plana"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Sant Llorenç de Morunys"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Sant Martí de Riucorb"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Sant Ramon"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Sarroca de Bellera"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Sarroca de Lleida"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Senterada"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Sentiu de Sió, La"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Seròs"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Seu d'Urgell, La"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Sidamon"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Soleràs, El"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Solsona"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Soriguera"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Sort"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Soses"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Sudanell"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Sunyer"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Talarn"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Talavera"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Tàrrega"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Tarrés"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Tarroja de Segarra"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Térmens"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Tírvia"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Tiurana"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Torà"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Torms, Els"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Tornabous"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Torre de Cabdella, La"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Torrebesses"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Torrefarrera"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Torrefeta i Florejacs"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Torregrossa"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Torrelameu"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Torres de Segre"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Torre-serona"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Tremp"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Vall de Boí, La"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Vall de Cardós"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Vallbona de les Monges"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Vallfogona de Balaguer"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Valls d'Aguilar, Les"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Valls de Valira, Les"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Vansa i Fórnols, La"
  },
  {
    "parent_code": "25",
    "code": "4",
    "label": "Verdú"
  },
  {
    "parent_code": "25",
    "code": "0",
    "label": "Vielha e Mijaran"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Vilagrassa"
  },
  {
    "parent_code": "25",
    "code": "8",
    "label": "Vilaller"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Vilamòs"
  },
  {
    "parent_code": "25",
    "code": "3",
    "label": "Vilanova de Bellpuig"
  },
  {
    "parent_code": "25",
    "code": "2",
    "label": "Vilanova de la Barca"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Vilanova de l'Aguda"
  },
  {
    "parent_code": "25",
    "code": "9",
    "label": "Vilanova de Meià"
  },
  {
    "parent_code": "25",
    "code": "6",
    "label": "Vilanova de Segrià"
  },
  {
    "parent_code": "25",
    "code": "1",
    "label": "Vila-sana"
  },
  {
    "parent_code": "25",
    "code": "7",
    "label": "Vilosell, El"
  },
  {
    "parent_code": "25",
    "code": "5",
    "label": "Vinaixa"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Ábalos"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Agoncillo"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Aguilar del Río Alhama"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Ajamil de Cameros"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Albelda de Iregua"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Alberite"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Alcanadre"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Aldeanueva de Ebro"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Alesanco"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Alesón"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Alfaro"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Almarza de Cameros"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Anguciana"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Anguiano"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Arenzana de Abajo"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Arenzana de Arriba"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Arnedillo"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Arnedo"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Arrúbal"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Ausejo"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Autol"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Azofra"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Badarán"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Bañares"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Baños de Río Tobía"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Baños de Rioja"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Berceo"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Bergasa"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Bergasillas Bajera"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Bezares"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Bobadilla"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Brieva de Cameros"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Briñas"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Briones"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Cabezón de Cameros"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Calahorra"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Camprovín"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Canales de la Sierra"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Canillas de Río Tuerto"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Cañas"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Cárdenas"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Casalarreina"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Castañares de Rioja"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Castroviejo"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Cellorigo"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Cenicero"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Cervera del Río Alhama"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Cidamón"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Cihuri"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Cirueña"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Clavijo"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Cordovín"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Corera"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Cornago"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Corporales"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Cuzcurrita de Río Tirón"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Daroca de Rioja"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Enciso"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Entrena"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Estollo"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Ezcaray"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Foncea"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Fonzaleche"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Fuenmayor"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Galbárruli"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Galilea"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Gallinero de Cameros"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Gimileo"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Grañón"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Grávalos"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Haro"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Herce"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Herramélluri"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Hervías"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Hormilla"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Hormilleja"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Hornillos de Cameros"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Hornos de Moncalvillo"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Huércanos"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Igea"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Jalón de Cameros"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Laguna de Cameros"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Lagunilla del Jubera"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Lardero"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Ledesma de la Cogolla"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Leiva"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Leza de Río Leza"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Logroño"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Lumbreras"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Manjarrés"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Mansilla de la Sierra"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Manzanares de Rioja"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Matute"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Medrano"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Munilla"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Murillo de Río Leza"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Muro de Aguas"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Muro en Cameros"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Nájera"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Nalda"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Navajún"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Navarrete"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Nestares"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Nieva de Cameros"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Ochánduri"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Ocón"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Ojacastro"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Ollauri"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Ortigosa de Cameros"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Pazuengos"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Pedroso"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Pinillos"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Pradejón"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Pradillo"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Préjano"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Quel"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Rabanera"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Rasillo de Cameros, El"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Redal, El"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Ribafrecha"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Rincón de Soto"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Robres del Castillo"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Rodezno"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Sajazarra"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "San Asensio"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "San Millán de la Cogolla"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "San Millán de Yécora"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "San Román de Cameros"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "San Torcuato"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "San Vicente de la Sonsierra"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Santa Coloma"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Santa Engracia del Jubera"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Santa Eulalia Bajera"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Santo Domingo de la Calzada"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Santurde de Rioja"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Santurdejo"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Sojuela"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Sorzano"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Sotés"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Soto en Cameros"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Terroba"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Tirgo"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Tobía"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Tormantos"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Torre en Cameros"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Torrecilla en Cameros"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Torrecilla sobre Alesanco"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Torremontalbo"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Treviana"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Tricio"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Tudelilla"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Uruñuela"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Valdemadera"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Valgañón"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Ventosa"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Ventrosa"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Viguera"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Villalba de Rioja"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Villalobar de Rioja"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Villamediana de Iregua"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Villanueva de Cameros"
  },
  {
    "parent_code": "26",
    "code": "9",
    "label": "Villar de Arnedo, El"
  },
  {
    "parent_code": "26",
    "code": "6",
    "label": "Villar de Torre"
  },
  {
    "parent_code": "26",
    "code": "1",
    "label": "Villarejo"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Villarroya"
  },
  {
    "parent_code": "26",
    "code": "2",
    "label": "Villarta-Quintana"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Villavelayo"
  },
  {
    "parent_code": "26",
    "code": "8",
    "label": "Villaverde de Rioja"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Villoslada de Cameros"
  },
  {
    "parent_code": "26",
    "code": "0",
    "label": "Viniegra de Abajo"
  },
  {
    "parent_code": "26",
    "code": "3",
    "label": "Viniegra de Arriba"
  },
  {
    "parent_code": "26",
    "code": "7",
    "label": "Zarratón"
  },
  {
    "parent_code": "26",
    "code": "4",
    "label": "Zarzosa"
  },
  {
    "parent_code": "26",
    "code": "5",
    "label": "Zorraquín"
  },
  {
    "parent_code": "27",
    "code": "8",
    "label": "Abadín"
  },
  {
    "parent_code": "27",
    "code": "3",
    "label": "Alfoz"
  },
  {
    "parent_code": "27",
    "code": "9",
    "label": "Antas de Ulla"
  },
  {
    "parent_code": "27",
    "code": "4",
    "label": "Baleira"
  },
  {
    "parent_code": "27",
    "code": "9",
    "label": "Baralla"
  },
  {
    "parent_code": "27",
    "code": "7",
    "label": "Barreiros"
  },
  {
    "parent_code": "27",
    "code": "0",
    "label": "Becerreá"
  },
  {
    "parent_code": "27",
    "code": "6",
    "label": "Begonte"
  },
  {
    "parent_code": "27",
    "code": "2",
    "label": "Bóveda"
  },
  {
    "parent_code": "27",
    "code": "4",
    "label": "Burela"
  },
  {
    "parent_code": "27",
    "code": "5",
    "label": "Carballedo"
  },
  {
    "parent_code": "27",
    "code": "9",
    "label": "Castro de Rei"
  },
  {
    "parent_code": "27",
    "code": "6",
    "label": "Castroverde"
  },
  {
    "parent_code": "27",
    "code": "1",
    "label": "Cervantes"
  },
  {
    "parent_code": "27",
    "code": "7",
    "label": "Cervo"
  },
  {
    "parent_code": "27",
    "code": "8",
    "label": "Chantada"
  },
  {
    "parent_code": "27",
    "code": "2",
    "label": "Corgo, O"
  },
  {
    "parent_code": "27",
    "code": "5",
    "label": "Cospeito"
  },
  {
    "parent_code": "27",
    "code": "4",
    "label": "Folgoso do Courel"
  },
  {
    "parent_code": "27",
    "code": "0",
    "label": "Fonsagrada, A"
  },
  {
    "parent_code": "27",
    "code": "3",
    "label": "Foz"
  },
  {
    "parent_code": "27",
    "code": "7",
    "label": "Friol"
  },
  {
    "parent_code": "27",
    "code": "9",
    "label": "Guitiriz"
  },
  {
    "parent_code": "27",
    "code": "5",
    "label": "Guntín"
  },
  {
    "parent_code": "27",
    "code": "0",
    "label": "Incio, O"
  },
  {
    "parent_code": "27",
    "code": "6",
    "label": "Láncara"
  },
  {
    "parent_code": "27",
    "code": "2",
    "label": "Lourenzá"
  },
  {
    "parent_code": "27",
    "code": "8",
    "label": "Lugo"
  },
  {
    "parent_code": "27",
    "code": "1",
    "label": "Meira"
  },
  {
    "parent_code": "27",
    "code": "5",
    "label": "Mondoñedo"
  },
  {
    "parent_code": "27",
    "code": "2",
    "label": "Monforte de Lemos"
  },
  {
    "parent_code": "27",
    "code": "7",
    "label": "Monterroso"
  },
  {
    "parent_code": "27",
    "code": "3",
    "label": "Muras"
  },
  {
    "parent_code": "27",
    "code": "8",
    "label": "Navia de Suarna"
  },
  {
    "parent_code": "27",
    "code": "1",
    "label": "Negueira de Muñiz"
  },
  {
    "parent_code": "27",
    "code": "0",
    "label": "Nogais, As"
  },
  {
    "parent_code": "27",
    "code": "6",
    "label": "Ourol"
  },
  {
    "parent_code": "27",
    "code": "9",
    "label": "Outeiro de Rei"
  },
  {
    "parent_code": "27",
    "code": "3",
    "label": "Palas de Rei"
  },
  {
    "parent_code": "27",
    "code": "0",
    "label": "Pantón"
  },
  {
    "parent_code": "27",
    "code": "5",
    "label": "Paradela"
  },
  {
    "parent_code": "27",
    "code": "1",
    "label": "Páramo, O"
  },
  {
    "parent_code": "27",
    "code": "6",
    "label": "Pastoriza, A"
  },
  {
    "parent_code": "27",
    "code": "9",
    "label": "Pedrafita do Cebreiro"
  },
  {
    "parent_code": "27",
    "code": "8",
    "label": "Pobra do Brollón, A"
  },
  {
    "parent_code": "27",
    "code": "2",
    "label": "Pol"
  },
  {
    "parent_code": "27",
    "code": "4",
    "label": "Pontenova, A"
  },
  {
    "parent_code": "27",
    "code": "7",
    "label": "Portomarín"
  },
  {
    "parent_code": "27",
    "code": "0",
    "label": "Quiroga"
  },
  {
    "parent_code": "27",
    "code": "9",
    "label": "Rábade"
  },
  {
    "parent_code": "27",
    "code": "7",
    "label": "Ribadeo"
  },
  {
    "parent_code": "27",
    "code": "2",
    "label": "Ribas de Sil"
  },
  {
    "parent_code": "27",
    "code": "8",
    "label": "Ribeira de Piquín"
  },
  {
    "parent_code": "27",
    "code": "3",
    "label": "Riotorto"
  },
  {
    "parent_code": "27",
    "code": "6",
    "label": "Samos"
  },
  {
    "parent_code": "27",
    "code": "5",
    "label": "Sarria"
  },
  {
    "parent_code": "27",
    "code": "1",
    "label": "Saviñao, O"
  },
  {
    "parent_code": "27",
    "code": "4",
    "label": "Sober"
  },
  {
    "parent_code": "27",
    "code": "8",
    "label": "Taboada"
  },
  {
    "parent_code": "27",
    "code": "5",
    "label": "Trabada"
  },
  {
    "parent_code": "27",
    "code": "0",
    "label": "Triacastela"
  },
  {
    "parent_code": "27",
    "code": "6",
    "label": "Valadouro, O"
  },
  {
    "parent_code": "27",
    "code": "1",
    "label": "Vicedo, O"
  },
  {
    "parent_code": "27",
    "code": "4",
    "label": "Vilalba"
  },
  {
    "parent_code": "27",
    "code": "7",
    "label": "Viveiro"
  },
  {
    "parent_code": "27",
    "code": "4",
    "label": "Xermade"
  },
  {
    "parent_code": "27",
    "code": "3",
    "label": "Xove"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Acebeda, La"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Ajalvir"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Alameda del Valle"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Álamo, El"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Alcalá de Henares"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Alcobendas"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Alcorcón"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Aldea del Fresno"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Algete"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Alpedrete"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Ambite"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Anchuelo"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Aranjuez"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Arganda del Rey"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Arroyomolinos"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Atazar, El"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Batres"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Becerril de la Sierra"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Belmonte de Tajo"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Berrueco, El"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Berzosa del Lozoya"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Boadilla del Monte"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Boalo, El"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Braojos"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Brea de Tajo"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Brunete"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Buitrago del Lozoya"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Bustarviejo"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Cabanillas de la Sierra"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Cabrera, La"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Cadalso de los Vidrios"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Camarma de Esteruelas"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Campo Real"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Canencia"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Carabaña"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Casarrubuelos"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Cenicientos"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Cercedilla"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Cervera de Buitrago"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Chapinería"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Chinchón"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Ciempozuelos"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Cobeña"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Collado Mediano"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Collado Villalba"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Colmenar de Oreja"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Colmenar del Arroyo"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Colmenar Viejo"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Colmenarejo"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Corpa"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Coslada"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Cubas de la Sagra"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Daganzo de Arriba"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Escorial, El"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Estremera"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Fresnedillas de la Oliva"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Fresno de Torote"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Fuenlabrada"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Fuente el Saz de Jarama"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Fuentidueña de Tajo"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Galapagar"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Garganta de los Montes"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Gargantilla del Lozoya y Pinilla de Buitrago"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Gascones"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Getafe"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Griñón"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Guadalix de la Sierra"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Guadarrama"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Hiruela, La"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Horcajo de la Sierra-Aoslos"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Horcajuelo de la Sierra"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Hoyo de Manzanares"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Humanes de Madrid"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Leganés"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Loeches"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Lozoya"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Lozoyuela-Navas-Sieteiglesias"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Madarcos"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Madrid"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Majadahonda"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Manzanares el Real"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Meco"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Mejorada del Campo"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Miraflores de la Sierra"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Molar, El"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Molinos, Los"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Montejo de la Sierra"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Moraleja de Enmedio"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Moralzarzal"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Morata de Tajuña"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Móstoles"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Navacerrada"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Navalafuente"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Navalagamella"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Navalcarnero"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Navarredonda y San Mamés"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Navas del Rey"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Nuevo Baztán"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Olmeda de las Fuentes"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Orusco de Tajuña"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Paracuellos de Jarama"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Parla"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Patones"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Pedrezuela"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Pelayos de la Presa"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Perales de Tajuña"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Pezuela de las Torres"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Pinilla del Valle"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Pinto"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Piñuécar-Gandullas"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Pozuelo de Alarcón"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Pozuelo del Rey"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Prádena del Rincón"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Puebla de la Sierra"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Puentes Viejas"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Quijorna"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Rascafría"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Redueña"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Ribatejada"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Rivas-Vaciamadrid"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Robledillo de la Jara"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Robledo de Chavela"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Robregordo"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Rozas de Madrid, Las"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Rozas de Puerto Real"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "San Agustín del Guadalix"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "San Fernando de Henares"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "San Lorenzo de El Escorial"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "San Martín de la Vega"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "San Martín de Valdeiglesias"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "San Sebastián de los Reyes"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Santa María de la Alameda"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Santorcaz"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Santos de la Humosa, Los"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Serna del Monte, La"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Serranillos del Valle"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Sevilla la Nueva"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Somosierra"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Soto del Real"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Talamanca de Jarama"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Tielmes"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Titulcia"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Torrejón de Ardoz"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Torrejón de la Calzada"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Torrejón de Velasco"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Torrelaguna"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Torrelodones"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Torremocha de Jarama"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Torres de la Alameda"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Tres Cantos"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Valdaracete"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Valdeavero"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Valdelaguna"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Valdemanco"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Valdemaqueda"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Valdemorillo"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Valdemoro"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Valdeolmos-Alalpardo"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Valdepiélagos"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Valdetorres de Jarama"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Valdilecha"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Valverde de Alcalá"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Velilla de San Antonio"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Vellón, El"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Venturada"
  },
  {
    "parent_code": "28",
    "code": "8",
    "label": "Villa del Prado"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Villaconejos"
  },
  {
    "parent_code": "28",
    "code": "3",
    "label": "Villalbilla"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Villamanrique de Tajo"
  },
  {
    "parent_code": "28",
    "code": "4",
    "label": "Villamanta"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Villamantilla"
  },
  {
    "parent_code": "28",
    "code": "0",
    "label": "Villanueva de la Cañada"
  },
  {
    "parent_code": "28",
    "code": "2",
    "label": "Villanueva de Perales"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Villanueva del Pardillo"
  },
  {
    "parent_code": "28",
    "code": "5",
    "label": "Villar del Olmo"
  },
  {
    "parent_code": "28",
    "code": "9",
    "label": "Villarejo de Salvanés"
  },
  {
    "parent_code": "28",
    "code": "6",
    "label": "Villaviciosa de Odón"
  },
  {
    "parent_code": "28",
    "code": "1",
    "label": "Villavieja del Lozoya"
  },
  {
    "parent_code": "28",
    "code": "7",
    "label": "Zarzalejo"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Alameda"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Alcaucín"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Alfarnate"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Alfarnatejo"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Algarrobo"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Algatocín"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Alhaurín de la Torre"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Alhaurín el Grande"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Almáchar"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Almargen"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Almogía"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Álora"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Alozaina"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Alpandeire"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Antequera"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Árchez"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Archidona"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Ardales"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Arenas"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Arriate"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Atajate"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Benadalid"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Benahavís"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Benalauría"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Benalmádena"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Benamargosa"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Benamocarra"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Benaoján"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Benarrabá"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Borge, El"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Burgo, El"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Campillos"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Canillas de Aceituno"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Canillas de Albaida"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Cañete la Real"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Carratraca"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Cartajima"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Cártama"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Casabermeja"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Casarabonela"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Casares"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Coín"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Colmenar"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Comares"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Cómpeta"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Cortes de la Frontera"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Cuevas Bajas"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Cuevas de San Marcos"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Cuevas del Becerro"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Cútar"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Estepona"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Faraján"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Frigiliana"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Fuengirola"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Fuente de Piedra"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Gaucín"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Genalguacil"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Guaro"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Humilladero"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Igualeja"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Istán"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Iznate"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Jimera de Líbar"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Jubrique"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Júzcar"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Macharaviaya"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Málaga"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Manilva"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Marbella"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Mijas"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Moclinejo"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Mollina"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Monda"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Montecorto"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Montejaque"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Nerja"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Ojén"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Parauta"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Periana"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Pizarra"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Pujerra"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Rincón de la Victoria"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Riogordo"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Ronda"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Salares"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Sayalonga"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Sedella"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Serrato"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Sierra de Yeguas"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Teba"
  },
  {
    "parent_code": "29",
    "code": "1",
    "label": "Tolox"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Torremolinos"
  },
  {
    "parent_code": "29",
    "code": "8",
    "label": "Torrox"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Totalán"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Valle de Abdalajís"
  },
  {
    "parent_code": "29",
    "code": "4",
    "label": "Vélez-Málaga"
  },
  {
    "parent_code": "29",
    "code": "7",
    "label": "Villanueva de Algaidas"
  },
  {
    "parent_code": "29",
    "code": "3",
    "label": "Villanueva de la Concepción"
  },
  {
    "parent_code": "29",
    "code": "2",
    "label": "Villanueva de Tapia"
  },
  {
    "parent_code": "29",
    "code": "0",
    "label": "Villanueva del Rosario"
  },
  {
    "parent_code": "29",
    "code": "6",
    "label": "Villanueva del Trabuco"
  },
  {
    "parent_code": "29",
    "code": "5",
    "label": "Viñuela"
  },
  {
    "parent_code": "29",
    "code": "9",
    "label": "Yunquera"
  },
  {
    "parent_code": "30",
    "code": "1",
    "label": "Abanilla"
  },
  {
    "parent_code": "30",
    "code": "6",
    "label": "Abarán"
  },
  {
    "parent_code": "30",
    "code": "2",
    "label": "Águilas"
  },
  {
    "parent_code": "30",
    "code": "7",
    "label": "Albudeite"
  },
  {
    "parent_code": "30",
    "code": "0",
    "label": "Alcantarilla"
  },
  {
    "parent_code": "30",
    "code": "7",
    "label": "Alcázares, Los"
  },
  {
    "parent_code": "30",
    "code": "3",
    "label": "Aledo"
  },
  {
    "parent_code": "30",
    "code": "9",
    "label": "Alguazas"
  },
  {
    "parent_code": "30",
    "code": "5",
    "label": "Alhama de Murcia"
  },
  {
    "parent_code": "30",
    "code": "8",
    "label": "Archena"
  },
  {
    "parent_code": "30",
    "code": "2",
    "label": "Beniel"
  },
  {
    "parent_code": "30",
    "code": "9",
    "label": "Blanca"
  },
  {
    "parent_code": "30",
    "code": "4",
    "label": "Bullas"
  },
  {
    "parent_code": "30",
    "code": "0",
    "label": "Calasparra"
  },
  {
    "parent_code": "30",
    "code": "5",
    "label": "Campos del Río"
  },
  {
    "parent_code": "30",
    "code": "8",
    "label": "Caravaca de la Cruz"
  },
  {
    "parent_code": "30",
    "code": "1",
    "label": "Cartagena"
  },
  {
    "parent_code": "30",
    "code": "7",
    "label": "Cehegín"
  },
  {
    "parent_code": "30",
    "code": "3",
    "label": "Ceutí"
  },
  {
    "parent_code": "30",
    "code": "6",
    "label": "Cieza"
  },
  {
    "parent_code": "30",
    "code": "0",
    "label": "Fortuna"
  },
  {
    "parent_code": "30",
    "code": "7",
    "label": "Fuente Álamo de Murcia"
  },
  {
    "parent_code": "30",
    "code": "2",
    "label": "Jumilla"
  },
  {
    "parent_code": "30",
    "code": "8",
    "label": "Librilla"
  },
  {
    "parent_code": "30",
    "code": "3",
    "label": "Lorca"
  },
  {
    "parent_code": "30",
    "code": "6",
    "label": "Lorquí"
  },
  {
    "parent_code": "30",
    "code": "9",
    "label": "Mazarrón"
  },
  {
    "parent_code": "30",
    "code": "5",
    "label": "Molina de Segura"
  },
  {
    "parent_code": "30",
    "code": "1",
    "label": "Moratalla"
  },
  {
    "parent_code": "30",
    "code": "4",
    "label": "Mula"
  },
  {
    "parent_code": "30",
    "code": "8",
    "label": "Murcia"
  },
  {
    "parent_code": "30",
    "code": "5",
    "label": "Ojós"
  },
  {
    "parent_code": "30",
    "code": "0",
    "label": "Pliego"
  },
  {
    "parent_code": "30",
    "code": "6",
    "label": "Puerto Lumbreras"
  },
  {
    "parent_code": "30",
    "code": "1",
    "label": "Ricote"
  },
  {
    "parent_code": "30",
    "code": "4",
    "label": "San Javier"
  },
  {
    "parent_code": "30",
    "code": "7",
    "label": "San Pedro del Pinatar"
  },
  {
    "parent_code": "30",
    "code": "2",
    "label": "Santomera"
  },
  {
    "parent_code": "30",
    "code": "3",
    "label": "Torre-Pacheco"
  },
  {
    "parent_code": "30",
    "code": "9",
    "label": "Torres de Cotillas, Las"
  },
  {
    "parent_code": "30",
    "code": "2",
    "label": "Totana"
  },
  {
    "parent_code": "30",
    "code": "6",
    "label": "Ulea"
  },
  {
    "parent_code": "30",
    "code": "3",
    "label": "Unión, La"
  },
  {
    "parent_code": "30",
    "code": "8",
    "label": "Villanueva del Río Segura"
  },
  {
    "parent_code": "30",
    "code": "4",
    "label": "Yecla"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Abáigar"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Abárzuza/Abartzuza"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Abaurregaina/Abaurrea Alta"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Abaurrepea/Abaurrea Baja"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Aberin"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Ablitas"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Adiós"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Aguilar de Codés"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Aibar/Oibar"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Allín/Allin"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Allo"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Altsasu/Alsasua"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Améscoa Baja"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Ancín/Antzin"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Andosilla"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Ansoáin/Antsoain"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Anue"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Añorbe"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Aoiz/Agoitz"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Araitz"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Arakil"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Aranarache/Aranaratxe"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Aranguren"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Arano"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Arantza"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Aras"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Arbizu"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Arce/Artzi"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Arcos, Los"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Arellano"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Areso"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Arguedas"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Aria"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Aribe"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Armañanzas"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Arróniz"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Arruazu"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Artajona"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Artazu"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Atez/Atetz"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Auritz/Burguete"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Ayegui/Aiegi"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Azagra"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Azuelo"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Bakaiku"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Barañáin/Barañain"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Barásoain"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Barbarin"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Bargota"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Barillas"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Basaburua"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Baztan"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Beintza-Labaien"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Beire"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Belascoáin"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Bera"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Berbinzana"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Beriáin"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Berrioplano/Berriobeiti"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Berriozar"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Bertizarana"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Betelu"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Bidaurreta"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Biurrun-Olcoz"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Buñuel"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Burgui/Burgi"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Burlada/Burlata"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Busto, El"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Cabanillas"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Cabredo"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Cadreita"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Caparroso"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Cárcar"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Carcastillo"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Cascante"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Cáseda"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Castejón"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Castillonuevo"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Cendea de Olza/Oltza Zendea"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Cintruénigo"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Cirauqui/Zirauki"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Ciriza/Ziritza"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Cizur"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Corella"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Cortes"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Desojo"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Dicastillo"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Donamaria"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Doneztebe/Santesteban"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Echarri/Etxarri"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Elgorriaga"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Enériz/Eneritz"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Eratsun"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Ergoiena"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Erro"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Eslava"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Esparza de Salazar/Espartza Zaraitzu"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Espronceda"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Estella-Lizarra"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Esteribar"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Etayo"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Etxalar"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Etxarri Aranatz"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Etxauri"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Eulate"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Ezcabarte"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Ezcároz/Ezkaroze"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Ezkurra"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Ezprogui"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Falces"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Fitero"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Fontellas"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Funes"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Fustiñana"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Galar"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Gallipienzo/Galipentzu"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Gallués/Galoze"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Garaioa"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Garde"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Garínoain"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Garralda"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Genevilla"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Goizueta"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Goñi"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Güesa/Gorza"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Guesálaz/Gesalatz"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Guirguillano"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Hiriberri/Villanueva de Aezkoa"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Huarte/Uharte"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Ibargoiti"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Igantzi"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Igúzquiza"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Imotz"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Irañeta"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Irurtzun"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Isaba/Izaba"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Ituren"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Iturmendi"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Iza/Itza"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Izagaondoa"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Izalzu/Itzaltzu"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Jaurrieta"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Javier"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Juslapeña"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Lakuntza"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Lana"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Lantz"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Lapoblación"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Larraga"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Larraona"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Larraun"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Lazagurría"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Leache/Leatxe"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Legarda"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Legaria"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Leitza"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Lekunberri"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Leoz/Leotz"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Lerga"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Lerín"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Lesaka"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Lezaun"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Liédena"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Lizoáin-Arriasgoiti"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Lodosa"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Lónguida/Longida"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Lumbier"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Luquin"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Luzaide/Valcarlos"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Mañeru"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Marañón"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Marcilla"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Mélida"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Mendavia"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Mendaza"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Mendigorría"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Metauten"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Milagro"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Mirafuentes"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Miranda de Arga"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Monreal/Elo"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Monteagudo"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Morentin"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Mues"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Murchante"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Murieta"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Murillo el Cuende"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Murillo el Fruto"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Muruzábal"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Navascués/Nabaskoze"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Nazar"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Noáin (Valle de Elorz)/Noain (Elortzibar)"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Obanos"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Ochagavía/Otsagabia"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Oco"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Odieta"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Oiz"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Olaibar"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Olazti/Olazagutía"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Olejua"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Olite/Erriberri"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Olóriz/Oloritz"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Orbaizeta"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Orbara"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Orísoain"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Orkoien"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Oronz/Orontze"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Oroz-Betelu/Orotz-Betelu"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Orreaga/Roncesvalles"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Oteiza"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Pamplona/Iruña"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Peralta/Azkoien"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Petilla de Aragón"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Piedramillera"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Pitillas"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Puente la Reina/Gares"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Pueyo"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Ribaforada"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Romanzado"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Roncal/Erronkari"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Sada"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Saldías"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Salinas de Oro/Jaitz"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "San Adrián"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "San Martín de Unx"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Sangüesa/Zangoza"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Sansol"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Santacara"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Sarriés/Sartze"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Sartaguda"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Sesma"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Sorlada"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Sunbilla"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Tafalla"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Tiebas-Muruarte de Reta"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Tirapu"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Torralba del Río"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Torres del Río"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Tudela"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Tulebras"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Ucar"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Uharte Arakil"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Ujué/Uxue"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Ultzama"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Unciti"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Unzué/Untzue"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Urdazubi/Urdax"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Urdiain"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Urraul Alto"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Urraul Bajo"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Urroz"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Urroz-Villa"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Urzainqui/Urzainki"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Uterga"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Uztárroz/Uztarroze"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Valle de Egüés/Eguesibar"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Valle de Ollo/Ollaran"
  },
  {
    "parent_code": "31",
    "code": "6",
    "label": "Valle de Yerri/Deierri"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Valtierra"
  },
  {
    "parent_code": "31",
    "code": "5",
    "label": "Viana"
  },
  {
    "parent_code": "31",
    "code": "0",
    "label": "Vidángoz/Bidankoze"
  },
  {
    "parent_code": "31",
    "code": "1",
    "label": "Villafranca"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Villamayor de Monjardín"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Villatuerta"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Villava/Atarrabia"
  },
  {
    "parent_code": "31",
    "code": "3",
    "label": "Yesa"
  },
  {
    "parent_code": "31",
    "code": "8",
    "label": "Zabalza/Zabaltza"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Ziordia"
  },
  {
    "parent_code": "31",
    "code": "7",
    "label": "Zizur Mayor/Zizur Nagusia"
  },
  {
    "parent_code": "31",
    "code": "4",
    "label": "Zubieta"
  },
  {
    "parent_code": "31",
    "code": "9",
    "label": "Zugarramurdi"
  },
  {
    "parent_code": "31",
    "code": "2",
    "label": "Zúñiga"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Allariz"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Amoeiro"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Arnoia, A"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Avión"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Baltar"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Bande"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Baños de Molgas"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Barbadás"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Barco de Valdeorras, O"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Beade"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Beariz"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Blancos, Os"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Boborás"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Bola, A"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Bolo, O"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Calvos de Randín"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Carballeda de Avia"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Carballeda de Valdeorras"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Carballiño, O"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Cartelle"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Castrelo de Miño"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Castrelo do Val"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Castro Caldelas"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Celanova"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Cenlle"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Chandrexa de Queixa"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Coles"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Cortegada"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Cualedro"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Entrimo"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Esgos"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Gomesende"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Gudiña, A"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Irixo, O"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Larouco"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Laza"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Leiro"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Lobeira"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Lobios"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Maceda"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Manzaneda"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Maside"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Melón"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Merca, A"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Mezquita, A"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Montederramo"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Monterrei"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Muíños"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Nogueira de Ramuín"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Oímbra"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Ourense"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Paderne de Allariz"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Padrenda"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Parada de Sil"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Pereiro de Aguiar, O"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Peroxa, A"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Petín"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Piñor"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Pobra de Trives, A"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Pontedeva"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Porqueira"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Punxín"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Quintela de Leirado"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Rairiz de Veiga"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Ramirás"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Ribadavia"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Riós"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Rúa, A"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Rubiá"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "San Amaro"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "San Cibrao das Viñas"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "San Cristovo de Cea"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "San Xoán de Río"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Sandiás"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Sarreaus"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Taboadela"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Teixeira, A"
  },
  {
    "parent_code": "32",
    "code": "6",
    "label": "Toén"
  },
  {
    "parent_code": "32",
    "code": "1",
    "label": "Trasmiras"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Veiga, A"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Verea"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Verín"
  },
  {
    "parent_code": "32",
    "code": "8",
    "label": "Viana do Bolo"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Vilamarín"
  },
  {
    "parent_code": "32",
    "code": "0",
    "label": "Vilamartín de Valdeorras"
  },
  {
    "parent_code": "32",
    "code": "3",
    "label": "Vilar de Barrio"
  },
  {
    "parent_code": "32",
    "code": "7",
    "label": "Vilar de Santos"
  },
  {
    "parent_code": "32",
    "code": "4",
    "label": "Vilardevós"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Vilariño de Conso"
  },
  {
    "parent_code": "32",
    "code": "2",
    "label": "Xinzo de Limia"
  },
  {
    "parent_code": "32",
    "code": "9",
    "label": "Xunqueira de Ambía"
  },
  {
    "parent_code": "32",
    "code": "5",
    "label": "Xunqueira de Espadanedo"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "Allande"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Aller"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Amieva"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Avilés"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Belmonte de Miranda"
  },
  {
    "parent_code": "33",
    "code": "1",
    "label": "Bimenes"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "Boal"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Cabrales"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "Cabranes"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Candamo"
  },
  {
    "parent_code": "33",
    "code": "2",
    "label": "Cangas de Onís"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "Cangas del Narcea"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Caravia"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Carreño"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "Caso"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "Castrillón"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Castropol"
  },
  {
    "parent_code": "33",
    "code": "1",
    "label": "Coaña"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Colunga"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Corvera de Asturias"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Cudillero"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Degaña"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "Franco, El"
  },
  {
    "parent_code": "33",
    "code": "1",
    "label": "Gijón"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Gozón"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "Grado"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Grandas de Salime"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "Ibias"
  },
  {
    "parent_code": "33",
    "code": "2",
    "label": "Illano"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "Illas"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Langreo"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Laviana"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Lena"
  },
  {
    "parent_code": "33",
    "code": "2",
    "label": "Llanera"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Llanes"
  },
  {
    "parent_code": "33",
    "code": "1",
    "label": "Mieres"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "Morcín"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Muros de Nalón"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Nava"
  },
  {
    "parent_code": "33",
    "code": "1",
    "label": "Navia"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "Noreña"
  },
  {
    "parent_code": "33",
    "code": "2",
    "label": "Onís"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "Oviedo"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Parres"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Peñamellera Alta"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "Peñamellera Baja"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Pesoz"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Piloña"
  },
  {
    "parent_code": "33",
    "code": "1",
    "label": "Ponga"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Pravia"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Proaza"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "Quirós"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Regueras, Las"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "Ribadedeva"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Ribadesella"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "Ribera de Arriba"
  },
  {
    "parent_code": "33",
    "code": "2",
    "label": "Riosa"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Salas"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "San Martín de Oscos"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "San Martín del Rey Aurelio"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "San Tirso de Abres"
  },
  {
    "parent_code": "33",
    "code": "1",
    "label": "Santa Eulalia de Oscos"
  },
  {
    "parent_code": "33",
    "code": "2",
    "label": "Santo Adriano"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Sariego"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Siero"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Sobrescobio"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Somiedo"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Soto del Barco"
  },
  {
    "parent_code": "33",
    "code": "7",
    "label": "Tapia de Casariego"
  },
  {
    "parent_code": "33",
    "code": "4",
    "label": "Taramundi"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "Teverga"
  },
  {
    "parent_code": "33",
    "code": "5",
    "label": "Tineo"
  },
  {
    "parent_code": "33",
    "code": "9",
    "label": "Valdés"
  },
  {
    "parent_code": "33",
    "code": "0",
    "label": "Vegadeo"
  },
  {
    "parent_code": "33",
    "code": "3",
    "label": "Villanueva de Oscos"
  },
  {
    "parent_code": "33",
    "code": "6",
    "label": "Villaviciosa"
  },
  {
    "parent_code": "33",
    "code": "2",
    "label": "Villayón"
  },
  {
    "parent_code": "33",
    "code": "8",
    "label": "Yernes y Tameza"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Abarca de Campos"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Abia de las Torres"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Aguilar de Campoo"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Alar del Rey"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Alba de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Amayuelas de Arriba"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Ampudia"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Amusco"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Antigüedad"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Arconada"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Astudillo"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Autilla del Pino"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Autillo de Campos"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Ayuela"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Baltanás"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Baquerín de Campos"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Bárcena de Campos"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Barruelo de Santullán"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Báscones de Ojeda"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Becerril de Campos"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Belmonte de Campos"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Berzosilla"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Boada de Campos"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Boadilla de Rioseco"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Boadilla del Camino"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Brañosera"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Buenavista de Valdavia"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Bustillo de la Vega"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Bustillo del Páramo de Carrión"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Calahorra de Boedo"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Calzada de los Molinos"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Capillas"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Cardeñosa de Volpejera"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Carrión de los Condes"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Castil de Vela"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Castrejón de la Peña"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Castrillo de Don Juan"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Castrillo de Onielo"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Castrillo de Villavega"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Castromocho"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Cervatos de la Cueza"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Cervera de Pisuerga"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Cevico de la Torre"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Cevico Navero"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Cisneros"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Cobos de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Collazos de Boedo"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Congosto de Valdavia"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Cordovilla la Real"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Cubillas de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Dehesa de Montejo"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Dehesa de Romanos"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Dueñas"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Espinosa de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Espinosa de Villagonzalo"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Frechilla"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Fresno del Río"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Frómista"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Fuentes de Nava"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Fuentes de Valdepero"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Grijota"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Guardo"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Guaza de Campos"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Hérmedes de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Herrera de Pisuerga"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Herrera de Valdecañas"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Hontoria de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Hornillos de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Husillos"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Itero de la Vega"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Lagartos"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Lantadilla"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Ledigos"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Loma de Ucieza"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Lomas"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Magaz de Pisuerga"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Manquillos"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Mantinos"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Marcilla de Campos"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Mazariegos"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Mazuecos de Valdeginate"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Melgar de Yuso"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Meneses de Campos"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Micieces de Ojeda"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Monzón de Campos"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Moratinos"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Mudá"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Nogal de las Huertas"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Olea de Boedo"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Olmos de Ojeda"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Osornillo"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Osorno la Mayor"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Palencia"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Palenzuela"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Páramo de Boedo"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Paredes de Nava"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Payo de Ojeda"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Pedraza de Campos"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Pedrosa de la Vega"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Perales"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Pernía, La"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Pino del Río"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Piña de Campos"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Población de Arroyo"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Población de Campos"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Población de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Polentinos"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Pomar de Valdivia"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Poza de la Vega"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Pozo de Urama"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Prádanos de Ojeda"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Puebla de Valdavia, La"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Quintana del Puente"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Quintanilla de Onsoña"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Reinoso de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Renedo de la Vega"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Requena de Campos"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Respenda de la Peña"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Revenga de Campos"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Revilla de Collazos"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Ribas de Campos"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Riberos de la Cueza"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Saldaña"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Salinas de Pisuerga"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "San Cebrián de Campos"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "San Cebrián de Mudá"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "San Cristóbal de Boedo"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "San Mamés de Campos"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "San Román de la Cuba"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Santa Cecilia del Alcor"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Santa Cruz de Boedo"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Santervás de la Vega"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Santibáñez de Ecla"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Santibáñez de la Peña"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Santoyo"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Serna, La"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Soto de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Sotobañado y Priorato"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Tabanera de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Tabanera de Valdavia"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Támara de Campos"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Tariego de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Torquemada"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Torremormojón"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Triollo"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Valbuena de Pisuerga"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Valdeolmillos"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Valderrábano"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Valde-Ucieza"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Valle de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Valle del Retortillo"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Velilla del Río Carrión"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Venta de Baños"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Vertavillo"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Vid de Ojeda, La"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Villabasta de Valdavia"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Villacidaler"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Villaconancio"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Villada"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Villaeles de Valdavia"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Villahán"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Villaherreros"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Villalaco"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Villalba de Guardo"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Villalcázar de Sirga"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Villalcón"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Villalobón"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Villaluenga de la Vega"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Villamartín de Campos"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Villamediana"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Villameriel"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Villamoronta"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Villamuera de la Cueza"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Villamuriel de Cerrato"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Villanueva del Rebollar"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Villanuño de Valdavia"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Villaprovedo"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Villarmentero de Campos"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Villarrabé"
  },
  {
    "parent_code": "34",
    "code": "1",
    "label": "Villarramiel"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Villasarracino"
  },
  {
    "parent_code": "34",
    "code": "2",
    "label": "Villasila de Valdavia"
  },
  {
    "parent_code": "34",
    "code": "8",
    "label": "Villaturde"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Villaumbrales"
  },
  {
    "parent_code": "34",
    "code": "0",
    "label": "Villaviudas"
  },
  {
    "parent_code": "34",
    "code": "7",
    "label": "Villerías de Campos"
  },
  {
    "parent_code": "34",
    "code": "4",
    "label": "Villodre"
  },
  {
    "parent_code": "34",
    "code": "9",
    "label": "Villodrigo"
  },
  {
    "parent_code": "34",
    "code": "5",
    "label": "Villoldo"
  },
  {
    "parent_code": "34",
    "code": "3",
    "label": "Villota del Páramo"
  },
  {
    "parent_code": "34",
    "code": "6",
    "label": "Villovieco"
  },
  {
    "parent_code": "35",
    "code": "7",
    "label": "Agaete"
  },
  {
    "parent_code": "35",
    "code": "2",
    "label": "Agüimes"
  },
  {
    "parent_code": "35",
    "code": "6",
    "label": "Aldea de San Nicolás, La"
  },
  {
    "parent_code": "35",
    "code": "8",
    "label": "Antigua"
  },
  {
    "parent_code": "35",
    "code": "3",
    "label": "Arrecife"
  },
  {
    "parent_code": "35",
    "code": "6",
    "label": "Artenara"
  },
  {
    "parent_code": "35",
    "code": "9",
    "label": "Arucas"
  },
  {
    "parent_code": "35",
    "code": "5",
    "label": "Betancuria"
  },
  {
    "parent_code": "35",
    "code": "1",
    "label": "Firgas"
  },
  {
    "parent_code": "35",
    "code": "4",
    "label": "Gáldar"
  },
  {
    "parent_code": "35",
    "code": "8",
    "label": "Haría"
  },
  {
    "parent_code": "35",
    "code": "5",
    "label": "Ingenio"
  },
  {
    "parent_code": "35",
    "code": "0",
    "label": "Mogán"
  },
  {
    "parent_code": "35",
    "code": "6",
    "label": "Moya"
  },
  {
    "parent_code": "35",
    "code": "1",
    "label": "Oliva, La"
  },
  {
    "parent_code": "35",
    "code": "4",
    "label": "Pájara"
  },
  {
    "parent_code": "35",
    "code": "7",
    "label": "Palmas de Gran Canaria, Las"
  },
  {
    "parent_code": "35",
    "code": "3",
    "label": "Puerto del Rosario"
  },
  {
    "parent_code": "35",
    "code": "9",
    "label": "San Bartolomé"
  },
  {
    "parent_code": "35",
    "code": "2",
    "label": "San Bartolomé de Tirajana"
  },
  {
    "parent_code": "35",
    "code": "3",
    "label": "Santa Brígida"
  },
  {
    "parent_code": "35",
    "code": "8",
    "label": "Santa Lucía de Tirajana"
  },
  {
    "parent_code": "35",
    "code": "4",
    "label": "Santa María de Guía de Gran Canaria"
  },
  {
    "parent_code": "35",
    "code": "9",
    "label": "Teguise"
  },
  {
    "parent_code": "35",
    "code": "2",
    "label": "Tejeda"
  },
  {
    "parent_code": "35",
    "code": "5",
    "label": "Telde"
  },
  {
    "parent_code": "35",
    "code": "1",
    "label": "Teror"
  },
  {
    "parent_code": "35",
    "code": "7",
    "label": "Tías"
  },
  {
    "parent_code": "35",
    "code": "0",
    "label": "Tinajo"
  },
  {
    "parent_code": "35",
    "code": "4",
    "label": "Tuineje"
  },
  {
    "parent_code": "35",
    "code": "6",
    "label": "Valleseco"
  },
  {
    "parent_code": "35",
    "code": "1",
    "label": "Valsequillo de Gran Canaria"
  },
  {
    "parent_code": "35",
    "code": "2",
    "label": "Vega de San Mateo"
  },
  {
    "parent_code": "35",
    "code": "7",
    "label": "Yaiza"
  },
  {
    "parent_code": "36",
    "code": "9",
    "label": "Agolada"
  },
  {
    "parent_code": "36",
    "code": "0",
    "label": "Arbo"
  },
  {
    "parent_code": "36",
    "code": "1",
    "label": "Baiona"
  },
  {
    "parent_code": "36",
    "code": "5",
    "label": "Barro"
  },
  {
    "parent_code": "36",
    "code": "6",
    "label": "Bueu"
  },
  {
    "parent_code": "36",
    "code": "9",
    "label": "Caldas de Reis"
  },
  {
    "parent_code": "36",
    "code": "2",
    "label": "Cambados"
  },
  {
    "parent_code": "36",
    "code": "8",
    "label": "Campo Lameiro"
  },
  {
    "parent_code": "36",
    "code": "4",
    "label": "Cangas"
  },
  {
    "parent_code": "36",
    "code": "7",
    "label": "Cañiza, A"
  },
  {
    "parent_code": "36",
    "code": "1",
    "label": "Catoira"
  },
  {
    "parent_code": "36",
    "code": "6",
    "label": "Cerdedo-Cotobade"
  },
  {
    "parent_code": "36",
    "code": "9",
    "label": "Covelo"
  },
  {
    "parent_code": "36",
    "code": "4",
    "label": "Crecente"
  },
  {
    "parent_code": "36",
    "code": "7",
    "label": "Cuntis"
  },
  {
    "parent_code": "36",
    "code": "0",
    "label": "Dozón"
  },
  {
    "parent_code": "36",
    "code": "6",
    "label": "Estrada, A"
  },
  {
    "parent_code": "36",
    "code": "2",
    "label": "Forcarei"
  },
  {
    "parent_code": "36",
    "code": "5",
    "label": "Fornelos de Montes"
  },
  {
    "parent_code": "36",
    "code": "6",
    "label": "Gondomar"
  },
  {
    "parent_code": "36",
    "code": "1",
    "label": "Grove, O"
  },
  {
    "parent_code": "36",
    "code": "7",
    "label": "Guarda, A"
  },
  {
    "parent_code": "36",
    "code": "1",
    "label": "Illa de Arousa, A"
  },
  {
    "parent_code": "36",
    "code": "2",
    "label": "Lalín"
  },
  {
    "parent_code": "36",
    "code": "5",
    "label": "Lama, A"
  },
  {
    "parent_code": "36",
    "code": "8",
    "label": "Marín"
  },
  {
    "parent_code": "36",
    "code": "4",
    "label": "Meaño"
  },
  {
    "parent_code": "36",
    "code": "0",
    "label": "Meis"
  },
  {
    "parent_code": "36",
    "code": "3",
    "label": "Moaña"
  },
  {
    "parent_code": "36",
    "code": "7",
    "label": "Mondariz"
  },
  {
    "parent_code": "36",
    "code": "4",
    "label": "Mondariz-Balneario"
  },
  {
    "parent_code": "36",
    "code": "9",
    "label": "Moraña"
  },
  {
    "parent_code": "36",
    "code": "5",
    "label": "Mos"
  },
  {
    "parent_code": "36",
    "code": "0",
    "label": "Neves, As"
  },
  {
    "parent_code": "36",
    "code": "3",
    "label": "Nigrán"
  },
  {
    "parent_code": "36",
    "code": "6",
    "label": "Oia"
  },
  {
    "parent_code": "36",
    "code": "2",
    "label": "Pazos de Borbén"
  },
  {
    "parent_code": "36",
    "code": "2",
    "label": "Poio"
  },
  {
    "parent_code": "36",
    "code": "3",
    "label": "Ponte Caldelas"
  },
  {
    "parent_code": "36",
    "code": "7",
    "label": "Ponteareas"
  },
  {
    "parent_code": "36",
    "code": "8",
    "label": "Pontecesures"
  },
  {
    "parent_code": "36",
    "code": "8",
    "label": "Pontevedra"
  },
  {
    "parent_code": "36",
    "code": "1",
    "label": "Porriño, O"
  },
  {
    "parent_code": "36",
    "code": "5",
    "label": "Portas"
  },
  {
    "parent_code": "36",
    "code": "1",
    "label": "Redondela"
  },
  {
    "parent_code": "36",
    "code": "4",
    "label": "Ribadumia"
  },
  {
    "parent_code": "36",
    "code": "0",
    "label": "Rodeiro"
  },
  {
    "parent_code": "36",
    "code": "6",
    "label": "Rosal, O"
  },
  {
    "parent_code": "36",
    "code": "9",
    "label": "Salceda de Caselas"
  },
  {
    "parent_code": "36",
    "code": "2",
    "label": "Salvaterra de Miño"
  },
  {
    "parent_code": "36",
    "code": "9",
    "label": "Sanxenxo"
  },
  {
    "parent_code": "36",
    "code": "4",
    "label": "Silleda"
  },
  {
    "parent_code": "36",
    "code": "0",
    "label": "Soutomaior"
  },
  {
    "parent_code": "36",
    "code": "5",
    "label": "Tomiño"
  },
  {
    "parent_code": "36",
    "code": "8",
    "label": "Tui"
  },
  {
    "parent_code": "36",
    "code": "1",
    "label": "Valga"
  },
  {
    "parent_code": "36",
    "code": "7",
    "label": "Vigo"
  },
  {
    "parent_code": "36",
    "code": "6",
    "label": "Vila de Cruces"
  },
  {
    "parent_code": "36",
    "code": "3",
    "label": "Vilaboa"
  },
  {
    "parent_code": "36",
    "code": "0",
    "label": "Vilagarcía de Arousa"
  },
  {
    "parent_code": "36",
    "code": "7",
    "label": "Vilanova de Arousa"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Abusejo"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Agallas"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Ahigal de los Aceiteros"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Ahigal de Villarino"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Alameda de Gardón, La"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Alamedilla, La"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Alaraz"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Alba de Tormes"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Alba de Yeltes"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Alberca, La"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Alberguería de Argañán, La"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Alconada"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Aldea del Obispo"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Aldeacipreste"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Aldeadávila de la Ribera"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Aldealengua"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Aldeanueva de Figueroa"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Aldeanueva de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Aldearrodrigo"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Aldearrubia"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Aldeaseca de Alba"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Aldeaseca de la Frontera"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Aldeatejada"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Aldeavieja de Tormes"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Aldehuela de la Bóveda"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Aldehuela de Yeltes"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Almenara de Tormes"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Almendra"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Anaya de Alba"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Añover de Tormes"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Arabayona de Mógica"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Arapiles"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Arcediano"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Arco, El"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Armenteros"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Atalaya, La"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Babilafuente"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Bañobárez"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Barbadillo"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Barbalos"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Barceo"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Barruecopardo"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Bastida, La"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Béjar"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Beleña"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Bermellar"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Berrocal de Huebra"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Berrocal de Salvatierra"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Boada"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Bodón, El"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Bogajo"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Bouza, La"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Bóveda del Río Almar"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Brincones"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Buenamadre"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Buenavista"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Cabaco, El"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Cabeza de Béjar, La"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Cabeza del Caballo"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Cabezabellosa de la Calzada"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Cabrerizos"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Cabrillas"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Calvarrasa de Abajo"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Calvarrasa de Arriba"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Calzada de Béjar, La"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Calzada de Don Diego"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Calzada de Valdunciel"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Campillo de Azaba"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Campo de Peñaranda, El"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Candelario"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Canillas de Abajo"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Cantagallo"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Cantalapiedra"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Cantalpino"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Cantaracillo"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Carbajosa de la Sagrada"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Carpio de Azaba"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Carrascal de Barregas"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Carrascal del Obispo"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Casafranca"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Casas del Conde, Las"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Casillas de Flores"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Castellanos de Moriscos"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Castellanos de Villiquera"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Castillejo de Martín Viejo"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Castraz"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Cepeda"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Cereceda de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Cerezal de Peñahorcada"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Cerralbo"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Cerro, El"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Cespedosa de Tormes"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Chagarcía Medianero"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Cilleros de la Bastida"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Cipérez"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Ciudad Rodrigo"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Coca de Alba"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Colmenar de Montemayor"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Cordovilla"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Cristóbal"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Cubo de Don Sancho, El"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Dios le Guarde"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Doñinos de Ledesma"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Doñinos de Salamanca"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Éjeme"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Encina de San Silvestre"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Encina, La"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Encinas de Abajo"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Encinas de Arriba"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Encinasola de los Comendadores"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Endrinal"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Escurial de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Espadaña"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Espeja"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Espino de la Orbada"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Florida de Liébana"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Forfoleda"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Frades de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Fregeneda, La"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Fresnedoso"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Fresno Alhándiga"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Fuente de San Esteban, La"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Fuenteguinaldo"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Fuenteliante"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Fuenterroble de Salvatierra"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Fuentes de Béjar"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Fuentes de Oñoro"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Gajates"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Galindo y Perahuy"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Galinduste"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Galisancho"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Gallegos de Argañán"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Gallegos de Solmirón"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Garcibuey"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Garcihernández"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Garcirrey"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Gejuelo del Barro"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Golpejas"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Gomecello"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Guadramiro"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Guijo de Ávila"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Guijuelo"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Herguijuela de Ciudad Rodrigo"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Herguijuela de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Herguijuela del Campo"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Hinojosa de Duero"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Horcajo de Montemayor"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Horcajo Medianero"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Hoya, La"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Huerta"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Iruelos"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Ituero de Azaba"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Juzbado"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Lagunilla"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Larrodrigo"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Ledesma"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Ledrada"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Linares de Riofrío"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Lumbrales"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Machacón"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Macotera"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Madroñal"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Maíllo, El"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Malpartida"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Mancera de Abajo"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Manzano, El"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Martiago"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Martín de Yeltes"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Martinamor"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Masueco"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Mata de Ledesma, La"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Matilla de los Caños del Río"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Maya, La"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Membribe de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Mieza"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Milano, El"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Miranda de Azán"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Miranda del Castañar"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Mogarraz"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Molinillo"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Monforte de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Monleón"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Monleras"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Monsagro"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Montejo"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Montemayor del Río"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Monterrubio de Armuña"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Monterrubio de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Morasverdes"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Morille"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Moríñigo"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Moriscos"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Moronta"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Mozárbez"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Narros de Matalayegua"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Nava de Béjar"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Nava de Francia"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Nava de Sotrobal"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Navacarros"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Navales"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Navalmoral de Béjar"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Navamorales"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Navarredonda de la Rinconada"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Navasfrías"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Negrilla de Palencia"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Olmedo de Camaces"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Orbada, La"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Pajares de la Laguna"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Palacios del Arzobispo"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Palaciosrubios"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Palencia de Negrilla"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Parada de Arriba"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Parada de Rubiales"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Paradinas de San Juan"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Pastores"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Payo, El"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Pedraza de Alba"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Pedrosillo de Alba"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Pedrosillo de los Aires"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Pedrosillo el Ralo"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Pedroso de la Armuña, El"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Pelabravo"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Pelarrodríguez"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Pelayos"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Peña, La"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Peñacaballera"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Peñaparda"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Peñaranda de Bracamonte"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Peñarandilla"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Peralejos de Abajo"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Peralejos de Arriba"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Pereña de la Ribera"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Peromingo"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Pinedas"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Pino de Tormes, El"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Pitiegua"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Pizarral"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Poveda de las Cintas"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Pozos de Hinojo"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Puebla de Azaba"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Puebla de San Medel"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Puebla de Yeltes"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Puente del Congosto"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Puertas"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Puerto de Béjar"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Puerto Seguro"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Rágama"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Redonda, La"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Retortillo"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Rinconada de la Sierra, La"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Robleda"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Robliza de Cojos"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Rollán"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Saelices el Chico"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Sagrada, La"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Sahugo, El"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Salamanca"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Saldeana"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Salmoral"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Salvatierra de Tormes"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "San Cristóbal de la Cuesta"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "San Esteban de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "San Felices de los Gallegos"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "San Martín del Castañar"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "San Miguel de Valero"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "San Miguel del Robledo"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "San Morales"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "San Muñoz"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "San Pedro de Rozados"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "San Pedro del Valle"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "San Pelayo de Guareña"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Sanchón de la Ribera"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Sanchón de la Sagrada"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Sanchotello"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Sancti-Spíritus"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Sando"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Santa María de Sando"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Santa Marta de Tormes"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Santiago de la Puebla"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Santibáñez de Béjar"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Santibáñez de la Sierra"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Santiz"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Santos, Los"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Sardón de los Frailes"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Saucelle"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Sepulcro-Hilario"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Sequeros"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Serradilla del Arroyo"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Serradilla del Llano"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Sierpe, La"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Sieteiglesias de Tormes"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Sobradillo"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Sorihuela"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Sotoserrano"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Tabera de Abajo"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Tala, La"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Tamames"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Tarazona de Guareña"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Tardáguila"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Tejado, El"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Tejeda y Segoyuela"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Tenebrón"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Terradillos"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Topas"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Tordillos"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Tornadizo, El"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Torresmenudas"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Trabanca"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Tremedal de Tormes"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Valdecarros"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Valdefuentes de Sangusín"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Valdehijaderos"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Valdelacasa"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Valdelageve"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Valdelosa"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Valdemierque"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Valderrodrigo"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Valdunciel"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Valero"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Vallejera de Riofrío"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Valsalabroso"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Valverde de Valdelacasa"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Valverdón"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Vecinos"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Vega de Tirados"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Veguillas, Las"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Vellés, La"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Ventosa del Río Almar"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Vídola, La"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Villaflores"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Villagonzalo de Tormes"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Villalba de los Llanos"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Villamayor"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Villanueva del Conde"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Villar de Argañán"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Villar de Ciervo"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Villar de Gallimazo"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Villar de la Yegua"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Villar de Peralonso"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Villar de Samaniego"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Villares de la Reina"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Villares de Yeltes"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Villarino de los Aires"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Villarmayor"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Villarmuerto"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Villasbuenas"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Villasdardo"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Villaseco de los Gamitos"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Villaseco de los Reyes"
  },
  {
    "parent_code": "37",
    "code": "8",
    "label": "Villasrubias"
  },
  {
    "parent_code": "37",
    "code": "3",
    "label": "Villaverde de Guareña"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Villavieja de Yeltes"
  },
  {
    "parent_code": "37",
    "code": "4",
    "label": "Villoria"
  },
  {
    "parent_code": "37",
    "code": "7",
    "label": "Villoruela"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Vilvestre"
  },
  {
    "parent_code": "37",
    "code": "0",
    "label": "Vitigudino"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Yecla de Yeltes"
  },
  {
    "parent_code": "37",
    "code": "2",
    "label": "Zamarra"
  },
  {
    "parent_code": "37",
    "code": "5",
    "label": "Zamayón"
  },
  {
    "parent_code": "37",
    "code": "9",
    "label": "Zarapicos"
  },
  {
    "parent_code": "37",
    "code": "6",
    "label": "Zarza de Pumareda, La"
  },
  {
    "parent_code": "37",
    "code": "1",
    "label": "Zorita de la Frontera"
  },
  {
    "parent_code": "38",
    "code": "2",
    "label": "Adeje"
  },
  {
    "parent_code": "38",
    "code": "7",
    "label": "Agulo"
  },
  {
    "parent_code": "38",
    "code": "3",
    "label": "Alajeró"
  },
  {
    "parent_code": "38",
    "code": "8",
    "label": "Arafo"
  },
  {
    "parent_code": "38",
    "code": "1",
    "label": "Arico"
  },
  {
    "parent_code": "38",
    "code": "4",
    "label": "Arona"
  },
  {
    "parent_code": "38",
    "code": "0",
    "label": "Barlovento"
  },
  {
    "parent_code": "38",
    "code": "6",
    "label": "Breña Alta"
  },
  {
    "parent_code": "38",
    "code": "9",
    "label": "Breña Baja"
  },
  {
    "parent_code": "38",
    "code": "3",
    "label": "Buenavista del Norte"
  },
  {
    "parent_code": "38",
    "code": "0",
    "label": "Candelaria"
  },
  {
    "parent_code": "38",
    "code": "5",
    "label": "Fasnia"
  },
  {
    "parent_code": "38",
    "code": "1",
    "label": "Frontera"
  },
  {
    "parent_code": "38",
    "code": "6",
    "label": "Fuencaliente de la Palma"
  },
  {
    "parent_code": "38",
    "code": "9",
    "label": "Garachico"
  },
  {
    "parent_code": "38",
    "code": "2",
    "label": "Garafía"
  },
  {
    "parent_code": "38",
    "code": "8",
    "label": "Granadilla de Abona"
  },
  {
    "parent_code": "38",
    "code": "4",
    "label": "Guancha, La"
  },
  {
    "parent_code": "38",
    "code": "7",
    "label": "Guía de Isora"
  },
  {
    "parent_code": "38",
    "code": "1",
    "label": "Güímar"
  },
  {
    "parent_code": "38",
    "code": "8",
    "label": "Hermigua"
  },
  {
    "parent_code": "38",
    "code": "3",
    "label": "Icod de los Vinos"
  },
  {
    "parent_code": "38",
    "code": "4",
    "label": "Llanos de Aridane, Los"
  },
  {
    "parent_code": "38",
    "code": "7",
    "label": "Matanza de Acentejo, La"
  },
  {
    "parent_code": "38",
    "code": "0",
    "label": "Orotava, La"
  },
  {
    "parent_code": "38",
    "code": "6",
    "label": "Paso, El"
  },
  {
    "parent_code": "38",
    "code": "3",
    "label": "Pinar de El Hierro, El"
  },
  {
    "parent_code": "38",
    "code": "2",
    "label": "Puerto de la Cruz"
  },
  {
    "parent_code": "38",
    "code": "5",
    "label": "Puntagorda"
  },
  {
    "parent_code": "38",
    "code": "9",
    "label": "Puntallana"
  },
  {
    "parent_code": "38",
    "code": "6",
    "label": "Realejos, Los"
  },
  {
    "parent_code": "38",
    "code": "1",
    "label": "Rosario, El"
  },
  {
    "parent_code": "38",
    "code": "7",
    "label": "San Andrés y Sauces"
  },
  {
    "parent_code": "38",
    "code": "9",
    "label": "San Cristóbal de La Laguna"
  },
  {
    "parent_code": "38",
    "code": "2",
    "label": "San Juan de la Rambla"
  },
  {
    "parent_code": "38",
    "code": "5",
    "label": "San Miguel de Abona"
  },
  {
    "parent_code": "38",
    "code": "8",
    "label": "San Sebastián de la Gomera"
  },
  {
    "parent_code": "38",
    "code": "4",
    "label": "Santa Cruz de la Palma"
  },
  {
    "parent_code": "38",
    "code": "0",
    "label": "Santa Cruz de Tenerife"
  },
  {
    "parent_code": "38",
    "code": "3",
    "label": "Santa Úrsula"
  },
  {
    "parent_code": "38",
    "code": "7",
    "label": "Santiago del Teide"
  },
  {
    "parent_code": "38",
    "code": "4",
    "label": "Sauzal, El"
  },
  {
    "parent_code": "38",
    "code": "9",
    "label": "Silos, Los"
  },
  {
    "parent_code": "38",
    "code": "5",
    "label": "Tacoronte"
  },
  {
    "parent_code": "38",
    "code": "0",
    "label": "Tanque, El"
  },
  {
    "parent_code": "38",
    "code": "3",
    "label": "Tazacorte"
  },
  {
    "parent_code": "38",
    "code": "6",
    "label": "Tegueste"
  },
  {
    "parent_code": "38",
    "code": "2",
    "label": "Tijarafe"
  },
  {
    "parent_code": "38",
    "code": "1",
    "label": "Valle Gran Rey"
  },
  {
    "parent_code": "38",
    "code": "4",
    "label": "Vallehermoso"
  },
  {
    "parent_code": "38",
    "code": "8",
    "label": "Valverde"
  },
  {
    "parent_code": "38",
    "code": "1",
    "label": "Victoria de Acentejo, La"
  },
  {
    "parent_code": "38",
    "code": "6",
    "label": "Vilaflor de Chasna"
  },
  {
    "parent_code": "38",
    "code": "2",
    "label": "Villa de Mazo"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Alfoz de Lloredo"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Ampuero"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Anievas"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Arenas de Iguña"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Argoños"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Arnuero"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Arredondo"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Astillero, El"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Bárcena de Cicero"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Bárcena de Pie de Concha"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Bareyo"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Cabezón de la Sal"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Cabezón de Liébana"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Cabuérniga"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Camaleño"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Camargo"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Campoo de Enmedio"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Campoo de Yuso"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Cartes"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Castañeda"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Castro-Urdiales"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Cieza"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Cillorigo de Liébana"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Colindres"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Comillas"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Corrales de Buelna, Los"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Corvera de Toranzo"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Entrambasaguas"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Escalante"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Guriezo"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Hazas de Cesto"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Hermandad de Campoo de Suso"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Herrerías"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Lamasón"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Laredo"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Liendo"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Liérganes"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Limpias"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Luena"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Marina de Cudeyo"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Mazcuerras"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Medio Cudeyo"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Meruelo"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Miengo"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Miera"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Molledo"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Noja"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Penagos"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Peñarrubia"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Pesaguero"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Pesquera"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Piélagos"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Polaciones"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Polanco"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Potes"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Puente Viesgo"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Ramales de la Victoria"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Rasines"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Reinosa"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Reocín"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Ribamontán al Mar"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Ribamontán al Monte"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Rionansa"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Riotuerto"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Rozas de Valdearroyo, Las"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Ruente"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Ruesga"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Ruiloba"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "San Felices de Buelna"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "San Miguel de Aguayo"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "San Pedro del Romeral"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "San Roque de Riomiera"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "San Vicente de la Barquera"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Santa Cruz de Bezana"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Santa María de Cayón"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Santander"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Santillana del Mar"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Santiurde de Reinosa"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Santiurde de Toranzo"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Santoña"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Saro"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Selaya"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Soba"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Solórzano"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Suances"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Tojos, Los"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Torrelavega"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Tresviso"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Tudanca"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Udías"
  },
  {
    "parent_code": "39",
    "code": "5",
    "label": "Val de San Vicente"
  },
  {
    "parent_code": "39",
    "code": "6",
    "label": "Valdáliga"
  },
  {
    "parent_code": "39",
    "code": "1",
    "label": "Valdeolea"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Valdeprado del Río"
  },
  {
    "parent_code": "39",
    "code": "2",
    "label": "Valderredible"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Valle de Villaverde"
  },
  {
    "parent_code": "39",
    "code": "8",
    "label": "Vega de Liébana"
  },
  {
    "parent_code": "39",
    "code": "4",
    "label": "Vega de Pas"
  },
  {
    "parent_code": "39",
    "code": "0",
    "label": "Villacarriedo"
  },
  {
    "parent_code": "39",
    "code": "3",
    "label": "Villaescusa"
  },
  {
    "parent_code": "39",
    "code": "7",
    "label": "Villafufre"
  },
  {
    "parent_code": "39",
    "code": "9",
    "label": "Voto"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Abades"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Adrada de Pirón"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Adrados"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Aguilafuente"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Alconada de Maderuelo"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Aldea Real"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Aldealcorvo"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Aldealengua de Pedraza"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Aldealengua de Santa María"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Aldeanueva de la Serrezuela"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Aldeanueva del Codonal"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Aldeasoña"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Aldehorno"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Aldehuela del Codonal"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Aldeonte"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Anaya"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Añe"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Arahuetes"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Arcones"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Arevalillo de Cega"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Armuña"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Ayllón"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Barbolla"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Basardilla"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Bercial"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Bercimuel"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Bernardos"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Bernuy de Porreros"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Boceguillas"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Brieva"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Caballar"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Cabañas de Polendos"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Cabezuela"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Calabazas de Fuentidueña"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Campo de San Pedro"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Cantalejo"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Cantimpalos"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Carabias"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Carbonero el Mayor"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Carrascal del Río"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Casla"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Castillejo de Mesleón"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Castro de Fuentidueña"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Castrojimeno"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Castroserna de Abajo"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Castroserracín"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Cedillo de la Torre"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Cerezo de Abajo"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Cerezo de Arriba"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Chañe"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Cilleruelo de San Mamés"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Cobos de Fuentidueña"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Coca"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Codorniz"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Collado Hermoso"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Condado de Castilnovo"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Corral de Ayllón"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Cozuelos de Fuentidueña"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Cubillo"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Cuéllar"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Cuevas de Provanco"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Domingo García"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Donhierro"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Duruelo"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Encinas"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Encinillas"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Escalona del Prado"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Escarabajosa de Cabezas"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Escobar de Polendos"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Espinar, El"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Espirdo"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Fresneda de Cuéllar"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Fresno de Cantespino"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Fresno de la Fuente"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Frumales"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Fuente de Santa Cruz"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Fuente el Olmo de Fuentidueña"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Fuente el Olmo de Íscar"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Fuentepelayo"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Fuentepiñel"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Fuenterrebollo"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Fuentesaúco de Fuentidueña"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Fuentesoto"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Fuentidueña"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Gallegos"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Garcillán"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Gomezserracín"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Grajera"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Honrubia de la Cuesta"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Hontalbilla"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Hontanares de Eresma"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Huertos, Los"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Ituero y Lama"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Juarros de Riomoros"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Juarros de Voltoya"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Labajos"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Laguna de Contreras"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Languilla"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Lastras de Cuéllar"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Lastras del Pozo"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Lastrilla, La"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Losa, La"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Maderuelo"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Marazoleja"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Marazuela"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Martín Miguel"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Martín Muñoz de la Dehesa"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Martín Muñoz de las Posadas"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Marugán"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Mata de Cuéllar"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Matabuena"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Matilla, La"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Melque de Cercos"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Membibre de la Hoz"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Migueláñez"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Montejo de Arévalo"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Montejo de la Vega de la Serrezuela"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Monterrubio"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Moral de Hornuez"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Mozoncillo"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Muñopedro"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Muñoveros"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Nava de la Asunción"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Navafría"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Navalilla"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Navalmanzano"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Navares de Ayuso"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Navares de Enmedio"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Navares de las Cuevas"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Navas de Oro"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Navas de Riofrío"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Navas de San Antonio"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Nieva"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Olombrada"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Orejana"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Ortigosa de Pestaño"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Ortigosa del Monte"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Otero de Herreros"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Pajarejos"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Palazuelos de Eresma"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Pedraza"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Pelayos del Arroyo"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Perosillo"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Pinarejos"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Pinarnegrillo"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Prádena"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Puebla de Pedraza"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Rapariegos"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Real Sitio de San Ildefonso"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Rebollo"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Remondo"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Riaguas de San Bartolomé"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Riaza"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Ribota"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Riofrío de Riaza"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Roda de Eresma"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Sacramenia"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Samboal"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "San Cristóbal de Cuéllar"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "San Cristóbal de la Vega"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "San Cristóbal de Segovia"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "San Martín y Mudrián"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "San Miguel de Bernuy"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "San Pedro de Gaíllos"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Sanchonuño"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Sangarcía"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Santa María la Real de Nieva"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Santa Marta del Cerro"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Santiuste de Pedraza"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Santiuste de San Juan Bautista"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Santo Domingo de Pirón"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Santo Tomé del Puerto"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Sauquillo de Cabezas"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Sebúlcor"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Segovia"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Sepúlveda"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Sequera de Fresno"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Sotillo"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Sotosalbos"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Tabanera la Luenga"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Tolocirio"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Torre Val de San Pedro"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Torreadrada"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Torrecaballeros"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Torrecilla del Pinar"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Torreiglesias"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Trescasas"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Turégano"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Urueñas"
  },
  {
    "parent_code": "40",
    "code": "5",
    "label": "Valdeprados"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Valdevacas de Montejo"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Valdevacas y Guijar"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Valle de Tabladillo"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Vallelado"
  },
  {
    "parent_code": "40",
    "code": "6",
    "label": "Valleruela de Pedraza"
  },
  {
    "parent_code": "40",
    "code": "3",
    "label": "Valleruela de Sepúlveda"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Valseca"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Valtiendas"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Valverde del Majano"
  },
  {
    "parent_code": "40",
    "code": "8",
    "label": "Veganzones"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Vegas de Matute"
  },
  {
    "parent_code": "40",
    "code": "9",
    "label": "Ventosilla y Tejadilla"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Villacastín"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Villaverde de Íscar"
  },
  {
    "parent_code": "40",
    "code": "0",
    "label": "Villaverde de Montejo"
  },
  {
    "parent_code": "40",
    "code": "4",
    "label": "Villeguillo"
  },
  {
    "parent_code": "40",
    "code": "1",
    "label": "Yanguas de Eresma"
  },
  {
    "parent_code": "40",
    "code": "2",
    "label": "Zarzuela del Monte"
  },
  {
    "parent_code": "40",
    "code": "7",
    "label": "Zarzuela del Pinar"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Aguadulce"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Alanís"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Albaida del Aljarafe"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Alcalá de Guadaíra"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Alcalá del Río"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Alcolea del Río"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Algaba, La"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Algámitas"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Almadén de la Plata"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Almensilla"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Arahal"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Aznalcázar"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Aznalcóllar"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Badolatosa"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Benacazón"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Bollullos de la Mitación"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Bormujos"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Brenes"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Burguillos"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Cabezas de San Juan, Las"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Camas"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Campana, La"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Cantillana"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Cañada Rosal"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Carmona"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Carrión de los Céspedes"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Casariche"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Castilblanco de los Arroyos"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Castilleja de Guzmán"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Castilleja de la Cuesta"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Castilleja del Campo"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Castillo de las Guardas, El"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Cazalla de la Sierra"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Constantina"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Coria del Río"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Coripe"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Coronil, El"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Corrales, Los"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Cuervo de Sevilla, El"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Dos Hermanas"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Écija"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Espartinas"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Estepa"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Fuentes de Andalucía"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Garrobo, El"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Gelves"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Gerena"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Gilena"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Gines"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Guadalcanal"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Guillena"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Herrera"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Huévar del Aljarafe"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Isla Mayor"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Lantejuela"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Lebrija"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Lora de Estepa"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Lora del Río"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Luisiana, La"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Madroño, El"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Mairena del Alcor"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Mairena del Aljarafe"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Marchena"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Marinaleda"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Martín de la Jara"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Molares, Los"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Montellano"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Morón de la Frontera"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Navas de la Concepción, Las"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Olivares"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Osuna"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Palacios y Villafranca, Los"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Palmar de Troya, El"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Palomares del Río"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Paradas"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Pedrera"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Pedroso, El"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Peñaflor"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Pilas"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Pruna"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Puebla de Cazalla, La"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Puebla de los Infantes, La"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Puebla del Río, La"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Real de la Jara, El"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Rinconada, La"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Roda de Andalucía, La"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Ronquillo, El"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Rubio, El"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Salteras"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "San Juan de Aznalfarache"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "San Nicolás del Puerto"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Sanlúcar la Mayor"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Santiponce"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Saucejo, El"
  },
  {
    "parent_code": "41",
    "code": "7",
    "label": "Sevilla"
  },
  {
    "parent_code": "41",
    "code": "2",
    "label": "Tocina"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Tomares"
  },
  {
    "parent_code": "41",
    "code": "3",
    "label": "Umbrete"
  },
  {
    "parent_code": "41",
    "code": "6",
    "label": "Utrera"
  },
  {
    "parent_code": "41",
    "code": "9",
    "label": "Valencina de la Concepción"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Villamanrique de la Condesa"
  },
  {
    "parent_code": "41",
    "code": "8",
    "label": "Villanueva de San Juan"
  },
  {
    "parent_code": "41",
    "code": "1",
    "label": "Villanueva del Ariscal"
  },
  {
    "parent_code": "41",
    "code": "4",
    "label": "Villanueva del Río y Minas"
  },
  {
    "parent_code": "41",
    "code": "5",
    "label": "Villaverde del Río"
  },
  {
    "parent_code": "41",
    "code": "0",
    "label": "Viso del Alcor, El"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Abejar"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Adradas"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Ágreda"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Alconaba"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Alcubilla de Avellaneda"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Alcubilla de las Peñas"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Aldealafuente"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Aldealices"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Aldealpozo"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Aldealseñor"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Aldehuela de Periáñez"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Aldehuelas, Las"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Alentisque"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Aliud"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Almajano"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Almaluez"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Almarza"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Almazán"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Almazul"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Almenar de Soria"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Alpanseque"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Arancón"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Arcos de Jalón"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Arenillas"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Arévalo de la Sierra"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Ausejo de la Sierra"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Baraona"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Barca"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Barcones"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Bayubas de Abajo"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Bayubas de Arriba"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Beratón"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Berlanga de Duero"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Blacos"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Bliecos"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Borjabad"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Borobia"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Buberos"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Buitrago"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Burgo de Osma-Ciudad de Osma"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Cabrejas del Campo"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Cabrejas del Pinar"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Calatañazor"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Caltojar"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Candilichera"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Cañamaque"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Carabantes"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Caracena"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Carrascosa de Abajo"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Carrascosa de la Sierra"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Casarejos"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Castilfrío de la Sierra"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Castillejo de Robledo"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Castilruiz"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Centenera de Andaluz"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Cerbón"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Cidones"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Cigudosa"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Cihuela"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Ciria"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Cirujales del Río"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Coscurita"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Covaleda"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Cubilla"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Cubo de la Solana"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Cueva de Ágreda"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Dévanos"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Deza"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Duruelo de la Sierra"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Escobosa de Almazán"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Espeja de San Marcelino"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Espejón"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Estepa de San Juan"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Frechilla de Almazán"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Fresno de Caracena"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Fuentearmegil"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Fuentecambrón"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Fuentecantos"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Fuentelmonge"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Fuentelsaz de Soria"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Fuentepinilla"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Fuentes de Magaña"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Fuentestrún"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Garray"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Golmayo"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Gómara"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Gormaz"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Herrera de Soria"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Hinojosa del Campo"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Langa de Duero"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Liceras"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Losilla, La"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Magaña"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Maján"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Matalebreras"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Matamala de Almazán"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Medinaceli"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Miño de Medinaceli"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Miño de San Esteban"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Molinos de Duero"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Momblona"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Monteagudo de las Vicarías"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Montejo de Tiermes"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Montenegro de Cameros"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Morón de Almazán"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Muriel de la Fuente"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Muriel Viejo"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Nafría de Ucero"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Narros"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Navaleno"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Nepas"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Nolay"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Noviercas"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Ólvega"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Oncala"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Pinilla del Campo"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Portillo de Soria"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Póveda de Soria, La"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Pozalmuro"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Quintana Redonda"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Quintanas de Gormaz"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Quiñonería"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Rábanos, Los"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Rebollar"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Recuerda"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Rello"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Renieblas"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Retortillo de Soria"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Reznos"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Riba de Escalote, La"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Rioseco de Soria"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Rollamienta"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Royo, El"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Salduero"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "San Esteban de Gormaz"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "San Felices"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "San Leonardo de Yagüe"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "San Pedro Manrique"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Santa Cruz de Yanguas"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Santa María de Huerta"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Santa María de las Hoyas"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Serón de Nágima"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Soliedra"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Soria"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Sotillo del Rincón"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Suellacabras"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Tajahuerce"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Tajueco"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Talveila"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Tardelcuende"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Taroda"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Tejado"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Torlengua"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Torreblacos"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Torrubia de Soria"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Trévago"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Ucero"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Vadillo"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Valdeavellano de Tera"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Valdegeña"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Valdelagua del Cerro"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Valdemaluque"
  },
  {
    "parent_code": "42",
    "code": "0",
    "label": "Valdenebro"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Valdeprado"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Valderrodilla"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Valtajeros"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Velamazán"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Velilla de la Sierra"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Velilla de los Ajos"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Viana de Duero"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Villaciervos"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Villanueva de Gormaz"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Villar del Ala"
  },
  {
    "parent_code": "42",
    "code": "3",
    "label": "Villar del Campo"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Villar del Río"
  },
  {
    "parent_code": "42",
    "code": "7",
    "label": "Villares de Soria, Los"
  },
  {
    "parent_code": "42",
    "code": "2",
    "label": "Villasayas"
  },
  {
    "parent_code": "42",
    "code": "8",
    "label": "Villaseca de Arciel"
  },
  {
    "parent_code": "42",
    "code": "6",
    "label": "Vinuesa"
  },
  {
    "parent_code": "42",
    "code": "9",
    "label": "Vizmanos"
  },
  {
    "parent_code": "42",
    "code": "5",
    "label": "Vozmediano"
  },
  {
    "parent_code": "42",
    "code": "1",
    "label": "Yanguas"
  },
  {
    "parent_code": "42",
    "code": "4",
    "label": "Yelo"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Aiguamúrcia"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Albinyana"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Albiol, L'"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Alcanar"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Alcover"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Aldea, L'"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Aldover"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Aleixar, L'"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Alfara de Carles"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Alforja"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Alió"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Almoster"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Altafulla"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Ametlla de Mar, L'"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Ampolla, L'"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Amposta"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Arboç, L'"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Arbolí"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Argentera, L'"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Arnes"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Ascó"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Banyeres del Penedès"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Barberà de la Conca"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Batea"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Bellmunt del Priorat"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Bellvei"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Benifallet"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Benissanet"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Bisbal de Falset, La"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Bisbal del Penedès, La"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Blancafort"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Bonastre"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Borges del Camp, Les"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Bot"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Botarell"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Bràfim"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Cabacés"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Cabra del Camp"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Calafell"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Camarles"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Cambrils"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Canonja, La"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Capafonts"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Capçanes"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Caseres"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Castellvell del Camp"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Catllar, El"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Colldejou"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Conesa"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Constantí"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Corbera d'Ebre"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Cornudella de Montsant"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Creixell"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Cunit"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Deltebre"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Duesaigües"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Espluga de Francolí, L'"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Falset"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Fatarella, La"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Febró, La"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Figuera, La"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Figuerola del Camp"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Flix"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Forès"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Freginals"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Galera, La"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Gandesa"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Garcia"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Garidells, Els"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Ginestar"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Godall"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Gratallops"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Guiamets, Els"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Horta de Sant Joan"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Lloar, El"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Llorac"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Llorenç del Penedès"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Marçà"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Margalef"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Mas de Barberans"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Masdenverge"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Masllorenç"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Masó, La"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Maspujols"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Masroig, El"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Milà, El"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Miravet"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Molar, El"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Montblanc"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Montbrió del Camp"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Montferri"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Montmell, El"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Mont-ral"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Mont-roig del Camp"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Móra d'Ebre"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Móra la Nova"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Morell, El"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Morera de Montsant, La"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Nou de Gaià, La"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Nulles"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Pallaresos, Els"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Palma d'Ebre, La"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Passanant i Belltall"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Paüls"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Perafort"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Perelló, El"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Piles, Les"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Pinell de Brai, El"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Pira"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Pla de Santa Maria, El"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Pobla de Mafumet, La"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Pobla de Massaluca, La"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Pobla de Montornès, La"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Poboleda"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Pont d'Armentera, El"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Pontils"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Porrera"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Pradell de la Teixeta"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Prades"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Prat de Comte"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Pratdip"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Puigpelat"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Querol"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Rasquera"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Renau"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Reus"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Riba, La"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Riba-roja d'Ebre"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Riera de Gaià, La"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Riudecanyes"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Riudecols"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Riudoms"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Rocafort de Queralt"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Roda de Berà"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Rodonyà"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Roquetes"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Rourell, El"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Salomó"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Salou"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Sant Carles de la Ràpita"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Sant Jaume dels Domenys"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Sant Jaume d'Enveja"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Santa Bàrbara"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Santa Coloma de Queralt"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Santa Oliva"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Sarral"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Savallà del Comtat"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Secuita, La"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Selva del Camp, La"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Senan"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Sénia, La"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Solivella"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Tarragona"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Tivenys"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Tivissa"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Torre de Fontaubella, La"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Torre de l'Espanyol, La"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Torredembarra"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Torroja del Priorat"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Tortosa"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Ulldecona"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Ulldemolins"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Vallclara"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Vallfogona de Riucorb"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Vallmoll"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Valls"
  },
  {
    "parent_code": "43",
    "code": "8",
    "label": "Vandellòs i l'Hospitalet de l'Infant"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Vendrell, El"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Vespella de Gaià"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Vilabella"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Vilalba dels Arcs"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Vilallonga del Camp"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Vilanova de Prades"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Vilanova d'Escornalbou"
  },
  {
    "parent_code": "43",
    "code": "0",
    "label": "Vilaplana"
  },
  {
    "parent_code": "43",
    "code": "4",
    "label": "Vila-rodona"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Vila-seca"
  },
  {
    "parent_code": "43",
    "code": "6",
    "label": "Vilaverd"
  },
  {
    "parent_code": "43",
    "code": "2",
    "label": "Vilella Alta, La"
  },
  {
    "parent_code": "43",
    "code": "7",
    "label": "Vilella Baixa, La"
  },
  {
    "parent_code": "43",
    "code": "3",
    "label": "Vimbodí i Poblet"
  },
  {
    "parent_code": "43",
    "code": "9",
    "label": "Vinebre"
  },
  {
    "parent_code": "43",
    "code": "5",
    "label": "Vinyols i els Arcs"
  },
  {
    "parent_code": "43",
    "code": "1",
    "label": "Xerta"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Ababuj"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Abejuela"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Aguatón"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Aguaviva"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Aguilar del Alfambra"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Alacón"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Alba"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Albalate del Arzobispo"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Albarracín"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Albentosa"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Alcaine"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Alcalá de la Selva"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Alcañiz"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Alcorisa"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Alfambra"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Aliaga"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Allepuz"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Alloza"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Allueva"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Almohaja"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Alobras"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Alpeñés"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Anadón"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Andorra"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Arcos de las Salinas"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Arens de Lledó"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Argente"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Ariño"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Azaila"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Bádenas"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Báguena"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Bañón"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Barrachina"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Bea"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Beceite"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Bello"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Belmonte de San José"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Berge"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Bezas"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Blancas"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Blesa"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Bordón"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Bronchales"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Bueña"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Burbáguena"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Cabra de Mora"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Calaceite"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Calamocha"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Calanda"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Calomarde"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Camañas"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Camarena de la Sierra"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Camarillas"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Caminreal"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Cantavieja"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Cañada de Benatanduz"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Cañada de Verich, La"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Cañada Vellida"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Cañizar del Olivar"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Cascante del Río"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Castejón de Tornos"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Castel de Cabra"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Castellar, El"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Castellote"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Castelnou"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Castelserás"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Cedrillas"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Celadas"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Cella"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Cerollera, La"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Codoñera, La"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Corbalán"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Cortes de Aragón"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Cosa"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Cretas"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Crivillén"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Cuba, La"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Cubla"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Cucalón"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Cuervo, El"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Cuevas de Almudén"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Cuevas Labradas"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Ejulve"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Escorihuela"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Escucha"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Estercuel"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Ferreruela de Huerva"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Fonfría"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Formiche Alto"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Fórnoles"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Fortanete"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Foz-Calanda"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Fresneda, La"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Frías de Albarracín"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Fuenferrada"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Fuentes Calientes"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Fuentes Claras"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Fuentes de Rubielos"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Fuentespalda"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Galve"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Gargallo"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Gea de Albarracín"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Ginebrosa, La"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Griegos"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Guadalaviar"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Gúdar"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Híjar"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Hinojosa de Jarque"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Hoz de la Vieja, La"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Huesa del Común"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Iglesuela del Cid, La"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Jabaloyas"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Jarque de la Val"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Jatiel"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Jorcas"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Josa"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Lagueruela"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Lanzuela"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Libros"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Lidón"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Linares de Mora"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Lledó"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Loscos"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Maicas"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Manzanera"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Martín del Río"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Mas de las Matas"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Mata de los Olmos, La"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Mazaleón"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Mezquita de Jarque"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Mirambel"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Miravete de la Sierra"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Molinos"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Monforte de Moyuela"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Monreal del Campo"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Monroyo"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Montalbán"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Monteagudo del Castillo"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Monterde de Albarracín"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Mora de Rubielos"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Moscardón"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Mosqueruela"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Muniesa"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Noguera de Albarracín"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Nogueras"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Nogueruelas"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Obón"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Odón"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Ojos Negros"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Olba"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Oliete"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Olmos, Los"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Orihuela del Tremedal"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Orrios"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Palomar de Arroyos"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Pancrudo"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Parras de Castellote, Las"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Peñarroya de Tastavins"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Peracense"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Peralejos"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Perales del Alfambra"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Pitarque"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Plou"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Pobo, El"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Portellada, La"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Pozondón"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Pozuel del Campo"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Puebla de Híjar, La"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Puebla de Valverde, La"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Puertomingalvo"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Ráfales"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Rillo"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Riodeva"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Ródenas"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Royuela"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Rubiales"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Rubielos de la Cérida"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Rubielos de Mora"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Salcedillo"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Saldón"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Samper de Calanda"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "San Agustín"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "San Martín del Río"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Santa Cruz de Nogueras"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Santa Eulalia"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Sarrión"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Segura de los Baños"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Seno"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Singra"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Terriente"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Teruel"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Toril y Masegoso"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Tormón"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Tornos"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Torralba de los Sisones"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Torre de Arcas"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Torre de las Arcas"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Torre del Compte"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Torre los Negros"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Torrecilla de Alcañiz"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Torrecilla del Rebollar"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Torrelacárcel"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Torremocha de Jiloca"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Torres de Albarracín"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Torrevelilla"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Torrijas"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Torrijo del Campo"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Tramacastiel"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Tramacastilla"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Tronchón"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Urrea de Gaén"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Utrillas"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Valacloche"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Valbona"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Valdealgorfa"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Valdecuenca"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Valdelinares"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Valdeltormo"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Valderrobres"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Valjunquera"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Vallecillo, El"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Veguillas de la Sierra"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Villafranca del Campo"
  },
  {
    "parent_code": "44",
    "code": "4",
    "label": "Villahermosa del Campo"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Villanueva del Rebollar de la Sierra"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Villar del Cobo"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Villar del Salz"
  },
  {
    "parent_code": "44",
    "code": "0",
    "label": "Villarluengo"
  },
  {
    "parent_code": "44",
    "code": "7",
    "label": "Villarquemado"
  },
  {
    "parent_code": "44",
    "code": "2",
    "label": "Villarroya de los Pinares"
  },
  {
    "parent_code": "44",
    "code": "8",
    "label": "Villastar"
  },
  {
    "parent_code": "44",
    "code": "3",
    "label": "Villel"
  },
  {
    "parent_code": "44",
    "code": "6",
    "label": "Vinaceite"
  },
  {
    "parent_code": "44",
    "code": "9",
    "label": "Visiedo"
  },
  {
    "parent_code": "44",
    "code": "5",
    "label": "Vivel del Río Martín"
  },
  {
    "parent_code": "44",
    "code": "1",
    "label": "Zoma, La"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Ajofrín"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Alameda de la Sagra"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Albarreal de Tajo"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Alcabón"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Alcañizo"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Alcaudete de la Jara"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Alcolea de Tajo"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Aldea en Cabo"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Aldeanueva de Barbarroya"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Aldeanueva de San Bartolomé"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Almendral de la Cañada"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Almonacid de Toledo"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Almorox"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Añover de Tajo"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Arcicóllar"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Argés"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Azután"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Barcience"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Bargas"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Belvís de la Jara"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Borox"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Buenaventura"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Burguillos de Toledo"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Burujón"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Cabañas de la Sagra"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Cabañas de Yepes"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Cabezamesada"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Calera y Chozas"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Caleruela"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Calzada de Oropesa"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Camarena"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Camarenilla"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Campillo de la Jara, El"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Camuñas"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Cardiel de los Montes"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Carmena"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Carpio de Tajo, El"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Carranque"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Carriches"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Casar de Escalona, El"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Casarrubios del Monte"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Casasbuenas"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Castillo de Bayuela"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Cazalegas"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Cebolla"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Cedillo del Condado"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Cerralbos, Los"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Cervera de los Montes"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Chozas de Canales"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Chueca"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Ciruelos"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Cobeja"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Cobisa"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Consuegra"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Corral de Almaguer"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Cuerva"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Domingo Pérez"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Dosbarrios"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Erustes"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Escalona"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Escalonilla"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Espinoso del Rey"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Esquivias"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Estrella, La"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Fuensalida"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Gálvez"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Garciotum"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Gerindote"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Guadamur"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Guardia, La"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Herencias, Las"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Herreruela de Oropesa"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Hinojosa de San Vicente"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Hontanar"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Hormigos"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Huecas"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Huerta de Valdecarábanos"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Iglesuela del Tiétar, La"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Illán de Vacas"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Illescas"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Lagartera"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Layos"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Lillo"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Lominchar"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Lucillos"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Madridejos"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Magán"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Malpica de Tajo"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Manzaneque"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Maqueda"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Marjaliza"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Marrupe"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Mascaraque"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Mata, La"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Mazarambroz"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Mejorada"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Menasalbas"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Méntrida"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Mesegar de Tajo"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Miguel Esteban"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Mocejón"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Mohedas de la Jara"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Montearagón"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Montesclaros"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Mora"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Nambroca"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Nava de Ricomalillo, La"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Navahermosa"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Navalcán"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Navalmoralejo"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Navalmorales, Los"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Navalucillos, Los"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Navamorcuende"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Noblejas"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Noez"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Nombela"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Novés"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Numancia de la Sagra"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Nuño Gómez"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Ocaña"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Olías del Rey"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Ontígola"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Orgaz"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Oropesa"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Otero"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Palomeque"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Pantoja"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Paredes de Escalona"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Parrillas"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Pelahustán"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Pepino"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Polán"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Portillo de Toledo"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Puebla de Almoradiel, La"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Puebla de Montalbán, La"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Pueblanueva, La"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Puente del Arzobispo, El"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Puerto de San Vicente"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Pulgar"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Quero"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Quintanar de la Orden"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Quismondo"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Real de San Vicente, El"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Recas"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Retamoso de la Jara"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Rielves"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Robledo del Mazo"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Romeral, El"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "San Bartolomé de las Abiertas"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "San Martín de Montalbán"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "San Martín de Pusa"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "San Pablo de los Montes"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "San Román de los Montes"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Santa Ana de Pusa"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Santa Cruz de la Zarza"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Santa Cruz del Retamar"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Santa Olalla"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Santo Domingo-Caudilla"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Sartajada"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Segurilla"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Seseña"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Sevilleja de la Jara"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Sonseca"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Sotillo de las Palomas"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Talavera de la Reina"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Tembleque"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Toboso, El"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Toledo"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Torralba de Oropesa"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Torre de Esteban Hambrán, La"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Torrecilla de la Jara"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Torrico"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Torrijos"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Totanés"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Turleque"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Ugena"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Urda"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Valdeverdeja"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Valmojado"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Velada"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Ventas con Peña Aguilera, Las"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Ventas de Retamosa, Las"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Ventas de San Julián, Las"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Villa de Don Fadrique, La"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Villacañas"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Villafranca de los Caballeros"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Villaluenga de la Sagra"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Villamiel de Toledo"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Villaminaya"
  },
  {
    "parent_code": "45",
    "code": "5",
    "label": "Villamuelas"
  },
  {
    "parent_code": "45",
    "code": "0",
    "label": "Villanueva de Alcardete"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Villanueva de Bogas"
  },
  {
    "parent_code": "45",
    "code": "1",
    "label": "Villarejo de Montalbán"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Villarrubia de Santiago"
  },
  {
    "parent_code": "45",
    "code": "7",
    "label": "Villaseca de la Sagra"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Villasequilla"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Villatobas"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Viso de San Juan, El"
  },
  {
    "parent_code": "45",
    "code": "6",
    "label": "Yébenes, Los"
  },
  {
    "parent_code": "45",
    "code": "3",
    "label": "Yeles"
  },
  {
    "parent_code": "45",
    "code": "8",
    "label": "Yepes"
  },
  {
    "parent_code": "45",
    "code": "4",
    "label": "Yuncler"
  },
  {
    "parent_code": "45",
    "code": "9",
    "label": "Yunclillos"
  },
  {
    "parent_code": "45",
    "code": "2",
    "label": "Yuncos"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Ademuz"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Ador"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Agullent"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Aielo de Malferit"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Aielo de Rugat"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Alaquàs"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Albaida"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Albal"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Albalat de la Ribera"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Albalat dels Sorells"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Albalat dels Tarongers"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Alberic"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Alborache"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Alboraia/Alboraya"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Albuixech"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Alcàntera de Xúquer"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Alcàsser"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Alcublas"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Alcúdia de Crespins, l'"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Alcúdia, l'"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Aldaia"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Alfafar"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Alfara de la Baronia"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Alfara del Patriarca"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Alfarp"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Alfarrasí"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Alfauir"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Algar de Palancia"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Algemesí"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Algímia d'Alfara"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Alginet"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Almàssera"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Almiserà"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Almoines"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Almussafes"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Alpuente"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Alqueria de la Comtessa, l'"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Alzira"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Andilla"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Anna"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Antella"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Aras de los Olmos"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Atzeneta d'Albaida"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Ayora"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Barx"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Barxeta"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Bèlgida"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Bellreguard"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Bellús"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Benagéber"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Benaguasil"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Benavites"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Beneixida"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Benetússer"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Beniarjó"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Beniatjar"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Benicolet"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Benicull de Xúquer"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Benifaió"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Benifairó de la Valldigna"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Benifairó de les Valls"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Beniflá"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Benigànim"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Benimodo"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Benimuslem"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Beniparrell"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Benirredrà"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Benissanó"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Benissoda"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Benissuera"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Bétera"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Bicorp"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Bocairent"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Bolbaite"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Bonrepòs i Mirambell"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Bufali"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Bugarra"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Buñol"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Burjassot"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Calles"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Camporrobles"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Canals"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Canet d'En Berenguer"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Carcaixent"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Càrcer"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Carlet"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Carrícola"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Casas Altas"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Casas Bajas"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Casinos"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Castelló de Rugat"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Castellonet de la Conquesta"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Castielfabib"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Catadau"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Catarroja"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Caudete de las Fuentes"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Cerdà"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Chella"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Chelva"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Chera"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Cheste"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Chiva"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Chulilla"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Cofrentes"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Corbera"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Cortes de Pallás"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Cotes"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Cullera"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Daimús"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Domeño"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Dos Aguas"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Eliana, l'"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Emperador"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Enguera"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Ènova, l'"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Estivella"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Estubeny"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Faura"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Favara"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Foios"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Font de la Figuera, la"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Font d'En Carròs, la"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Fontanars dels Alforins"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Fortaleny"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Fuenterrobles"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Gandia"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Gátova"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Gavarda"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Genovés, el"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Gestalgar"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Gilet"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Godella"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Godelleta"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Granja de la Costera, la"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Guadasséquies"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Guadassuar"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Guardamar de la Safor"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Higueruelas"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Jalance"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Jarafuel"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Llanera de Ranes"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Llaurí"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Llíria"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Llocnou de la Corona"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Llocnou de Sant Jeroni"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Llocnou d'En Fenollet"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Llombai"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Llosa de Ranes, la"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Llutxent"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Loriguilla"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Losa del Obispo"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Macastre"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Manises"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Manuel"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Marines"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Massalavés"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Massalfassar"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Massamagrell"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Massanassa"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Meliana"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Millares"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Miramar"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Mislata"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Mogente/Moixent"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Moncada"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Montaverner"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Montesa"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Montitxelvo/Montichelvo"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Montroi/Montroy"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Montserrat"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Museros"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Nàquera/Náquera"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Navarrés"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Novelé/Novetlè"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Oliva"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Olleria, l'"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Olocau"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Ontinyent"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Otos"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Paiporta"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Palma de Gandía"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Palmera"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Palomar, el"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Paterna"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Pedralba"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Petrés"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Picanya"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Picassent"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Piles"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Pinet"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Pobla de Farnals, la"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Pobla de Vallbona, la"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Pobla del Duc, la"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Pobla Llarga, la"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Polinyà de Xúquer"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Potries"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Puçol"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Puebla de San Miguel"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Puig de Santa Maria, el"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Quart de les Valls"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Quart de Poblet"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Quartell"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Quatretonda"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Quesa"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Rafelbunyol"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Rafelcofer"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Rafelguaraf"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Ráfol de Salem"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Real"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Real de Gandia, el"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Requena"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Riba-roja de Túria"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Riola"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Rocafort"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Rotglà i Corberà"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Rótova"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Rugat"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Sagunto/Sagunt"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Salem"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "San Antonio de Benagéber"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Sant Joanet"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Sedaví"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Segart"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Sellent"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Sempere"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Senyera"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Serra"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Siete Aguas"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Silla"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Simat de la Valldigna"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Sinarcas"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Sollana"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Sot de Chera"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Sueca"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Sumacàrcer"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Tavernes Blanques"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Tavernes de la Valldigna"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Teresa de Cofrentes"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Terrateig"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Titaguas"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Torrebaja"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Torrella"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Torrent"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Torres Torres"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Tous"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Tuéjar"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Turís"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Utiel"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "València"
  },
  {
    "parent_code": "46",
    "code": "5",
    "label": "Vallada"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Vallanca"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Vallés"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Venta del Moro"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Vilallonga/Villalonga"
  },
  {
    "parent_code": "46",
    "code": "7",
    "label": "Vilamarxant"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Villanueva de Castellón"
  },
  {
    "parent_code": "46",
    "code": "9",
    "label": "Villar del Arzobispo"
  },
  {
    "parent_code": "46",
    "code": "2",
    "label": "Villargordo del Cabriel"
  },
  {
    "parent_code": "46",
    "code": "6",
    "label": "Vinalesa"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Xàtiva"
  },
  {
    "parent_code": "46",
    "code": "0",
    "label": "Xeraco"
  },
  {
    "parent_code": "46",
    "code": "1",
    "label": "Xeresa"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Xirivella"
  },
  {
    "parent_code": "46",
    "code": "3",
    "label": "Yátova"
  },
  {
    "parent_code": "46",
    "code": "8",
    "label": "Yesa, La"
  },
  {
    "parent_code": "46",
    "code": "4",
    "label": "Zarra"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Adalia"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Aguasal"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Aguilar de Campos"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Alaejos"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Alcazarén"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Aldea de San Miguel"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Aldeamayor de San Martín"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Almenara de Adaja"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Amusquillo"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Arroyo de la Encomienda"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Ataquines"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Bahabón"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Barcial de la Loma"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Barruelo del Valle"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Becilla de Valderaduey"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Benafarces"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Bercero"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Berceruelo"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Berrueces"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Bobadilla del Campo"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Bocigas"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Bocos de Duero"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Boecillo"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Bolaños de Campos"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Brahojos de Medina"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Bustillo de Chaves"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Cabezón de Pisuerga"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Cabezón de Valderaduey"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Cabreros del Monte"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Campaspero"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Campillo, El"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Camporredondo"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Canalejas de Peñafiel"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Canillas de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Carpio"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Casasola de Arión"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Castrejón de Trabancos"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Castrillo de Duero"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Castrillo-Tejeriego"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Castrobol"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Castrodeza"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Castromembibre"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Castromonte"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Castronuevo de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Castronuño"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Castroponce"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Castroverde de Cerrato"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Ceinos de Campos"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Cervillego de la Cruz"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Cigales"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Ciguñuela"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Cistérniga"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Cogeces de Íscar"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Cogeces del Monte"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Corcos"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Corrales de Duero"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Cubillas de Santa Marta"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Cuenca de Campos"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Curiel de Duero"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Encinas de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Esguevillas de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Fombellida"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Fompedraza"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Fontihoyuelo"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Fresno el Viejo"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Fuensaldaña"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Fuente el Sol"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Fuente-Olmedo"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Gallegos de Hornija"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Gatón de Campos"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Geria"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Herrín de Campos"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Hornillos de Eresma"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Íscar"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Laguna de Duero"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Langayo"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Llano de Olmedo"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Lomoviejo"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Manzanillo"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Marzales"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Matapozuelos"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Matilla de los Caños"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Mayorga"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Medina de Rioseco"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Medina del Campo"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Megeces"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Melgar de Abajo"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Melgar de Arriba"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Mojados"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Monasterio de Vega"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Montealegre de Campos"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Montemayor de Pililla"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Moral de la Reina"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Moraleja de las Panaderas"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Morales de Campos"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Mota del Marqués"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Mucientes"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Mudarra, La"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Muriel"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Nava del Rey"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Nueva Villa de las Torres"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Olivares de Duero"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Olmedo"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Olmos de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Olmos de Peñafiel"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Palazuelo de Vedija"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Parrilla, La"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Pedraja de Portillo, La"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Pedrajas de San Esteban"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Pedrosa del Rey"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Peñafiel"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Peñaflor de Hornija"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Pesquera de Duero"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Piña de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Piñel de Abajo"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Piñel de Arriba"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Pollos"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Portillo"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Pozal de Gallinas"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Pozaldez"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Pozuelo de la Orden"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Puras"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Quintanilla de Arriba"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Quintanilla de Onésimo"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Quintanilla de Trigueros"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Quintanilla del Molar"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Rábano"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Ramiro"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Renedo de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Roales de Campos"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Robladillo"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Roturas"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Rubí de Bracamonte"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Rueda"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Saelices de Mayorga"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Salvador de Zapardiel"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "San Cebrián de Mazote"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "San Llorente"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "San Martín de Valvení"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "San Miguel del Arroyo"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "San Miguel del Pino"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "San Pablo de la Moraleja"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "San Pedro de Latarce"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "San Pelayo"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "San Román de Hornija"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "San Salvador"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "San Vicente del Palacio"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Santa Eufemia del Arroyo"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Santervás de Campos"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Santibáñez de Valcorba"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Santovenia de Pisuerga"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Sardón de Duero"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Seca, La"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Serrada"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Siete Iglesias de Trabancos"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Simancas"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Tamariz de Campos"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Tiedra"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Tordehumos"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Tordesillas"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Torre de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Torre de Peñafiel"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Torrecilla de la Abadesa"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Torrecilla de la Orden"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Torrecilla de la Torre"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Torrelobatón"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Torrescárcela"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Traspinedo"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Trigueros del Valle"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Tudela de Duero"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Unión de Campos, La"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Urones de Castroponce"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Urueña"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Valbuena de Duero"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Valdearcos de la Vega"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Valdenebro de los Valles"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Valdestillas"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Valdunquillo"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Valladolid"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Valoria la Buena"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Valverde de Campos"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Vega de Ruiponce"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Vega de Valdetronco"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Velascálvaro"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Velilla"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Velliza"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Ventosa de la Cuesta"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Viana de Cega"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Villabáñez"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Villabaruz de Campos"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Villabrágima"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Villacarralón"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Villacid de Campos"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Villaco"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Villafrades de Campos"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Villafranca de Duero"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Villafrechós"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Villafuerte"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Villagarcía de Campos"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Villagómez la Nueva"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Villalán de Campos"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Villalar de los Comuneros"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Villalba de la Loma"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Villalba de los Alcores"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Villalbarba"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Villalón de Campos"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Villamuriel de Campos"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Villán de Tordesillas"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Villanubla"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Villanueva de Duero"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Villanueva de la Condesa"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Villanueva de los Caballeros"
  },
  {
    "parent_code": "47",
    "code": "8",
    "label": "Villanueva de los Infantes"
  },
  {
    "parent_code": "47",
    "code": "3",
    "label": "Villanueva de San Mancio"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Villardefrades"
  },
  {
    "parent_code": "47",
    "code": "4",
    "label": "Villarmentero de Esgueva"
  },
  {
    "parent_code": "47",
    "code": "7",
    "label": "Villasexmir"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Villavaquerín"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Villavellid"
  },
  {
    "parent_code": "47",
    "code": "2",
    "label": "Villaverde de Medina"
  },
  {
    "parent_code": "47",
    "code": "5",
    "label": "Villavicencio de los Caballeros"
  },
  {
    "parent_code": "47",
    "code": "0",
    "label": "Viloria"
  },
  {
    "parent_code": "47",
    "code": "9",
    "label": "Wamba"
  },
  {
    "parent_code": "47",
    "code": "6",
    "label": "Zaratán"
  },
  {
    "parent_code": "47",
    "code": "1",
    "label": "Zarza, La"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Abadiño"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Abanto y Ciérvana-Abanto Zierbena"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Ajangiz"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Alonsotegi"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Amorebieta-Etxano"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Amoroto"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Arakaldo"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Arantzazu"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Areatza"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Arrankudiaga"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Arratzu"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Arrieta"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Arrigorriaga"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Artea"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Artzentales"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Atxondo"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Aulesti"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Bakio"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Balmaseda"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Barakaldo"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Barrika"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Basauri"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Bedia"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Berango"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Bermeo"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Berriatua"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Berriz"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Bilbao"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Busturia"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Derio"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Dima"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Durango"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Ea"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Elantxobe"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Elorrio"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Erandio"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Ereño"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Ermua"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Errigoiti"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Etxebarri"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Etxebarria"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Forua"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Fruiz"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Galdakao"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Galdames"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Gamiz-Fika"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Garai"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Gatika"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Gautegiz Arteaga"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Gernika-Lumo"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Getxo"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Gizaburuaga"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Gordexola"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Gorliz"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Güeñes"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Ibarrangelu"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Igorre"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Ispaster"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Iurreta"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Izurtza"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Karrantza Harana/Valle de Carranza"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Kortezubi"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Lanestosa"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Larrabetzu"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Laukiz"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Leioa"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Lekeitio"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Lemoa"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Lemoiz"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Lezama"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Loiu"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Mallabia"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Mañaria"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Markina-Xemein"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Maruri-Jatabe"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Mendata"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Mendexa"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Meñaka"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Morga"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Mundaka"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Mungia"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Munitibar-Arbatzegi Gerrikaitz"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Murueta"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Muskiz"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Muxika"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Nabarniz"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Ondarroa"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Orozko"
  },
  {
    "parent_code": "48",
    "code": "4",
    "label": "Ortuella"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Otxandio"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Plentzia"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Portugalete"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Santurtzi"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Sestao"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Sondika"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Sopela"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Sopuerta"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Sukarrieta"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Trucios-Turtzioz"
  },
  {
    "parent_code": "48",
    "code": "7",
    "label": "Ubide"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Ugao-Miraballes"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Urduliz"
  },
  {
    "parent_code": "48",
    "code": "1",
    "label": "Urduña/Orduña"
  },
  {
    "parent_code": "48",
    "code": "6",
    "label": "Valle de Trápaga-Trapagaran"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Zaldibar"
  },
  {
    "parent_code": "48",
    "code": "3",
    "label": "Zalla"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Zamudio"
  },
  {
    "parent_code": "48",
    "code": "9",
    "label": "Zaratamo"
  },
  {
    "parent_code": "48",
    "code": "2",
    "label": "Zeanuri"
  },
  {
    "parent_code": "48",
    "code": "5",
    "label": "Zeberio"
  },
  {
    "parent_code": "48",
    "code": "0",
    "label": "Zierbena"
  },
  {
    "parent_code": "48",
    "code": "8",
    "label": "Ziortza-Bolibar"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Abezames"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Alcañices"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Alcubilla de Nogales"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Alfaraz de Sayago"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Algodre"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Almaraz de Duero"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Almeida de Sayago"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Andavías"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Arcenillas"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Arcos de la Polvorosa"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Argañín"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Argujillo"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Arquillinos"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Arrabalde"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Aspariegos"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Asturianos"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Ayoó de Vidriales"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Barcial del Barco"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Belver de los Montes"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Benavente"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Benegiles"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Bermillo de Sayago"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Bóveda de Toro, La"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Bretó"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Bretocino"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Brime de Sog"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Brime de Urz"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Burganes de Valverde"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Bustillo del Oro"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Cabañas de Sayago"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Calzadilla de Tera"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Camarzana de Tera"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Cañizal"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Cañizo"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Carbajales de Alba"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Carbellino"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Casaseca de Campeán"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Casaseca de las Chanas"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Castrillo de la Guareña"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Castrogonzalo"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Castronuevo"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Castroverde de Campos"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Cazurra"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Cerecinos de Campos"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Cerecinos del Carrizal"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Cernadilla"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Cobreros"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Coomonte"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Coreses"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Corrales del Vino"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Cotanes del Monte"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Cubillos"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Cubo de Benavente"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Cubo de Tierra del Vino, El"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Cuelgamures"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Entrala"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Espadañedo"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Faramontanos de Tábara"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Fariza"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Fermoselle"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Ferreras de Abajo"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Ferreras de Arriba"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Ferreruela"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Figueruela de Arriba"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Fonfría"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Fresno de la Polvorosa"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Fresno de la Ribera"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Fresno de Sayago"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Friera de Valverde"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Fuente Encalada"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Fuentelapeña"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Fuentes de Ropel"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Fuentesaúco"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Fuentesecas"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Fuentespreadas"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Galende"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Gallegos del Pan"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Gallegos del Río"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Gamones"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Gema"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Granja de Moreruela"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Granucillo"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Guarrate"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Hermisende"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Hiniesta, La"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Jambrina"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Justel"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Losacino"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Losacio"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Lubián"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Luelmo"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Maderal, El"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Madridanos"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Mahide"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Maire de Castroponce"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Malva"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Manganeses de la Lampreana"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Manganeses de la Polvorosa"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Manzanal de Arriba"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Manzanal de los Infantes"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Manzanal del Barco"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Matilla de Arzón"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Matilla la Seca"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Mayalde"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Melgar de Tera"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Micereces de Tera"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Milles de la Polvorosa"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Molacillos"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Molezuelas de la Carballeda"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Mombuey"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Monfarracinos"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Montamarta"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Moral de Sayago"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Moraleja de Sayago"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Moraleja del Vino"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Morales de Rey"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Morales de Toro"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Morales de Valverde"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Morales del Vino"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Moralina"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Moreruela de los Infanzones"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Moreruela de Tábara"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Muelas de los Caballeros"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Muelas del Pan"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Muga de Sayago"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Navianos de Valverde"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Olmillos de Castro"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Otero de Bodas"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Pajares de la Lampreana"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Palacios de Sanabria"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Palacios del Pan"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Pedralba de la Pradería"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Pego, El"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Peleagonzalo"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Peleas de Abajo"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Peñausende"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Peque"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Perdigón, El"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Pereruela"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Perilla de Castro"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Pías"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Piedrahita de Castro"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Pinilla de Toro"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Pino del Oro"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Piñero, El"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Pobladura de Valderaduey"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Pobladura del Valle"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Porto"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Pozoantiguo"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Pozuelo de Tábara"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Prado"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Puebla de Sanabria"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Pueblica de Valverde"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Quintanilla de Urz"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Quintanilla del Monte"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Quintanilla del Olmo"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Quiruelas de Vidriales"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Rabanales"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Rábano de Aliste"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Requejo"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Revellinos"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Riofrío de Aliste"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Rionegro del Puente"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Roales"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Robleda-Cervantes"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Roelos de Sayago"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Rosinos de la Requejada"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Salce"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Samir de los Caños"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "San Agustín del Pozo"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "San Cebrián de Castro"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "San Cristóbal de Entreviñas"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "San Esteban del Molar"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "San Justo"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "San Martín de Valderaduey"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "San Miguel de la Ribera"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "San Miguel del Valle"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "San Pedro de Ceque"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "San Pedro de la Nave-Almendra"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "San Vicente de la Cabeza"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "San Vitero"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Santa Clara de Avedillo"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Santa Colomba de las Monjas"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Santa Cristina de la Polvorosa"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Santa Croya de Tera"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Santa Eufemia del Barco"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Santa María de la Vega"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Santa María de Valverde"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Santibáñez de Tera"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Santibáñez de Vidriales"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Santovenia"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Sanzoles"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Tábara"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Tapioles"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Toro"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Torre del Valle, La"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Torregamones"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Torres del Carrizal"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Trabazos"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Trefacio"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Uña de Quintana"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Vadillo de la Guareña"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Valcabado"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Valdefinjas"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Valdescorriel"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Vallesa de la Guareña"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Vega de Tera"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Vega de Villalobos"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Vegalatrave"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Venialbo"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Vezdemarbán"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Vidayanes"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Videmala"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Villabrázaro"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Villabuena del Puente"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Villadepera"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Villaescusa"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Villafáfila"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Villaferrueña"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Villageriz"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Villalazán"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Villalba de la Lampreana"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Villalcampo"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Villalobos"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Villalonso"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Villalpando"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Villalube"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Villamayor de Campos"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Villamor de los Escuderos"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Villanázar"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Villanueva de Azoague"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Villanueva de Campeán"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Villanueva de las Peras"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Villanueva del Campo"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Villar de Fallaves"
  },
  {
    "parent_code": "49",
    "code": "4",
    "label": "Villar del Buey"
  },
  {
    "parent_code": "49",
    "code": "8",
    "label": "Villaralbo"
  },
  {
    "parent_code": "49",
    "code": "3",
    "label": "Villardeciervos"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Villardiegua de la Ribera"
  },
  {
    "parent_code": "49",
    "code": "0",
    "label": "Villárdiga"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Villardondiego"
  },
  {
    "parent_code": "49",
    "code": "2",
    "label": "Villarrín de Campos"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Villaseco del Pan"
  },
  {
    "parent_code": "49",
    "code": "9",
    "label": "Villavendimio"
  },
  {
    "parent_code": "49",
    "code": "1",
    "label": "Villaveza de Valverde"
  },
  {
    "parent_code": "49",
    "code": "6",
    "label": "Villaveza del Agua"
  },
  {
    "parent_code": "49",
    "code": "7",
    "label": "Viñas"
  },
  {
    "parent_code": "49",
    "code": "5",
    "label": "Zamora"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Abanto"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Acered"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Agón"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Aguarón"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Aguilón"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Ainzón"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Aladrén"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Alagón"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Alarba"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Alberite de San Juan"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Albeta"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Alborge"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Alcalá de Ebro"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Alcalá de Moncayo"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Alconchel de Ariza"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Aldehuela de Liestos"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Alfajarín"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Alfamén"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Alforque"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Alhama de Aragón"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Almochuel"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Almolda, La"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Almonacid de la Cuba"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Almonacid de la Sierra"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Almunia de Doña Godina, La"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Alpartir"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Ambel"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Anento"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Aniñón"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Añón de Moncayo"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Aranda de Moncayo"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Arándiga"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Ardisa"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Ariza"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Artieda"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Asín"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Atea"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Ateca"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Azuara"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Badules"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Bagüés"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Balconchán"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Bárboles"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Bardallur"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Belchite"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Belmonte de Gracián"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Berdejo"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Berrueco"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Biel"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Bijuesca"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Biota"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Bisimbre"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Boquiñeni"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Bordalba"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Borja"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Botorrita"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Brea de Aragón"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Bubierca"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Bujaraloz"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Bulbuente"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Bureta"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Burgo de Ebro, El"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Buste, El"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Cabañas de Ebro"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Cabolafuente"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Cadrete"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Calatayud"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Calatorao"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Calcena"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Calmarza"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Campillo de Aragón"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Carenas"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Cariñena"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Caspe"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Castejón de Alarba"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Castejón de las Armas"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Castejón de Valdejasa"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Castiliscar"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Cervera de la Cañada"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Cerveruela"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Cetina"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Chiprana"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Chodes"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Cimballa"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Cinco Olivas"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Clarés de Ribota"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Codo"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Codos"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Contamina"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Cosuenda"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Cuarte de Huerva"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Cubel"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Cuerlas, Las"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Daroca"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Ejea de los Caballeros"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Embid de Ariza"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Encinacorba"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Épila"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Erla"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Escatrón"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Fabara"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Farlete"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Fayón"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Fayos, Los"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Figueruelas"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Fombuena"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Frago, El"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Frasno, El"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Fréscano"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Fuendejalón"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Fuendetodos"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Fuentes de Ebro"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Fuentes de Jiloca"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Gallocanta"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Gallur"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Gelsa"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Godojos"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Gotor"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Grisel"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Grisén"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Herrera de los Navarros"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Ibdes"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Illueca"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Isuerre"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Jaraba"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Jarque de Moncayo"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Jaulín"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Joyosa, La"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Lagata"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Langa del Castillo"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Layana"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Lécera"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Lechón"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Leciñena"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Letux"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Litago"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Lituénigo"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Lobera de Onsella"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Longares"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Longás"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Lucena de Jalón"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Luceni"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Luesia"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Luesma"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Lumpiaque"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Luna"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Maella"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Magallón"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Mainar"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Malanquilla"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Maleján"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Mallén"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Malón"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Maluenda"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Manchones"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Mara"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "María de Huerva"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Marracos"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Mediana de Aragón"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Mequinenza"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Mesones de Isuela"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Mezalocha"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Mianos"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Miedes de Aragón"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Monegrillo"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Moneva"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Monreal de Ariza"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Monterde"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Montón"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Morata de Jalón"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Morata de Jiloca"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Morés"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Moros"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Moyuela"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Mozota"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Muel"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Muela, La"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Munébrega"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Murero"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Murillo de Gállego"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Navardún"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Nigüella"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Nombrevilla"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Nonaspe"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Novallas"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Novillas"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Nuévalos"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Nuez de Ebro"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Olvés"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Orcajo"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Orera"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Orés"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Oseja"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Osera de Ebro"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Paniza"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Paracuellos de Jiloca"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Paracuellos de la Ribera"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Pastriz"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Pedrola"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Pedrosas, Las"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Perdiguera"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Piedratajada"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Pina de Ebro"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Pinseque"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Pintanos, Los"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Plasencia de Jalón"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Pleitas"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Plenas"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Pomer"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Pozuel de Ariza"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Pozuelo de Aragón"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Pradilla de Ebro"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Puebla de Albortón"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Puebla de Alfindén, La"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Puendeluna"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Purujosa"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Quinto"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Remolinos"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Retascón"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Ricla"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Romanos"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Rueda de Jalón"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Ruesca"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Sabiñán"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Sádaba"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Salillas de Jalón"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Salvatierra de Esca"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Samper del Salz"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "San Martín de la Virgen de Moncayo"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "San Mateo de Gállego"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Santa Cruz de Grío"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Santa Cruz de Moncayo"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Santa Eulalia de Gállego"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Santed"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Sástago"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Sediles"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Sestrica"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Sierra de Luna"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Sigüés"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Sisamón"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Sobradiel"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Sos del Rey Católico"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Tabuenca"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Talamantes"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Tarazona"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Tauste"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Terrer"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Tierga"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Tobed"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Torralba de los Frailes"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Torralba de Ribota"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Torralbilla"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Torrehermosa"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Torrelapaja"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Torrellas"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Torres de Berrellén"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Torrijo de la Cañada"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Tosos"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Trasmoz"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Trasobares"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Uncastillo"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Undués de Lerda"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Urrea de Jalón"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Urriés"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Used"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Utebo"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Val de San Martín"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Valdehorna"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Valmadrid"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Valpalmas"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Valtorres"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Velilla de Ebro"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Velilla de Jiloca"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Vera de Moncayo"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Vierlas"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Villadoz"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Villafeliche"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Villafranca de Ebro"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Villalba de Perejil"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Villalengua"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Villamayor de Gállego"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Villanueva de Gállego"
  },
  {
    "parent_code": "50",
    "code": "8",
    "label": "Villanueva de Huerva"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Villanueva de Jiloca"
  },
  {
    "parent_code": "50",
    "code": "5",
    "label": "Villar de los Navarros"
  },
  {
    "parent_code": "50",
    "code": "0",
    "label": "Villarreal de Huerva"
  },
  {
    "parent_code": "50",
    "code": "6",
    "label": "Villarroya de la Sierra"
  },
  {
    "parent_code": "50",
    "code": "1",
    "label": "Villarroya del Campo"
  },
  {
    "parent_code": "50",
    "code": "2",
    "label": "Vilueña, La"
  },
  {
    "parent_code": "50",
    "code": "4",
    "label": "Vistabella"
  },
  {
    "parent_code": "50",
    "code": "7",
    "label": "Zaida, La"
  },
  {
    "parent_code": "50",
    "code": "3",
    "label": "Zaragoza"
  },
  {
    "parent_code": "50",
    "code": "9",
    "label": "Zuera"
  },
  {
    "parent_code": "51",
    "code": "3",
    "label": "Ceuta"
  },
  {
    "parent_code": "52",
    "code": "8",
    "label": "Melilla"
  }
]