import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openModal, closeModal } from '../../../actions/popup';
import HeroImg from '../../../assets/madrid_hero.png';
import LogoMe from '../../../assets/logos/me_azul2.png';
import './RequestReceived.scss';

export default function RequestRecieved() {
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const history = useHistory()

    const [requestSuccess, setRequestSuccess] = useState(false)

    const openHelpModal = () => {
        const optionValues = {
            id: 'helpModal',
            dataTestId: 'helpModal',
            cssClass: 'modal-header__transparent',
            title: 'Ayuda'
        };
    
        const options = {
            ...optionValues,
            body: (
                <p className='modal-text'>{t('HelpGlobalMessage')}</p>
            )
        };
    
        dispatch(openModal(options));
    }


    return (
        <div className='request'>
            <div className='request-container'>
                <div className='request-header'>
                    <h1 className='logome-wrapper'>
                        <img className='logome' src={LogoMe} alt='logo-madrid-excelente' />
                    </h1>
                    <div className='hero-wrapper'>
                        <img className='hero-img' src={HeroImg} aria-describedby="ciudad-madrid" alt='ciudad-madrid' />
                        <div className='sr-only' id='ciudad-madrid'>{t('ImgMadridSROnly')}</div>
                    </div>
                    <div className='help-wrapper' >
                        <div className='return-wrapper'>
                            <span className='material-icons' aria-hidden={true}>arrow_back</span> 
                            <a className='return-link' href='https://madridexcelente.com/'>
                                Volver a la web de Madrid Excelente
                            </a>
                        </div>
                        <span onClick={() => openHelpModal()} className='material-icons icon' role='button'>help</span>
                        <span className='help'>{t('Help')}</span>
                    </div>
                </div>

                <div className='request-content container'>
                    <div className='row  request-success'>
                        <div className='col-lg-12'>
                            <h2 className='heading-page'>{t('RequestRecieved')}</h2>
                            <p className='info-text-page mb-4 text-center'>Gracias por enviarnos tu solicitud de obtención del sello Madrid Excelente. Nuestro equipo la revisará y nos pondremos en contacto contigo lo antes posible.</p>
                            <p className='info-text-page text-center mb-4'>Mientras tanto, quizás podamos <a href='https://madridexcelente.com/preguntas-frecuentes/' className='link'>resolver tus dudas</a> o presentarte las <a href='https://madridexcelente.com/actividades/' className='link'>actividades</a> que realizamos con nuestras empresas certificadas.</p>
                            <div className='logo-send-success'>
                                <span className='material-icons-outlined' aria-hidden={true}>mark_email_read</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
