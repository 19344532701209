import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogoAside from '../../assets/logos/madrid_rojo.png';
import { GetMenuSessionStorage } from '@backoffice/common/utils/storageManager';
import './MenuLeft.scss';
import { GetSessionStorage } from '../../common/utils/storageManager';
import checkIsPrincipalAnyCompany from '../../common/utils/checkIsPrincipal';

function MenuLeft() {
	const history = useHistory();
	const { t } = useTranslation();
	const [ menu, setMenu ] = useState([]);
	const [ hideMenu, sethideMenu ] = useState(true);
	const [ userLoged, setUserLoged ] = useState(true);

	useEffect(() => {
		setMenu(GetMenuSessionStorage());
		setUserLoged(GetSessionStorage('PROFILE_'));
	}, []);	

	const renderMenuElements = () => {
		let htmlMenuElements = [];
		if (menu && menu.length > 0) {
			menu.forEach((element, index) => {
				let include = false;
				if (userLoged.userRoles[0].name !== 'Company'
					|| userLoged.userRoles[0].name === 'Company' && checkIsPrincipalAnyCompany(userLoged) 
					|| userLoged.userRoles[0].name === 'Company' && !checkIsPrincipalAnyCompany(userLoged) && element.name !== 'Users'
				) {
					if(userLoged.userRoles[0].name === 'Company' && userLoged.userData.companyUserData?.isPrincipalInFranchise && element.name === 'Users') {
						include = false;
					} else {
						include = true;
					}
				}

				if(include) {
					if (element.menuType === 'General') {
						htmlMenuElements.push(
							<li
								key={index}
								className={
									history.location.pathname === element.link ? (
										'menu-left-list__item active'
									) : (
										'menu-left-list__item noactive'
									)
								}
							>
								<Link to={element.link.toLowerCase()}>
									<div className="row anchor-item">
										{element.icon ? (
											//  <span className="icon">
											//  	<ion-icon name={element.icon} />{' '}
											//  </span>
											<span className="material-icons-outlined" aria-hidden={true}>
												{element.icon}
											</span> 
										) : (
											''
										)}
										<span>{t(element.name)}</span>
									</div>
								</Link>
							</li>
						);
					}
				}
			});
		}
		return htmlMenuElements;
	};

	return (
		<aside className="menu-left aside">
			<div className='logo-container'>
				<h1 className='logo-wrapper'>
					<img src={LogoAside} alt='Logo Madrid Excelente' />
				</h1>
			</div>
			<ul className="menu-left-list">
				<li key={'mini-menu'} className={'mini-menu'} onClick={() => sethideMenu(!hideMenu)}>
					<span className="icon">
						<ion-icon name={'menu-outline'} />
					</span>
					<span>{t('Menu')}</span>
					<span className="icon-float-left">
						
						{
							hideMenu ? (
								<span className="material-icons" aria-hidden={true}>
								expand_more
								</span>
							) : (
								<span className="material-icons" aria-hidden={true}>
								expand_less
								</span>
							)
						}
					</span>
				</li>
				<span className={hideMenu ? 'display-none' : 'display'}>{renderMenuElements()}</span>
			</ul>
		</aside>
	);
}

export default MenuLeft;
