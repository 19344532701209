import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { GetCookie_refreshToken } from '@backoffice/common/utils/cookiesManager';
import { GetCookie_culture } from '@backoffice/common/utils/cookiesManager';
import FixedLabel from '@backoffice/shared/FixedLabel';
import { hideFixedLabel } from '@backoffice/actions/fixedLabel';
import { createToken, createRefreshToken } from '@backoffice/actions/token';
import { startRequest } from '@backoffice/actions/apiCall';
import { showDialog, hideDialog } from '@backoffice/actions/loading';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { refreshTokenParams } from './LayoutCalls';
import moment from 'moment';
import MenuLeft from '../../shared/MenuLeft';
import { ROUTE } from '../../common/constants/routes';
import './Layout.scss';
import { refreshAppearance } from '../../actions/appearance';

const Layout = ({ children, path }) => {
	const tokenReducer = useSelector((state) => state.token);
	const appearanceReducer = useSelector((state) => state.appearance);
	const languageReducer = useSelector((state) => state.language);
	const [page, setPage] = useState('/home')
	const [ hide, setHide ] = useState(false);

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const [ sideBarOpen, setSideBarOpen ] = useState(false);
	const history = useHistory();
	const isMaintenance = false;

	const handleDrawerClose = () => {
		if (sideBarOpen) {
			setSideBarOpen(false);
		}
	};
	
	useEffect(
		() => {
			if(isMaintenance) history.push(ROUTE.ROOT);
			if (appearanceReducer.isDefault) {
				dispatch(refreshAppearance());
			}
		},
		[ appearanceReducer.isDefault ]
	);

	useEffect(() => {
		checkCookie();
		i18n.changeLanguage(GetCookie_culture());
		// dispatch(refreshAppearance());
	}, []);

	useEffect(
		() => {
			dispatch(hideFixedLabel());
			setTitle();
			setPage(history.location.pathname);
		},
		[ history.location.pathname ]
	);

	useEffect(
		() => {
			dispatch(showDialog());
			setHide(true);
			setTimeout(() => {
				setHide(false);
				dispatch(hideDialog());
			}, 500);
		},
		[ languageReducer.lang ]
	);

	const setTitle = () => {
		let title = 'Backoffice';
		switch (path) {
			case ROUTE.HOME:
				title = 'Home';
				break;
			case ROUTE.CALENDAR:
				title = 'Calendar';
				break;
			case ROUTE.USERS:
				title = 'Users';
				break;
			case ROUTE.PROFILE:
				title = 'Profile';
				break;
			case ROUTE.SETTINGS:
				title = 'Settings';
				break;
			default:
				title = 'Backoffice';
				break;
		}
		document.title = t(title);
	};

	const checkCookie = () => {
		let token = tokenReducer.token;
		let token_exp = tokenReducer.tokenExp;
		if (token !== '' && token_exp !== '') {
			let token_exp_date = moment(token_exp);
			let today = moment().format();
			let refreshToken = GetCookie_refreshToken();
			if (token_exp_date.diff(today, 'hours') <= 10 && refreshToken !== '') {
				let data = {
					accessToken: token,
					refreshToken: refreshToken
				};
				dispatch(
					startRequest(
						refreshTokenParams(data, (response) => {
							dispatch(createToken(response.accessToken));
							dispatch(createRefreshToken(response.refreshToken));
						})
					)
				);
			}
		}
	};

	return (
		<div onKeyDown={handleDrawerClose} className="layout">
			{/* <Header /> */}
			<main className="main">
				{
					page === '/usuarios' || page === '/empresas' || page === '/solicitudes' || page === '/empresasauditoras' || page === '/cuestionarios' || page.includes('/empresasinfo') ? (<MenuLeft />) : null
				}
				<FixedLabel parentType="general" />
				<section className={page === '/usuarios' || page === '/empresas' || page === '/solicitudes' || page === '/empresasauditoras' || page === '/cuestionarios' || page.includes('/empresasinfo') ? 'main-content' : 'main-content-full'}>
					{hide === false ? (
						<React.Fragment>
							{children}
							{/* <Footer /> */}
						</React.Fragment>
					) : (
						''
					)}
				</section>
			</main>
		</div>
	);
};

export default Layout;
