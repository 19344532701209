import React from 'react';
import { useTranslation } from 'react-i18next';

const RequestInformEvaluator = ({formValues, filesData, colorEvaluationStates}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='title-wrapper'>
                <h2 className='title'>{t('EvaluationReport')}</h2>
                <div className='state'>
                    <span
                        className='material-icons-round'
                        style={{
                            color: colorEvaluationStates(formValues.EvaluationState)
                        }}
                        aria-hidden={true}
                    >assignment</span>
                    <span>{formValues.EvaluationState}</span>
                </div>
            </div>
            <div className='content-wrapper'>
                <div className='data'>
                    <div className='label'>{t('Date')}</div>
                    <div className='data'>
                        {formValues.EvaluationDate ? new Date(formValues.EvaluationDate).toLocaleDateString() : '-'}
                    </div>
                </div>
                {
                    filesData.evaluationMedia.url !== '' ? (
                        <div className='actions'>
                            <div className="upload-wrapper">
                                <div className='button-upload'>
                                    <div className='file-wrapper'>
                                        <span className='file-name'>{filesData.evaluationMedia.name}</span>
                                        <a title={filesData.evaluationMedia.name} href={filesData.evaluationMedia.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </>
    )
}

export default RequestInformEvaluator;