import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FixedLabel from '../../../shared/FixedLabel/FixedLabel';
import Logo from '../../../assets/logos/me_azul2.png';
import { startRequest } from '../../../actions/apiCall';
import { getUserLoggedParams } from '../SelfAssessment/SelfAssessmentCalls/SelfAssessmentCalls';
import { Button, Combo, Input, Textarea } from '../../../components/FormComponents';
import './ImprovementPlans.scss';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { getAutoevaluationAreasFromBasisParams, getAutoevaluationBaisisesParams, getAutoevaluationSubAreasFromBasisAreaParams, getImprovementPlanDetailParams, postSaveImprovementPlanParams, putSendImprovementPlanParams } from './ImprovementPlansCalls/ImprovementPlansCalls';
import { showFixedLabel } from '../../../actions/fixedLabel';
import ImprovementPlansModal from './ImprovementPlansModal';
import { closeModal, openModal } from '../../../actions/popup';

const ImprovementPlans = () => {
    const history = useHistory()
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const grid = useRef(null);

    const [requestId, setRequestId] = useState('')
    const [improvementPlanId, setImprovementPlanId] = useState('')
    const [autoevaluationId, setAutoevaluationId] = useState('')
    const [userRol, setUserRol] = useState('')
    const [userLogged, setUserLoged] = useState('')
    const [showExportExcelBtn, setShowExportExcelBtn] = useState(false)

    const initForm = {
        requestId: requestId,
        improvementPlanId: improvementPlanId,
        name: "",
        description: "",
        basisId: "",
        areaId: "",
        subAreasIds: [],
        responsible: "",
        necessaryResources: "",
        descriptionCurrentSituation: "",
        objectivesToAchieve: [],
        actionsToPerform: []
    }

    const initErrors = {
        name: {
            error: false,
            message: "El nombre del plan no puede estar vacío",
            type: "text"
        },
        description: {
            error: false,
            message: "La descripción del plan no puede estar vacía",
            type: "text"
        },
        basisId: {
            error: false,
            message: "El propósito no puede estar vacío",
            type: "text"
        },
        areaId: {
            error: false,
            message: "El área no puede estar vacía",
            type: "array"
        },
        subAreasIds: {
            error: false,
            message: "El subárea no puede estar vacía",
            type: "array"
        },
        responsible: {
            error: false,
            message: "El responsable no puede estar vacío",
            type: "text"
        },
        necessaryResources: {
            error: false,
            message: "Los recursos no pueden estar vacíos",
            type: "text"
        },
        descriptionCurrentSituation: {
            error: false,
            message: "La descripción actual del plan no puede estar vacía",
            type: "text"
        },
        objectivesToAchieve: {
            error: false,
            message: "Debes incluir al menos un objetivo",
            type: "goal"
        },
        actionsToPerform: {
            error: false,
            message: "Debes incluir al menos una acción",
            type: "action"
        },
    }

    const initFormGoal = {
        name: "",
        measurementVariable: "",
        objective: ""
    }

    const initFormAction = {
        name: "",
        startDate: "",
        endDate: "",
        responsible: ""  
    }

    const [loadingData, setLoadingData] = useState(false);
    const [formValues, setFormValues] = useState(initForm);
    const [isCompleted, setIsCompleted] = useState(false);
    const [formValuesError, setFormValuesError] = useState(initErrors);
    const [formValuesGoal, setFormValuesGoal] = useState(initFormGoal);
    const [formValuesAction, setFormValuesAction] = useState(initFormAction);

    const [baisisesComboOptions, setBaisisesComboOptions] = useState([])
    const [baisisesComboSelected, setBaisisesComboSelected] = useState([])

    const [areasComboOptions, setAreasComboOptions] = useState([])
    const [areasComboSelected, setAreasComboSelected] = useState([])

    const [subareasComboOptions, setSubareasComboOptions] = useState([])
    const [subareasComboSelected, setSubareasComboSelected] = useState([])

    useEffect(() => {
        if(history.location.state){
            setRequestId(history.location.state.requestId)
        }else{
            //get id from params
            const params = window.location.pathname
            setRequestId(params.split('/')[2])
        }
        setAutoevaluationId(history.location.state.autoevaluationId)
        history.location.state.improvementPlanId ? setImprovementPlanId(history.location.state.improvementPlanId) : setLoadingData(true)
        getUserLoged();
    }, [])

    useEffect(() => {
        setFormValues({
            ...formValues,
            requestId: requestId,
        })
    }, [requestId])

    useEffect(() => {
        setFormValues({
            ...formValues,
            improvementPlanId: improvementPlanId,
        })
    }, [improvementPlanId])           

    const getUserLoged = () =>{
        dispatch(startRequest(getUserLoggedParams(getUserLoggedParamsSuccess)))
	}

    const getUserLoggedParamsSuccess = (data) => {
        setUserRol(data.userRoles[0].name)
        //setRol('Auditor')
        setUserLoged(data.name)
    }

    useEffect(() => {
        formValues.improvementPlanId && getImprovementPlanDetail()
    }, [formValues.improvementPlanId])

    const getImprovementPlanDetail = () => {
        dispatch(startRequest(getImprovementPlanDetailParams(improvementPlanId, getImprovementPlanDetailParamsSuccess)))
    }

    const getImprovementPlanDetailParamsSuccess = (data) => {
        setFormValues({
            ...formValues,
            name: data.name,
            description: data.description,
            basisId: data.basis.id,
            areaId: data.area.id,
            subAreasIds: data.subAreas.map((el) => { return el.id }),
            responsible: data.responsible,
            necessaryResources: data.necessaryResources,
            descriptionCurrentSituation: data.descriptionCurrentSituation,
            objectivesToAchieve: data.objectivesToAchieve,
            actionsToPerform: data.actionsToPerform
        })   
        setIsCompleted(data.isCompleted);     
    }

    useEffect(() => {        
        formValues.name != '' && setLoadingData(true);
    }, [formValues.name])

    useEffect(() => {
        loadingData && dispatch(startRequest(getAutoevaluationBaisisesParams(autoevaluationId, getAutoevaluationBaisisesParamsSuccess)))
    }, [loadingData])    

    const getAutoevaluationBaisisesParamsSuccess = (data) => {
        let options = [];
        data.forEach((el) => {
            const option = {
                id: el.id,
                value: el.name,
                label: el.name
            }
            options.push(option);
            if(formValues.basisId === el.id) {
                setBaisisesComboSelected(option)
            }
        });
        setBaisisesComboOptions(options)
    }    

    useEffect(() => {       
        setFormValues({
            ...formValues,
            basisId: baisisesComboSelected.id
        })
        setFormValuesError({
            ...formValuesError,
            basisId: {
                ...formValuesError.basisId,
                error: false
            }
        })
        baisisesComboSelected.id && dispatch(startRequest(getAutoevaluationAreasFromBasisParams(autoevaluationId, baisisesComboSelected.id, getAutoevaluationAreasFromBasisParamsSuccess)))     
    }, [baisisesComboSelected])

    const getAutoevaluationAreasFromBasisParamsSuccess = (data) => {
        let options = [];
        let optionsSelected = [];
        data.forEach((el) => {
            const option = {
                id: el.id,
                value: el.name,
                label: el.name
            }
            options.push(option);
            if(formValues.areaId === el.id) {
                optionsSelected = option;
            }
        });
        setAreasComboOptions(options)
        setAreasComboSelected(optionsSelected)
        if(optionsSelected.length === 0) {
            setSubareasComboSelected([])
        }
    }

    useEffect(() => {
        areasComboSelected.id && dispatch(startRequest(getAutoevaluationSubAreasFromBasisAreaParams(autoevaluationId, baisisesComboSelected.id, areasComboSelected.id, getAutoevaluationSubAreasFromBasisAreaParamsSuccess)))
        setFormValues({
            ...formValues,
            areaId: areasComboSelected.id
        })
        
        if(!areasComboSelected || areasComboSelected.length <= 0) {
        } else {
            setFormValuesError({
                ...formValuesError,
                areaId: {
                    ...formValuesError.areaId,
                    error: false
                }
            })
        }
    }, [areasComboSelected])

    const getAutoevaluationSubAreasFromBasisAreaParamsSuccess = (data) => {
        let options = [];
        let optionsSelected = [];
        data.forEach((el) => {
            let option = {
                id: el.id,
                value: el.name,
                label: el.name
            }
            options.push(option);
            formValues.subAreasIds.forEach((el2) => {
                if(el2 === el.id) {
                    optionsSelected.push(option);
                }
            })
        });
        setSubareasComboOptions(options)
        setSubareasComboSelected(optionsSelected)
    }

    useEffect(() => {
        let subareasIds = [];
        subareasComboSelected.length > 0 && subareasComboSelected.forEach((subarea) => {
            subareasIds.push(subarea.id)
        })
        setFormValues({
            ...formValues,
            subAreasIds: subareasIds
        })
        if(subareasComboSelected.length > 0) {
            setFormValuesError({
                ...formValuesError,
                subAreasIds: {
                    ...formValuesError.subAreasIds,
                    error: false
                }
            })
        }
    }, [subareasComboSelected])

    const handleBtnAddGoal = () => {
        if(formValuesGoal.name === '' || formValuesGoal.measurementVariable === '' || formValuesGoal.objective === '') {
            setFormValuesError({
                ...formValuesError,
                objectivesToAchieve: {
                    ...formValuesError.objectivesToAchieve,
                    error: true,
                    message: 'Debes rellenar los tres campos'
                }
            })
        } else {
            let goals = [...formValues.objectivesToAchieve];        
            goals.push(formValuesGoal);

            setFormValues({
                ...formValues,
                objectivesToAchieve: goals
            })
            setFormValuesGoal(initFormGoal);
            setFormValuesError({
                ...formValuesError,
                objectivesToAchieve: {
                    ...formValuesError.objectivesToAchieve,
                    error: false
                }
            })
        }
    }
    const handleBtnAddAction = () => {
        if(formValuesAction.name === '' || formValuesAction.startDate === '' || formValuesAction.endDate === '' || formValuesAction.responsible === '') {
            setFormValuesError({
                ...formValuesError,
                actionsToPerform: {
                    ...formValuesError.actionsToPerform,
                    error: true,
                    message: 'Debes rellenar los cuatro campos'
                }
            })
        } else {
            let actions = [...formValues.actionsToPerform];        
            actions.push(formValuesAction);

            setFormValues({
                ...formValues,
                actionsToPerform: actions
            })
            setFormValuesAction(initFormAction);
            setFormValuesError({
                ...formValuesError,
                actionsToPerform: {
                    ...formValuesError.actionsToPerform,
                    error: false
                }
            })
        }
    }

    const editGoal = (index) => {
        const objective = formValues.objectivesToAchieve[index];
        setFormValuesGoal(objective);
        removeGoal(index);
    }
    const removeGoal = (index) => {
        const newObjectives = formValues.objectivesToAchieve;
        newObjectives.splice(index, 1);
        setFormValues({...formValues, objectivesToAchieve: newObjectives});
    }
    const editAction = (index) => {
        const action = formValues.actionsToPerform[index];
        setFormValuesAction(action);
        removeAction(index);
    }
    const removeAction = (index) => {
        const newActions = formValues.actionsToPerform;
        newActions.splice(index, 1);
        setFormValues({...formValues, actionsToPerform: newActions});
    }

    const drawTableGoals = () => {
        return (
            <>
                <div className="table-header">
                    <div className="table-header__th">{t('GoalName')}</div>
                    <div className="table-header__th">{t('GoalVariable')}</div>
                    <div className="table-header__th">{t('GoalGoal')}</div>
                    <div className="table-header__th"></div>
                </div>
                <div className="table-body">
                    {
                        formValues.objectivesToAchieve.map((goal, index) => (
                            <div className="table-body__row" key={goal.name}>
                                <div className="table-body__row__td">{goal.name}</div>
                                <div className="table-body__row__td">{goal.measurementVariable}</div>
                                <div className="table-body__row__td">{goal.objective}</div>
                                
                                
                                <div className="table-body__row__td">
                                {!isCompleted && 
                                    <>
                                        <span
                                        className="material-icons-round"
                                        data-testid={`btnEditGoalStandar_${index}`}
                                        role='navigation'
                                        aria-hidden={true}
                                        id={`btnEditGoalStandar_${index}`}
                                        onClick={() => editGoal(index)}
                                        type="button"
                                        >
                                        mode_edit
                                        </span>
                                        <span
                                        className="material-icons-round"
                                        data-testid={`btnRemoveGoalStandar_${index}`}
                                        role='navigation'
                                        aria-hidden={true}
                                        id={`btnRemoveGoalStandar_${index}`}
                                        onClick={() => removeGoal(index)}
                                        type="button"
                                        >
                                        delete
                                        </span>
                                    </>
                                } 
                                </div>
                                
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }

    const drawTableActions = () => {
        return (
            <>
                <div className="table-header">
                    <div className="table-header__th">{t('ActionName')}</div>
                    <div className="table-header__th">{t('StartDate')}</div>
                    <div className="table-header__th">{t('EndDate')}</div>
                    <div className="table-header__th">{t('NameResponsible')}</div>
                    <div className="table-header__th"></div>
                </div>
                <div className="table-body">
                    {
                        formValues.actionsToPerform.map((actions, index) => (
                            <div className="table-body__row" key={actions.name}>
                                <div className="table-body__row__td">{actions.name}</div>
                                <div className="table-body__row__td">{new Date(actions.startDate).toLocaleDateString()}</div>
                                <div className="table-body__row__td">{new Date(actions.endDate).toLocaleDateString()}</div>
                                <div className="table-body__row__td">{actions.responsible}</div>
                                <div className="table-body__row__td">
                                {!isCompleted && 
                                    <>
                                        <span
                                        className="material-icons-round"
                                        data-testid={`btnEditActionStandar_${index}`}
                                        role='navigation'
                                        aria-hidden={true}
                                        id={`btnEditActionStandar_${index}`}
                                        onClick={() => editAction(index)}
                                        type="button"
                                        >
                                        mode_edit
                                        </span>
                                        <span
                                        className="material-icons-round"
                                        data-testid={`btnRemoveActionStandar_${index}`}
                                        role='navigation'
                                        aria-hidden={true}
                                        id={`btnRemoveActionStandar_${index}`}
                                        onClick={() => removeAction(index)}
                                        type="button"
                                        >
                                        delete
                                        </span>
                                    </>
                                }
                                </div>            
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }

    const checkFormValues = () => {
        let check = true;
        let formValuesErrorObject = {...formValuesError};

        for (const key in formValuesError) {
            if (Object.hasOwnProperty.call(formValuesError, key)) {
                switch (formValuesErrorObject[key].type) {
                    case "text":
                        if(formValues[key] === '') {
                            formValuesErrorObject[key].error = true;
                            check = false;
                        } else {
                            formValuesErrorObject[key].error = false;
                        }
                        break;
                    case "array":
                        if(!formValues[key] || formValues[key].length <= 0) {
                            formValuesErrorObject[key].error = true;
                            check = false;
                        } else {
                            formValuesErrorObject[key].error = false;
                        }
                        break;                
                    case "goal":
                        if(formValues[key].length <= 0) {
                            formValuesErrorObject[key].error = true;
                            formValuesErrorObject[key].message = 'Debes incluir al menos un objetivo';
                            check = false;
                        } else {
                            formValuesErrorObject[key].error = false;
                        }
                        break;                
                    case "action":
                        if(formValues[key].length <= 0) {
                            formValuesErrorObject[key].error = true;
                            formValuesErrorObject[key].message = 'Debes incluir al menos una acción';
                            check = false;
                        } else {
                            formValuesErrorObject[key].error = false;
                        }
                        break;                
                    default:
                        break;
                }
            }
        }

        setFormValuesError(formValuesErrorObject)

        return check;
    }

    const handleSubmitFormSave = () => {
        const check = checkFormValues();
        check && dispatch(startRequest(postSaveImprovementPlanParams(formValues, postSaveImprovementPlanParamsSuccess)))
    }

    const postSaveImprovementPlanParamsSuccess = (data) => {
        setImprovementPlanId(data.improvementPlanId)
        dispatch(showFixedLabel(t('ImprovementPlanSave'), 'success'))
    }

    const sendImpromeventPlanModal = () => {
        const check = checkFormValues();
        
        if(check) {
            const optionValues = {
                id: 'sendImprovementPlanModal',
                dataTestId: 'sendImprovementPlanModal',
                cssClass: 'modal-header__transparent',
                title: t('SendImprovementPlan')
            };
        
            const options = {
                ...optionValues,
                body: (
                    <ImprovementPlansModal
                        handleSubmit={handleSubmitFormSend}
                    />
                )
            };
            dispatch(openModal(options));
        }
    }

    const handleSubmitFormSend = (e) => {
        e.preventDefault();
        dispatch(closeModal());
        dispatch(startRequest(putSendImprovementPlanParams(formValues, putSendImprovementPlanParamsSuccess)))
    }

    const putSendImprovementPlanParamsSuccess = () => {
        const linkName = userRol !== 'Company' ? 'empresas' : 'solicitudes';
        history.push(`/${linkName}/${requestId}`)
    }

    const onHandleChangeInputs = (inputName, value) => {
        setFormValues({
            ...formValues,
            [inputName]: value
        })
        setFormValuesError({
            ...formValuesError,
            [inputName]: {
                ...formValuesError[inputName],
                error: false
            }
        })
    }

    const onHandleChangeInputsGoals = (inputName, value) => {
        setFormValuesGoal({
            ...formValuesGoal,
            [inputName]: value
        })
    }

    const onHandleChangeInputsActions = (inputName, value) => {
        setFormValuesAction({
            ...formValuesAction,
            [inputName]: value
        })
    }

    return (
        <div className='request-detail '>
            <FixedLabel parentType="general" />
            <form className='request-detail__form' >
                <div className='request-detail__heading'>
                    <div className='heading-logo-container '>
                        <h1 className='logo-wrapper'>
                            <img className='logo-img' src={Logo} alt="logo madrid excelente" />
                        </h1>
                        <span className='sr-only' aria-labelledby='returnButton'>Volver atrás</span>
                        <button className='return-wrapper'
                            id='returnButton'
                            role='navigation'
                            onClick={() => {
                                //history.goBack()
                                const linkName = userRol !== 'Company' ? 'empresas' : 'solicitudes';
                                history.push(`/${linkName}/${requestId}`)
                            }}
                        >
                            <span className='material-icons' aria-hidden={true}>arrow_back</span>
                            <span className='return-link'>
                                {t('GoBack')}
                            </span>
                        </button>
                    </div>
                    <div className='user-container'>
                        <div className='help-wrapper' >
                            <a className='return-wrapper' href='https://www.madridexcelente.com/' role='button'>
                                <span className='material-icons' aria-hidden={true}>arrow_back</span> 
                                <span className='return-link'>
                                    Volver a la web de Madrid Excelente
                                </span>
                            </a>
                        </div> 
                        <div className='user-wrapper'>
                            <span className='user-text'>{userLogged ? userLogged.name : null}</span>
                        </div>
                    </div>
                </div>      
                <div className='request-detail__content me-container'>
                    <div className='improvement-plans-header'>
                        <h2 id="impromentPlansTitle" className="title">
                            {
                                userRol !== 'Company'
                                    ? `${t('ImprovementPlanLong')} (${isCompleted ? t('Completed') : t('NotCompleted')})`
                                    : t('ImprovementPlanLong')
                            }                            
                        </h2>
                    </div>
                    <div className='improvement-plans'>
                        <div className='improvement-plans__row'>
                            <div className="improvement-plans__row__inputs-wrapper">
                                <Input 
                                    isRequired={true}
                                    inputName="textNamePlan"
                                    inputType="text"
                                    inputLabel={t('NamePlan')}
                                    inputID="textNamePlan"
                                    isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                    showLabel={true}
                                    value={formValues.name}
                                    inputPlaceholder={t('NamePlan')}
                                    onHandleChange={(e) => onHandleChangeInputs('name', e.target.value)}
                                    inputMaxLength={600}
                                    isError={formValuesError.name.error}
                                    errorMessage={formValuesError.name.message}
                                />
                                <Input 
                                    isRequired={true}
                                    inputName="textResponsiblePlan"
                                    inputType="text"
                                    inputLabel={t('NameResponsible')}
                                    inputID="textResponsiblePlan"
                                    isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                    showLabel={true}
                                    value={formValues.responsible}
                                    inputPlaceholder={t('NameResponsible')}
                                    onHandleChange={(e) => onHandleChangeInputs('responsible', e.target.value)}
                                    inputMaxLength={600}
                                    isError={formValuesError.responsible.error}
                                    errorMessage={formValuesError.responsible.message}
                                />
                            </div>
                        </div>
                        <div className='improvement-plans__row'>
                            <Textarea
                                showLabel={true}
                                inputID="descriptionPlan"
                                inputLabel={t('DescriptionPlan')}
                                inputName="descriptionPlan"
                                inputValue={formValues.description}
                                dataTestID="descriptionPlan"
                                isRequired={true}
                                textPlaceholder={t('DescriptionPlan')}
                                max={1200}
                                onHandleChange={(e) => onHandleChangeInputs('description', e.target.value)}
                                isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                isError={formValuesError.description.error}
                                errorMessage={formValuesError.description.message}
                            />
                        </div>
                        <div className='improvement-plans__row'>
                            <Textarea
                                showLabel={true}
                                inputID="resourcesPlan"
                                inputLabel={t('ResourcesPlan')}
                                inputName="resourcesPlan"
                                inputValue={formValues.necessaryResources}
                                dataTestID="resourcesPlan"
                                isRequired={true}
                                textPlaceholder={t('ResourcesPlan')}
                                max={600}
                                onHandleChange={(e) => onHandleChangeInputs('necessaryResources', e.target.value)}
                                isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                isError={formValuesError.necessaryResources.error}
                                errorMessage={formValuesError.necessaryResources.message}
                            />
                        </div>
                        <div className='improvement-plans__row'>
                            <Textarea
                                showLabel={true}
                                inputID="descriptionActualSituation"
                                inputLabel={t('DescriptionActualSituation')}
                                inputName="descriptionActualSituation"
                                inputValue={formValues.descriptionCurrentSituation}
                                dataTestID="descriptionActualSituation"
                                isRequired={true}
                                textPlaceholder={t('DescriptionActualSituation')}
                                max={1200}                                
                                onHandleChange={(e) => onHandleChangeInputs('descriptionCurrentSituation', e.target.value)}
                                isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                isError={formValuesError.descriptionCurrentSituation.error}
                                errorMessage={formValuesError.descriptionCurrentSituation.message}
                            />
                        </div>
                        <div className='improvement-plans__row'>
                            <div className="improvement-plans__row__inputs-wrapper">
                                <div className="improvement-plans__row__inputs-wrapper__item">
                                    <Combo 
                                        id="BaisisesType"
                                        data-testid="BaisisesType"
                                        defaultInputValue=""
                                        options={baisisesComboOptions}
                                        isSearchable={false}
                                        isClearable={true}
                                        className="comboBox"
                                        handleChange={(e) => {                                            
                                            setBaisisesComboSelected(e)
                                        }}
                                        inputValue={baisisesComboSelected}
                                        isMultiple={false}
                                        comboLabel={t('BaisisesType')}
                                        comboPlaceholder={t('BaisisesType')}
                                        isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                    />
                                </div>
                                <div className="improvement-plans__row__inputs-wrapper__item">
                                    {
                                        areasComboOptions.length > 0 &&
                                            <Combo 
                                                id="AreasType"
                                                data-testid="AreasType"
                                                defaultInputValue=""
                                                options={areasComboOptions}
                                                isSearchable={false}
                                                isClearable={true}
                                                className="comboBox"
                                                handleChange={(e) => setAreasComboSelected(e)}
                                                inputValue={areasComboSelected}
                                                isMultiple={false}
                                                comboLabel={t('AreasType')}
                                                comboPlaceholder={t('AreasType')}
                                                isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                            />
                                    }
                                </div>
                                <div className="improvement-plans__row__inputs-wrapper__item">
                                    {
                                        subareasComboOptions.length > 0 &&
                                            <Combo 
                                                id="SubareasType"
                                                data-testid="SubareasType"
                                                defaultInputValue=""
                                                options={subareasComboOptions}
                                                isSearchable={false}
                                                isClearable={true}
                                                className="comboBox"
                                                handleChange={(e) => setSubareasComboSelected(e)}
                                                inputValue={subareasComboSelected}
                                                isMultiple={true}
                                                comboLabel={t('SubareasType')}
                                                comboPlaceholder={t('SubareasType')}
                                                isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                            />
                                    }
                                </div>
                            </div>
                            {
                                formValuesError.basisId.error &&
                                    <div className="improvement-plans__row__error-wrapper">
                                        <span>{formValuesError.basisId.message}</span>
                                    </div>
                            }
                            {
                                formValuesError.areaId.error &&
                                    <div className="improvement-plans__row__error-wrapper">
                                        <span>{formValuesError.areaId.message}</span>
                                    </div>
                            }
                            {
                                formValuesError.subAreasIds.error &&
                                    <div className="improvement-plans__row__error-wrapper">
                                        <span>{formValuesError.subAreasIds.message}</span>
                                    </div>
                            }
                        </div>
                        <div className='improvement-plans__row goals_wrapper'>
                            {
                                userRol === 'Company' &&
                                <>
                                    <div className="improvement-plans__row__inputs-wrapper">
                                        <Input 
                                            isRequired={true}
                                            inputName="goalName"
                                            inputType="text"
                                            inputLabel={t('GoalName')}
                                            inputID="goalName"
                                            isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                            showLabel={true}
                                            value={formValuesGoal.name}
                                            inputPlaceholder={t('GoalName')}
                                            onHandleChange={(e) => onHandleChangeInputsGoals('name', e.target.value)}
                                            inputMaxLength={600}
                                        />
                                        <Input 
                                            isRequired={true}
                                            inputName="goalVariable"
                                            inputType="text"
                                            inputLabel={t('GoalVariable')}
                                            inputID="goalVariable"
                                            isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                            showLabel={true}
                                            value={formValuesGoal.measurementVariable}
                                            inputPlaceholder={t('GoalVariable')}
                                            onHandleChange={(e) => onHandleChangeInputsGoals('measurementVariable', e.target.value)}
                                            inputMaxLength={600}
                                        />
                                        <Input 
                                            isRequired={true}
                                            inputName="goalGoal"
                                            inputType="text"
                                            inputLabel={t('GoalGoal')}
                                            inputID="goalGoal"
                                            isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                            showLabel={true}
                                            value={formValuesGoal.objective}
                                            inputPlaceholder={t('GoalGoal')}
                                            onHandleChange={(e) => onHandleChangeInputsGoals('objective', e.target.value)}
                                            inputMaxLength={600}
                                        />
                                    </div>
                                    {
                                        formValuesError.objectivesToAchieve.error &&
                                            <div className="improvement-plans__row__error-wrapper">
                                                <span>{formValuesError.objectivesToAchieve.message}</span>
                                            </div>
                                    }
                                    <div className="improvement-plans__row__buttons-wrapper">
                                        <Button
                                            dataTestID="btnAddGoal"
                                            btnText={t('AddGoal')}
                                            btnType="button"
                                            btnClass="btn-base_normal"
                                            onHandleBtnClick={handleBtnAddGoal}
                                            isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                        />
                                    </div>
                                </>
                            }
                            <div className="improvement-plans__row__table-wrapper">
                                <div className='grid-wrapper-goals' style={{ flex: "1", width: "100%", height: "100%" }}>
                                {
                                    formValues.objectivesToAchieve.length > 0 && drawTableGoals()
                                }
                                </div>
                            </div>
                        </div>
                        <div className='improvement-plans__row actions-wrapper'>
                            {
                                userRol === 'Company' &&
                                <>
                                    <div className="improvement-plans__row__inputs-wrapper">
                                        <Input 
                                            isRequired={true}
                                            inputName="actionName"
                                            inputType="text"
                                            inputLabel={t('ActionName')}
                                            inputID="actionName"
                                            isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                            showLabel={true}
                                            value={formValuesAction.name}
                                            inputPlaceholder={t('ActionName')}
                                            onHandleChange={(e) => onHandleChangeInputsActions('name', e.target.value)}
                                            inputMaxLength={600}
                                        />
                                        <div className='date-wrapper input-container'>                                
                                            <label className="inputLabel">{t('StartDate')} <sup className="required">*</sup></label>
                                            <DatePickerComponent
                                                showTodayButton={false}
                                                allowEdit={false}
                                                openOnFocus={true}
                                                isRequired={true}
                                                value={formValuesAction.startDate}
                                                onChange={(e) => onHandleChangeInputsActions('startDate', e.target.value)}
                                                placeholder={t('StartDate')}
                                                disabled={userRol !== 'Company' || isCompleted ? true : false}
                                            /> 
                                        </div>
                                        <div className='date-wrapper input-container'>
                                            <label className="inputLabel">{t('EndDate')} <sup className="required">*</sup></label>
                                            <DatePickerComponent
                                                showTodayButton={false}
                                                allowEdit={false}
                                                openOnFocus={true}
                                                isRequired={true}
                                                value={formValuesAction.endDate}
                                                onChange={(e) => onHandleChangeInputsActions('endDate', e.target.value)}
                                                placeholder={t('EndDate')}
                                                disabled={userRol !== 'Company' || isCompleted ? true : false}
                                            /> 
                                        </div>
                                        <Input 
                                            isRequired={true}
                                            inputName="actionResponsible"
                                            inputType="text"
                                            inputLabel={t('NameResponsible')}
                                            inputID="actionResponsible"
                                            isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                            showLabel={true}
                                            value={formValuesAction.responsible}
                                            inputPlaceholder={t('NameResponsible')}
                                            onHandleChange={(e) => onHandleChangeInputsActions('responsible', e.target.value)}
                                            inputMaxLength={600}
                                        />
                                    </div>                                    
                                    {
                                        formValuesError.actionsToPerform.error &&
                                            <div className="improvement-plans__row__error-wrapper">
                                                <span>{formValuesError.actionsToPerform.message}</span>
                                            </div>
                                    }
                                    <div className="improvement-plans__row__buttons-wrapper">
                                        <Button
                                            dataTestID="btnAddAction"
                                            btnText={t('AddAction')}
                                            btnType="button"
                                            btnClass="btn-base_normal"
                                            onHandleBtnClick={handleBtnAddAction}
                                            isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                                        />
                                    </div>
                                </>
                            }
                            <div className="improvement-plans__row__table-wrapper">
                                <div className='grid-wrapper-actions' style={{ flex: "1", width: "100%", height: "100%" }}>
                                {
                                    formValues.actionsToPerform.length > 0 && drawTableActions()
                                }
                                </div>
                            </div>
                        </div>
                        <div className="improvement-plans__row buttons-wrapper">
                            <Button
                                dataTestID="btnAddPlan"
                                btnText={t('Save')}
                                btnType="button"
                                btnClass="btn-base_normal"
                                onHandleBtnClick={handleSubmitFormSave}
                                isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                            />
                            {console.log(userRol !== 'Company', isCompleted ? true : false)}
                            <Button
                                dataTestID="btnUpdatePlan"
                                btnText={t('Send')}
                                btnType="button"
                                btnClass="btn-base_normal"
                                onHandleBtnClick={sendImpromeventPlanModal}
                                isDisabled={userRol !== 'Company' || isCompleted ? true : false}
                            />
                        </div> 
                    </div>         
                </div>
            </form>
        </div>
    )
}

export default ImprovementPlans;