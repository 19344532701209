import apiCall from '../../actions/apiCall';

/* Redux state init */
const initialState = {
	// request: true,
	requestOptions: []
};

export default function apiCallReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case apiCall.START_REQUEST:
				let newRequestOptions = state.requestOptions;
				action.requestOptions.id = Math.random();
				newRequestOptions.push(action.requestOptions);
				return {
					...state,
					// request: true,
					requestOptions: newRequestOptions
				};

			case apiCall.END_REQUEST:
				let newEndRequestOptions = state.requestOptions;

				state.requestOptions.forEach((requestInState, i) => {
					if (action.requestOptions.id === requestInState.id) {
						newEndRequestOptions.splice(i, 1);
					}
				});
				return {
					...state,
					// request: false,
					requestOptions: newEndRequestOptions
				};

			default:
				return { ...state };
		}
	}
	return state;
}
