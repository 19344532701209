import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../actions/popup';
import { Button } from '../../../../components/FormComponents';
import './UserCVModal.scss';

const UserCVModal = ({user, action, uploadFile, handleOnClickCloseModal}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className='user-cv-edit-modal'>
            <form className='form-user-edit'>
                <div className='btn-submit-container row mt-4'>
                    <div className={`btn-submit-wrapper col-lg-12 d-flex`}>
                        <div className='d-flex w-100 justify-content-end'>
                            <Button
                                dataTestID="btnCancel"
                                btnText={t('Cancel')}
                                btnType="button"
                                btnClass="btn-base_outline ml-5"
                                onHandleBtnClick={() => {
                                    dispatch(closeModal());
                                }}
                            />

                            <input
                                id="btnCvUser"
                                data-testid="btnCvUser"
                                type="file"
                                onChange={(e) => uploadFile(e, user)}
                                name="btnCvUser"
                                accept=".pdf, .doc, .docx"
                                className="inputfile"
                            />
                            <label
                                htmlFor="btnCvUser"
                                role={'button'}
                                className={`btn-base_normal ml-3 ${action === 'edit' ? 'btn-base_outline' : null}`}
                            >
                                {
                                    action !== 'edit' ? (
                                        <i className="material-icons-round">file_upload</i>
                                    ) : <i className="material-icons-round">refresh</i>
                                }

                                <span>{action !== 'edit' ? `${t('AddCV')}` : `${t('UpdateCV')}`}</span>
                            </label>

                            {
                                user.userData.auditorUserData.auditorCV &&
                                    <div className='file-wrapper' onClick={handleOnClickCloseModal}>
                                        <a title={user.userData.auditorUserData.auditorCV.name} href={user.userData.auditorUserData.auditorCV.url} className="btn-base_normal" target="_blank" rel="noreferrer">
                                            <span
                                                role={'button'}
                                                className={`btn-base_normal`}
                                            >
                                                <i className="material-icons-round">file_download</i>
                                                <span>{t('DownloadContract')}</span>
                                            </span>
                                        </a>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UserCVModal;