import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Textarea.scss';

export default function Textarea(props) {
	const { inputID, inputLabel, inputName, inputValue, isDisabled, onHandleChange, dataTestID, isRequired, textPlaceholder, max, showLabel, isError, errorMessage } = props;

	const { t } = useTranslation();
	const [ showError, setShowError ] = useState(isError && inputValue !== undefined);

	useEffect(
		() => {
			if (isError && inputValue !== undefined) {
				setShowError(true);
			} else {
				setShowError(false);
			}
		},
		[ inputValue, isError ]
	);

	return (
		<div className={`textarea-wrapper ${isDisabled ? 'isDisabled' : ''}`}>
			<label className={`textarea-label${ showLabel ? '' : ' sr-only'}`} htmlFor={inputID}>
				{inputLabel}{isRequired ? <sup className="required"> *</sup> : ''}
			</label>
			<textarea
				className={`textarea-control${showError ? ' textarea-error' : ''}`}
				name={inputName}
				data-testid={dataTestID}
				id={inputID}
				rows={5}
				onChange={onHandleChange}
				value={inputValue}
				autoComplete="off"
				disabled={isDisabled}
				placeholder={textPlaceholder}
				maxLength={max}
			/>
			{showError ? <span className="error">{errorMessage ? errorMessage : t('Empty')}</span> : null}
		</div>
	);
}
