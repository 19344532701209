const CREATETOKEN = 'CREATETOKEN';
const CREATEREFRESHTOKEN = 'CREATEREFRESHTOKEN';
const DELETETOKEN = 'DELETETOKEN';
const DELETEALLCOOKIES = 'DELETEALLCOOKIES';

let defaultConst = {
	CREATETOKEN,
	DELETETOKEN,
	CREATEREFRESHTOKEN,
	DELETEALLCOOKIES,
};

export default defaultConst;
export const createToken = (token) => ({ type: CREATETOKEN, token: token });
export const createRefreshToken = (refreshToken) => ({ type: CREATEREFRESHTOKEN, refreshToken: refreshToken });
export const deleteToken = () => ({ type: DELETETOKEN, token: '' });
export const deleteAllCookies = () => ({ type: DELETEALLCOOKIES });