import React, { useEffect, useRef, useState } from 'react';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { startRequest } from '../../../actions/apiCall';
import { GetSessionStorage } from '../../../common/utils/storageManager';
import { Button, Combo, Input } from '../../../components/FormComponents';
import HeaderPages from '../../../shared/HeaderPages';
import { deleteAuditorCompaniesParams, getAuditorCompaniesParams, postAuditorCompaniesParams, putAuditorCompaniesParams, uploadAuditorCompanyAgreementParamsMedia } from './AuditorCompaniesCalls/AuditorCompaniesCalls';
import './AuditorCompanies.scss';
import AuditorCompaniesModal from './AuditorCompaniesModal';
import { showFixedLabel } from '../../../actions/fixedLabel';
import { closeModal, openModal } from '../../../actions/popup';
import PopUpGenericDelete from '../../../components/PopUpGenericDelete/PopUpGenericDelete';

const AuditorCompanies = () => {

    const { t } = useTranslation();
    const pageSettings = { pageSize: 10 };
	const grid = useRef(null);
    const dispatch = useDispatch();

    const [ search, setSearch ] = useState('');
    const [showExportExcelBtn, setShowExportExcelBtn] = useState(false)
    const [userLogedName, setUserLogedName] = useState('')
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
	const [isCompany, setIsCompany] = useState(false)
	const [isAuditor, setIsAuditor] = useState(false)
	const [firstCall, setFirstCall] = useState(true)
    const [auditorCompanies, setAuditorCompanies] = useState([]);
    const [allAuditorCompanies, setAllAuditorCompanies] = useState([]);

    useEffect(() => {
		getUserLoged()
	}, []);

	const getUserLoged = () =>{
		const storage = GetSessionStorage('PROFILE_')
		setUserLogedName(storage.name)
		if(storage) {
            storage.userRoles.map(el => {
                if(el.name === 'Admin') {
					setIsSuperAdmin(true)
					setIsCompany(false)
					setIsAuditor(false)
				} 
				if(el.name === 'Company') {
					setIsCompany(true)
					setIsSuperAdmin(false)
					setIsAuditor(false)
				}
				if(el.name === 'Auditor'){
					setIsAuditor(true)
					setIsCompany(false)
					setIsSuperAdmin(false)
				}
            })
        }

        getAuditorCompanies();
	}

    const getAuditorCompanies = () => {
		dispatch(startRequest(getAuditorCompaniesParams(getAuditorCompaniesParamsSuccess)));
	};

	const getAuditorCompaniesParamsSuccess = (response) => {
        let options = [];
        response.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });

		setAuditorCompanies(response);
        setAllAuditorCompanies(response);
		setFirstCall(false);
	};

    const resetFilters = () => {
		setAuditorCompanies(allAuditorCompanies);
		setSearch('');
	}

    const filterAuditorCompanies = () => {
		if (search === '') {
			setAuditorCompanies(allAuditorCompanies);
		}
		const newData = GetDataBySearch(allAuditorCompanies, search);
		if (newData === '') {
			setAuditorCompanies(allAuditorCompanies);
		}
		setAuditorCompanies(newData);
	};

    const GetDataBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				element.name.toLowerCase().includes(searching.toLowerCase())				
			);
		});
		return result;
	};

    const handleOnSubmit = (action, data) => {
        if (action === 'edit') {
            dispatch(startRequest(putAuditorCompaniesParams(data, auditorCompaniesParamsSuccess, auditorCompaniesParamsError(action)))); 
        }
        if (action === 'add') {
            dispatch(startRequest(postAuditorCompaniesParams(data, auditorCompaniesParamsSuccess, auditorCompaniesParamsError(action))));
        }
    }

    const auditorCompaniesParamsSuccess = (action) => {
        dispatch(showFixedLabel(action === 'edit' ? 'Empresa auditora creada correctamente' : 'Empresa auditora editada correctamente', 'success'));
        getAuditorCompanies();
    }

    const auditorCompaniesParamsError = (response) => {
        dispatch(showFixedLabel('Ha surgido un error no se ha podido guardar', 'error'));
    }

    const auditorCompanyModalPopUp = (auditorCompany, action) => {
        const optionValues = {
            id: 'auditorCompanyModal',
            dataTestId: 'auditorCompanyModal',
            cssClass: 'modal-header__transparent',
            title: action === 'edit' ? t('EditAuditorCompany') : t('AddAuditorCompany')
        };
    
        const options = {
            ...optionValues,
            body: (
                <AuditorCompaniesModal 
					auditorCompany={auditorCompany}
					action={action}
					handleSubmit={handleOnSubmit}
					showFixedLabel={showFixedLabel}
				/>
            )
        };
        dispatch(openModal(options));
    }

    const openDeleteAuditorCompany = (action, auditorCompany) => {
		const optionValues = {
			id: 'delete-auditor-' + auditorCompany.id + '-' + Math.random(),
			dataTestId: 'delete-auditor',
			cssClass: 'modal-header__danger',
			title: t('RemoveAuditorCompany')
		};
		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					user={auditorCompany}
					deleteMessage={`¿Seguro que quieres eliminar la empresa evaluadora ${auditorCompany.name}?`}
					handleButtonDelete={() => deleteAuditorCompany(auditorCompany)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};
		dispatch(openModal(options));
	};

    const openActivateAuditorCompany = (action, auditorCompany) => {
		const optionValues = {
			id: 'delete-auditor-' + auditorCompany.id + '-' + Math.random(),
			dataTestId: 'delete-auditor',
			cssClass: 'modal-header__success',
			title: t('ActivateAuditorCompany')
		};
		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					user={auditorCompany}
					buttonDeleteName={t('ActivateAuditorCompany')}
					deleteMessage={`¿Seguro que quieres activar la empresa evaluadora ${auditorCompany.name}?`}
					handleButtonDelete={() => activateAuditorCompany(auditorCompany)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};
		dispatch(openModal(options));
	};

    const deleteAuditorCompany = (auditorCompany) => {
		dispatch(startRequest(deleteAuditorCompaniesParams(auditorCompany.id, deleteAuditorCompaniesParamsSuccess)));
	};

    const activateAuditorCompany = (auditorCompany) => {
		const newData = {
			...auditorCompany,
			isDeleted: false
		}
		
		handleOnSubmit('edit', newData);
	};

	const deleteAuditorCompaniesParamsSuccess = (response) => {
		dispatch(showFixedLabel('Empresa auditora borrada correctamente', 'success'));
		getAuditorCompanies();
	};

	const uploadFile = (e, id) => {
        const data = { 
			AuditorCompanyId: id,
			File: e.target.files[0] 
		};

		dispatch(startRequest(uploadAuditorCompanyAgreementParamsMedia(data, uploadAuditorCompanyAgreementMediaParamsSucces, uploadAuditorCompanyAgreementMediaParamsError)))        
    }

	const uploadAuditorCompanyAgreementMediaParamsSucces = (response) => {
		dispatch(showFixedLabel(t('AgreementFileUploadSuccess'), 'success'));
		getAuditorCompanies();
	}

	const uploadAuditorCompanyAgreementMediaParamsError = (response) => {
		dispatch(showFixedLabel(t('AgreementFileUploadError'), 'error'));
	}

    const documentTemplate = (rowData) => {
        const haveDocument = rowData.collaborationAgreementData.fileName === '' ? false : true;

		return (
			<div className="document-template">
				<div className="button-upload">
					<label 
						htmlFor={`btnUploadFile-${rowData.id}`}
						role={'button'}
						className={haveDocument ? 'btn-base_normal btn-base_outline' : 'btn-base_outline-primary'}
					>
						<i className="material-icons-round">{ haveDocument ? 'refresh' : 'upload'}</i>
						<span>{haveDocument ? t('ReplaceAgreement') : t('UploadAgreement')}</span> 
					
					</label>
					<input
						id={`btnUploadFile-${rowData.id}`}
						data-testid={`btnUploadFile-${rowData.id}`}
						type="file"
						onChange={(e) => {uploadFile(e, rowData.id)}}
						name={`btnUploadFile-${rowData.id}`}
						accept=".pdf"
						className="inputfile"
					/>
				</div>
				{
					haveDocument 
						? <a title={rowData.collaborationAgreementData.fileName} href={rowData.collaborationAgreementData.fileUrl} className="file-link material-icons-round" target="_blank" rel="noreferrer">
							<Button 
								dataTestID={rowData.id}
								id={rowData.id}
								btnText={ t('DownloadAgreement')}
								btnType="button"
								btnClass={ 'btn-base_outline-success' }
								onHandleBtnClick={ () => {} }
								icon={ 'download' }
							/>
						</a>
						: null
				}                
			</div>
		);
	};

    const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() => {
                        auditorCompanyModalPopUp(rowData, "edit")
                    }}
					role="button"
					type="button"
				>
					mode_edit
				</span>
				{
					rowData.isDeleted
						? <span
							className="material-icons-round"
							id={`btnDeleteStandar_${rowData.index}`}
							data-testid={`btnDeleteStandar_${rowData.index}`}
							onClick={() => {
								openActivateAuditorCompany('delete', rowData);
							}}
							role="button"
							type="button"
						>
							add
						</span>

						: <span
							className="material-icons-round"
							id={`btnDeleteStandar_${rowData.index}`}
							data-testid={`btnDeleteStandar_${rowData.index}`}
							onClick={() => {
								openDeleteAuditorCompany('delete', rowData);
							}}
							role="button"
							type="button"
						>
							delete
						</span>
				}
			</div>
		);
	};

	const statusTemplate = (rowData) => {
		return (
			<span className={`material-icons-round ${rowData.isDeleted ? 'deactivated' : 'activated' }`}>{rowData.isDeleted ? 'close' : 'check' }</span>
		)
	}

    return (
        <div className="auditor-companies">
            <HeaderPages 
                title={t('AuditorCompanies')}
                userLogedName={userLogedName}
            />

            <div className="filters-container row">
				<div className="col-lg-12">
					<div className='fields-search-wrapper'>
						<div className='form-input-group select-group'>
                            <Input
                                dataTestID="txtSearch"
                                inputName="txtSearch"
                                inputPlaceholder={t('Search')}
                                inputType="text"
                                value={search}
                                inputOutline={true}
                                icon={'search'}
                                iconLeft={true}
                                isRequired={false}
                                onHandleChange={(e) => setSearch(e.target.value)}
                                onHandleKeyUp={filterAuditorCompanies}
                            />
						</div>
					</div>
					<div className='fields-search-wrapper'>
						<div className='form-input-group btn-group'>
							<Button
								btnText={t('ResetFilters')}
								btnType="button"
								btnClass="btn-base_normal "
								id="resetFilters"
								dataTestID="resetFilters"
								onHandleBtnClick={() => resetFilters()}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='add-user-wrapper col-lg-12'>
					<Button
						btnText={t('AddAuditorCompany')}
						btnType="button"
						btnClass="btn-base_normal "
						dataTestID="addUserBtn"
						id="addUserBtn"
						onHandleBtnClick={() => {
                            auditorCompanyModalPopUp(null, 'add')
                        }}
					/>
				</div>
			</div>

            <div className='request-grid row'>
				<div className="col-md-12">
					{/* <ResponsiveGridWrapper> */}
					<div className='grid-wrapper' style={{ flex: "1", width: "100%", height: "100%" }}>
						<GridComponent
							className="gridTable"
							dataSource={auditorCompanies}
							width="100%"
							toolbar={false}
							ref={grid}
							//allowExcelExport={true}
							allowPaging={true}
							enableAdaptiveUI={true}
							allowSorting={true}
							rowRenderingMode={'Vertical'}
							pageSettings={pageSettings}
							allowResizing={true}
							locale="es"
							sortSettings={
								{
									columns:[{
										field: 'name',
										direction: 'Descending'
									}]
								}
							}
						>
							<ColumnsDirective>
								<ColumnDirective field="id" width="0" visible={false} />
								<ColumnDirective 
									field="name" 
									headerText={t('SocialName')}
									textAlign="Left"
									width="120" 
								/>
								<ColumnDirective 
									field="cif" 
									headerText='CIF' 
									textAlign="Left"
									width='120'
								/>
								<ColumnDirective 
									field="document" 
                                    template={documentTemplate}
									headerText={t('CollaborationAgreement')}
									textAlign="Left"
									width='200'
								/>
								<ColumnDirective 
									field="active" 
                                    template={statusTemplate}
									headerText={t('Status')}
									textAlign="Left"
									width='30'
								/>
								<ColumnDirective
									field="actions"
									headerText=''
									template={actionsTemplate}
									textAlign="Right"
									width="30"
								/>
							</ColumnsDirective>
							<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
						</GridComponent>
					
						{
							showExportExcelBtn ? (
								<div className="export-excel-wrapper">
									<div className="col-lg-12">
										<Button
											btnText={t('ExportExcel')}
											btnType="button"
											btnClass="btn-base_normal export-excel-button"
											onHandleBtnClick={() => {
												grid.current.excelExport();
											}}
										/>
									</div>
								</div>

							) : null
						}
					{/* </ResponsiveGridWrapper> */}
					</div>
				</div>
			</div>
        </div>
    )
}

export default AuditorCompanies;