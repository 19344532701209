const postResetPasswordParams = (data, funcSucc, funcErr) => {
    const options = {
        method: 'Post',
        data,
        endpoint: '/Account/ResetPassword',
        onSuccess: (response) => {funcSucc(response.data.data)},
        onError: (error) => {funcErr(error)},
    };
    return options;
}

export {postResetPasswordParams};