import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../actions/popup';
import { Button } from '../../../../components/FormComponents';

const ImprovementPlansModal = ({handleSubmit}) => {

    const { t } = useTranslation();
	const dispatch = useDispatch();

    return (
        <div className='auditor-edit-modal'>
            <form className='form-auditor-edit'>
                <div className='row'>
                    <div>{t('SendImprovementPlanValidate')}</div>
                </div>
                <div className='btn-submit-container row mt-4'>
                    <div className={`btn-submit-wrapper col-lg-12 d-flex`}>
                        <div className='d-flex justify-content-end'>
                            <Button
                                dataTestID="btnCancel"
                                btnText={t('Cancel')}
                                btnType="button"
                                btnClass="btn-base_outline ml-5"
                                onHandleBtnClick={() => {
                                    dispatch(closeModal());
                                }}
                            />

                            <Button
                                btnText={t('Send')}
                                btnType="submit"
                                isDisabled={false}
                                btnClass="btn-base_normal ml-3"
                                dataTestID="btnSendImprovementPlan"
                                id='btnSendImprovementPlan'
                                onHandleBtnClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ImprovementPlansModal