import React from 'react'
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../../../components/FormComponents';
import './RequestVisitEvaluator.scss';

const RequestVisitEvaluator = ({userRol, formValues, disableDataBlocks, removeSecondVisitDate, setAddSecondVisitDate, addSecondVisitDate, setFormValues}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='title-wrapper'>
                <h2 className='title'>{t('EvaluationVisitDate')}</h2>
            </div>
            <div className='content-wrapper'>
                <div className="flex-container">
                    <div className='fields-wrapper date'>
                        <div className='row-wrapper'>
                            <div className='date-container'>
                                <label className='input-label '>{`1a ${t('Visit')}`}</label>
                                {
                                    userRol === 'Auditor' ? (
                                        <div className='date-wrapper '>
                                            {
                                                !disableDataBlocks ? (
                                                    <DateTimePickerComponent
                                                        locale='es'
                                                        showTodayButton={false}
                                                        //showClearButton={false}
                                                        allowEdit={true}
                                                        readOnly={false}
                                                        isRequired={true}
                                                        value={formValues.FirstVisitingDate}
                                                        isDisabled={false}
                                                        placeholder={`1 ${t('Visit')}`}
                                                        onChange={(e) => setFormValues({...formValues, FirstVisitingDate: e.target.value})}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    ) : (
                                        <div className='date-wrapper '>
                                            <div className='ghost-input'>{formValues.FirstVisitingDate ? formValues.FirstVisitingDate.toLocaleString() : ''}</div>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                formValues.SecondVisitingDate === null ?
                                    !addSecondVisitDate ? (
                                        <div className='btn-wrapper add'>
                                            <Button
                                                btnText={t('Add')}
                                                btnType="button"
                                                btnClass="btn-base_normal"
                                                dataTestID="addDateBtn"
                                                isDisabled={disableDataBlocks || userRol !== 'Auditor' || formValues.FirstVisitingDate === null}
                                                id="AddEvaluationDateBtn"
                                                onHandleBtnClick={(e) => setAddSecondVisitDate(true)}
                                                icon="add"
                                            />
                                        </div>

                                    ) : null
                                : null
                            }
                            {
                                formValues.SecondVisitingDate || addSecondVisitDate  ? (
                                    <div className='btn-wrapper add'>
                                        <Button
                                            btnText={t('Delete')}
                                            btnType="button"
                                            btnClass="btn-base_normal"
                                            dataTestID="deleteDateBtn"
                                            id="DeleteEvaluationDateBtn"
                                            isDisabled={disableDataBlocks || userRol !== 'Auditor'}
                                            onHandleBtnClick={(e) => removeSecondVisitDate(e)}
                                            //icon="remove"
                                        />
                                    </div>
                                ) :  null
                            }
                        </div>
                        {
                            formValues.SecondVisitingDate || addSecondVisitDate  ? (
                                <div className='row-wrapper'>                                    
                                    <div className='date-container'>                                    
                                        <label className='input-label '>{`2a ${t('Visit')}`}</label>
                                        {
                                            userRol === 'Auditor' ? (
                                                <div className='date-wrapper '>
                                                    <DateTimePickerComponent
                                                        locale='es'
                                                        showTodayButton={false}
                                                        allowEdit={true}
                                                        readOnly={false}
                                                        isRequired={true}
                                                        value={formValues.SecondVisitingDate}
                                                        disabled={false}
                                                        placeholder={`2 ${t('Visit')}`}
                                                        onChange={(e) => setFormValues({...formValues, SecondVisitingDate: e.target.value})}
                                                        min={formValues.FirstVisitingDate}
                                                    />
                                                </div>

                                            ) : (
                                                <div className='date-wrapper '>
                                                    <div className='ghost-input'>{formValues.SecondVisitingDate ? formValues.SecondVisitingDate.toLocaleString() : null}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                    {
                        userRol !== 'Auditor' &&
                            <div className='row-wrapper evaluator-container'>
                                <div className='evaluator-wrapper'>
                                    <Input
                                        inputName="evaluatorsNumber"
                                        inputType="number"
                                        inputLabel={t('EvaluatorsNumber')}
                                        inputID="evaluatorsNumber"
                                        isDisabled={true}
                                        showLabel={true}
                                        inputPlaceholder={t('EvaluatorsNumber')}
                                        onHandleChange={() => {}}
                                        value={formValues.EvaluatorsNumber}
                                        readOnly={true}
                                        icon="person"
                                    />                       

                                </div>
                            </div>
                    }
                </div>
                
            </div>
        </>
    )
}

export default RequestVisitEvaluator;