import error from '../../actions/error';
import ERROR_CONSTANTS from '../../locales/global/errors';

/* Redux state init */
const initialState = {
	errorPageNumber: 0,
	errorTitle: '',
	errorMsg: ''
};

export default function errorReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case error.CHANGEERRORPAGENUMBER:
				let newTitle, newMessage;
				switch (action.errorPageNumber) {
					case 500:
						newTitle = ERROR_CONSTANTS.Error500Title;
						newMessage = ERROR_CONSTANTS.Error500Message;
						break;
					case 404:
						newTitle = ERROR_CONSTANTS.Error404Title;
						newMessage = ERROR_CONSTANTS.Error404Message;
						break;

					case 403:
						newTitle = ERROR_CONSTANTS.Error403Title;
						newMessage = ERROR_CONSTANTS.Error403Message;
						break;

					case 401:
						newTitle = ERROR_CONSTANTS.Error401Title;
						newMessage = ERROR_CONSTANTS.Error401Message;
						break;

					default:
						newTitle = '';
						newMessage = '';
						break;
				}

				return {
					...state,
					errorPageNumber: action.errorPageNumber,
					errorTitle: newTitle,
					errorMsg: newMessage
				};
			default:
				return { ...state };
		}
	}
	return state;
}
