import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/FormComponents';

const RequestBudget = ({userRol, formValues, filesData, colorStates, disableDataBlocks, uploadFile, handleAuthorizeBudgetByCompany, handleRejectBudgetByCompany}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='title-wrapper'>
                <h2 className='title'>{t('Budget')}</h2>
                <div className='state'>
                    <span
                        className='material-icons-round'
                        style={{
                            color: colorStates(formValues.BudgetState)
                        }}
                        aria-hidden={true}
                    >request_page</span>
                    <span>{formValues.BudgetState}</span>
                </div>
                
            </div>
            <div className='content-wrapper'>
                <div className='data'>
                    <div className='label'>{t('Date')} / {t('Year')}</div>
                    <div className='data'>{formValues.BudgetDate ? formValues.BudgetDate.getFullYear() : '-'}</div>
                </div>
                <div className='actions'>
                    <div className="upload-wrapper">
                        {
                            userRol !== null && userRol === 'Admin' ? (
                                <div className="button-upload">
                                    {
                                        formValues.BudgetFileLink || filesData.budgetMedia.url !== '' ? (
                                            <div className='file-wrapper'>
                                                <a title={formValues.BudgetFileName } href={formValues.BudgetFileLink ? formValues.BudgetFileLink : filesData.budgetMedia.url} className="file-link material-icons-round a" target="_blank" rel="noreferrer">file_download</a>
                                            </div>
                                        ) : null
                                    }
                                    <input
                                        id="btnRequestBudgetFile"
                                        data-testid="btnRequestBudgetFile"
                                        type="file"
                                        onChange={(e) => uploadFile(e, 'requestBudgetFile')}
                                        name="btnRequestBudgetFile"
                                        accept=".pdf, .doc, .docx, image/png, image/jpeg"
                                        className="inputfile"
                                        disabled={formValues.RequestState === 'Rechazado' ? true : false}
                                    />
                                    {
                                        formValues.BudgetState !== 'Aceptado' ? (
                                            <label
                                                htmlFor="btnRequestBudgetFile"
                                                role={'button'}
                                                className={`btn-base_normal ${formValues.BudgetFileName ? 'btn-base_outline' : null}`}
                                                >
                                                {
                                                    !formValues.BudgetFileName ? (
                                                        <i className="material-icons-round">file_upload</i>
                                                        ) : <i className="material-icons-round">refresh</i>
                                                    }

                                                <span>{!formValues.BudgetFileName ? `${t('Importar')}` : 'Reemplazar'}</span>
                                            </label>

                                        ) : null
                                    }
                                    <span className='file-name'>{formValues.BudgetFileName}</span>
                                </div>

                            ) : userRol !== null && userRol === 'Company' ? (
                                <>
                                    {
                                        formValues.BudgetFileLink ? (
                                            <div className="button-upload">
                                                <div className='file-wrapper'>
                                                    <span className='file-name'>{formValues.BudgetFileName}</span>
                                                    <a title={formValues.BudgetFileName} href={formValues.BudgetFileLink} className="material-icons-round file-link b" target="_blank" rel="noreferrer">file_download</a>
                                                </div>
                                            </div>

                                        ): null
                                    }                                          
                                    <div className='content-wrapper'>
                                        <div className='actions btn-group'>
                                            <div className='btn-wrapper'>
                                                <Button
                                                    btnText={t('Authorize')}
                                                    btnType="button"
                                                    btnClass="btn-base_normal "
                                                    dataTestID="AuthorizeBudgetByCompanyBtn"
                                                    id="AuthorizeBudgetByCompanyBtn"
                                                    onHandleBtnClick={(e) => handleAuthorizeBudgetByCompany(e)}
                                                    isDisabled={disableDataBlocks || formValues.BudgetFileLink === '' || formValues.BudgetState === 'Aceptado'}
                                                />
                                                <Button
                                                    btnText={t('Decline')}
                                                    btnType="button"
                                                    btnClass="btn-base_outline"
                                                    dataTestID="DeclineBudgetByCompanyBtn"
                                                    id="DeclineBudgetByCompanyBtn"
                                                    onHandleBtnClick={(e) => handleRejectBudgetByCompany(e)}
                                                    isDisabled={disableDataBlocks || formValues.BudgetFileLink === '' || formValues.BudgetState === 'Aceptado'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestBudget;