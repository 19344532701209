import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { parseTime } from '../../../common/utils/parseUtils';
import './Input.scss';

export default function Input(props) {
	const {
		dataTestID,
		inputID,
		inputName,
		inputOutline,
		inputType,
		inputLabel,
		value,
		autocomplete,
		isDisabled,
		isReadOnly,
		isHidden,
		isError,
		icon,
		iconLeft,
		errorMessage,
		inputMaxLength,
		onHandleChange,
		onHandleMouseDown,
		onHandleBlur,
		onHandleMouseUp,
		isRequired,
		minTime,
		maxTime,
		inputStep,
		onHandleKeyUp,
		onHandleClick,
		onHandleKeyPress,
		inputPlaceholder,
		helperText,
		inputDescribed,
		smallRef,
		pattern,
		inputmode,
		showLabel,
		avoidCharacters,
		avoidPoint,
		floatingLabel,
		showFloatingLabel
	} = props;

	const { t } = useTranslation();

	const [ showError, setShowError ] = useState(isError && value !== undefined);

	useEffect(
		() => {
			if (isError && value !== undefined) {
				setShowError(true);
			} else {
				setShowError(false);
			}
		},
		[ value, isError ]
	);

	return (
		<div className="input-container">
			<label className={showLabel ? 'inputLabel' : 'inputLabel sr-only'} htmlFor={inputID} >
				{inputLabel}
				{isRequired ? <sup className="required"> *</sup> : ''}
				{smallRef}
			</label>
			{inputType === 'time' ? (
				<TimePickerComponent
					format={'HH:mm'}
					step={inputStep}
					value={value}
					min={new Date('1/1/2021 ' + minTime)}
					max={new Date('1/1/2021 ' + maxTime)}
					showClearButton={false}
					onChange={(e) => {
						let ob = { ...e, currentTarget: e.target };
						ob.currentTarget.value = parseTime(e.target.value);
						onHandleChange(ob);
					}}
					disabled={isDisabled}
					required={isRequired}
					openOnFocus={true}
				/>
			) : (
				<Fragment>
					<input
						className={`input-control ${inputOutline ? 'input-outline ' : ''}${iconLeft
							? 'left'
							: ''}${showError ? 'input-error' : ''}`}
						id={inputID}
						data-testid={dataTestID}
						type={inputType}
						name={inputName}
						onKeyPress={onHandleKeyPress}
						maxLength={inputMaxLength ? inputMaxLength : 100}
						onChange={onHandleChange}
						onBlur={onHandleBlur} 
						value={value}
						pattern={pattern}
						inputMode={inputmode}
						autoComplete={autocomplete ? 'off' : ''}
						readOnly={isReadOnly}
						disabled={isDisabled}
						required={isRequired}
						hidden={isHidden}
						min={minTime}
						step={inputStep}
						max={maxTime}
						onKeyUp={onHandleKeyUp}
						// onKeyDown={
						// 	inputType === 'number' ? (
						// 		(e) => (e.key === 'e' || e.key === '.' || e.key === '-') && e.preventDefault()
						// 	) : null
						// }
						onKeyDown={
							avoidPoint ? (
								(e) => (e.key === 'e' || e.key === '.' || e.key === ',')  && e.preventDefault()
							) : null
						}
						placeholder={`${inputPlaceholder} ${isRequired ? '*' : ''}`}
						aria-describedby={helperText && inputDescribed}
					/>
					{
						floatingLabel && <span className={`floating-label${ showFloatingLabel ? ' floating-disabled' : ''}`}>
							{floatingLabel}
							{isRequired ? <sup className="required">*</sup> : ''}
						</span>
					}
					{
						helperText && !showError && (
							<div id={inputDescribed} className="form-text">
								{helperText}
							</div>
						)
					}
				</Fragment>
			)}
			{icon ? (
				<span
					className={`material-icons ${iconLeft ? 'left' : 'right'} ${onHandleMouseDown
						? 'pointer'
						: onHandleClick ? 'pointer' : ''}`}
					onMouseDown={onHandleMouseDown}
					onMouseUp={onHandleMouseUp}
					onClick={onHandleClick}
					aria-hidden={true}
				>
					{icon}
				</span>
			) : null}
			{showError ? <span className="error">{errorMessage ? errorMessage : t('Empty')}</span> : null}
		</div>
	);
}
