import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../../../components/FormComponents';

const RequestApplication = ({
    userRol, 
    formValues,
    handleOnClick,
    colorStates,
    handleAuthorizeRequest,
    handleRejectRequest,
    numberEmployees,
    setNumberEmployees,
    revenueVolumen,
    setRevenueVolumen,
    handleAuthorizeRenewal,
    handleRejectRenewal
}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='request-wrapper'>
                <div className='title-wrapper'>
                    <h2 className='title'>{formValues.requestType === 2 ? t('RenewalRequest') : t(formValues.requestTypeValue)}</h2>
                    <div className='state'>
                        <span
                            className='material-icons-round'
                            style={{
                                color: colorStates(formValues.RequestState)
                            }}
                            aria-hidden={true}
                        >request_page</span>
                        <span>{formValues.RequestState}</span>
                    </div>

                </div>
                {
                    userRol === 'Admin' && formValues.requestType !== 1 ? (
                        <div
                            className='actions'
                            role='button'
                            onClick={handleOnClick}
                        >
                            <span className='material-icons-round eye' aria-hidden={true}>visibility</span>
                        </div>
                    ) : null
                }
            </div>
            {
                userRol === 'Admin' && formValues.requestType === 0 ? (
                    <div className='content-wrapper'>
                        <div className='actions btn-group'>
                            <div className='btn-wrapper'>
                                {
                                    formValues.RequestState !== 'Rechazado' ? (
                                        <>
                                            <Button
                                                btnText={t('Authorize')}
                                                btnType="button"
                                                btnClass="btn-base_normal "
                                                dataTestID="AuthorizeRequestBtn"
                                                id="AuthorizeRequestBtn"
                                                onHandleBtnClick={(e) => handleAuthorizeRequest(e)}
                                                isDisabled={formValues.RequestState === "Aprobado" ? true : false}
                                            />
                                            <Button
                                                btnText={t('Decline')}
                                                btnType="button"
                                                btnClass="btn-base_outline"
                                                dataTestID="DeclineRequestBtn"
                                                id="DeclineRequestBtn"
                                                onHandleBtnClick={(e) => handleRejectRequest(e)}
                                                isDisabled={formValues.RequestState === "Aprobado" ? true : false}
                                            />
                                        </>
                                    ) : null
                                }
                            </div>

                        </div>
                    </div>
                ) : userRol === 'Company' && formValues.requestType === 2 && formValues.RequestState === 'Pendiente'
                    || userRol === 'Admin' && formValues.RequestState === 'Aprobado'
                    ? <div className='content-wrapper'>
                        <div className='flex-container flex-row actions-renewal-application'>
                            <div className="input-group">
                                <Input 
                                    dataTestID="txtNumberEmployees"
                                    inputName="txtNumberEmployees"
                                    inputType="number"
                                    inputLabel={t('NumberEmployees')}
                                    inputID="numberEmployees"
                                    onHandleChange={(e) => setNumberEmployees(e.target.value)}
                                    value={numberEmployees}
                                    isRequired={true}
                                    inputPlaceholder={t('NumberEmployees')}
                                    floatingLabel={t('NumberEmployees')}
                                    isDisabled={userRol === 'Company' && formValues.RequestState !== 'Rechazado' ? false : true}
                                    showFloatingLabel={userRol === 'Company' && formValues.RequestState !== 'Rechazado' ? false : true}
                                    avoidPoint={true}
                                />
                            </div>
                            <div className="input-group">
                                <Input 
                                    dataTestID="txtRevenueVolumen"
                                    inputName="txtRevenueVolumen"
                                    inputType="number"
                                    inputLabel={t('RevenueVolumen')}
                                    inputID="revenueVolumen"
                                    onHandleChange={(e) => setRevenueVolumen(e.target.value)}
                                    value={revenueVolumen}
                                    //isError={tradenameError}
                                    //errorMessage={t('IsRequired')}
                                    isRequired={true}
                                    inputPlaceholder={t('RevenueVolumen')}
                                    floatingLabel={t('RevenueVolumen')}
                                    isDisabled={userRol === 'Company' && formValues.RequestState !== 'Rechazado' ? false : true}
                                    showFloatingLabel={userRol === 'Company' && formValues.RequestState !== 'Rechazado' ? false : true}
                                    avoidPoint={true}
                                />
                            </div>
                        </div>
                        <div className='actions btn-group'>
                            <div className='btn-wrapper'>
                                {
                                    userRol === 'Company' && formValues.RequestState !== 'Rechazado' ? (
                                        <>
                                            <Button
                                                btnText={t('Authorize')}
                                                btnType="button"
                                                btnClass="btn-base_normal "
                                                dataTestID="AuthorizeRequestBtn"
                                                id="AuthorizeRequestBtn"
                                                onHandleBtnClick={(e) => handleAuthorizeRenewal(e)}
                                                isDisabled={formValues.RequestState === "Aprobado" ? true : false}
                                            />
                                            <Button
                                                btnText={t('Decline')}
                                                btnType="button"
                                                btnClass="btn-base_outline"
                                                dataTestID="DeclineRequestBtn"
                                                id="DeclineRequestBtn"
                                                onHandleBtnClick={(e) => handleRejectRenewal(e)}
                                                isDisabled={formValues.RequestState === "Aprobado" ? true : false}
                                            />
                                        </>
                                    ) : null
                                }
                            </div>

                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default RequestApplication;