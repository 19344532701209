import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../../actions/popup';
import { startRequest } from '@backoffice/actions/apiCall';
import Button from '../../../../components/FormComponents/Button';
import Textarea from '../../../../components/FormComponents/Textarea';
import { postSaveAutoevaluationData } from '../SelfAssessmentCalls/SelfAssessmentCalls';
import './SelfAssessmentDescriptionModal.scss';

export default function SelfAssessmentDescriptionModal({
    e,
    selfAssessment,
    question,
    indexSecondLevel,
    indexThirdLevel,
    indexFourthLevel,
    indexFiveLevel,
    description,
    handleSubmit,
    userRoll,
    isCompleted
}) {
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const onHandleClickDescription = (e, action) => {
        e.preventDefault()
        selfAssessment.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].questionDescription = questionDescription
        //console.log(selfAssessment);
        //call endpoint to save
        dispatch(startRequest(postSaveAutoevaluationData(selfAssessment, postSaveAutoevaluationDataSuccess, postSaveAutoevaluationDataError)))

    }

    const postSaveAutoevaluationDataSuccess = (data) => {
        //console.log(data);
        dispatch(closeModal())
        handleSubmit()
    }
    const postSaveAutoevaluationDataError = (error) => {
        //console.log(error);
    }

    const [questionDescription, setQuestionDescription] = useState(description)
   
    return(
        <div className='selfAssessment-edit-modal'>
            <form className='form-user-edit'>
                <div className='form-input-group input-group'>
                    <div className="form-wrapper">
                        <Textarea
                            inputLabel={'questionModalDescription'}
                            id="questionModalDescription"
                            className="textarea-description"
                            inputValue={questionDescription}
                            onHandleChange={(e) => setQuestionDescription(e.target.value)}
                            isDisabled={userRoll !== 'Auditor' || isCompleted}
                        />
                    </div>
                    <div className='btn-wrapper'>
                        <div className='save-wrapper'>
                            <Button
                                btnText={t('Save')}
                                btnType="button"
                                btnClass="btn-base_normal "
                                dataTestID="SaveDescriptionBtn"
                                id="SaveDescriptionBtn"
                                //onHandleBtnClick={(e) => handlePostSelfAssessment('fromModal')}
                                onHandleBtnClick={(e) => onHandleClickDescription(e)}
                                isDisabled={isCompleted}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}








