import i18next from 'i18next';
import moment from 'moment';

const isSameValue = (firstValue, secondValue) => {
    if (firstValue !== secondValue) {
        return {
            error: true,
            errorMessage: i18next.t('formValidation.notSameValue'),
        };
    }
    return {
        error: false,
    };
};

const isNumeric = (value) => {
    if (isNaN(value)) {
        return {
            error: true,
            errorMessage: i18next.t('formValidation.numericField'),
        };
    }
    return {
        error: false,
    };
};

const isMin1Value = (value) => {
    if (value !== '' && value !== '0' && value !== 0) {
        return {
            error: false,
        };
    }
    return {
        error: true,
        errorMessage: i18next.t('formValidation.minNumber'),
    };
};

const isCorrectPassword = (value) => {
    //TODO: password validation with special character
    // const expr =
    //     /^(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]))(?=(?:.*\d))(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>]){1,})(?!.*(.)\1{2})([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>]{8,20})$/;

    const expr = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,20})\S$/;

    if (!expr.test(value)) {
        return {
            error: true,
            //errorMessage: i18next.t('formValidation.passwordFormat'),
            errorMessage: i18next.t('PasswordMandatory')
        };
    }
    return {
        error: false,
    };
};

const isDate = (value) => {
    if (!moment(value, 'YYYY-MM-DD[T]HH:mm:ss', true).isValid()) {
        return {
            error: true,
            errorMessage: i18next.t('formValidation.isNotValidDate'),
        };
    }
    return {
        error: false,
    };
};

const isRequired = (value) => {
    if (!value) {
        return {
            error: true,
            errorMessage: i18next.t('formValidation.isRequired'),
        };
    }
    return {
        error: false,
    };
};

const isInRange = (value, minNumber, maxNumber) => {
    try {
        const number = parseInt(value);
        if (number < minNumber || number > maxNumber) {
            return {
                error: true,
                errorMessage: i18next.t('formValidation.outOfRange'),
            };
        }
        return {
            error: false,
        };
    } catch (error) {
        return {
            error: true,
            errorMessage: i18next.t('formValidation.numericField'),
        };
    }
};

const isInTextLength = (value, maxLength) => {
    const valueLength = `${value}`.length;
    if (valueLength > maxLength) {
        return {
            error: true,
            errorMessage: `${i18next.t(
                'formValidation.maxLength'
            )} ${maxLength}`,
        };
    } else {
        return {
            error: false,
        };
    }
};

const regEx = (value, regExParams) => {
    const params = new RegExp(regExParams);
    if (params.test(value)) {
        return { error: false };
    } else {
        return {
            error: true,
            errorMessage: `${i18next.t('formValidation.regEx')} ${regExParams}`,
        };
    }
};

const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(email).toLowerCase())) {
        return { error: false };
    } else {
        return {
            error: true,
            //errorMessage: `${i18next.t('formValidation.emailFormat')}`,

            errorMessage: `${i18next.t('EmailFormatNotValid')}`
        };
    }
};

const validateDNI = (dni) => {
    let numero, lett, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
        numero = dni.substr(0, dni.length - 1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        lett = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero + 1);
        if (letra !== lett) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

const testPassword = (password) => {
    if (
        password.match(/[a-z]/g) &&
        password.match(/[A-Z]/g) &&
        password.match(/[0-9]/g) &&
        password.match(/[^a-zA-Z\d]/g) &&
        password.length >= 8
    ) {
        return true;
    } else {
        return false;
    }
};

const validateLicensePlate = (value) => {
    return (
        validateCurrentVehicleLicence(value) ||
        validateOldVehicleLicence(value) ||
        validateCurrentRemolqueLicence(value) ||
        validateOldRemolqueLicence(value) ||
        validateCurrentMotorcycleLicence(value)
    );
};

const validateCurrentVehicleLicence = (value) => {
    if (value === '') return false;
    var reg = /^(\d{4})([A-Z]{3})$/;

    var valid = reg.test(value);
    return valid;
};

const validateOldVehicleLicence = (value) => {
    if (value === '') return false;
    var reg = /^([A-Z]{1,2})(\d{4})([A-Z]{0,2})$/;

    var valid = reg.test(value);
    return valid;
};

const validateCurrentRemolqueLicence = (value) => {
    if (value === '') return false;
    var reg = /^R(\d{4})([A-Z]{3})$/;

    var valid = reg.test(value);
    return valid;
};

const validateOldRemolqueLicence = (value) => {
    if (value === '') return false;
    var reg = /^([A-Z]{1,2})(\d{5,6})VE$/;

    var valid = reg.test(value);
    return valid;
};

const validateCurrentMotorcycleLicence = (value) => {
    if (value === '') return false;
    var reg = /^C(\d{4})([A-Z]{3})$/;

    var valid = reg.test(value);
    return valid;
};

const isValidCif = (value) => {
    /*
     * CIF: 9 dig, O P P N N N N N C
     * O: Tipo de Organización  ; P: Código provincia  ; N: Número correlativo por provincia ; C: Dígito o letra de control
     */
    const regExp = /^[ABCDEFGHJNPQRSUVW]\d\d\d\d\d\d\d[0-9,A-J]$/g;
    const cif = value.toUpperCase();

    if (value === '' || value.length < 9 || !regExp.test(cif)) {
        return false;
    }
    let isValid = false;
    let pairs = 0;
    let odd = 0;
    let controlDigit;
    let numOrLetter;
    /*
     * ODD num: we must multiply them by two and add the digits
     * e.g.: (5 x 2 = 10; 1 + 0 = 1)
     */
    const calcResult = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    const lastLetter = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

    controlDigit = cif.substr(8, 1);

    for (var cont = 2; cont <= 6; cont += 2) {
        pairs += parseInt(cif.substr(cont, 1));
        odd += calcResult[parseInt(cif.substr(cont - 1, 1))];
    }
    odd += calcResult[parseInt(cif.substr(cont - 1, 1))];
    numOrLetter = 10 - ((pairs + odd) % 10);

    if (numOrLetter === 10) {
        numOrLetter = 0;
    }

    isValid =
        controlDigit === numOrLetter.toString() ||
        controlDigit === lastLetter[numOrLetter];

    return isValid;
};

function validateDniNieNif(value) {
    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var str = value.toString().toUpperCase();
  
    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;
  
    var nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');
  
    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;
  
    if (validChars.charAt(charIndex) === letter) return true;
  
    return false;
  }

export {
    isSameValue,
    isNumeric,
    isMin1Value,
    isCorrectPassword,
    isDate,
    isRequired,
    isInRange,
    isInTextLength,
    regEx,
    validateDNI,
    validateEmail,
    validateLicensePlate,
    testPassword,
    isValidCif,
    validateDniNieNif
};