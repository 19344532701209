const postLoginParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		endpoint: '/Account/LoginWithData',
		data,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { postLoginParams };
