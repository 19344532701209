import {
    isSameValue,
    isNumeric,
    isMin1Value,
    isCorrectPassword,
    isDate,
    isRequired,
    isInRange,
    isInTextLength,
    regEx,
    validateEmail,
    isValidCif,
    validateDniNieNif, 
} from './common';

const validationsDictionary = [];
validationsDictionary["isSameValue"] = isSameValue;
validationsDictionary["isNumeric"] = isNumeric;
validationsDictionary["isMin1Value"] = isMin1Value;
validationsDictionary["isInRange"] = isInRange;
validationsDictionary["isCorrectPassword"] = isCorrectPassword;
validationsDictionary["isDate"] = isDate;
validationsDictionary["isRequired"] = isRequired;
validationsDictionary["isInTextLength"] = isInTextLength;
validationsDictionary["regEx"] = regEx;
validationsDictionary["validateEmail"] = validateEmail;
validationsDictionary["isValidCif"] = isValidCif;
validationsDictionary["validateDniNieNif"] = validateDniNieNif;

export default validationsDictionary;