const CREATETOKEN = 'CREATETOKEN';
const CREATEREFRESHTOKEN = 'CREATEREFRESHTOKEN';
const DELETETOKEN = 'DELETETOKEN';

const defaultConst = {
    CREATETOKEN,
    DELETETOKEN,
    CREATEREFRESHTOKEN,
};

export default defaultConst;
export const createToken = (token) => ({ type: CREATETOKEN, token: token });
export const createRefreshToken = (refreshToken) => ({
    type: CREATEREFRESHTOKEN,
    refreshToken: refreshToken,
});
export const deleteToken = () => ({ type: DELETETOKEN, token: '' });
