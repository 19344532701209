const checkIsPrincipalAnyCompany = (userLoged) => {
    const user = userLoged.userData.companyUserData?.companiesInfo.filter((companyInfo) => companyInfo.isPrincipalUser)

    if(user && user.length > 0) {
        return true
    } else {
        return false
    }
}

const checkIsPrincipalOneCompany = (userLoged, companyId) => {
    const user = userLoged.userData.companyUserData?.companiesInfo.filter((companyInfo) => companyInfo.companyId === companyId)

    if(user && user.length > 0) {
        if(user[0].isPrincipalUser)
        return true
    } else {
        return false
    }
}

export default checkIsPrincipalAnyCompany;
export {
    checkIsPrincipalOneCompany
}