import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createToken, createRefreshToken } from '@backoffice/actions/token';
import { changeWeekStart, changeFormat, changeLanguage } from '@backoffice/actions/language';
import { startRequest } from '@backoffice/actions/apiCall';
import { changeErrorPageNumber } from '@backoffice/actions/error';
import { updateProfile } from '@backoffice/actions/profile';
import { updateMenu } from '@backoffice/actions/menu';
import { Input, Button } from '@backoffice/components/FormComponents/index';
import { validateEmail } from '../../../../common/validations/common';
import { postLoginParams } from './LoginCalls';
import { loadLanguage } from '@backoffice/locales/SyncfusionTranslationService';
import menu from '../../../../menu.json';
import './LoginForm.scss';

const LoginForm = ({ setForgotPassword, redirect, successFn, errorFn }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ passwordHidden, setPasswordHidden ] = useState(true);
	const [ culture, setCulture ] = useState('es');
	const [ emailError, setEmailError ] = useState(false);

	useEffect(() => {
		document.title = t('LogIn');
	});

	const mySuccessLogin = (response) => {
		
		dispatch(
			updateProfile({
				name: response.userData.name,
				firstSurname: response.userData.surnames,
				secondSurname: '',
				email: response.userData.email,
				userRoles: response.userData.userRoles,
				userData: response.userData.userData
			})
		);
		dispatch(createToken(response.tokenData.accessToken));
		dispatch(createRefreshToken(response.tokenData.refreshToken));
		dispatch(changeErrorPageNumber(0));
		dispatch(updateMenu(menu));
		dispatch(updateMenu(response.menuData));
		changeCulture(response.cultureCode ? response.cultureCode : 'es');
		dispatch(createRefreshToken(response.tokenData.refreshToken));
		if (successFn) {
			successFn(response);
		} else {
			response.userData.userRoles.map(el => {
				if(el.name === 'Admin' || el.name === 'Auditor'){
					history.push('/empresas')
				} else {
					history.push('/solicitudes')
				}
			})
		}
	};

	const changeCulture = (lng) => {
		i18n.changeLanguage(lng);
		dispatch(changeLanguage(lng));
		loadLanguage();
		let newFormat = '';
		let newWeekStart = '';
		switch (lng) {
			case 'es':
				newFormat = 'DD/MM/YYYY';
				newWeekStart = 1;
				break;
			case 'en':
				newFormat = 'MM/DD/YYYY';
				newWeekStart = 0;
				break;
			case 'ca':
				newFormat = 'DD/MM/YYYY';
				newWeekStart = 1;
				break;
			default:
				newFormat = 'DD/MM/YYYY';
				break;
		}
		dispatch(changeFormat(newFormat));
		dispatch(changeWeekStart(newWeekStart));
	};

	// const defaultLink = (menuArray) => {
	// 	menuArray.forEach((menuElement) => {
	// 		if (menuElement.isDefault && redirect) {
	// 			// history.push(menuElement.link);
	// 		}
	// 	});
	// };

	const getLoginData = () => {
		const myData = {
			email,
			password,
			culture
		};
		return myData;
	};

	const submitLogin = (e) => {
		e.preventDefault();
		const data = getLoginData();
		dispatch(startRequest(postLoginParams(data, mySuccessLogin)));
	};

	const forgotPasswordHandler = () => {
		if (setForgotPassword) {
			setForgotPassword(true);
		}
	};

	return (
		<div className="login-form">
			<form
				className="form-signin"
				name="loginForm"
				title={t('LogIn')}
				aria-label={t('LogIn')}
				onSubmit={submitLogin}
			>
				<h2 className="login-title sr-only" >Iniciar Sesión</h2>
				<div className="form-wrapper">
					<Input
						dataTestID="txtLoginEmail"
						inputName="txtLoginEmail"
						inputType="text"
						inputLabel={t('Email')}
						inputID="user-loginEmail"
						isDisabled={false}
						onHandleChange={(e) => {
							let val = e.currentTarget.value;
							setEmail(val);
							setEmailError(validateEmail(val).error);
						}}
						value={email}
						isError={emailError}
						errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
						isRequired={true}
						inputPlaceholder={t('Email')}
					/>
				</div>

				<div className="form-wrapper">
					<Input
						dataTestID="txtPassword"
						inputName="txtPassword"
						inputType={passwordHidden ? 'password' : 'text'}
						inputLabel={t('Contraseña')}
						inputID="user-loginPassword"
						isDisabled={false}
						onHandleChange={(e) => setPassword(e.currentTarget.value)}
						value={password}
						icon={passwordHidden ? 'visibility' : 'visibility_off'}
						isRequired={true}
						inputPlaceholder={passwordHidden ? 'Contraseña' : ''}
						onHandleClick={() => setPasswordHidden(!passwordHidden)}
					/>
				</div>
				<div className="link-forgotPass" data-testid="lnkForgotPassword" id="lnkForgotPassword">
					<span type="button" role="button" onClick={forgotPasswordHandler}>
						{t('Recuperar contraseña')}
					</span>
				</div>
				<div className="button-container">
					<Button
						btnText="Acceder"
						btnType="submit"
						isDisabled={emailError || email === '' || password === ''}
						btnClass="btn-base_normal"
						dataTestID="btnLoginButton"
					/>
				</div>
			</form>
		</div>
	);
};

export default LoginForm;
