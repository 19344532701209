const COOKIES_CONSTANTS = {
	LANG: 'LANG_',
	TOKEN: 'TOKEN_',
	NAME: 'NAME_',
	SURNAME: 'SURNAME_',
	SURNAME2: 'SURNAME2',
	BIRTHDAY: 'BIRTHDAY_',
	DNI: 'DNI_',
	EMAIL: 'EMAIL_',
	TOWN: 'TOWN_',
	REFRESHTOKEN: 'REFRESHTOKEN_',
	CULTURE: 'CULTURE_',
	WEEKSTART: 'WEEKSTART_',
	DATEFORMAT: 'DATEFORMAT_',
	CONSENT_COOKIES: 'CookieConsent',
	CONSENT_COOKIES_ANALYTICS: 'CookieConsentAnalytics',
	CONSENT_COOKIES_MARKETING: 'CookieConsentMarketing',
	ONBOARDING: 'ONBOARDING_',
	COLORPRIMARY: 'COLORPRIMARY_',
	COLORPRIMARYVARIANT: 'COLORPRIMARYVARIANT_',
	COLORPRIMARYVARIANT2: 'COLORPRIMARYVARIANT2_',
	COLORPRIMARYHOVER: 'COLORPRIMARYHOVER_',
	COLORPRIMARYDISABLED: 'COLORPRIMARYDISABLED_',
	COLORPRIMARYPRESSED: 'COLORPRIMARYPRESSED_',
	COLORSECONDARY: 'COLORSECONDARY_',
	COLORSECONDARYVARIANT: 'COLORSECONDARYVARIANT_',
	COLORSECONDARYVARIANT2: 'COLORSECONDARYVARIANT2_',
	COLORTEXT: 'COLORTEXT_',
	COLORERROR: 'COLORERROR_',
	COLORERRORBG: 'COLORERRORBG_',
	COLORWARNING: 'COLORWARNING_',
	COLORWARNINGBG: 'COLORWARNINGBG_',
	COLORSUCCESS: 'COLORSUCCESS_',
	COLORSUCCESSBG: 'COLORSUCCESSBG_',
	COLORMAINBG: 'COLORMAINBG_'
};

export { COOKIES_CONSTANTS };
