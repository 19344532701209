import React, { useState, useEffect } from 'react';
import './Checkbox.scss';

export default function Checkbox(props) {
	const {
		dataTestID,
		inputID,
		inputName,
		inputLabel,
		inputChecked,
		isDisabled,
		onHandleChange,
		withLink,
		link,
		linkLabel,
		subLabel,
		isRequired,
		srOnlyMessage
	} = props;
	const [checked, setChecked] = useState(inputChecked);

	useEffect(
		() => {
			setChecked(inputChecked);
		},
		[inputChecked]
	);

	return (
		<div className={`checkbox ${isDisabled ? 'isDisabled' : ''}`}>
			{
				srOnlyMessage ? (
					<span aria-labelledby={inputID} className='sr-only'>{srOnlyMessage}</span>
				) : null
			}
			<input
				className="inputCheck"
				id={inputID}
				type="checkbox"
				name={inputName}
				onChange={(e) => {
					onHandleChange(e);
				}}
				checked={checked}
				autoComplete="off"
				disabled={isDisabled}
			/>
			{
				withLink ? (
					<>
						<label data-testid={dataTestID} htmlFor={inputID}>{inputLabel}</label>
						<span className='link-wrapper'> 
							{` `}<a href={link} target="_blank">{linkLabel}</a>
							<span> {subLabel}</span>
							{isRequired ? ' *' : ''}
						</span>
					</>
				) : (
					<label data-testid={dataTestID} htmlFor={inputID}>{inputLabel}</label>
				)
			}
		</div>
	);
}