import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../../actions/popup';
import { Button } from '../../../../../components/FormComponents';
import './RequestContractsUploadModal.scss';

export default function RequestContractsUploadModal({formValues, disableDataBlocks, uploadFile, isPrincipalCompany}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return(
        <div className="contract-upload-modal">
            {
                !formValues.ContractsAndAnnexesFileName
                    ? ''
                    : <>                    
                        <div><span>¿Deseas reemplazar el contrato?</span></div>
                        <div className="text-danger-contract"><span>Esto provocará que se reinicie el proceso de firma</span></div>
                    </>

            }
            <div className='btn-submit-container row mt-4'>
                <div className={`btn-submit-wrapper col-lg-12 d-flex`}>
                    <div className='d-flex justify-content-end'>
                        <Button
                            dataTestID="btnCancel"
                            btnText={t('Cancel')}
                            btnType="button"
                            btnClass="btn-base_outline ml-5"
                            onHandleBtnClick={() => {
                                dispatch(closeModal());
                            }}
                        />
                        
                        <input
                            id="btnContractsAndAnnexesFileLinkReplace"
                            data-testid="btnContractsAndAnnexesFileLinkReplace"
                            type="file"
                            onChange={(e) => uploadFile(e, 'contractsAndAnnexesFile', !formValues.ContractsAndAnnexesFileName ? false : true)}
                            name="btnContractsAndAnnexesFileLinkReplace"
                            accept=".pdf, .doc, .docx, image/png, image/jpeg, .zip"
                            className="inputfile"
                            disabled={disableDataBlocks || formValues.EvaluationState !== 'Completado' && isPrincipalCompany}
                        />
                        <label
                            htmlFor="btnContractsAndAnnexesFileLinkReplace"
                            role={'button'}
                            className={`btn-base_normal ml-3 ${formValues.ContractsAndAnnexesFileName ? 'btn-base_outline' : null}`}
                        >
                            {
                                !formValues.ContractsAndAnnexesFileName ? (
                                    <i className="material-icons-round">file_upload</i>
                                ) : <i className="material-icons-round">refresh</i>
                            }

                            <span>{!formValues.ContractsAndAnnexesFileName ? `${t('Contrato')}` : 'Reemplazar'}</span>
                        </label>
                    </div>
                </div>
            </div>
            
        </div>
    )
}