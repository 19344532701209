import React from 'react';

import './Button.scss';

export default function Button(props) {
    const {
        dataTestID,
        id,
        btnText,
        btnType,
        btnClass,
        isDisabled,
        onHandleBtnClick,
        icon
    } = props;
    return (
        <button
            type={btnType}
            id={id}
            data-testid={dataTestID}
            onClick={onHandleBtnClick}
            className={`btn-base ${btnClass}`}
            disabled={isDisabled}
        >
            {
                icon ? (
                    <span className='material-icons-round' aria-hidden={true} >{icon}</span>
                ) : null
            }
            {btnText}
        </button>
    );
}
