import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../../actions/popup';
import { Button, Input, Textarea } from '../../../../../components/FormComponents';
import './NoteCompaniesModal.scss';

export default function NoteCompaniesModal({data, companyId, handleSubmit, action, showFixedLabel}) {
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        companyId: companyId,
        notesId: data ? data.id : '',
        about: data ? data.about : '',
        date: new Date().toISOString(),
        notes: data ? data.notes : '',
    })

    const postNoteForm = (e) => {
        e.preventDefault();

        if( formValues.about === '' ) {
            dispatch(closeModal())
            dispatch(showFixedLabel(t('EmptyNameError'), 'error'))
        } else if( formValues.notes === '' ) {
            dispatch(closeModal())
            dispatch(showFixedLabel(t('EmptyNoteError'), 'error'))
        } else {
            dispatch(closeModal())
            handleSubmit(action, formValues)            
        }
    }

    return(
        <div className='note-edit-modal'>
            <form className='form-note-edit'>
                <div className='row'>
                    <div className='form-input-group input-group col-lg-12'>
                        <div className="form-wrapper">
                            <Input
                                inputID="About"
                                dataTestID="About"
                                inputLabel={t('Name')}
                                inputName="About"
                                inputType="text"
                                showLabel={true}
                                isRequired={true}
                                inputPlaceholder={t('Name')}
                                onHandleChange={(e) => setFormValues({
                                    ...formValues,
                                    about: e.target.value
                                })}
                                value={formValues.about}
                            />
                        </div>
                    </div>
                    <div className='form-input-group input-group col-lg-12'>
                        <div className="form-wrapper">
                            <Textarea
                                inputID="Note"
                                inputLabel={t('Note')}
                                inputName="Note"
                                inputValue={formValues.notes}
                                isDisabled={false}
                                dataTestID="Note"
                                isRequired={true}
                                textPlaceholder={t('Note')}
                                max={500}
                                onHandleChange={(e) => setFormValues({
                                    ...formValues,
                                    notes: e.target.value
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className='btn-submit-container row mt-4'>
                    <div className={`btn-submit-wrapper col-lg-12 d-flex`}>
                        <div className='d-flex justify-content-end'>
                            <Button
                                dataTestID="btnCancel"
                                btnText={t('Cancel')}
                                btnType="button"
                                btnClass="btn-base_outline ml-5"
                                onHandleBtnClick={() => {
                                    dispatch(closeModal());
                                }}
                            />

                            <Button
                                btnText={(action === 'edit') ? t('EditNote') : t('AddNote')}
                                btnType="submit"
                                isDisabled={false}
                                btnClass="btn-base_normal ml-3"
                                dataTestID="btnUserEditRequest"
                                id='btnUserEditRequest'
                                onHandleBtnClick={(e) => postNoteForm(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}