import React from 'react'
import './Radio.scss'

export default function Radio(props) {
    const {
        id,
        label,
        name,
        value,
        isDisabled,
        handleChecked,
        handleChange,
        dataId
    } = props;

    return (
        <div className='radio-wrapper'>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                disabled={isDisabled}
                checked={handleChecked}
                onChange={handleChange}
                itemID={dataId ? dataId : null}
            />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label}} />
               
        </div>
    )
}

