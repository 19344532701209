import popUpActions from '../../actions/popup';

/* Redux state init */
const initialState = {
	popUpCount: 0,
	popUps: []
};

export default function popupReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case popUpActions.OPEN_MODAL:
				let newPopUps = state.popUps;
				let newPopUpCount = state.popUpCount + 1;

				if (Array.isArray(newPopUps)) {
					newPopUps.forEach((popup, i) => {
						if (popup.show) {
							action.options.fatherPopup = popup.options.id;
						}
						popup.hide = true;
					});
				}

				newPopUps.push({
					show: true,
					hide: false,
					options: action.options
				});

				return {
					...state,
					popUpCount: newPopUpCount,
					popUps: newPopUps
				};

			case popUpActions.CLOSE_MODAL:
				let newPopUpsC = state.popUps;
				let newPopUpCountC = state.popUpCount === 0 ? 0 : state.popUpCount - 1;
				let found = false;

				if (Array.isArray(state.popUps)) {
					state.popUps.forEach((popup, i) => {
						if (popup.show && !popup.hide) {
							found = popup;
							newPopUpsC.splice(i, 1);
						}
					});
					state.popUps.forEach((pup, i) => {
						if (pup.options.id === found.options.fatherPopup) {
							newPopUpsC[i].hide = false;
						}
					});
				}

				return {
					...state,
					popUpCount: newPopUpCountC,
					popUps: newPopUpsC
				};
			default:
				return { ...state };
		}
	}
	return state;
}
