import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Combo, Input } from '../../../../components/FormComponents';
import './RequestSelectEvaluator.scss';

const RequestSelectEvaluator = ({userRol, formValues, disableDataBlocks, auditorsByCompanyComboOptions, auditorsByCompanyComboSelected, setAuditorsByCompanyComboSelected, addEvaluatorToList}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='content-wrapper'>
                <div className="flex-container">
                    <div className='row-wrapper auditors-selected'>
                        <Combo
                            id="auditorsByCompanyComboSelected"
                            data-testid="auditorsByCompanyComboSelected"
                            defaultInputValue=""
                            options={auditorsByCompanyComboOptions}
                            isSearchable={false}
                            isClearable={true}
                            className="comboBox"
                            handleChange={(e) => setAuditorsByCompanyComboSelected(e)}
                            inputValue={auditorsByCompanyComboSelected}
                            isMultiple={false}
                            comboPlaceholder={t('Seleccionar')}
                            isDisabled={
                                disableDataBlocks
                            }
                        />
                        <Button 
                            btnText={t('Add')}
                            btnType="button"
                            btnClass="btn-base_normal"
                            dataTestID="addAuditorsByCompanyCombo"
                            isDisabled={disableDataBlocks || userRol !== 'Auditor' || auditorsByCompanyComboSelected.length === 0}
                            id="addAuditorsByCompanyCombo"
                            onHandleBtnClick={(e) => addEvaluatorToList()}
                            icon="add"
                        />   
                    </div>
                    <div className='row-wrapper evaluator-data-container'>
                        <div className='evaluator-wrapper'>
                            <Input
                                inputName="evaluatorsNumber"
                                inputType="number"
                                inputLabel={t('EvaluatorsNumber')}
                                inputID="evaluatorsNumber"
                                isDisabled={true}
                                showLabel={true}
                                inputPlaceholder={t('EvaluatorsNumber')}
                                onHandleChange={() => {}}
                                value={formValues.EvaluatorsNumber}
                                readOnly={true}
                                icon="person"
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default RequestSelectEvaluator;