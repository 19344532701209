import loadingActions from '../../actions/loading';

/* Redux state init */
const initialState = {
  show: 0,
};

export default function dialogReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case loadingActions.SHOW_LOADING:
        const nextShowValue = state.show + 1;
        return { ...state, show: nextShowValue >= 0 ? nextShowValue : 0 };
      case loadingActions.HIDE_LOADING:
        const nextHideValue = state.show - 1;
        return { ...state, show: nextHideValue >= 0 ? nextHideValue : 0 };
      default:
        return state;
    }
  }
  return state;
}