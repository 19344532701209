import React from 'react'
import { useTranslation } from 'react-i18next';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

const RequestSeal = ({userRol, formValues, filesData, colorStates, uploadFile, disableDataBlocks, onHandleChange, isPrincipalCompany}) => {

    const { t } = useTranslation();

    const showDateLabel = () => {
        switch (formValues.requestType) {
            case 0:
                return (t('ConcessionDate'))
                break;
            case 1:
                return (t('TrackingDate'))
                break;
            case 2:
                return (t('RenewalDate'))
                break;
        
            default:
                break;
        }
    }
    
    return (        
        <>
            <div className='title-wrapper'>
                <h2 className='title'>{t('Seal')}</h2>
                <div className='state'>
                    <span
                        className='material-icons-round'
                        style={{
                            color: colorStates(formValues.SealState)
                        }}
                        aria-hidden={true}
                    >star</span>
                    <span>{formValues.SealState}</span>
                </div>
            </div>
            <div className='content-wrapper'>
                <div className='data'>
                    {
                        userRol === 'Admin'
                        ? formValues.EvaluationState === 'Completado' || formValues.EvaluationState !== 'Completado' && !isPrincipalCompany
                            ? (
                                // <div className={`fields-wrapper date mb-2${!formValues.SealConcessionDate ? '' : ' date-hide'}`}> To hide date input
                                <div className={`fields-wrapper date mb-2`}>
                                    <label className='input-label'>{t('AssignDate')}</label>
                                    <div className='row-wrapper'>
                                        <div className={`date-wrapper`}>
                                            <DatePickerComponent
                                                showTodayButton={false}
                                                allowEdit={true}
                                                openOnFocus={true}
                                                isRequired={true}
                                                value={formValues.SealConcessionDate}
                                                disabled={false}
                                                placeholder={showDateLabel()}
                                                onChange={onHandleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) 
                            : null
                        :null
                    }
                    <div className='label'>{showDateLabel()}</div>

                    <div className='data'>
                        {formValues.SealConcessionDate ? formValues.SealConcessionDate.toLocaleDateString() : '-'}
                    </div>
                </div>
                <div className='actions'>
                    <div className="upload-wrapper">
                        {
                            userRol === 'Admin' ? (
                                <>
                                    <div className='button-upload'>
                                        {
                                            filesData.sealMedia.url || formValues.requestType === 1 ? (
                                                <>
                                                    {/* <div className='file-wrapper mb-2'>
                                                        <span className='file-name'>Contratos</span>
                                                        <a className="file-link material-icons-round" href='https://madridexcelentestorage.blob.core.windows.net/requestmedia/common/contratos.zip' target="_blank" rel="noreferrer">file_download</a>
                                                    </div> */}
                                                    <div className='file-wrapper mb-2'>
                                                        <span className='file-name'>Gráfica</span>
                                                        <a className="file-link material-icons-round" href='https://madridexcelente-my.sharepoint.com/:f:/p/ana_sanchez/El-1NSkyhBlKlPNsTnQwWZIB-iV7wIV1OoUQ-GxNgkml1g?e=l9kdS9' target="_blank" rel="noreferrer">file_download</a>
                                                    </div>

                                                </>
                                            ) : null
                                        }
                                    </div>
                                    <div className="button-upload">
                                        {
                                            filesData.sealMedia.url !== '' ? (
                                                <div className='file-wrapper'>
                                                    {/* <span className='file-name 1'>{formValues.SealFileName}</span> */}
                                                    <a title={filesData.sealMedia.name} href={filesData.sealMedia.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                                </div>
                                            ) : null
                                        }
                                        <input
                                            id="btnSealFile"
                                            data-testid="btnSealFile"
                                            type="file"
                                            onChange={(e) => uploadFile(e, 'sealFile')}
                                            name="btnSealFile"
                                            accept=".pdf, .doc, .docx, image/png, image/jpeg, .zip"
                                            className="inputfile"
                                            disabled={disableDataBlocks || formValues.EvaluationState !== 'Completado' && isPrincipalCompany}
                                        />
                                        <label
                                            htmlFor="btnSealFile"
                                            role={'button'}
                                            className={`btn-base_normal ${formValues.SealFileName ? 'btn-base_outline' : null}`}
                                        >
                                            {
                                                !formValues.SealFileName ? (
                                                    <i className="material-icons-round">file_upload</i>
                                                ) : <i className="material-icons-round">refresh</i>
                                            }

                                            <span>{!formValues.SealFileName ? `${t('Importar')}` : 'Reemplazar'}</span>
                                        </label>
                                        <span className='file-name 2'>{formValues.SealFileName}</span>                                         
                                        
                                    </div>
                                </>
                            ) : userRol === 'Company' ? (
                                <div className='button-upload link-contracts'>
                                    {
                                        filesData.sealMedia.url || formValues.requestType === 1 ? (
                                            <>
                                                {/* <div className='file-wrapper mb-2'> 
                                                    <span className='file-name'>Contratos</span>
                                                    <a className="file-link material-icons-round" href='https://madridexcelentestorage.blob.core.windows.net/requestmedia/common/contratos.zip' target="_blank" rel="noreferrer">file_download</a>
                                                </div> */}
                                                <div className='file-wrapper mb-2'>
                                                    <span className='file-name'>Gráfica</span>
                                                    <a className="file-link material-icons-round" href='https://madridexcelente-my.sharepoint.com/:f:/p/ana_sanchez/El-1NSkyhBlKlPNsTnQwWZIB-iV7wIV1OoUQ-GxNgkml1g?e=l9kdS9' target="_blank" rel="noreferrer">file_download</a>
                                                </div>

                                            </>
                                        ) : null
                                    }
                                    {
                                        filesData.sealMedia.url ? (
                                            <div className='file-wrapper'>
                                                <span className='file-name'>{filesData.sealMedia.name}</span>
                                                <a title={filesData.sealMedia.name} href={filesData.sealMedia.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestSeal;