const getRequestsParams = (skip, take, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetAllRequests?skip=${skip}&take=${take}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postFilterRequests = (data, skip, take, funcSucc) => {
	const options = {
		method: 'POST',
		endpoint: `/Request/FilterRequests?skip=${skip}&take=${take}`,
		data,
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
	};
	return options;
};

const getClientCompaniesParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Company/GetBasicClientCompanies',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getBasicClientCompaniesForAuditorParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Company/GetBasicClientCompaniesForAuditor/${id}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getBudgetStatusParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/BudgetStatusType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getRequestTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/RequestType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getQuestionnaireStatusTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/QuestionnaireStatusType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getEvaluationStatusTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/EvaluationStatusType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getApplicantStatusTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/ApplicantStatusType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getContractStatusTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/ContractsStatusType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postReactivateRequest = (id, funcSucc, funcError) => {
	const options = {
		method: 'POST',
		endpoint: `/Request/ReactivateRequest/${id}`,
		onError: (response) => {
			funcError(response.data);
		},
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
	};
	return options;
};

const getAuditorCompaniesParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Company/GetAllAuditorCompanies',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAllEvaluators = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetAllEvaluators',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
    getRequestsParams,
	postFilterRequests,
	getClientCompaniesParams,
	getBudgetStatusParams,
	getRequestTypeParams,
	getQuestionnaireStatusTypeParams,
	getBasicClientCompaniesForAuditorParams,
	getEvaluationStatusTypeParams,
	getApplicantStatusTypeParams,
	getContractStatusTypeParams,
	postReactivateRequest,
	getAuditorCompaniesParams,
	getAllEvaluators
}