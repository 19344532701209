import React, { useState, useEffect, useRef } from 'react';
import './InputAutoSuggest.scss';
import Input from '../Input/Input';

export default function InputAutoSuggest({
	inputLabel,
	defaultValue,
	placeholderInput,
	isRequired,
	onHandleChange,
	filterFunction,
	isDisabled,
	showLabel = true
}) {
	const resultsRef = useRef(null);
	const [ value, setValue ] = useState(defaultValue ? defaultValue : '');
	const [ results, setResults ] = useState([]);

	useEffect(
		() => {
			setValue(defaultValue);
		},
		[ defaultValue ]
	);

	const handleSearchChange = (inputValue) => {
		if (inputValue.length > 3) {
			const data = filterFunction(inputValue);
			setResults(data);
		} else if (inputValue.length === 0) {
			setResults([]);
		}
	};

	const handleSelectedLocation = (element) => {
		setValue(element.label);
		setResults([]);
		onHandleChange(element.label)
	};

	useEffect(() => {
		const handleClickOutsideElement = (e) => {
			if (resultsRef.current && !resultsRef.current.contains(e.target)) {
				setResults([]);
				setValue('');
				onHandleChange('')
			}
		};
		document.addEventListener('mousedown', handleClickOutsideElement);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideElement);
		};
	}, []);

	return (
		<div id={`InputAutoSuggest-${inputLabel}`} className={`inputAutoSuggest`} data-label={inputLabel}>
			<Input
				inputType="text"
				inputLabel={inputLabel}
				inputID={`txtAutoSuggest-${inputLabel}`}
				dataTestID={`txtAutoSuggest-${inputLabel}`}
				isDisabled={isDisabled}
				isRequired={isRequired}
				onHandleChange={(e) => {
					setValue(e.currentTarget.value);
					handleSearchChange(e.currentTarget.value);
					if(e.currentTarget.value === '')
						onHandleChange('')
				}}
				value={value}
				showLabel={showLabel}
				autoComplete="off"
				inputPlaceholder={placeholderInput}
			/>
			{
				results.length > 0 
				? (
					<div id={`suggestedResults-${inputLabel}`} className={`suggestedResults backoffice-search ${showLabel ? 'label' : 'no-label'}`} ref={resultsRef}>
						<ul className="results-list">
							{results.map((el, index) => {
								return (
									<li
										key={index}
										tabIndex={index}
										data-list-item={index}
										data-i
										data-label={el.label}
										className="results-list__item"
										onClick={() => handleSelectedLocation(el)}
									>
										<small>{el.label}</small>
									</li>
								);
							})}
						</ul>
					</div>
				) : null
			}
		</div>
	);
}
