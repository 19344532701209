import React from 'react';
import './Checkbox.css';

const Checkbox = ({ id, handleInputChange, checked, readOnly, addClass, text, text2 }) => {
	return (
		<div className={'form-check form-check-inline' + addClass}>
			<input type="checkbox" id={id} onChange={handleInputChange} checked={checked} readOnly={readOnly} />
			<label htmlFor={id} className="ml-2">
				{text}
				{text2 ? <p className="text-muted">{text2}</p> : ''}
			</label>
		</div>
	);
};
export default Checkbox;
