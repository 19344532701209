const postLogoutParams = (params, funcSucc) => {
	const options = {
		method: 'POST',
		params,
		endpoint: '/Account/Logout',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
		onError: (response) => {
			funcSucc();
		}
	};
	return options;
};

export { postLogoutParams };
