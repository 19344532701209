import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../../actions/popup';
import { Button, Input } from '../../../../../components/FormComponents';
import './DocumentCompaniesModal.scss';

export default function DocumentCompaniesModal({data, companyId, handleSubmit, action, showFixedLabel}) {
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        mediaId: data ? data.id : '',
        companyId: companyId,
        about: data ? data.about : '',
        date: new Date().toISOString(),
        file: '',
    })

    const postDocumentForm = (e) => {
        e.preventDefault();

        if( formValues.about === '' ) {
            dispatch(closeModal())
            dispatch(showFixedLabel(t('EmptyNameError'), 'error'))
        } else if(formValues.file.length === 0 && action === 'add' ) {
            dispatch(closeModal())
            dispatch(showFixedLabel(t('EmptyUpload'), 'error'))
        } else {
            dispatch(closeModal())
            handleSubmit(action, formValues)            
        }
    }

    return(
        <div className='document-edit-modal'>
            <form className='form-document-edit'>
                <div className='row'>
                    <div className='form-input-group input-group col-lg-12'>
                        <div className="form-wrapper">
                            <Input
                                inputID="About"
                                dataTestID="About"
                                inputLabel={t('Name')}
                                inputName="About"
                                inputType="text"
                                showLabel={true}
                                isRequired={true}
                                inputPlaceholder={t('Name')}
                                onHandleChange={(e) => setFormValues({
                                    ...formValues,
                                    about: e.target.value
                                })}
                                value={formValues.about}
                            />
                        </div>
                    </div>
                    <div className="document-template col-lg-12">
                        <div className="button-upload">
                            <label 
                                htmlFor="btnUploadFile" 
                                role={'button'}
                                className={'btn-base_normal btn-base_outline'}
                            >
                                <i className="material-icons-round">{ data ? 'refresh' : 'upload'}</i>
                                <span>{data ? t('ReplaceDocument') : t('AddDocument')}</span> 
                            
                            </label>
                            <input
                                id="btnUploadFile"
                                data-testid="btnUploadFile"
                                type="file"
                                onChange={(e) => setFormValues({
                                    ...formValues,
                                    file: e.target.files[0],
                                    date: new Date().toISOString()
                                })}
                                name="btnUploadFile"
                                accept=".pdf, .doc, .docx, image/png, image/jpeg, .zip"
                                className="inputfile"
                            />
                        </div>                
                        {
                            formValues.file && <div>{formValues.file.name}</div>
                        }
                    </div>
                </div>
                <div className='btn-submit-container row mt-4'>
                    <div className={`btn-submit-wrapper col-lg-12 d-flex`}>
                        <div className='d-flex justify-content-end'>
                            <Button
                                dataTestID="btnCancel"
                                btnText={t('Cancel')}
                                btnType="button"
                                btnClass="btn-base_outline ml-5"
                                onHandleBtnClick={() => {
                                    dispatch(closeModal());
                                }}
                            />

                            <Button
                                btnText={(action === 'edit') ? t('EditDocument') : t('AddDocument')}
                                btnType="submit"
                                isDisabled={false}
                                btnClass="btn-base_normal ml-3"
                                dataTestID="btnUserEditRequest"
                                id='btnUserEditRequest'
                                onHandleBtnClick={(e) => postDocumentForm(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}