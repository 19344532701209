import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/FormComponents';

const RequestQuestionnaire = ({userRol, formValues, colorStates, disableDataBlocks, handleAuthorizeQuestionnaire, handleRejectQuestionnaire, handleOnClickDetail, handleOnClickDownloadExcel, isEvaluationPublic}) => {

    const { t } = useTranslation();
    return (
        <>
            <div className='request-wrapper'>
                <div className='title-wrapper'>
                    <h2 className='title'>{`${t('SelfQuestionnaire')} ${formValues.QuestionnaireVersion ? `(${isEvaluationPublic !== null && isEvaluationPublic ? t("PublicSector") : t("PrivateSector") } V.${formValues.QuestionnaireVersion})` : '' }`}</h2>
                    <div className='state'>
                        <span
                            className='pill'
                            style={{
                                backgroundColor: colorStates(formValues.QuestionnaireState),
                                color: formValues.QuestionnaireState === "Validado" || formValues.QuestionnaireState === 'Completado' ? '#fff' : null
                            }}
                        >{formValues.QuestionnaireState}</span>
                    </div>
                </div>
                { ((formValues.QuestionnaireState === "Validado" && userRol === 'Auditor') || userRol !== 'Auditor') &&
                    <div
                        className='actions'
                        onClick={() => {
                            userRol === 'Company'
                                ? handleOnClickDownloadExcel(formValues.EvaluationState === "Completado")
                                : handleOnClickDownloadExcel(true)  
                        }}
                    >
                        <span className='material-icons-round eye' aria-hidden={true}>save_alt</span>
                    </div>
                }
                {
                    userRol === 'Admin' ? (
                        <div
                            className='actions'
                            onClick={handleOnClickDetail}
                        >
                            <span className='material-icons-round eye' aria-hidden={true}>visibility</span>
                        </div>
                    ) : null
                }
            </div>
            <div className='content-wrapper'>
                <div className='data points-wrapper'>
                    {
                        userRol !== 'Company' ? (
                            <div className='points-group'>
                                <div className='points'>
                                    {t('TotalCompanyScore')}: {formValues.QuestionnaireCompanyTotalPoints ? formValues.QuestionnaireCompanyTotalPoints.toFixed(2) : ' 0'}
                                </div>
                                <div className='points'>{
                                    t('TotalEvaluatorScore')}: {formValues.QuestionnaireEvaluatorTotalPoints ? formValues.QuestionnaireEvaluatorTotalPoints.toFixed(2) :  '0'}
                                </div>
                            </div>

                        ) : null
                    }
                    <div className='percentage-bar'>
                        <div className='percentage-bar__data'>
                            {t('Complete')}
                            {` ${formValues.QuestionnaireCompletePercent}%`}
                        </div>
                        <div
                            className='percentage-bar__progress'
                            style={{
                                width: `${formValues.QuestionnaireCompletePercent}%`,
                                backgroundColor: formValues.QuestionnaireCompletePercent === 100 ? 'rgb(171, 210, 104)' : 'red'
                            }}
                        ></div>
                    </div>
                </div>
                {
                    userRol === 'Admin' ? (
                        <div className='actions btn-group'>
                            <div className='btn-wrapper'>
                                <Button
                                    btnText={t('Authorize')}
                                    btnType="button"
                                    btnClass="btn-base_normal "
                                    dataTestID="AuthorizeQuestionnaireBtn"
                                    id="AuthorizeQuestionnaireBtn"
                                    isDisabled={disableDataBlocks || (formValues.QuestionnaireState === 'Validado' ? true : false) || formValues.QuestionnaireState === 'Pendiente'}
                                    onHandleBtnClick={(e) => handleAuthorizeQuestionnaire(e)}
                                />
                                <Button
                                    btnText={t('Decline')}
                                    btnType="button"
                                    btnClass="btn-base_outline"
                                    dataTestID="DeclineBtn"
                                    id="DeclineBtn"
                                    isDisabled={disableDataBlocks || (formValues.QuestionnaireState === 'Validado' ? true : false) || formValues.QuestionnaireState === 'Pendiente'}
                                    onHandleBtnClick={(e) => handleRejectQuestionnaire(e)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='actions btn-group'>
                            <div className='btn-wrapper'>
                                <Button
                                    btnText={
                                        (formValues.QuestionnaireState === 'Completado' || formValues.QuestionnaireState === 'Validado') && 
                                        userRol === 'Company' || (userRol === 'Auditor' && formValues.IsAuditorComplete) ? t('Ver') : t('Completar')
                                    }
                                    btnType="button"
                                    btnClass="btn-base_outline"
                                    dataTestID="CompleteBtn"
                                    id="CompleteBtn"
                                    isDisabled={
                                        disableDataBlocks ||
                                        formValues.BudgetState === 'Pendiente' ||
                                        (userRol !== 'Company' && formValues.QuestionnaireCompletePercent < 100) ||
                                        (userRol === 'Auditor' && formValues.QuestionnaireState !== 'Validado')
                                    }
                                    onHandleBtnClick={handleOnClickDetail}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default RequestQuestionnaire;
