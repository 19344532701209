import React, {useState, useEffect, useRef} from 'react';
import { openModal, closeModal } from '../../../actions/popup';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startRequest } from '@backoffice/actions/apiCall';
import { 
    getSectorTypeParams, 
    getEntitiesTypeParams, 
    postCreateRequestParams,
    putCertificateEntityParams,
    putCertificateAllPaidTaxesParams,
    putCertificateSocialSecurityParams,
    getPublicRequestDetailByID,
    getNotCombinedClientCompanies,
    postUpdateEvaluationBasicData,
    getUserLoggedParams
    //getCompanies
} from './RequestCalls/RequestCalls';
import { GetSessionStorage } from '../../../common/utils/storageManager';
import { showFixedLabel } from '../../../actions/fixedLabel';
import HeroImg from '../../../assets/madrid_hero.png';
import LogoMe from '../../../assets/logos/me_azul2.png';
import Radio from '../../../components/FormComponents/RadioButton/Radio';
import Combo from '../../../components/FormComponents/Combo';
import Input from '../../../components/FormComponents/Input';
import Textarea from '../../../components/FormComponents/Textarea';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Button from '../../../components/FormComponents/Button';
import Checkbox from '../../../components/FormComponents/Checkbox';
import validations from '../../../common/validations/index';
import FixedLabel from '../../../shared/FixedLabel';
import { orderComboByText } from '../../../common/utils/parseUtils';
import Footer from '../../../components/Footer/Footer';

import './Request.scss';
import { MUNICIPIOS } from '../../../common/constants/municipios';
import { PROVINCIAS } from '../../../common/constants/provincias';
import { InputAutoSuggest } from '../../../components/FormComponents';
import { getCompanySizeTypeParams } from '../../Layout/CompaniesInfo/CompaniesInfoCalls.js/CompaniesInfoCalls';

export default function Request() {
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const history = useHistory()

    /*** Radios ***/
    //const [datosEvaluacionRadiosChecked, setDatosEvaluacionRadiosChecked] = useState(false)
    //const [renovacionRadiosChecked, setRenovacionRadiosChecked] = useState(false)
    const [fisicPersonChecked, setFisicPersonChecked] = useState(null)
    const [entitiesTypes, setEntitiesTypes] = useState([])
    const [entityTypeId, setEntityTypeId] = useState('')
    const [yearsOfActivityChecked, setYearsOfActivityChecked] = useState(null)
    const [allOrganizationChecked, setAllOrganizationChecked] = useState(null)
    const [jointEvaluation, setJointEvaluation] = useState(null)
    const [renewal, setRenewal] = useState(null)

    /***  Questionay Radios ***/
    const [q1DefidePurposeChecked, setQ1DefidePurposeChecked] = useState(null)
    const [q2SchemeDevelopmentChecked, setQ2SchemeDevelopmentChecked] = useState(null)
    const [q3QualityManagementSystemChecked, setQ3QualityManagementSystemChecked] = useState(null)
    const [q4QualityISOSystemChecked, setQ4QualityISOSystemChecked] = useState(null)
    const [q5SatisfactionEmployeesDegreesChecked, setQ5SatisfactionEmployeesDegreesChecked] = useState(null)
    const [q6SatisfactionClientDegreesCheck, setQ6SatisfactionClientDegreesCheck] = useState(null)
    const [q7EnvironmentalInpactPracticesChecked, setQ7EnvironmentalInpactPracticesChecked] = useState(null)
    const [q8QualityISOEnvironmentChecked, setQ8QualityISOEnvironmentChecked] = useState(null)
    const [q9ContributeEnvironmentCommunityChecked, setQ9ContributeEnvironmentCommunityChecked] = useState(null)

    //Errors
    const [ emailError, setEmailError ] = useState(false);
    const [isValidNIF, setIsValidNIF] = useState(null);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    //Combos
    const [companies, setCompanies] = useState([])
    const [companySelected, setCompanySelected] = useState()
    const [companyDataSectorTypology, setCompanyDataSectorTypology] = useState([])
    const [companyDataSector, setCompanyDataSector] = useState([])
    const [companyDataSectorId, setCompanyDataSectorId] = useState('')
    const [companyDataSectorDescription, setCompanyDataSectorDescription] = useState('')
    const [companyDataSectorOther, setCompanyDataSectorOther] = useState('')
    const optionsPublic = [
		{
			id: 1,
			value: true,
			label: t('Public')
		},
		{
			id: 2,
			value: false,
			label: t('Private')
		}
	]
    const [companyPublicSelected, setCompanyPublicSelected] = useState(optionsPublic[1])
    const [companySizeType, setCompanySizeType] = useState([])

    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [isAuditor, setIsAuditor] = useState(false)

    const [requestId, setRequestId] = useState('')

    //const [requestSuccess, setRequestSuccess] = useState(false)

    useEffect(() => {
        //checkIfAdmin()
        getSectors();        
    }, [])

    useEffect(() => {
        
        if(fisicPersonChecked !== null && entitiesTypes.length > 0){
            if(fisicPersonChecked === true) setEntityTypeId(entitiesTypes[0].id)
            else setEntityTypeId(entitiesTypes[1].id)
        }
    }, [fisicPersonChecked])

    useEffect(() => {
        if(history.location.state){
            setRequestId(history.location.state.requestId)
        }else{
            //get id from params
            const params = window.location.pathname
            setRequestId(params.split('/')[2])
        }
    }, [])


    //Last closed exercises data
    const [lastClosedData, setLastClosedData] = useState({
        employeesNumberLastYear: '',
        employeesNumberPenultimateYear: '',
        totalIncomeLastYear: '',
        totalIncomePenultimateYear: '',
        exerciseResultLastYear: '',
        exerciseResultPenultimateYear: '',
        totalCurrentActiveLastYear: '',
        totalCurrentActivePenultimateYear: '',
        totalCurrentPassiveLastYear: '',
        totalCurrentPassivePenultimateYear: '',
        totalNotCurrentPassiveLastYear: '',
        totalNotCurrentPassivePenultimateYear: '',
        ownFundsLastYear: '',
        ownFundsPenultimateYear: ''
    })

    const [formValues, setFormValues] = useState({
        renewalDate: null,
		socialDenomination: '',
		tradename: '',
		nif: '',
		address: '',
		postalCode: '',
		city: '',
		province: '',
		web: '',
		activityDescription: '',
        contactPersonName: '',
        contactPersonSurname: '',
        contactPersonPosition: '',
        contactPersonPhone: '',
        contactPersonEmail: '',
        companyDataSector: companyDataSector ? companyDataSector : [],
        notAllOrganizationDescription: '',
        observations: '',
        registrationConstitutionFileName: '',
        registrationConstitutionLink: '',
        paymentTaxFileName: '',
        paymentTaxFileLink: '',
        paymentSocialSecurityFileName: '',
        paymentSocialSecurityFileLink: '',
        acceptTerms: false,
        acceptMailing: false,
		isActive: true
	});
    
    const [showOtherCompanySectorField, setShowOtherCompanySectorField] = useState(false)
    const [showNotAllOrganizationReason, setShowNotAllOrganizationReason] = useState(false)

    const [filesData, setFilesData] = useState({
        certificateEntity: {
            id: "",
            name: "",
            url: "",
            internalName: ""
        },
        certificateAllPaidTaxes: {
            id: "",
            name: "",
            url: "",
            internalName: ""
        },
        certificateSocialSecurity: {
            id: "",
            name: "",
            url: "",
            internalName: ""
        }
    })

    

    useEffect(() => {
        if( requestId && companyDataSectorTypology.length > 0 ) {
            dispatch(startRequest(getPublicRequestDetailByID(requestId, getPublicRequestDetailByIDSuccess)))
        }
    }, [companies])
    
    const getPublicRequestDetailByIDSuccess = (data) => {
        //console.log(data);
        
        if(data){
            setFormValues({
                renewalDate: data.evaluationBasicInfo.renewalDate,
                socialDenomination: data.companyData.infoData.socialName,
                tradename: data.companyData.infoData.commercialName,
                nif: data.companyData.infoData.identityNumber,
                address: data.companyData.infoData.registeredOffice,
                postalCode: data.companyData.infoData.postalCode ? data.companyData.infoData.postalCode : '',
                city: data.companyData.infoData.town,
                province: data.companyData.infoData.province,
                web: data.companyData.infoData.website,
                activityDescription: data.companyData.infoData.activityDescription,
                contactPersonName: data.contact.name,
                contactPersonSurname: data.contact.surnames,
                contactPersonPosition: data.contact.companyPosition,
                contactPersonPhone: data.contact.phone,
                contactPersonEmail: data.contact.email,
                companyDataSector: data.companyData.sectorTypeId,
                notAllOrganizationDescription: data.scope.comments,
                observations: data.observations,
                registrationConstitutionFileName: data.certificateEntity.internalName,
                registrationConstitutionLink: data.certificateEntity.url,
                paymentTaxFileName: data.certificateAllPaidTaxes.internalName,
                paymentTaxFileLink: data.certificateAllPaidTaxes.url,
                paymentSocialSecurityFileName: data.certificateSocialSecurity.internalName,
                paymentSocialSecurityFileLink: data.certificateSocialSecurity.url,
                acceptTerms: data.acceptTerms ,
                acceptMailing: data.acceptMailing ,
                isActive: true
            })
            //Last closed data
            setLastClosedData({
                employeesNumberLastYear: data.companyData.lastFinancialYear.numberOfEmployees,
                employeesNumberPenultimateYear: data.companyData.penultimateFinancialYear.numberOfEmployees,
                totalIncomeLastYear: data.companyData.lastFinancialYear.totalIncomes,
                totalIncomePenultimateYear: data.companyData.penultimateFinancialYear.totalIncomes,
                exerciseResultLastYear: data.companyData.lastFinancialYear.result,
                exerciseResultPenultimateYear: data.companyData.penultimateFinancialYear.result,
                totalCurrentActiveLastYear: data.companyData.lastFinancialYear.totalCurrentAssets,
                totalCurrentActivePenultimateYear: data.companyData.penultimateFinancialYear.totalCurrentAssets,
                totalCurrentPassiveLastYear: data.companyData.lastFinancialYear.totalCurrentLiabilities,
                totalCurrentPassivePenultimateYear: data.companyData.penultimateFinancialYear.totalCurrentLiabilities,
                totalNotCurrentPassiveLastYear: data.companyData.lastFinancialYear.totalNonCurrentLiabilities,
                totalNotCurrentPassivePenultimateYear: data.companyData.penultimateFinancialYear.totalNonCurrentLiabilities,
                ownFundsLastYear: data.companyData.lastFinancialYear.ownFunds,
                ownFundsPenultimateYear: data.companyData.penultimateFinancialYear.ownFunds
            })
            //Files
            setFilesData({
                certificateEntity: {
                    id: data.certificateEntity.id,
                    name: data.certificateEntity.name,
                    url: data.certificateEntity.url
                },
                certificateAllPaidTaxes: {
                    id: data.certificateAllPaidTaxes.id,
                    name: data.certificateAllPaidTaxes.name,
                    url: data.certificateAllPaidTaxes.url
                },
                certificateSocialSecurity: {
                    id: data.certificateSocialSecurity.id,
                    name: data.certificateSocialSecurity.name,
                    url: data.certificateSocialSecurity.url
                }
            })
            //Checks
            setYearsOfActivityChecked(data.companyData.moreThreeYearsActivity ? true : false)
            //Fisic or Law person
            if(data.companyData.infoData.entityTypeId === '10265233-10ed-4203-8912-d07c63b1d897'){
                setFisicPersonChecked(true)
            }else{
                setFisicPersonChecked(false)
            }
            setAllOrganizationChecked(data.scope.isAllOrganization ? true : false)
            setJointEvaluation(data.evaluationBasicInfo.isCombined ? true : null)
            setRenewal(data.evaluationBasicInfo.renewal  ? true : null)

            //Combo companySector
            setCompanyDataSectorId(data.companyData.sectorTypeId)
            setCompanyDataSectorDescription(data.companyData.otherSector)
            if(data.companyData.sectorTypeId === 'af503ece-2146-4e09-886d-ed1007661efc'){
                setShowOtherCompanySectorField(true)
            }else{
                setShowOtherCompanySectorField(false)
                setCompanyDataSectorOther('')
            }
            const sector = companyDataSectorTypology.filter(el => { return el.id === data.companyData.sectorTypeId})
            setCompanyDataSector(sector[0])

            //EvaluationCompanyCombo
            if(data.evaluationBasicInfo.companyParentId){
                const company = companies.filter(el => { return el.id === data.evaluationBasicInfo.companyParentId})
                setCompanySelected(company)
            }

            //Questionnaire
            setQ1DefidePurposeChecked(data.initialQuestionnaire.question1 ? true : false)
            setQ2SchemeDevelopmentChecked(data.initialQuestionnaire.question2 ? true : false)
            setQ3QualityManagementSystemChecked(data.initialQuestionnaire.question3 ? true : false)
            setQ4QualityISOSystemChecked(data.initialQuestionnaire.question4 ? true : false)
            setQ5SatisfactionEmployeesDegreesChecked(data.initialQuestionnaire.question5 ? true : false)
            setQ6SatisfactionClientDegreesCheck(data.initialQuestionnaire.question6 ? true : false)
            setQ7EnvironmentalInpactPracticesChecked(data.initialQuestionnaire.question7 ? true : false)
            setQ8QualityISOEnvironmentChecked(data.initialQuestionnaire.question8 ? true : false)
            setQ9ContributeEnvironmentCommunityChecked(data.initialQuestionnaire.question9 ? true : false)
        }
        
    } 

    const checkIfAdmin = () => {
        const storage = GetSessionStorage('PROFILE_')
        if(storage) {
            storage.userRoles.map(el => {
                if(el.name === 'Admin'){
                    setIsSuperAdmin(true)
                    //console.log(el.name);
                    getCompanies()
                } 
                else setIsSuperAdmin(false)

                if(el.name === 'Auditor') setIsAuditor(true)
                else setIsAuditor(false)
            })
        } else {
            setIsSuperAdmin(false)
        }
    }

    //Sectors
    const getSectors = () => {
        dispatch(startRequest(getSectorTypeParams(getSectorTypeParamsSuccess)))
    }
    const getSectorTypeParamsSuccess = (data) => {
        if(data){
            formatComboData(data)
        } 
        getEntities()
    }
    const formatComboData = (data) => {
        let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name,
                description: el.description
            });
        });
        setCompanyDataSectorTypology(options)
    }

    //Entities
    const getEntities = () => {
        dispatch(startRequest(getEntitiesTypeParams(getEntitiesTypeParamsSuccess)))
    }
    const getEntitiesTypeParamsSuccess = (data) => {
        setEntitiesTypes(data)
        getCompanySizeType()
    }
    
    const getCompanySizeType = () => {
        dispatch(startRequest(getCompanySizeTypeParams(getCompanySizeTypeParamsSuccess)))
	}
    
    const getCompanySizeTypeParamsSuccess = (data) => {
        let options = [];
		
        data.forEach((el) => {
			let label = "";
			switch (el.name) {
				case "Grupo 1":
					label = `${el.name}: < 10 empleados y ≤ 2 millones`
					break;

				case "Grupo 2":
					label = `${el.name}: < 50 empleados y/o < 10 millones`
					break;

				case "Grupo 3":
					label = `${el.name}: < 250 empleados y/o < 50 millones`
					break;

				case "Grupo 4":
					label = `${el.name}: ≥ 250 empleados y ≥ 50 millones`
					break;

				case "Grupo 5":
					label = `${el.name}: > 500 empleados y/o > 200 millones`
					break;

				case "Grupo 6":
					label = `${el.name}: > 5000 empleados y/o > 1.000 millones`
					break;
			
				default:
					break;
			}

			options.push({
                id: el.id,
                value: el.name,
                label: label
            });
        });
		setCompanySizeType(options)
        checkIfAdmin()
	}

    const recoverSizeType = (numEmployees) => {
        let sizeTypeId = companySizeType.filter((option) => option.value === "Grupo 1")[0].id
        switch (true) {
            case numEmployees <= 10:
                sizeTypeId = companySizeType.filter((option) => option.value === "Grupo 1")[0].id
                break;

            case numEmployees <= 50:
                sizeTypeId = companySizeType.filter((option) => option.value === "Grupo 2")[0].id
                break;

            case numEmployees <= 250:
                sizeTypeId = companySizeType.filter((option) => option.value === "Grupo 3")[0].id
                break;

            case numEmployees <= 500:
                sizeTypeId = companySizeType.filter((option) => option.value === "Grupo 4")[0].id
                break;

            case numEmployees <= 5000:
                sizeTypeId = companySizeType.filter((option) => option.value === "Grupo 5")[0].id
                break;

            case numEmployees > 5000:
                sizeTypeId = companySizeType.filter((option) => option.value === "Grupo 6")[0].id
                break;
        
            default:
                break;
        }

        return sizeTypeId
    }

    const getCompanies = () => {
        //call companyCombo
        dispatch(startRequest(getNotCombinedClientCompanies(getNotCombinedClientCompaniesSuccess)))
    }

    const getNotCombinedClientCompaniesSuccess = (data) => {
        let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });
        options = orderComboByText(options);
        setCompanies(options)
    }

    const handleCompanySectorChange = (e) => {
        setCompanyDataSector(e)
        setCompanyDataSectorId(e.id)
        setCompanyDataSectorDescription(e.description)
        if(e.id === 'af503ece-2146-4e09-886d-ed1007661efc'){
            setShowOtherCompanySectorField(true)
        }else{
            setShowOtherCompanySectorField(false)
            setCompanyDataSectorOther('')
        }
    }

    useEffect(
		() => {
			validateBtnSuccess();
		},
		[formValues, lastClosedData, allOrganizationChecked, fisicPersonChecked]
	);

    useEffect(() => {
        if(!allOrganizationChecked){
            setShowNotAllOrganizationReason(true)
        }else{
            //reset notAllOrganizationDescription
            setFormValues({
                ...formValues,
                notAllOrganizationDescription: ''
            })
            setShowNotAllOrganizationReason(false)
        }
    }, [allOrganizationChecked])    

    const validateBtnSuccess = () => {
        let isButtonDisabled = false;
        if(
            //formValues.socialDenomination === '' ||
            //formValues.tradename === '' ||
            //formValues.nif === '' ||
            //validations.validateDniNieNif(formValues.nif) === false ||
            //formValues.address === '' ||
            //formValues.postalCode === '' ||
            //formValues.city === '' ||
            //formValues.province === '' ||
            //formValues.web === '' ||
            //formValues.activityDescription === '' ||
            formValues.contactPersonName === '' ||
            formValues.contactPersonSurname === '' ||
            formValues.contactPersonPosition === '' ||
            formValues.contactPersonPhone === '' ||
            formValues.contactPersonEmail === '' ||
            emailError === true ||
            formValues.companyDataSector === '' ||
            formValues.acceptTerms === false ||
            lastClosedData.employeesNumberLastYear === '' ||
            lastClosedData.employeesNumberPenultimateYear === '' ||
            lastClosedData.totalIncomeLastYear === '' ||
            lastClosedData.totalIncomePenultimateYear === '' ||
            lastClosedData.exerciseResultLastYear === '' ||
            lastClosedData.exerciseResultPenultimateYear === '' ||
            lastClosedData.totalCurrentActiveLastYear === '' ||
            lastClosedData.totalCurrentActivePenultimateYear === '' ||
            lastClosedData.totalCurrentPassiveLastYear === '' ||
            lastClosedData.totalCurrentPassivePenultimateYear === '' ||
            lastClosedData.totalNotCurrentPassiveLastYear === '' ||
            lastClosedData.totalNotCurrentPassivePenultimateYear === '' ||
            lastClosedData.ownFundsLastYear === '' ||
            lastClosedData.ownFundsPenultimateYear === '' ||
            formValues.registrationConstitutionFileName === '' ||  
            formValues.paymentTaxFileName === '' ||
            formValues.paymentSocialSecurityFileName === '' ||
            fisicPersonChecked === null ||
            yearsOfActivityChecked === null ||
            allOrganizationChecked === null ||
            q1DefidePurposeChecked === null ||
            q2SchemeDevelopmentChecked === null ||
            q3QualityManagementSystemChecked === null ||
            q4QualityISOSystemChecked === null ||
            q5SatisfactionEmployeesDegreesChecked === null ||
            q6SatisfactionClientDegreesCheck === null ||
            q7EnvironmentalInpactPracticesChecked === null ||
            q8QualityISOEnvironmentChecked === null ||
            q9ContributeEnvironmentCommunityChecked === null
            
        ){isButtonDisabled = true}
        if(allOrganizationChecked === false && formValues.notAllOrganizationDescription === '') {isButtonDisabled = true}
        setIsBtnDisabled(isButtonDisabled);
    }

    const openHelpModal = () => {
        const optionValues = {
            id: 'helpModal',
            dataTestId: 'helpModal',
            cssClass: 'modal-header__transparent',
            title: 'Ayuda' 
        };
    
        const options = {
            ...optionValues,
            body: (
                <p className='modal-text'>{t('HelpGlobalMessage')}</p>
            )
        };
    
        dispatch(openModal(options));
    }

    const handleNifChange = (e) => {
		let val = e.target.value;
		//setNif(val);
        setFormValues({
            ...formValues,
            nif: val
        })
		let isValidNIF = validations.validateDniNieNif(val) || validations.isValidCif(val)
		setIsValidNIF((prev) => {
			return !isValidNIF;
		});
	};

    const handleEmailChange = (e) => {
		let val = e.target.value;
        setEmailError(validations.validateEmail(val).error);
        setFormValues({
            ...formValues,
            contactPersonEmail: val
        })
	};

    const uploadFile = (e, type) => {
        const data = { File: e.target.files[0] };
		e.target.value = null;
        if (type === 'registrationConstitutionFile') {
            setFormValues({
                ...formValues,
                registrationConstitutionFileName: data.File.name
            })
            dispatch(startRequest(putCertificateEntityParams(data, putCertificateEntityParamsSuccess)))
        }
        if (type === 'paymentTaxFile') {
            setFormValues({
                ...formValues,
                paymentTaxFileName: data.File.name
            })
            dispatch(startRequest(putCertificateAllPaidTaxesParams(data, putCertificateAllPaidTaxesParamsSuccess)))
        }
        if (type === 'paymentSocialSecurityFile') {
            setFormValues({
                ...formValues,
                paymentSocialSecurityFileName: data.File.name
            })
            dispatch(startRequest(putCertificateSocialSecurityParams(data, putCertificateSocialSecurityParamsSuccess)))
        }
    }

    const putCertificateEntityParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            certificateEntity: {
                id: data.id,
                name: data.name,
                url: data.url,
                internalName: data.internalName
            }
        })
    }

    const putCertificateAllPaidTaxesParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            certificateAllPaidTaxes: {
                id: data.id,
                name: data.name,
                url: data.url,
                internalName: data.internalName
            }
        })
    }

    const putCertificateSocialSecurityParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            certificateSocialSecurity: {
                id: data.id,
                name: data.name,
                url: data.url,
                internalName: data.internalName
            }
        })
    }

    //Reset if false radios
    useEffect(() => {
        if (!renewal) {
           setFormValues({
                ...formValues,
                renewalDate: null
            })
        }
    }, [renewal])

    useEffect(() => {
        if(!jointEvaluation){
            setCompanySelected()
        }
    }, [jointEvaluation])

    const updateRequestData = (e) => {
        e.preventDefault();
        const data = {
            requestId: requestId,
            renewal: renewal ? renewal : false,
            isCombined: jointEvaluation ? jointEvaluation : false,
            renewalDate: formValues.renewalDate,
            companyParentId: companySelected ? companySelected.id : ''
        }
        dispatch(startRequest(postUpdateEvaluationBasicData(data, postUpdateEvaluationBasicDataSuccess)))
    }
    const postUpdateEvaluationBasicDataSuccess = (data) => {
        dispatch(showFixedLabel("Solicitud editada correctamente", 'success'));
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }

    const requestData = (e) => {
        e.preventDefault();
        //montar objeto para enviar a back
        const data = {
            companyData: {
                otherSector: companyDataSectorOther,
                sectorTypeId: companyDataSectorId,
                moreThreeYearsActivity: yearsOfActivityChecked,
                lastFinancialYear: {
                    numberOfEmployees: lastClosedData.employeesNumberLastYear,
                    totalIncomes: lastClosedData.totalIncomeLastYear,
                    totalCurrentAssets: lastClosedData.totalCurrentActiveLastYear,
                    totalCurrentLiabilities: lastClosedData.totalCurrentPassiveLastYear,
                    totalNonCurrentLiabilities: lastClosedData.totalNotCurrentPassiveLastYear,
                    result: lastClosedData.exerciseResultLastYear,
                    ownFunds: lastClosedData.ownFundsLastYear
                },
                penultimateFinancialYear: {
                    numberOfEmployees: lastClosedData.employeesNumberPenultimateYear,
                    totalIncomes: lastClosedData.totalIncomePenultimateYear,
                    totalCurrentAssets: lastClosedData.totalCurrentActivePenultimateYear,
                    totalCurrentLiabilities: lastClosedData.totalCurrentPassivePenultimateYear,
                    totalNonCurrentLiabilities: lastClosedData.totalNotCurrentPassivePenultimateYear,
                    result: lastClosedData.exerciseResultPenultimateYear,
                    ownFunds: lastClosedData.ownFundsPenultimateYear
                },
                infoData: {
                    entityTypeId: entityTypeId,
                    socialName: formValues.socialDenomination,
                    commercialName: formValues.tradename,
                    identityNumber: formValues.nif,
                    town: formValues.city,
                    province: formValues.province,
                    website: formValues.web,
                    activityDescription: formValues.activityDescription,
                    registeredOffice: formValues.address,
                    postalCode: formValues.postalCode
                },
                currentCompanyInfoHistory: {
                    town: formValues.city,
                    region: formValues.province,
                    isPublicCompany: companyPublicSelected.value,
                    sectorTypeId: companyDataSectorId,
                    companySizeTypeId: recoverSizeType(lastClosedData.employeesNumberLastYear),
                    companyId: ""
                }
            },
            contact: {
                name: formValues.contactPersonName,
                surnames: formValues.contactPersonSurname,
                companyPosition: formValues.contactPersonPosition,
                phone: formValues.contactPersonPhone,
                email: formValues.contactPersonEmail
            },
            initialQuestionnaire: { 
                question1: q1DefidePurposeChecked,
                question2: q2SchemeDevelopmentChecked,
                question3: q3QualityManagementSystemChecked,
                question4: q4QualityISOSystemChecked,
                question5: q5SatisfactionEmployeesDegreesChecked,
                question6: q6SatisfactionClientDegreesCheck,
                question7: q7EnvironmentalInpactPracticesChecked,
                question8: q8QualityISOEnvironmentChecked,
                question9: q9ContributeEnvironmentCommunityChecked
            },
            scope: {
                isAllOrganization: allOrganizationChecked,
                comments: formValues.notAllOrganizationDescription
            },                      
            acceptTerms: formValues.acceptTerms,
            acceptMailing: formValues.acceptMailing,
            observations: formValues.observations,
            certificateEntity: filesData.certificateEntity,
            certificateAllPaidTaxes: filesData.certificateAllPaidTaxes,
            certificateSocialSecurity: filesData.certificateSocialSecurity
        } 
       
        // console.log(data);
        //enviar request
        
        dispatch(startRequest(postCreateRequestParams(data, postCreateRequestParamsSuccess, postCreateRequestParamsError)))
    }

    const postCreateRequestParamsSuccess = (data) => {
        dispatch(showFixedLabel("Solicitud creada correctamente", 'success'));
        //(() => {
            //setRequestSuccess(true)
            history.push('/solicitud-recibida')
        //}, 1500);
        //setTimeout(() => {
            window.scrollTo(0, 0);
        //}, 1000);
    }

    const postCreateRequestParamsError = (error) => {
        if(error){
            dispatch(showFixedLabel(t(error.data.errors[0].errorMessageKey), 'error'));  
        }
    }
    
    return (
        <div className='request'>
            <FixedLabel parentType="general" />
            <div className='request-container'>
                <div className='request-header'>
                    <h1 className='logome-wrapper'>
                        <a href='https://www.madridexcelente.com/'>
                            <img className='logome' src={LogoMe} alt='logo-madrid-excelente' />
                        </a>
                    </h1>
                    <div className='hero-wrapper'>
                        <img className='hero-img' src={HeroImg} aria-describedby="ciudad-madrid" alt='ciudad-madrid' />
                        <div className='sr-only' id='ciudad-madrid'>{t('ImgMadridSROnly')}</div>
                    </div>
                    <div className='help-wrapper' >
                        {
                            requestId === '' || !requestId ? (
                                <a className='return-wrapper' href='https://www.madridexcelente.com/' role='button'>
                                    <span className='material-icons' aria-hidden={true}>arrow_back</span> 
                                    <span className='return-link'>
                                        Volver a la web de Madrid Excelente
                                    </span>
                                </a>

                            ) : (
                                <div 
                                    className='return-wrapper'
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    <span className='material-icons' aria-hidden={true}>arrow_back</span>
                                    <span className='return-link'>
                                        Volver al detalle de la petición
                                    </span>
                                </div>
                            )
                        }
                        <button role='button' onClick={() => openHelpModal()} className='material-icons icon' aria-hidden={true}>help</button>
                        <span className='help'>{t('Help')}</span>
                    </div>
                </div>
            
                <div className='request-content container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='heading-page'>{t('Request')}</h2>
                            <p className='info-text-page'>{t('RequestPageInfo')}</p>
                        </div>
                    </div>
                    <form className='request-form' onSubmit={(e) => requestData(e)}>
                        {
                            isSuperAdmin && requestId ? (
                                <fieldset className='field-container row' >
                                    <legend className='legend-heading col-lg-12'>{t('EvaluationData')}</legend>
                                    <div className='column-wrapper'>
                                        <div className='form-input-group radio-group col-lg-12'>
                                            <div className='radio-group__label' >{t('JointEvaluation')}</div>
                                            <div className='radio-group__inputs'>
                                                <Radio
                                                    id='jointEvaluationYes'
                                                    label="Sí"
                                                    name='typeJointEvaluation'
                                                    value={true}
                                                    isDisabled={false}
                                                    handleChecked={jointEvaluation === null ? false : jointEvaluation ? true : false}
                                                    handleChange={() => setJointEvaluation(true)}
                                                />
                                                <Radio
                                                    id='jointEvaluationNo'
                                                    label="No"
                                                    name='typeJointEvaluation'
                                                    value={false}
                                                    isDisabled={false}
                                                    handleChecked={jointEvaluation === null ? false : jointEvaluation ? false : true}
                                                    handleChange={() =>  setJointEvaluation(false) }
                                                /> 

                                            </div>
                                        </div>
                                        {
                                            jointEvaluation ? (
                                                <div className='form-input-group select-group col-lg-12 no-label'>
                                                    <Combo 
                                                        id="NameMainCompany"
                                                        data-testid="NameMainCompany"
                                                        defaultInputValue=""
                                                        options={companies}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        className="comboBox"
                                                        handleChange={(e) => setCompanySelected(e)}
                                                        inputValue={companySelected}
                                                        isMultiple={false}
                                                        comboPlaceholder={t('NameMainCompany')}
                                                    /> 
                                                </div>

                                            ) : null
                                        }
                                    </div>
                                    {/* <div className='column-wrapper'>
                                        <div className='form-input-group radio-group col-lg-12'>
                                            <div className='radio-group__label' >{t('Renewal')}</div>
                                            <div className='radio-group__inputs'>
                                                <Radio
                                                    id='renewalYes'
                                                    label="Sí"
                                                    name='typeRenewall'
                                                    value={true}
                                                    isDisabled={false}
                                                    handleChecked={renewal === null ? false : renewal ? true : false}
                                                    handleChange={() => setRenewal(true)}
                                                />
                                                <Radio
                                                    id='renewalNo'
                                                    label="No"
                                                    name='typeRenewall'
                                                    value={false}
                                                    isDisabled={false}
                                                    handleChecked={renewal === null ? false : renewal ? false : true}
                                                    handleChange={() =>  setRenewal(false) }
                                                /> 

                                            </div>
                                        </div>
                                        {
                                            renewal ? (
                                                <div className='form-input-group select-group col-lg-12'>
                                                    <div className='date-wrapper '>
                                                        <DatePickerComponent
                                                            showTodayButton={false}
                                                            allowEdit={false}
                                                            openOnFocus={true}
                                                            isRequired={true}
                                                            value={formValues.renewalDate}
                                                            onChange={(e) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    renewalDate: e.value
                                                                })
                                                            }}
                                                            max={new Date()}
                                                            placeholder="Fecha última renovación"
                                                        /> 
                                                    </div>
                                                </div>

                                            ) : null
                                        }
                                    </div> */}
                                </fieldset>
                            ) : null
                        }
                        <fieldset className='field-container row' >
                            <legend className='legend-heading col-lg-12'>{t('RequestDataHeader')}</legend>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >{t('NaturalOrLegalPerson')} *</div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='fisicPerson'
                                        label="Física"
                                        name='typePerson'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={fisicPersonChecked === null ? false : fisicPersonChecked ? true : false}
                                        handleChange={() => setFisicPersonChecked(true)}
                                    />
                                    <Radio
                                        id='legalPerson'
                                        label="Jurídica"
                                        name='typePerson'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={fisicPersonChecked === null ? false : fisicPersonChecked ? false : true}
                                        handleChange={() =>  setFisicPersonChecked(false) }
                                    /> 

                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-5'>
                                <div className="form-wrapper">
                                    <Input
                                        dataTestID="txtSocialDenomination"
                                        inputName="txtSocialDenomination"
                                        inputType="text"
                                        inputLabel={t('SocialDenomination')}
                                        inputID="socialDenomination"
                                        isDisabled={isSuperAdmin}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            socialDenomination: e.target.value
                                        })}
                                        value={formValues.socialDenomination}
                                        //isError={tradenameError}
                                        //errorMessage={t('IsRequired')}
                                        isRequired={true}
                                        inputPlaceholder={t('SocialDenomination')}
                                    />
                                </div>
                            </div>
                            <div className='form-input-group select-group col-lg-5'>
                                <div className="form-wrapper">
                                    <Input
                                        dataTestID="txtTradename"
                                        inputName="txtTradename"
                                        inputType="text"
                                        inputLabel={t('TradeName')}
                                        inputID="tradename"
                                        isDisabled={isSuperAdmin}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            tradename: e.target.value
                                        })}
                                        value={formValues.tradename}
                                        //isError={tradenameError}
                                        //errorMessage={t('IsRequired')}
                                        isRequired={true}
                                        inputPlaceholder={t('TradeName')}
                                    />
                                </div>
                            </div>    
                            <div className='form-input-group input-group col-lg-2'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="NIF"
                                        dataTestID="NIF"
                                        inputName="NIF"
                                        inputLabel="NIF / CIF / NIE"
                                        inputType="text"
                                        isRequired={true}
                                        inputMaxLength={9}
                                        isError={isValidNIF}
                                        errorMessage={'NIF incorrecto'}
                                        onHandleChange={handleNifChange}
                                        value={formValues.nif}
                                        inputPlaceholder='NIF / CIF / NIE'
                                        isDisabled={isSuperAdmin}
                                        
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group select-group col-lg-4 mt-2'>
                                <div className="form-wrapper">
                                    <Combo 
                                        id="CompanyPublic"
                                        data-testid="CompanyPublic"
                                        defaultInputValue=""
                                        options={optionsPublic}
                                        isSearchable={false}
                                        isClearable={true}
                                        className="comboBox"
                                        handleChange={(e) => setCompanyPublicSelected(e)}
                                        inputValue={companyPublicSelected}
                                        isMultiple={false}
                                        comboLabel={t('Public/Private')}
                                        comboPlaceholder={t('Public/Private')}
                                    /> 
                                </div>
                            </div>
                            <div className='input-group__label col-lg-12'>Domicilio social</div>
                            <div className='form-input-group input-group col-lg-12'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="Address"
                                        dataTestID="Address"
                                        inputName="Address"
                                        inputLabel={t('Address')}
                                        inputType="text"
                                        isRequired={true}
                                        //isError={isValidNIF}
                                        //errorMessage={'NIF incorrecto'}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            address: e.target.value
                                        })}
                                        value={formValues.address}
                                        inputPlaceholder={t('Address')}
                                        isDisabled={isSuperAdmin}
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-4'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="PostalCode"
                                        dataTestID="PostalCode"
                                        inputLabel={t('PostalCode')}
                                        inputName="PostalCode"
                                        inputType="number"
                                        isRequired={true}
                                        inputPlaceholder={t('PostalCode')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            postalCode: e.target.value
                                        })}
                                        value={formValues.postalCode}
                                        isDisabled={isSuperAdmin}
                                    />
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-4'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="City"
                                        dataTestID="City"
                                        inputLabel={t('Town')}
                                        inputName="City"
                                        inputType="text"
                                        isRequired={true}
                                        inputPlaceholder={t('Town')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            city: e.target.value
                                        })}
                                        value={formValues.city}
                                        isDisabled={isSuperAdmin}
                                    />
                                    {/* <InputAutoSuggest                                         
                                        inputLabel={t('Town')}
                                        defaultValue={formValues.city}
                                        placeholderInput={t('Town')}
                                        onHandleChange={(value) => setFormValues({
                                            ...formValues,
                                            city: value
                                        })}
                                        filterFunction={(inputValue) => MUNICIPIOS.filter((el) => el.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))}
                                        showLabel={false}
                                    /> */}
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-4'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="Province"
                                        dataTestID="Province"
                                        inputLabel={t('Province')}
                                        inputName="Province"
                                        inputType="text"
                                        isRequired={true}
                                        inputPlaceholder={t('Province')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            province: e.target.value
                                        })}
                                        value={formValues.province}
                                        isDisabled={isSuperAdmin}
                                    />
                                    {/* <InputAutoSuggest 
                                        inputLabel={t('Province')}
                                        defaultValue={formValues.province}
                                        placeholderInput={t('Province')}
                                        onHandleChange={(value) => setFormValues({
                                            ...formValues,
                                            province: value
                                        })}
                                        filterFunction={(inputValue) => PROVINCIAS.filter((el) => el.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))}
                                        showLabel={false}
                                    /> */}
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-12'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="Web"
                                        dataTestID="Web"
                                        inputLabel='Web'
                                        inputName="Web"
                                        inputType="text"
                                        isRequired={false}
                                        inputPlaceholder="Web"
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            web: e.target.value
                                        })}
                                        value={formValues.web}
                                        isDisabled={isSuperAdmin}
                                    />
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-12'>
                                <div className="form-wrapper">
                                    <Textarea
                                        inputLabel={t('ActivityDescription')}
                                        id="activityDescription"
                                        dataTestID="activityDescription"
                                        className="textarea-description"
                                        isDisabled={isSuperAdmin}
                                        textPlaceholder={t('ActivityDescription')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            activityDescription: e.target.value
                                        })}
                                        inputValue={formValues.activityDescription}
                                        max={500}
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='field-container row mb-2'>
                            <legend className='legend-heading col-lg-12'>Persona de contacto</legend>
                            <div className='form-input-group input-group col-lg-4'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="ContactPersonName"
                                        dataTestID="ContactPersonName"
                                        inputLabel={t('Name')}
                                        inputName="ContactPersonName"
                                        inputType="text"
                                        isRequired={true}
                                        inputPlaceholder={t('Name')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            contactPersonName: e.target.value
                                        })}
                                        value={formValues.contactPersonName}
                                        isDisabled={isSuperAdmin}
                                    />
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-8'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="ContactPersonSurname"
                                        dataTestID="ContactPersonSurname"
                                        inputLabel={t('Surnames')}
                                        inputName="ContactPersonSurname"
                                        inputType="text"
                                        isRequired={true}
                                        inputPlaceholder={t('Surnames')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            contactPersonSurname: e.target.value
                                        })}
                                        value={formValues.contactPersonSurname}
                                        isDisabled={isSuperAdmin}
                                    />
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-5'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="ContactPersonPosition"
                                        dataTestID="ContactPersonPosition"
                                        inputLabel={t('ContactPersonPosition')}
                                        inputName="ContactPersonPosition"
                                        inputType="text"
                                        isRequired={true}
                                        inputPlaceholder={t('ContactPersonPosition')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            contactPersonPosition: e.target.value
                                        })}
                                        value={formValues.contactPersonPosition}
                                        isDisabled={isSuperAdmin}
                                    />
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-3'>
                                <div className="form-wrapper">
                                    <Input
                                        inputID="ContactPersonPhone"
                                        dataTestID="ContactPersonPhone"
                                        inputLabel={t('Phone')}
                                        inputName="ContactPersonPhone"
                                        inputType="number"
                                        isRequired={true}
                                        inputPlaceholder={t('Phone')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            contactPersonPhone: e.target.value
                                        })}
                                        value={formValues.contactPersonPhone}
                                        isDisabled={isSuperAdmin}
                                        avoidCharacters={true}
                                        avoidPoint={true}
                                    />
                                </div>
                            </div>
                            <div className='form-input-group input-group col-lg-4'>
                                <div className="form-wrapper">       
                                    <Input
                                        dataTestID="ContactPersonEmail"
                                        inputName="ContactPersonEmail"
                                        inputType="text"
                                        inputLabel='Email'
                                        inputID="ContactPersonEmail"
                                        isDisabled={isSuperAdmin}
                                        onHandleChange={handleEmailChange}
                                        value={formValues.contactPersonEmail}
                                        isError={emailError}
                                        errorMessage={t('EmailFormatIncorrect')}
                                        isRequired={true}
                                        inputPlaceholder='Email'
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='field-container row mb-2'>
                            <legend className='legend-heading col-lg-12'>Datos empresariales</legend>
                            <div className='form-input-group select-group col-lg-4'>
                                <Combo 
                                    id="CompanyDataSector"
                                    data-testid="CompanyDataSector"
                                    defaultInputValue=""
                                    options={companyDataSectorTypology}
                                    isSearchable={false}
                                    isClearable={true}
                                    className="comboBox"
                                    handleChange={(e) => handleCompanySectorChange(e)}
                                    inputValue={companyDataSector}
                                    isMultiple={false}
                                    comboLabel={t('CompanyDataSector')}
                                    comboPlaceholder={t('CompanyDataSector')}
                                    isDisabled={isSuperAdmin}
                                    isRequired={true}
                                /> 
                            </div>
                            {
                                showOtherCompanySectorField ? (
                                <div className='form-input-group input-group col-lg-8'>
                                    <div className="form-wrapper">
                                        <label className='force-label' htmlFor='CompanyDataSectorFromInput'>{t('CompanyDataSectorFromInput')}</label>
                                        <Input
                                            inputID="CompanyDataSectorFromInput"
                                            dataTestID="CompanyDataSectorFromInput"
                                            inputLabel={t('CompanySector')}
                                            inputName="CompanyDataSectorFromInput"
                                            inputType="text"
                                            isRequired={true}
                                            inputPlaceholder={t('CompanySector')}
                                            onHandleChange={(e) => setCompanyDataSectorOther(e.target.value)}
                                            value={formValues.companyDataSector.value}
                                            isDisabled={isSuperAdmin}
                                        />
                                    </div>
                                </div>
                                ) : null
                            }
                            {
                                companyDataSectorDescription !== '' ? (
                                    <p className='col-lg-12 info-combo-option'>
                                        {companyDataSectorDescription}
                                    </p>
                                ) : null
                            }

                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('ThreeYearsQuestion')}  *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='yearsOfActivityYes'
                                        label={t('Yes')}
                                        name='yearsOfActivityYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={yearsOfActivityChecked === null ? false : yearsOfActivityChecked ? true : false}
                                        handleChange={() => setYearsOfActivityChecked(true)}
                                    />
                                    <Radio
                                        id='yearsOfActivityNo'
                                        label={t('No')}
                                        name='yearsOfActivityNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={yearsOfActivityChecked === null ? false : yearsOfActivityChecked ? false : true}
                                        handleChange={() =>  setYearsOfActivityChecked(false) }
                                    /> 

                                </div>
                            </div>
                            <div className='col-lg-12 heading-form' role='form' aria-label={t('TwoClosedYearsData')}>{t('TwoClosedYearsData')} <span className='info-fields'>(No se admiten decimales)</span></div>
                            <div className='column-wrapper'>
                                <div className='col-lg-12 heading-form__group' role='form' aria-label='último año cerrado'>{t('LastClosedYear')}</div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="LastYearEmployeesNumber"
                                            dataTestID="LastYearEmployeesNumber"
                                            inputLabel={t('EmployeesNumber')}
                                            inputName="LastYearEmployeesNumber"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('EmployeesNumber')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                employeesNumberLastYear: e.target.value
                                            })}
                                            value={lastClosedData.employeesNumberLastYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('EmployeesNumber')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="LastYearTotalIncome"
                                            dataTestID="LastYearTotalIncome"
                                            inputLabel={t('TotalIncome')}
                                            inputName="LastYearTotalIncome"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalIncome')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalIncomeLastYear: e.target.value
                                            })}
                                            value={lastClosedData.totalIncomeLastYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalIncome')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="LastYearExerciseResult"
                                            dataTestID="LastYearExerciseResult"
                                            inputLabel={t('ExerciseResult')}
                                            inputName="LastYearExerciseResult"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('ExerciseResult')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                exerciseResultLastYear: e.target.value
                                            })}
                                            value={lastClosedData.exerciseResultLastYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('ExerciseResult')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="LastYearTotalCurrentActive"
                                            dataTestID="LastYearTotalCurrentActive"
                                            inputLabel={t('TotalCurrentActive')}
                                            inputName="LastYearTotalCurrentActive"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalCurrentActive')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalCurrentActiveLastYear: e.target.value
                                            })}
                                            value={lastClosedData.totalCurrentActiveLastYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalCurrentActive')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="LastYearTotalCurrentPassive"
                                            dataTestID="LastYearTotalCurrentPassive"
                                            inputLabel={t('TotalCurrentPassive')}
                                            inputName="LastYearTotalCurrentPassive"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalCurrentPassive')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalCurrentPassiveLastYear: e.target.value
                                            })}
                                            value={lastClosedData.totalCurrentPassiveLastYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalCurrentPassive')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="LastYearTotalNotCurrentPassive"
                                            dataTestID="LastYearTotalNotCurrentPassive"
                                            inputLabel={t('TotalNotCurrentPassive')}
                                            inputName="LastYearTotalNotCurrentPassive"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalNotCurrentPassive')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalNotCurrentPassiveLastYear: e.target.value
                                            })}
                                            value={lastClosedData.totalNotCurrentPassiveLastYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalNotCurrentPassive')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="LastYearOwnFunds"
                                            dataTestID="LastYearOwnFunds"
                                            inputLabel={t('YearOwnFunds')}
                                            inputName="LastYearOwnFunds"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('YearOwnFunds')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                ownFundsLastYear: e.target.value
                                            })}
                                            value={lastClosedData.ownFundsLastYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('YearOwnFunds')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='column-wrapper'>
                                <div className='col-lg-6 heading-form__group' role='form' aria-label={t('PenultimateYearClosed')}>{t('PenultimateYearClosed')}</div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="PenultimateEmployeesNumber"
                                            dataTestID="PenultimateEmployeesNumber"
                                            inputLabel={t('EmployeesNumber')}
                                            inputName="PenultimateEmployeesNumber"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('EmployeesNumber')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                employeesNumberPenultimateYear: e.target.value
                                            })}
                                            value={lastClosedData.employeesNumberPenultimateYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('EmployeesNumber')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="PenultimateTotalIncome"
                                            dataTestID="PenultimateTotalIncome"
                                            inputLabel={t('TotalIncome')}
                                            inputName="PenultimateTotalIncome"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalIncome')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalIncomePenultimateYear: e.target.value
                                            })}
                                            value={lastClosedData.totalIncomePenultimateYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalIncome')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="PenultimateExerciseResult"
                                            dataTestID="PenultimateExerciseResult"
                                            inputLabel={t('ExerciseResult')}
                                            inputName="PenultimateExerciseResult"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('ExerciseResult')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                exerciseResultPenultimateYear: e.target.value
                                            })}
                                            value={lastClosedData.exerciseResultPenultimateYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('ExerciseResult')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="PenultimateTotalCurrentActive"
                                            dataTestID="PenultimateTotalCurrentActive"
                                            inputLabel={t('TotalCurrentActive')}
                                            inputName="PenultimateTotalCurrentActive"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalCurrentActive')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalCurrentActivePenultimateYear: e.target.value
                                            })}
                                            value={lastClosedData.totalCurrentActivePenultimateYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalCurrentActive')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="PenultimateTotalCurrentPassive"
                                            dataTestID="PenultimateTotalCurrentPassive"
                                            inputLabel={t('TotalCurrentPassive')}
                                            inputName="PenultimateTotalCurrentPassive"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalCurrentPassive')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalCurrentPassivePenultimateYear: e.target.value
                                            })}
                                            value={lastClosedData.totalCurrentPassivePenultimateYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalCurrentPassive')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="PenultimateTotalNotCurrentPassive"
                                            dataTestID="PenultimateTotalNotCurrentPassive"
                                            inputLabel={t('TotalNotCurrentPassive')}
                                            inputName="PenultimateTotalNotCurrentPassive"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('TotalNotCurrentPassive')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                totalNotCurrentPassivePenultimateYear: e.target.value
                                            })}
                                            value={lastClosedData.totalNotCurrentPassivePenultimateYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('TotalNotCurrentPassive')}
                                        />
                                    </div>
                                </div>
                                <div className='form-input-group input-group col-lg-12'>
                                    <div className="form-wrapper last-exercices-input">
                                        <Input
                                            inputID="PenultimateOwnFunds"
                                            dataTestID="PenultimateOwnFunds"
                                            inputLabel={t('YearOwnFunds')}
                                            inputName="PenultimateOwnFunds"
                                            inputType="number"
                                            isRequired={true}
                                            inputPlaceholder={t('YearOwnFunds')}
                                            onHandleChange={(e) => setLastClosedData({
                                                ...lastClosedData,
                                                ownFundsPenultimateYear: e.target.value
                                            })}
                                            value={lastClosedData.ownFundsPenultimateYear}
                                            isDisabled={isSuperAdmin}
                                            avoidPoint={true}
                                            showLabel={isSuperAdmin ? true : false}
                                            floatingLabel={t('YearOwnFunds')}
                                        />
                                    </div>
                                </div>
                            </div>

                        </fieldset>

                        <fieldset className='field-container row mb-2'>
                            <legend className='legend-heading col-lg-12'>{t('ScopeRequest')}</legend>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('EntireOrganization')}  *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='allOrganizationYes'
                                        label={t('Yes')}
                                        name='allOrganizationYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={allOrganizationChecked === null ? false : allOrganizationChecked ? true : false}
                                        handleChange={() => setAllOrganizationChecked(true)}
                                    />
                                    <Radio
                                        id='allOrganizationNo'
                                        label={t('No')}
                                        name='allOrganizationNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={allOrganizationChecked === null ? false : allOrganizationChecked ? false : true}
                                        handleChange={() =>  setAllOrganizationChecked(false) }
                                    /> 
                                </div>
                            </div>
                            {
                                showNotAllOrganizationReason ? (
                                    <div className='form-input-group input-group col-lg-12'>
                                        <div className="form-wrapper">
                                            <Textarea
                                                inputLabel={t('IfNotSpecify')}
                                                id="notAllOrganizationDescription"
                                                dataTestID="notAllOrganizationDescription"
                                                className="textarea-description"
                                                isDisabled={isSuperAdmin}
                                                textPlaceholder={t('IfNotSpecify')}
                                                onHandleChange={(e) => setFormValues({
                                                    ...formValues,
                                                    notAllOrganizationDescription: e.target.value
                                                })}
                                                inputValue={formValues.notAllOrganizationDescription}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }
                        </fieldset>

                        <fieldset className='field-container questions-radios row mb-2'>
                            <legend className='legend-heading col-lg-12'>{t('Questionnaire')}</legend>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire1')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q1DefidePurposeYes'
                                        label={t('Yes')}
                                        name='q1DefidePurposeYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q1DefidePurposeChecked === null ? false : q1DefidePurposeChecked ? true : false}
                                        handleChange={() => setQ1DefidePurposeChecked(true)}
                                    />
                                    <Radio
                                        id='q1DefidePurposeNo'
                                        label={t('No')}
                                        name='q1DefidePurposeNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q1DefidePurposeChecked === null ? false : q1DefidePurposeChecked ? false : true}
                                        handleChange={() =>  setQ1DefidePurposeChecked(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire2')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q2SchemeDevelopmentYes'
                                        label={t('Yes')}
                                        name='q2SchemeDevelopmentYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q2SchemeDevelopmentChecked === null ? false : q2SchemeDevelopmentChecked ? true : false}
                                        handleChange={() => setQ2SchemeDevelopmentChecked(true)}
                                    />
                                    <Radio
                                        id='q2SchemeDevelopmentNo'
                                        label={t('No')}
                                        name='q2SchemeDevelopmentNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q2SchemeDevelopmentChecked === null ? false : q2SchemeDevelopmentChecked ? false : true}
                                        handleChange={() =>  setQ2SchemeDevelopmentChecked(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire3')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q3QualityManagementSystemYes'
                                        label={t('Yes')}
                                        name='q3QualityManagementSystem'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q3QualityManagementSystemChecked === null ? false : q3QualityManagementSystemChecked ? true : false}
                                        handleChange={() => setQ3QualityManagementSystemChecked(true)}
                                    />
                                    <Radio
                                        id='q3QualityManagementSystemYesNo'
                                        label={t('No')}
                                        name='q3QualityManagementSystemNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q3QualityManagementSystemChecked === null ? false : q3QualityManagementSystemChecked ? false : true}
                                        handleChange={() =>  setQ3QualityManagementSystemChecked(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire4')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q4QualityISOSystemYes'
                                        label={t('Yes')}
                                        name='q4QualityISOSystemYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q4QualityISOSystemChecked === null ? false : q4QualityISOSystemChecked ? true : false}
                                        handleChange={() => setQ4QualityISOSystemChecked(true)}
                                    />
                                    <Radio
                                        id='q4QualityISOSystemNo'
                                        label={t('No')}
                                        name='q4QualityISOSystemNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q4QualityISOSystemChecked === null ? false : q4QualityISOSystemChecked ? false : true}
                                        handleChange={() =>  setQ4QualityISOSystemChecked(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire5')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q5SatisfactionEmployeesDegreesYes'
                                        label={t('Yes')}
                                        name='q5SatisfactionEmployeesDegreesYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q5SatisfactionEmployeesDegreesChecked === null ? false : q5SatisfactionEmployeesDegreesChecked ? true : false}
                                        handleChange={() => setQ5SatisfactionEmployeesDegreesChecked(true)}
                                    />
                                    <Radio
                                        id='q5SatisfactionEmployeesDegreesNo'
                                        label={t('No')}
                                        name='q5SatisfactionEmployeesDegreesNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q5SatisfactionEmployeesDegreesChecked === null ? false : q5SatisfactionEmployeesDegreesChecked ? false : true}
                                        handleChange={() =>  setQ5SatisfactionEmployeesDegreesChecked(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire6')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q6SatisfactionClientDegreesYes'
                                        label={t('Yes')}
                                        name='q6SatisfactionClientDegreesYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q6SatisfactionClientDegreesCheck === null ? false : q6SatisfactionClientDegreesCheck ? true : false}
                                        handleChange={() => setQ6SatisfactionClientDegreesCheck(true)}
                                    />
                                    <Radio
                                        id='q6SatisfactionClientDegreesNo'
                                        label={t('No')}
                                        name='q6SatisfactionClientDegreesNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q6SatisfactionClientDegreesCheck === null ? false : q6SatisfactionClientDegreesCheck ? false : true}
                                        handleChange={() =>  setQ6SatisfactionClientDegreesCheck(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire7')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q7EnvironmentalInpactPracticesYes'
                                        label={t('Yes')}
                                        name='q7EnvironmentalInpactPracticesYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q7EnvironmentalInpactPracticesChecked === null ? false : q7EnvironmentalInpactPracticesChecked ? true : false}
                                        handleChange={() => setQ7EnvironmentalInpactPracticesChecked(true)}
                                    />
                                    <Radio
                                        id='q7EnvironmentalInpactPracticesNo'
                                        label={t('No')}
                                        name='q7EnvironmentalInpactPracticesNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q7EnvironmentalInpactPracticesChecked === null ? false : q7EnvironmentalInpactPracticesChecked ? false : true}
                                        handleChange={() =>  setQ7EnvironmentalInpactPracticesChecked(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire8')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q8QualityISOEnvironmentYes'
                                        label={t('Yes')}
                                        name='q8QualityISOEnvironmentYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q8QualityISOEnvironmentChecked === null ? false : q8QualityISOEnvironmentChecked ? true : false}
                                        handleChange={() => setQ8QualityISOEnvironmentChecked(true)}
                                    />
                                    <Radio
                                        id='q8QualityISOEnvironmentNo'
                                        label={t('No')}
                                        name='q8QualityISOEnvironmentNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q8QualityISOEnvironmentChecked === null ? false : q8QualityISOEnvironmentChecked ? false : true}
                                        handleChange={() =>  setQ8QualityISOEnvironmentChecked(false) }
                                    /> 
                                </div>
                            </div>
                            <div className='form-input-group radio-group col-lg-12'>
                                <div className='radio-group__label' >
                                    {t('Questionnaire9')} *
                                </div>
                                <div className='radio-group__inputs'>
                                    <Radio
                                        id='q9ContributeEnvironmentCommunityYes'
                                        label={t('Yes')}
                                        name='q9ContributeEnvironmentCommunityYes'
                                        value={true}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q9ContributeEnvironmentCommunityChecked === null ? false : q9ContributeEnvironmentCommunityChecked ? true : false}
                                        handleChange={() => setQ9ContributeEnvironmentCommunityChecked(true)}
                                    />
                                    <Radio
                                        id='q9ContributeEnvironmentCommunityNo'
                                        label={t('No')}
                                        name='q9ContributeEnvironmentCommunityNo'
                                        value={false}
                                        isDisabled={isSuperAdmin}
                                        handleChecked={q9ContributeEnvironmentCommunityChecked === null ? false : q9ContributeEnvironmentCommunityChecked ? false : true}
                                        handleChange={() =>  setQ9ContributeEnvironmentCommunityChecked(false) }
                                    /> 
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='field-container row mb-2'>
                            <legend className='legend-heading col-lg-12'>Documentación a aportar</legend>
                            <div className="upload-container">
                                <ul className='upload-container__list'>
                                    <li className='upload-container__list-item'>
                                        <div className='radio-group__label'>- Certificado del registro correspondiente de la constitución de la entidad o alta en el régimen de autónomos *</div>
                                        {/* <p className="small">{t('OnlyPdf')}</p> */}
                                        <div className="upload-wrapper">
                                            <div className="button-upload">
                                            <span className='file-name'>{filesData.certificateEntity.name}</span>
                                                {
                                                    !isSuperAdmin ? (
                                                        <label 
                                                            htmlFor="btnRegistrationConstitutionFile" 
                                                            role={'button'}
                                                            className={`btn-base_normal ${formValues.registrationConstitutionFileName ? 'btn-base_outline' : ''}`}
                                                        >
                                                            {
                                                                !formValues.registrationConstitutionFileName ? (
                                                                    <i className="material-icons-round">file_upload</i>
                                                                ) : <i className="material-icons-round">refresh</i>
                                                            }
                                                            <span>{!formValues.registrationConstitutionFileName ? `${t('Subir archivo')}` : 'Reemplazar'}</span> 
                                                        
                                                        </label>

                                                    ) : null
                                                }
                                                {
                                                    filesData.certificateEntity.url !== '' ? (
                                                        <div className='file-wrapper'>
                                                            
                                                            <a title={formValues.registrationConstitutionFileName} href={filesData.certificateEntity.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                                        </div>
                                                    ) : ''
                                                }
                                                <input
                                                    id="btnRegistrationConstitutionFile"
                                                    data-testid="btnRegistrationConstitutionFile"
                                                    type="file"
                                                    onChange={(e) => uploadFile(e, 'registrationConstitutionFile')}
                                                    name="btnRegistrationConstitutionFile"
                                                    accept=".pdf, .doc, .docx, image/png, image/jpeg"
                                                    className="inputfile"
                                                    disabled={isSuperAdmin}
                                                />
                                            
                                            </div>
                                        </div>
                                    </li>
                                    <li className='upload-container__list-item'>
                                        <div className='radio-group__label'>- Certificado de estar al corriente de pago de las obligaciones tributarias *</div>
                                        {/* <p className="small">{t('OnlyPdf')}</p> */}
                                        <div className="upload-wrapper">
                                            <div className="button-upload">
                                            <span className='file-name'>{filesData.certificateAllPaidTaxes.name}</span>
                                                {
                                                    !isSuperAdmin ? (
                                                        <label 
                                                            htmlFor="btnPaymentTaxFile" 
                                                            role={'button'}
                                                            className={`btn-base_normal ${formValues.paymentTaxFileName ? 'btn-base_outline' : ''}`}
                                                        >
                                                            {
                                                                !formValues.paymentTaxFileName ? (
                                                                    <i className="material-icons-round">file_upload</i>
                                                                ) : <i className="material-icons-round">refresh</i>
                                                            }
                                                            <span>{!formValues.paymentTaxFileName ? `${t('Subir archivo')}` : 'Reemplazar'}</span> 
                                                        
                                                        </label>

                                                    ) : null
                                                }
                                                {
                                                    filesData.certificateAllPaidTaxes.url !== '' ? (
                                                        <div className='file-wrapper'>
                                                            <a title={formValues.paymentTaxFileName} href={filesData.certificateAllPaidTaxes.url} className="material-icons-round file-link" target="_blank" rel="noreferrer">file_download</a>
                                                        </div>
                                                    ) : null
                                                }
                                                <input
                                                    id="btnPaymentTaxFile"
                                                    data-testid="btnPaymentTaxFile"
                                                    type="file"
                                                    onChange={(e) => uploadFile(e, 'paymentTaxFile')}
                                                    name="btnPaymentTaxFile"
                                                    accept=".pdf, .doc, .docx, image/png, image/jpeg"
                                                    className="inputfile"
                                                    disabled={isSuperAdmin}
                                                />
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li className='upload-container__list-item'>
                                        <div className='radio-group__label'>- Certificado de estar al corriente de pago de las obligaciones con la Seguridad Social *</div>
                                        <div className="upload-wrapper">
                                            <div className="button-upload">
                                                <span className='file-name'>{filesData.certificateSocialSecurity.name}</span>
                                                {
                                                    !isSuperAdmin ? (
                                                        <label 
                                                            htmlFor="btnPaymentSocialSecurityFile" 
                                                            role={'button'}
                                                            className={`btn-base_normal ${formValues.paymentSocialSecurityFileName ? 'btn-base_outline' : ''}`}
                                                        >
                                                            {
                                                                !formValues.paymentSocialSecurityFileName ? (
                                                                    <i className="material-icons-round">file_upload</i>
                                                                ) : <i className="material-icons-round">refresh</i>
                                                            }
                                                            <span>{!formValues.paymentSocialSecurityFileName ? `${t('Subir archivo')}` : 'Reemplazar'}</span> 
                                                        
                                                        </label>

                                                    ) : null
                                                }
                                                {
                                                    filesData.certificateSocialSecurity.url ? (
                                                        <div className='file-wrapper'>
                                                            <a title={formValues.paymentSocialSecurityFileName} href={filesData.certificateSocialSecurity.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                                        </div>
                                                    ) : null
                                                }
                                                <input
                                                    id="btnPaymentSocialSecurityFile"
                                                    data-testid="btnPaymentSocialSecurityFile"
                                                    type="file"
                                                    onChange={(e) => uploadFile(e, 'paymentSocialSecurityFile')}
                                                    name="btnPaymentSocialSecurityFile"
                                                    accept=".pdf, .doc, .docx, image/png, image/jpeg"
                                                    className="inputfile"
                                                    disabled={isSuperAdmin}
                                                />
                                                
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </fieldset>

                        <fieldset className='field-container row mb-2'>
                            <legend className='legend-heading col-lg-12'>Observaciones</legend>
                            <div className='form-input-group input-group col-lg-12'>
                                <div className="form-wrapper">
                                    <Textarea
                                        inputLabel="Observaciones"
                                        id="observations"
                                        dataTestID="observations"
                                        className="textarea-description"
                                        isDisabled={isSuperAdmin}
                                        textPlaceholder='Observaciones'
                                    
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            observations: e.target.value
                                        })}
                                        inputValue={formValues.observations}
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <div className='checks-wrapper row'>
                            <div className='checks-wrapper__item col-lg-12'>
                                
                                <Checkbox
                                    inputID={'acceptTerms'}
                                    dataTestID={'acceptTerms'}
                                    inputName={'acceptTerms'}
                                    inputLabel={t('He leído y acepto la')}
                                    inputChecked={formValues.acceptTerms}
                                    onHandleChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            acceptTerms: e.target.checked
                                        });
                                    }}
                                    withLink={true}
                                    link={'https://www.fundacionmadrid.org/politica-de-privacidad/'} 
                                    linkLabel='política de privacidad'
                                    //subLabel='política de privacidad'
                                    isDisabled={isSuperAdmin}
                                    isRequired={true}
		                            srOnlyMessage='He leído y acepto la política de privacidad'
                                />
                            </div>
                            <div className='checks-wrapper__item col-lg-12'>
                                <Checkbox
                                    inputID={'acceptMailing'}
                                    dataTestID={'acceptMailing'}
                                    inputName={'acceptMailing'}
                                    inputLabel={t('Deseo recibir comunicaciones de Madrid Excelente')}
                                    inputChecked={formValues.acceptMailing}
                                    onHandleChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            acceptMailing: e.target.checked
                                        });
                                    }}
                                    withLink={false}
                                    isDisabled={isSuperAdmin}
                                />
                            </div>
                            <div className='col-lg-12'>
                                <p className='bottom-text'>
                                La Fundación Madrid por la Competitividad se compromete a guardar la confidencialidad de la información obtenida por el trámite que se efectúe para la concesión de la Licencia de Uso de la Marca de Garantía MADRID EXCELENTE, tanto por la organización de la propia Fundación, así como de terceras personas o entidades que intervengan en el trámite de la concesión. La Fundación Madrid por la Competitividad sólo facilitará información sobre las características de un producto o servicio previa autorización expresa de su titular o por imperativo legal, notificando, en este último caso, a su titular los datos suministrados.
                                </p>
                            </div>
                        </div>
                        {
                            requestId ? (
                                <div className='btn-submit-container row mt-4'>
                                    <div className='btn-submit-wrapper'>
                                        <Button
                                            btnText={ t('UpdateRequest')}
                                            btnType="submit"
                                            //isDisabled={isSuperAdmin ? false : isBtnDisabled}
                                            isDisabled={isSuperAdmin ? false : isBtnDisabled}
                                            btnClass="btn-base_normal"
                                            dataTestID="btnUpdateRequest"
                                            onHandleBtnClick={(e) => updateRequestData(e)}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className='btn-submit-container row mt-4'>
                                    <div className='btn-submit-wrapper'>
                                        <Button
                                            btnText={t('SendRequest')}
                                            btnType="submit"
                                            isDisabled={isSuperAdmin ? false : isBtnDisabled}
                                            btnClass="btn-base_normal"
                                            dataTestID="btnSendRequest"
                                            //onHandleBtnClick={(e) => requestData(e)}
                                        />
                                    </div>
                                </div>

                            )
                        }
                    </form>
                </div>

                <div className='request-footer'>
                    <Footer 
                        showLogo={false}
                    />
                </div>
            </div>
        </div>
    )
}
