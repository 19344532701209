import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { resetProfile } from '@backoffice/actions/profile';
import { startRequest } from '@backoffice/actions/apiCall';
import { DeleteAllCookies, GetCookie_refreshToken } from '@backoffice/common/utils/cookiesManager';
import { DeleteSessionStorage } from '@backoffice/common/utils/storageManager';
import { postLogoutParams } from './LogoutCalls';
import { updateMenu } from '@backoffice/actions/menu';
import { resetAppearance } from '@backoffice/actions/appearance';

const Logout = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		logout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logout = () => {
		let refreshToken = GetCookie_refreshToken();
		if (refreshToken !== '') {
			let params = { refreshToken: refreshToken };
			dispatch(startRequest(postLogoutParams(params, removeCredentials)));
		} else {
			removeCredentials();
		}
	};

	const removeCredentials = () => {
		DeleteAllCookies();
		DeleteSessionStorage();
		dispatch(resetAppearance());
		dispatch(updateMenu([]));
		dispatch(resetProfile());
		history.push('/');
	};

	return null;
};

export default Logout;
