import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../../actions/popup';
import Input from '../../../../components/FormComponents/Input';
import Button from '../../../../components/FormComponents/Button';
import { isValidCif } from '../../../../common/validations/common';
import './AuditorCompaniesModal.scss';

export default function AuditorCompaniesModal({auditorCompany, handleSubmit, action, showFixedLabel}) {
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        id: auditorCompany ? auditorCompany.id : '',
        name: auditorCompany ? auditorCompany.name : '',
        cif: auditorCompany ? auditorCompany.cif : ''
    })

    const postAuditorCompany = (e) => {
        e.preventDefault();
        let nameError = false;
        let cifError = false;

        if( !isValidCif(formValues.cif) ) {
            cifError = true;
            dispatch(closeModal())
            dispatch(showFixedLabel(t('CifFormatIncorrect'), 'error'))
        } 

        if( formValues.name === '' ) {
            nameError = true;
            dispatch(closeModal())
            dispatch(showFixedLabel(t('EmptyNameError'), 'error'))
        }
        
        if( !nameError && !cifError) {
            dispatch(closeModal())
            handleSubmit(action, formValues)            
        }
    }

    return(
        <div className='auditor-edit-modal'>
            <form className='form-auditor-edit'>
                <div className='row'>
                    <div className='form-input-group input-group col-lg-6'>
                        <div className="form-wrapper">
                            <Input
                                inputID="Name"
                                dataTestID="Name"
                                inputLabel={t('Name')}
                                inputName="Name"
                                inputType="text"
                                showLabel={true}
                                isRequired={true}
                                inputPlaceholder={t('Name')}
                                onHandleChange={(e) => setFormValues({
                                    ...formValues,
                                    name: e.target.value
                                })}
                                value={formValues.name}
                            />
                        </div>
                    </div>
                    <div className='form-input-group input-group col-lg-6'>
                        <div className="form-wrapper">
                            <Input
                                inputID="CIF"
                                dataTestID="CIF"
                                inputLabel={'CIF'}
                                inputName="Name"
                                inputType="text"
                                showLabel={true}
                                isRequired={true}
                                inputPlaceholder={'CIF'}
                                onHandleChange={(e) => setFormValues({
                                    ...formValues,
                                    cif: e.target.value
                                })}
                                value={formValues.cif}
                            />
                        </div>
                    </div>
                </div>
                <div className='btn-submit-container row mt-4'>
                    <div className={`btn-submit-wrapper col-lg-12 d-flex`}>
                        <div className='d-flex justify-content-end'>
                            <Button
                                dataTestID="btnCancel"
                                btnText={t('Cancel')}
                                btnType="button"
                                btnClass="btn-base_outline ml-5"
                                onHandleBtnClick={() => {
                                    dispatch(closeModal());
                                }}
                            />

                            <Button
                                btnText={(action === 'edit') ? t('EditAuditorCompany') : t('AddAuditorCompany')}
                                btnType="submit"
                                isDisabled={false}
                                btnClass="btn-base_normal ml-3"
                                dataTestID="btnUserEditRequest"
                                id='btnUserEditRequest'
                                onHandleBtnClick={(e) => postAuditorCompany(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}