import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Combo, Input } from '../../../../components/FormComponents';

const RequestDataEvaluator = ({isAuditorCoordinator, formValues, evaluatorDataComboSelected, setEvaluatorDataComboSelected, removeEvaluatorToList, firstEvaluator}) => {

    const { t } = useTranslation();

    return (
        <>
        {
            evaluatorDataComboSelected &&
            <div className='data-block'>
                <div className="content-wrapper">
                    <div className="flex-container">
                        <div className="row-wrapper full flex-container">
                            <div className='title-wrapper'>
                                <h2 className='title'>{t('EvaluationPersonData')}</h2>                
                            </div>
                            <div className={`flex-container combo-choose-evaluator ${ isAuditorCoordinator ? '' : 'full-combo'}`}>
                                
                                <Combo
                                    id="auditorsByCompanyComboSelectedInfo"
                                    data-testid="auditorsByCompanyComboSelectedInfo"
                                    defaultInputValue=""
                                    options={formValues.EvaluatorsList}
                                    isSearchable={false}
                                    isClearable={true}
                                    className="comboBox"
                                    handleChange={(e) => setEvaluatorDataComboSelected(e)}
                                    inputValue={evaluatorDataComboSelected}
                                    isMultiple={false}
                                    comboPlaceholder={t('Seleccionar')}
                                />
                                {
                                    isAuditorCoordinator &&
                                        <div
                                            className='actions'
                                            role='button'
                                            onClick={removeEvaluatorToList}
                                        >
                                            <span className='material-icons-round eye' aria-hidden={true}>close</span>
                                        </div>
                                }  
                            </div>
                        </div>
                        <div className='middle-wrapper'>
                            <div className='evaluator-data middle'>
                                <Input
                                    inputName="evaluatorName"
                                    inputType="text"
                                    inputLabel={t('Name')}
                                    inputID="evaluatorName"
                                    isDisabled={true}
                                    readOnly={true}
                                    showLabel={true}
                                    inputPlaceholder={t('Name')}
                                    onHandleChange={() => {}}
                                    value={evaluatorDataComboSelected.value ? evaluatorDataComboSelected.value : ''}
                                />
                            </div>
                            <div className='evaluator-data middle'>
                                <Input
                                    inputName="evaluatorSurnames"
                                    inputType="text"
                                    inputLabel={t('Surnames')}
                                    inputID="evaluatorSurnames"
                                    isDisabled={true}
                                    readOnly={true}
                                    showLabel={true}
                                    inputPlaceholder={t('Surnames')}
                                    onHandleChange={() => {}}
                                    value={evaluatorDataComboSelected.surnames ? evaluatorDataComboSelected.surnames : ''}
                                />
                            </div>
                        </div>
                        <div className='middle-wrapper'>
                            <div className='evaluator-data middle'>
                                <Input
                                    inputName="evaluatorsPhone"
                                    inputType="number"
                                    inputLabel={t('Phone')}
                                    inputID="evaluatorsPhone"
                                    isDisabled={true}
                                    readOnly={true}
                                    showLabel={true}
                                    inputPlaceholder={t('Phone')}
                                    onHandleChange={() => {}}
                                    value={evaluatorDataComboSelected.phoneNumber ? evaluatorDataComboSelected.phoneNumber : ''}
                                />
                            </div>
                            <div className='evaluator-data middle'>
                                <Input
                                    inputName="evaluatorEmail"
                                    inputType="text"
                                    inputLabel={t('Email')}
                                    inputID="evaluatorEmail"
                                    isDisabled={true}
                                    readOnly={true}
                                    showLabel={true}
                                    inputPlaceholder={t('Email')}
                                    onHandleChange={() => {}}
                                    value={evaluatorDataComboSelected.email ? evaluatorDataComboSelected.email : ''}
                                />
                            </div>

                        </div>
                        <div className='evaluator-data full'>
                            <Input
                                inputName="evaluatorEntity"
                                inputType="text"
                                inputLabel={t('Entity')}
                                inputID="evaluatorEntity"
                                isDisabled={true}
                                readOnly={true}
                                showLabel={true}
                                inputPlaceholder={t('Entity')}
                                onHandleChange={() => {}}
                                value={evaluatorDataComboSelected.auditorCompanyName ? evaluatorDataComboSelected.auditorCompanyName : ''}
                            />
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default RequestDataEvaluator;