import customCallback from '../../actions/customCallback';

/* Redux state init */
const initialState = {
	custom_callback: 0
};

export default function customCallbackReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {

			case customCallback.CUSTOM_CALLBACK:
				return {
					...state,
					custom_callback: state.custom_callback + 1
				}
			default:
				return { ...state };
		}
	}
	return state;
}
