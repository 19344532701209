import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../../actions/popup';
import { startRequest } from '@backoffice/actions/apiCall';
import { getAllRolesExceptCompany, postUserManagementParams, putUserManagementParams, getAllAuditorCompanies, postResentEmailParams, putUserManagementCompanyParams, getUserCompaniesParams, postUserManagementCompanyParams } from '../UsersCalls/UsersCalls'
import Input from '../../../../components/FormComponents/Input';
import Button from '../../../../components/FormComponents/Button';
import { Checkbox, Combo } from '../../../../components/FormComponents';
import validations from '../../../../common/validations/index';
import './UserModal.scss';
import checkIsPrincipalAnyCompany from '../../../../common/utils/checkIsPrincipal';

export default function UserModal({user, handleSubmit, action, showFixedLabel, isUserCompany, userRol}) {
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        name: '',
        surnames: '',
        email: '',
        rolId: '',
        companyId: '',
        phoneNumber: '',
        //isActive: null,
        isAuditorCoordinator: false,
        emailConfirmed: false,
        oldCompanyId: ''
    })

    const showFiltersByRol = () => {
        let showCompany = false;
        let showCheckCompany = false;

        if(userRol === 'Auditor' || rolName === 'Auditor') {
            showCompany = true;
            showCheckCompany = true;
        } else if(userRol === 'Company'){
            if( action === 'edit') {
                showCompany = !checkIsPrincipalAnyCompany(user) ? true : false;
            } else {
                showCompany = true;
            }
            showCheckCompany = false;
        } else {
            showCompany = false;
            showCheckCompany = false;
        }

        return { showCompany, showCheckCompany }
    }

    const [email, setEmail] = useState('');

    //Errors
    const [emailError, setEmailError ] = useState(false);
    const [rolTypeOptions, setRolTypeOptions] = useState([])
	const [rolTypeComboSelected, setRolTypeComboSelected] = useState()
    const [rolName, setRolName] = useState(user?.userRoles[0].name)
    const [companiesOptions, setCompaniesOptions] = useState([])
    const [companiesComboSelected, setCompaniesComboSelected] = useState()

    const [showCompanyCombo, setShowCompanyCombo] = useState(() => { 
        const { showCompany } = showFiltersByRol(); 
        return showCompany; 
    })
    const [showCheckAuditorCoordinator, setShowCheckAuditorCoordinator] = useState(() => { 
        const { showCheckCompany } = showFiltersByRol(); 
        return showCheckCompany; 
    })

    //const [isAuditorCoordinatorCheck, setIsAuditorCoordinatorCheck] = useState(false)
    
    useEffect(() => {
		!isUserCompany && getRoles();
		!isUserCompany ? getCompanies() : getUserCompanies()
        checkIsEdit();
	}, []);    
    
    useEffect(() => {
		checkIsEdit();
	},[rolTypeOptions]);
    
    useEffect(() => {
        if(user) {
            switch (user.userRoles[0].name) {
                case 'Auditor':
                    if(user ? user.userData.auditorUserData.companyId !== null : null){
                        const company = companiesOptions.filter((el) => el.id === user.userData.auditorUserData.companyId);
                        setCompaniesComboSelected(company)
                    }
                    break;
                case 'Company':
                    if(user ? user.userData.companyUserData !== null : null){
                        let company = '';

                        user.userData.companyUserData.companiesInfo.forEach((info) => {
                            company = companiesOptions.filter((el) => el.id === info.companyId)
                        })

                        setCompaniesComboSelected(company)
                    }
                    break;
            
                default:
                    break;
            }
        } 
    }, [companiesOptions])

    const checkIsEdit = () => {
		if (action === 'edit') setElementToEdit();
	};

    const setElementToEdit = () => {
        setFormValues({
            id: user.id,
            name: user.name,
            surnames: user.surnames,
            email: user.email,
            rolId: user.userRoles[0].id,
            companyId: user.userRoles[0].name === 'Auditor' 
                            ? user.userData.auditorUserData?.companyId 
                            : user.userRoles[0].name === 'Company'
                                ? user.userData.companyUserData?.companiesInfo[0].companyId
                                : null,
            oldCompanyId: user.userRoles[0].name === 'Auditor' 
                            ? user.userData.auditorUserData?.companyId 
                            : user.userRoles[0].name === 'Company'
                                ? user.userData.companyUserData?.companiesInfo[0].companyId
                                : null,
            phoneNumber: user.phoneNumber,
            isAuditorCoordinator: user.userData.auditorUserData ? user.userData.auditorUserData.isCoordinator : null
        })
        const role = rolTypeOptions.filter((el) => el.id === user.userRoles[0].id);
        setRolTypeComboSelected(role)
    }

    useEffect(() => {
        const { showCompany, showCheckCompany } = showFiltersByRol();
        setShowCompanyCombo(showCompany);
        setShowCheckAuditorCoordinator(showCheckCompany);
    }, [rolName]);

    useEffect(() => {
        user && setEmail(user.email);
    }, [user]);

    const getRoles = () => {
		dispatch(startRequest(getAllRolesExceptCompany(getAllRolesExceptCompanySuccess)))
	}

	const getAllRolesExceptCompanySuccess = (response) => {
		let options = [];
        response.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: t(el.name)
            });
        });
		setRolTypeOptions(options)
	}

    const getCompanies = () => {
        dispatch(startRequest(getAllAuditorCompanies(getAllAuditorCompaniesSuccess)))
    }

    const getUserCompanies = () => {
        dispatch(startRequest(getUserCompaniesParams(getAllAuditorCompaniesSuccess)))
    }

    const getAllAuditorCompaniesSuccess = (data) => {
        let options = [];
        data.forEach((el) => {            
            if( !el.isDeleted) {
                options.push({
                    id: el.id,
                    value: el.name,
                    label: t(el.name)
                });
            }
        });
        setCompaniesOptions(options)
    }

    const postEditUser = (e) => { 
        e.preventDefault()
        if(userRol === 'Admin') {
            if (action === 'edit') {
                dispatch(startRequest(putUserManagementParams(formValues, postUserManagementParamsSuccess, postUserManagementParamsError))); 
            }
            if (action === 'add') {
                dispatch(startRequest(postUserManagementParams(formValues, postUserManagementParamsSuccess, postUserManagementParamsError)));
            }
        } else {
            if (action === 'edit') {
                dispatch(startRequest(putUserManagementCompanyParams(formValues, postUserManagementParamsSuccess, postUserManagementParamsError))); 
            }
            if (action === 'add') {
                dispatch(startRequest(postUserManagementCompanyParams(formValues, postUserManagementParamsSuccess, postUserManagementParamsError)));
            }
        }
    }

    const resendEmail = () => {
		const resendEmail = { email };
		if (email) {
			dispatch(startRequest(postResentEmailParams(resendEmail, postResentEmailParamsSuccess)));
		}
	};

	const postResentEmailParamsSuccess = (data) => 
    { 
        if (data)
        {
            dispatch(showFixedLabel('El email de confirmación se ha enviado correctamente', 'success'));
        }
        else
        {
            dispatch(showFixedLabel('No se ha enviado el email de confirmación', 'success'));
        }
    };

    const postUserManagementParamsSuccess = (data) => {
        dispatch(closeModal())
        dispatch(
			showFixedLabel(action === 'edit' ? 'Usuario editado correctamente' : 'Usuario creado correctamente', 'success')
		);
        handleSubmit()
    }    

    const postUserManagementParamsError = (response) => {
        dispatch(closeModal())
        //dispatch(showFixedLabel(response.Errors[0].ErrorMessageKey, 'error'));
        const message = action === 'edit' ? 'Error al editar el usuario' : 'Error al crear el usuario'
        dispatch(showFixedLabel(message, 'error')) //TODO Translate back error
        //console.log(response);
    }

    const handleEmailChange = (e) => {
		let val = e.target.value;
        setEmailError(validations.validateEmail(val).error);
        setFormValues({
            ...formValues,
            email: val
        })
	};

    const handleSetRole = (e) => {
        setRolName(e.value);
        setRolTypeComboSelected(e);
        if(e.value === 'Admin') {
            setFormValues({
                ...formValues,
                rolId: e.id,
                companyId: ''
            })
            setCompaniesComboSelected([]);
            setShowCheckAuditorCoordinator(false);
        } else {
            setFormValues({
                ...formValues,
                rolId: e.id
            });
        }
	};

    const handleSetCompany = (e) => {
        setCompaniesComboSelected(e);
        setFormValues({
            ...formValues,
            oldCompanyId: formValues.companyId,
            companyId: e.id,
        })
    }

    return(
        <div className='user-edit-modal'>
            <form className='form-user-edit'>
                <div className='row'>
                    <div className='form-input-group input-group col-lg-6'>
                        <div className="form-wrapper">
                            <Input
                                inputID="Name"
                                dataTestID="Name"
                                inputLabel={t('Name')}
                                inputName="Name"
                                inputType="text"
                                showLabel={true}
                                isRequired={true}
                                inputPlaceholder={t('Name')}
                                onHandleChange={(e) => setFormValues({
                                    ...formValues,
                                    name: e.target.value
                                })}
                                value={formValues.name}
                            />
                        </div>
                    </div>
                    <div className='form-input-group input-group col-lg-6'>
                        <div className="form-wrapper">
                            <Input
                                inputID="Surnames"
                                dataTestID="Surnames"
                                inputLabel={t('Surnames')}
                                inputName="Name"
                                inputType="text"
                                showLabel={true}
                                isRequired={true}
                                inputPlaceholder={t('Surnames')}
                                onHandleChange={(e) => setFormValues({
                                    ...formValues,
                                    surnames: e.target.value
                                })}
                                value={formValues.surnames}
                            />
                        </div>
                    </div>
                    <div className='form-input-group input-group col-lg-6'>
                        <div className="form-wrapper">       
                            <Input
                                dataTestID="email"
                                inputName="email"
                                inputType="text"
                                inputLabel='Email'
                                inputID="email"
                                showLabel={true}
                                isDisabled={action === 'edit' ? true : false}
                                onHandleChange={handleEmailChange}
                                value={formValues.email}
                                isError={emailError}
                                errorMessage={t('EmailFormatIncorrect')}
                                isRequired={true}
                                inputPlaceholder='Email' 
                            />
                        </div>
                    </div>
                    {
                        !isUserCompany && user?.userRoles[0].name !== 'Company' &&
                            <div className='form-input-group input-group col-lg-6 select-group'>
                                <Combo 
                                    id="RoleId"
                                    data-testid="RoleId"
                                    defaultInputValue=""
                                    options={rolTypeOptions}
                                    isSearchable={false}
                                    isClearable={true}
                                    className="comboBox"
                                    handleChange={(e) => handleSetRole(e)}
                                    inputValue={rolTypeComboSelected}
                                    isMultiple={false}
                                    isRequired={true}
                                    comboLabel={t('Rol')}
                                    comboPlaceholder={t('Rol')}
                                    // isDisabled={action === 'edit' ? true : false}
                                /> 
                            </div>
                    }

                    <div className='form-input-group input-group col-lg-6 select-group'>
                        {
                            showCheckAuditorCoordinator || showCompanyCombo ?  (
                                <div className="form-wrapper">
                                    <Input
                                        inputID="ContactPersonPhone"
                                        dataTestID="ContactPersonPhone"
                                        inputLabel={t('Phone')}
                                        inputName="ContactPersonPhone"
                                        inputType="number"
                                        isRequired={true}
                                        showLabel={true}
                                        inputPlaceholder={t('Phone')}
                                        onHandleChange={(e) => setFormValues({
                                            ...formValues,
                                            phoneNumber: e.target.value
                                        })}
                                        value={formValues.phoneNumber ? formValues.phoneNumber : ''}
                                    />
                                </div>
 
                            ) : null
                        }
                    </div>
                    <div className='form-input-group input-group col-lg-6 select-group'>
                        
                        {
                            showCompanyCombo ? (
                                <Combo 
                                    id="CompanyId"
                                    data-testid="CompanyId"
                                    defaultInputValue=""
                                    options={companiesOptions}
                                    isSearchable={false}
                                    isClearable={true}
                                    className="comboBox"
                                    handleChange={(e) => handleSetCompany(e)}
                                    inputValue={companiesComboSelected}
                                    isMultiple={false}
                                    isRequired={true}
                                    comboLabel={t('Company')}
                                    comboPlaceholder={t('Company')}                                    
                                /> 
                            ) : null
                        }
                    </div>
                    
                </div>
                <div className='btn-submit-container row mt-4'>
                    {
                        showCheckAuditorCoordinator ? (
                            <div className='checks-wrapper__item col-lg-12'>
                                <Checkbox
									inputID={'coordinatorUser'}
									dataTestID={'coordinatorUser'}
									inputName={'coordinatorUser'}
									inputLabel={t('Usuario coordinador')}
									inputChecked={formValues.isAuditorCoordinator}
									// onHandleChange={(e) => {
									// 	setIsAuditorCoordinatorCheck(e.target.checked);
									// }}
                                    onHandleChange={(e) =>
                                        setFormValues({
                                            ...formValues,
                                            isAuditorCoordinator: e.target.checked
                                        })
                                    }
									 withLink={false}
                                    isDisabled={false}
								/>
                            </div>
                        ) : null
                    }

                    <div className={`btn-submit-wrapper col-lg-12 d-flex ${ (action === 'edit') && (!user.emailConfirmed) ? "justify-content-between" : "justify-content-end" }`}>
                        {
                            (action === 'edit') && (!user.emailConfirmed) &&
                                <Button
                                    btnText="Reenviar email"
                                    btnType="button"
                                    isDisabled={false}
                                    btnClass="btn-base_normal"
                                    dataTestID="btnResendButton"
                                    onHandleBtnClick={resendEmail}
                                />
                        }
                        <div className='d-flex justify-content-end'>
                            <Button
                                dataTestID="btnCancel"
                                btnText={t('Cancel')}
                                btnType="button"
                                btnClass="btn-base_outline ml-5"
                                onHandleBtnClick={() => {
                                    dispatch(closeModal());
                                }}
                            />

                            <Button
                                btnText={(action === 'edit') ? 'Editar usuario' : 'Crear usuario'}
                                btnType="submit"
                                isDisabled={false}
                                btnClass="btn-base_normal ml-3"
                                dataTestID="btnUserEditRequest"
                                id='btnUserEditRequest'
                                onHandleBtnClick={(e) => postEditUser(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}