import Storage from 'js-storage';
import { SESSION_STORAGE_CONSTANTS } from '../constants/sessionStorage';

const lStorage = Storage.localStorage;

const SaveSessionStorage = (lStorageName, lStorageValue) => {
	lStorage.set(lStorageName, lStorageValue);
};

const GetSessionStorageAsParsedJSON = (lStorageName, getDefaultValue) => {
	const lStorageValue = lStorage.get(lStorageName);
	if (lStorageValue) {
		return JSON.parse(lStorageValue);
	}
	return getDefaultValue();
};

const GetSessionStorage = (lStorageName) => {
	const lStorageValue = lStorage.get(lStorageName) ? lStorage.get(lStorageName) : '';
	return lStorageValue;
};

const RemoveSessionStorage = (lStorageName) => {
	lStorage.remove(lStorageName);
};

const GetMenuSessionStorage = () => {
	return lStorage.get(SESSION_STORAGE_CONSTANTS.MENU);
};

const GetOnBoardMenuSessionStorage = () => {
	return lStorage.get(SESSION_STORAGE_CONSTANTS.ONBOARDMENU);
};

const DeleteSessionStorage = () => {
	lStorage.removeAll();
};

const GetActionsForPage = (pageLink) => {
	const menu = GetMenuSessionStorage();
	let actions;
	if (Array.isArray(menu)) {
		menu.forEach((menuItem) => {
			if (pageLink.includes(menuItem.link)) {
				actions = menuItem.actions;
			}
		});
	}
	return actions;
};

const GetNameForPage = (pageLink) => {
	const menu = GetMenuSessionStorage();
	let name = 'Backoffice';
	if (Array.isArray(menu)) {
		menu.forEach((menuItem) => {
			if (menuItem.subMenus.length !== 0) {
				menuItem.subMenus.forEach((subMenuItem) => {
					if (pageLink.toLowerCase().includes(subMenuItem.link.toLowerCase())) {
						name = subMenuItem.name;
					}
				});
			} else {
				if (pageLink.toLowerCase().includes(menuItem.link.toLowerCase())) {
					name = menuItem.name;
				}
			}
		});
	}
	return name;
};

export {
	SaveSessionStorage,
	GetSessionStorage,
	GetActionsForPage,
	GetNameForPage,
	GetSessionStorageAsParsedJSON,
	GetMenuSessionStorage,
	GetOnBoardMenuSessionStorage,
	RemoveSessionStorage,
	DeleteSessionStorage
};
