import menu from '@backoffice/actions/menu';
import { GetMenuSessionStorage } from '@backoffice/common/utils/storageManager';
import { SaveSessionStorage } from '@backoffice/common/utils/storageManager';
import { SESSION_STORAGE_CONSTANTS } from '@backoffice/common/constants/sessionStorage';

/* Redux state init */
const initialState = {
	menuItems: GetMenuSessionStorage() ? GetMenuSessionStorage() : []
};

export default function menuReducer(state = initialState, action) {
	if (action.type !== undefined) {
		
		switch (action.type) {
			case menu.UPDATE_MENU:
				SaveSessionStorage(SESSION_STORAGE_CONSTANTS.MENU, action.items);
				return {
					...state,
					menuItems: [ ...action.items ]
				};

			default:
				return { ...state };
		}
	}
	return state;
}
