import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { startRequest } from '../../../../actions/apiCall';
import { openModal } from '../../../../actions/popup';
import { Button } from '../../../../components/FormComponents';
import { getContractStatusTypeParams } from '../RequestDetailCalls/RequestDetailCalls';
import RequestContractsUploadModal from './RequestContractsUploadModal';

const RequestContracts = ({userRol, formValues, filesData, colorContractStates, uploadFile, disableDataBlocks, onHandleChange, isPrincipalCompany}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectStateContractButtons = () => {
        let wrapper = '';
        switch (formValues.ContractsStatusId) {
			case "c1eb08e7-d5df-4927-8f58-9841984f459c": //'Pendiente subida'
				wrapper = drawDownloadFile();
				break;
            case "117014a4-9f49-49dc-8a9e-f7ddded5d565": // Pendiente firma empresa
                wrapper = userRol === 'Admin' ? drawDownloadFile() : drawStateContractButtonsPendingForm();
				break;
            case "a1da1b64-8801-4ea2-b4c9-65e868f25d13": // Pendiente firma Madrid Excelente':
				wrapper = userRol === 'Admin' ? drawStateContractButtonsPendingME() : drawDownloadFile();
				break;
            case "e78a6020-4a57-45ac-aa96-f691a48ad8d8": //'Firmado'
				wrapper = drawStateContractButtonsSigned();
				break;			
			default:
				break;
		}

        return (
            wrapper
        )
    }

    const contractUploadModalPopUp = () => {
        const optionValues = {
            id: 'contractUploadModalPopUp',
            dataTestId: 'contractUploadModalPopUp',
            cssClass: 'modal-header__transparent',
            title: !formValues.ContractsAndAnnexesFileName ? 'Subir contrato' : 'Reemplazar contrato'
        };
    
        const options = {
            ...optionValues,
            body: (
                <RequestContractsUploadModal 
					formValues={formValues}
					disableDataBlocks={disableDataBlocks}
					uploadFile={uploadFile}
                    isPrincipalCompany={isPrincipalCompany}
				/>
            )
        };
        dispatch(openModal(options));
    }

    const drawDownloadFile = () => {
        return (
            <>
                <div className="contrats-annexes-buttons-wrapper">
                    {
                        filesData.contractsMedia.url !== '' 
                            ? <>
                                <div className='file-wrapper mb-2'>
                                    <span className='file-name'>Anexos</span>
                                    <a className="file-link material-icons-round" href='https://madridexcelente-my.sharepoint.com/:f:/p/ana_sanchez/EnrtmgTc-EpLpZslMdnw2QYB4_5jftXTCyTDFZzUzwfJ0g?e=LCXTlP' target="_blank" rel="noreferrer">file_download</a>
                                </div>
                                <div className='file-wrapper mb-2'>
                                    <span className='file-name'>{ formValues.ContractsStatusId === 'e78a6020-4a57-45ac-aa96-f691a48ad8d8' ? 'Contrato firmado' : 'Borrador contrato' }</span>
                                    <a title={filesData.contractsMedia.name} href={filesData.contractsMedia.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                </div>
                            </>
                            : null
                    }
                    <div className='file-wrapper'>
                        {
                            userRol === 'Admin' &&
                                <Button 
                                    icon={!formValues.ContractsAndAnnexesFileName ? 'file_upload' : 'refresh'}
                                    dataTestID="btnUploadContract"
                                    btnText={!formValues.ContractsAndAnnexesFileName ? `${t('Contrato')}` : 'Reemplazar'}
                                    btnType="button"
                                    btnClass={`btn-base_normal ${formValues.ContractsAndAnnexesFileName ? 'btn-base_outline' : null}`}
                                    onHandleBtnClick={() => {
                                        contractUploadModalPopUp();
                                    }}
                                    isDisabled={disableDataBlocks || formValues.EvaluationState !== 'Completado' && isPrincipalCompany}
                                />
                        }
                    </div>
                </div>
            </>
        )
    }

    const drawStateContractButtonsPendingForm = () => {
        return (
            <>
                {
                    filesData.contractsMedia.url !== '' 
                        ? <>
                            <div className='file-wrapper mb-2'>
                                <span className='file-name'>Anexos</span>
                                <a className="file-link material-icons-round" href='https://madridexcelente-my.sharepoint.com/:f:/p/ana_sanchez/EnrtmgTc-EpLpZslMdnw2QYB4_5jftXTCyTDFZzUzwfJ0g?e=LCXTlP' target="_blank" rel="noreferrer">file_download</a>
                            </div>
                        </>
                        : null
                }
                <div className={`button-upload link-contracts g`}>
                    <div className="button-down">
                        <span className="span-title">Paso 1: descarga, rellena y firma</span>
                        <div className="button-down__button">
                            <a title={filesData.contractsMedia.name} href={filesData.contractsMedia.url} className="btn-base_normal" target="_blank" rel="noreferrer">
                                <span
                                    role={'button'}
                                    className={`btn-base_normal`}
                                >
                                    <i className="material-icons-round">file_download</i>
                                    <span>{t('DownloadContract')}</span>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="button-up">
                        <span className="span-title">Paso 2: sube el contrato firmado</span>
                        <div className="button-up__button">
                            <input
                                id="btnContractsAndAnnexesFileLink"
                                data-testid="btnContractsAndAnnexesFileLink"
                                type="file"
                                onChange={(e) => uploadFile(e, 'contractsAndAnnexesFile')}
                                name="btnContractsAndAnnexesFileLink"
                                accept=".pdf, .doc, .docx, image/png, image/jpeg, .zip"
                                className="inputfile"
                                disabled={disableDataBlocks || formValues.EvaluationState !== 'Completado' && isPrincipalCompany}
                            />
                            <label
                                htmlFor="btnContractsAndAnnexesFileLink"
                                role={'button'}
                                className={`btn-base_normal`}
                            >
                                <i className="material-icons-round">file_upload</i>
                                <span>{t('UploadContract')}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const drawStateContractButtonsPendingME = () => {
        return (
            <>            
                {
                    drawStateContractButtonsPendingForm()
                }
                {
                    drawDownloadFile()
                }
            </>
        )
    }

    const drawStateContractButtonsSigned = () => {
        return (
            drawDownloadFile()
        )
    }

    return (
        <>
            <div className='title-wrapper'>
                <h2 className='title'>{t('ContractsAndAnnexes')}</h2>
                <div className='state'>
                    <span
                        className='material-icons-round'
                        style={{
                            color: colorContractStates(formValues.ContractsStatusId)
                        }}
                        aria-hidden={true}
                    >sticky_note_2</span>
                    <span>{formValues.ContractsStatusName}</span>
                </div>
            </div>
            <div className='content-wrapper'>
                <div className='data'>
                    <div className='label'>{t('Date')} / {t('Year')}</div>
                    <div className='data'>{formValues.ContractsAndAnnexesDate ? formValues.ContractsAndAnnexesDate.getFullYear() : '-'}</div>
                </div>
                
                <div className='actions'>
                    <div className={`upload-wrapper`}>
                        {   
                            selectStateContractButtons()
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestContracts;