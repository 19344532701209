const getAdditionalInfoParams = ( id, SuccessFn ) => {
	const options = {
		method: 'GET',
		endpoint: `/Company/GetAdditionalInfo/${id}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const createAdditionalInfoDocumentParams = ( data, SuccessFn ) => {
	const options = {
		method: 'POST',
		data,
		contentType: 'multipart/form-data',
		endpoint: `/Company/CreateAdditionalInfoDocument/`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const updateAdditionalInfoDocumentParams = ( data, SuccessFn ) => {
	const options = {
		method: 'PUT',
		data,
		contentType: 'multipart/form-data',
		endpoint: `/Company/UpdateAdditionalInfoDocument/`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteAdditionalInfoDocumentParams = ( companyId, mediaId, SuccessFn ) => {
	const options = {
		method: 'DELETE',
		endpoint: `/Company/DeleteAdditionalInfoDocument/${companyId}/${mediaId}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const createAdditionalInfoNotesParams = ( data, SuccessFn ) => {
	const options = {
		method: 'POST',
		data,
		endpoint: `/Company/CreateAdditionalInfoNotes/`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const updateAdditionalInfoNotesParams = ( data, SuccessFn ) => {
	const options = {
		method: 'PUT',
		data,
		endpoint: `/Company/UpdateAdditionalInfoNotes/`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteAdditionalInfoNotesParams = ( companyId, mediaId, SuccessFn ) => {
	const options = {
		method: 'DELETE',
		endpoint: `/Company/DeleteAdditionalInfoNotes/${companyId}/${mediaId}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
	getAdditionalInfoParams,
	createAdditionalInfoDocumentParams,
	updateAdditionalInfoDocumentParams,
	deleteAdditionalInfoDocumentParams,
	createAdditionalInfoNotesParams,
	updateAdditionalInfoNotesParams,
	deleteAdditionalInfoNotesParams
}