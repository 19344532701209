const getCompanieInfoParams = (id, funcSucc) => {
    const options = {
        method: 'GET',
        endpoint: `/Company/GetCompanyInfoHistory/${id}`,
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const postCompaniesInfoParams = (data, funcSucc) => {
    const options = {
        method: 'POST',
        endpoint: `/Company/FilterCompanyInfoHistory`,
        data: data,
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const postCreateCompaniesInfoParams = (data, funcSucc) => {
    const options = {
        method: 'POST',
        endpoint: `/Company/CreateCompanyInfoHistory`,
        data: data,
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const putUpdateCompaniesInfoParams = (data, funcSucc) => {
    const options = {
        method: 'PUT',
        endpoint: `/Company/UpdateCompanyInfoHistory`,
        data: data,
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const getCompanySizeTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/CompanySizeType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
    getCompanieInfoParams,
    postCompaniesInfoParams,
    postCreateCompaniesInfoParams,
    getCompanySizeTypeParams,
    putUpdateCompaniesInfoParams
}