const getAuditorCompaniesParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Company/GetAllAuditorCompanies',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postAuditorCompaniesParams = ( data, SuccessFn, ErrorFn ) => {
	const options = {
		method: 'POST',
        data,
		endpoint: '/Company/CreateAuditorCompany',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
        onError: (response) => {
            ErrorFn(response.data.data);
        }
	};
	return options;
};

const putAuditorCompaniesParams = ( data, SuccessFn, ErrorFn ) => {
	const options = {
		method: 'PUT',
        data,
		endpoint: '/Company/UpdateAuditorCompany',
		onSuccess: () => {
			SuccessFn();
		},
        onError: (response) => {
            ErrorFn(response.data.data);
        }
	};
	return options;
};

const deleteAuditorCompaniesParams = ( id, SuccessFn ) => {
	const options = {
		method: 'DELETE',
		endpoint: `/Company/DeleteAuditorCompany/${id}`,
		onSuccess: () => {
			SuccessFn();
		}
	};
	return options;
};

const uploadAuditorCompanyAgreementParamsMedia = ( data, SuccessFn, ErrorFn ) => {
	const options = {
		method: 'POST',
		data,
		contentType: 'multipart/form-data',
		endpoint: `/Company/uploadAuditorCompanyAgreementMedia/`,
		onSuccess: () => {
			SuccessFn();
		},
		onError: (response) => {
            ErrorFn(response);
        }
	};
	return options;
};

export {
    getAuditorCompaniesParams,
    postAuditorCompaniesParams,
    putAuditorCompaniesParams,
    deleteAuditorCompaniesParams,
	uploadAuditorCompanyAgreementParamsMedia
}