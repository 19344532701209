import React from "react";
import "./CookiesConfigModal.scss";
import {
    AccordionComponent,
    AccordionItemDirective,
    AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";

const CookiesConfigModal = ({ 
    switchAnalytic,
    setSwitchAnalytic,
    switchMarketing,
    setSwitchMarketing,
    onClose 
}) => {    

    const Panel1Header = () => (
        <div className="item-header">
            <span>Necesarias</span>
            <span className="item-header__action">Siempre habilitado</span>
        </div>
    );

    const Panel1Content = () => (
        <div className="item-content">
            <p>
                Aquéllas que permiten al usuario la navegación a través de una
                página web, plataforma o aplicación y la utilización de las
                diferentes opciones o servicios que en ella existan como, por
                ejemplo, controlar el tráfico y la comunicación de datos,
                identificar la sesión, acceder a partes de acceso restringido,
                recordar los elementos que integran un pedido, realizar el
                proceso de compra de un pedido, realizar la solicitud de
                inscripción o participación en un evento, utilizar elementos de
                seguridad durante la navegación, almacenar contenidos para la
                difusión de videos o sonido o compartir contenidos a través de
                redes sociales.
            </p>
            <table className="cookies-modal-table">
                <thead>
                    <tr>
                        <th scope="col">
                            Cookie
                        </th>
                        <th scope="col">
                            Duración
                        </th>
                        <th scope="col">
                            Descripción
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* <tr>
                        <td>
                            cookielawinfo-checkbox-advertisement
                        </td>
                        <td>1 year</td>
                        <td>
                            Set by the GDPR Cookie Consent plugin, this cookie
                            records the user consent for the cookies in the
                            "Advertisement" category.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            cookielawinfo-checkbox-analytics
                        </td>
                        <td>11 months</td>
                        <td>
                            This cookie is set by GDPR Cookie Consent plugin.
                            The cookie is used to store the user consent for the
                            cookies in the category "Analytics".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            cookielawinfo-checkbox-functional
                        </td>
                        <td>11 months</td>
                        <td>
                            The cookie is set by GDPR cookie consent to record
                            the user consent for the cookies in the category
                            "Functional".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            cookielawinfo-checkbox-necessary
                        </td>
                        <td>11 months</td>
                        <td>
                            This cookie is set by GDPR Cookie Consent plugin.
                            The cookies is used to store the user consent for
                            the cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            cookielawinfo-checkbox-others
                        </td>
                        <td>11 months</td>
                        <td>
                            This cookie is set by GDPR Cookie Consent plugin.
                            The cookie is used to store the user consent for the
                            cookies in the category "Other.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            cookielawinfo-checkbox-performance
                        </td>
                        <td>11 months</td>
                        <td>
                            This cookie is set by GDPR Cookie Consent plugin.
                            The cookie is used to store the user consent for the
                            cookies in the category "Performance".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            CookieLawInfoConsent
                        </td>
                        <td>1 year</td>
                        <td>
                            CookieYes sets this cookie to record the default
                            button state of the corresponding category and the
                            status of CCPA. It works only in coordination with
                            the primary cookie.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            viewed_cookie_policy
                        </td>
                        <td>11 months</td>
                        <td>
                            The cookie is set by the GDPR Cookie Consent plugin
                            and is used to store whether or not user has
                            consented to the use of cookies. It does not store
                            any personal data.
                        </td>
                    </tr> */}
                    <tr>
                        <td>
                            WEEKSTART_
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>                    
                    <tr>
                        <td>
                            WEEKSTART_EXP
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            TOKEN_
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            TOKEN_EXP
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            REFRESHTOKEN_
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            REFRESHTOKEN_EXP
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            CULTURE_
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            CULTURE_EXP
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            DATEFORMAT_
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            DATEFORMAT_EXP
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            CookieConsent
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            CookieConsentAnalytics
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                    <tr>
                        <td>
                            CookieConsentMarketing
                        </td>
                        <td>2 days</td>
                        <td>
                            The cookie is used to store the user consent for the
                            cookies in the category "Necessary".
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    const Panel2Header = () => (
        <div className="item-header">
            <span>Analítica</span>
            <div className="item-header__right">
                <span className="item-header__action">{switchAnalytic ? 'Habilitadas' : 'Deshabilitadas'}</span>
                <SwitchComponent 
                    checked={switchAnalytic}
                    onChange={() => setSwitchAnalytic(!switchAnalytic)}
                />
            </div>
        </div>
    );

    const Panel2Content = () => (
        <div className="item-content">
            <p>
                Son aquéllas que permiten al responsable de las mismas, el
                seguimiento y análisis del comportamiento de los usuarios de los
                sitios web a los que están vinculadas. La información recogida
                mediante este tipo de cookies se utiliza en la medición de la
                actividad de los sitios web, aplicación o plataforma y para la
                elaboración de perfiles de navegación de los usuarios de dichos
                sitios, aplicaciones y plataformas, con el fin de introducir
                mejoras en función del análisis de los datos de uso que hacen
                los usuarios del servicio.
            </p>
            <table className="cookies-modal-table">
                <thead>
                    <tr>
                        <th scope="col">
                            Cookie
                        </th>
                        <th scope="col">
                            Duración
                        </th>
                        <th scope="col">
                            Descripción
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_ga</td>
                        <td>
                            1 year 1 month 4 days
                        </td>
                        <td>
                            Google Analytics sets this cookie to calculate
                            visitor, session and campaign data and track site
                            usage for the site's analytics report. The cookie
                            stores information anonymously and assigns a
                            randomly generated number to recognise unique
                            visitors.
                        </td>
                    </tr>
                    <tr>
                        <td>_ga_*</td>
                        <td>
                            1 year 1 month 4 days
                        </td>
                        <td>
                            Google Analytics sets this cookie to store and count
                            page views.
                        </td>
                    </tr>
                    <tr>
                        <td>_gat_UA-*</td>
                        <td>1 minute</td>
                        <td>
                            Google Analytics sets this cookie for user behaviour
                            tracking.
                        </td>
                    </tr>
                    <tr>
                        <td>_gcl_au</td>
                        <td>3 months</td>
                        <td>
                            Google Tag Manager sets the cookie to experiment
                            advertisement efficiency of websites using their
                            services.
                        </td>
                    </tr>
                    <tr>
                        <td>_gid</td>
                        <td>1 day</td>
                        <td>
                            Google Analytics sets this cookie to store
                            information on how visitors use a website while also
                            creating an analytics report of the website's
                            performance. Some of the collected data includes the
                            number of visitors, their source, and the pages they
                            visit anonymously.
                        </td>
                    </tr>
                    <tr>
                        <td>CONSENT</td>
                        <td>2 years</td>
                        <td>
                            YouTube sets this cookie via embedded YouTube videos
                            and registers anonymous statistical data.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    const Panel3Header = () => (
        <div className="item-header">
            <span>Marketing</span>
            <div className="item-header__right">
                <span className="item-header__action">{switchMarketing ? 'Habilitadas' : 'Deshabilitadas'}</span>
                <SwitchComponent
                    checked={switchMarketing}
                    onChange={() => setSwitchMarketing(!switchMarketing)}
                />
            </div>
        </div>
    );

    const Panel3Content = () => (
        <div className="item-content">
            <p>
                Son aquéllas que permiten la gestión, de la forma más eficaz
                posible, de los espacios publicitarios que, en su caso, el
                editor haya incluido en una página web, aplicación o plataforma
                desde la que presta el servicio solicitado en base a criterios
                como el contenido editado o la frecuencia en la que se muestran
                los anuncios.
            </p>
            <table className="cookies-modal-table">
                <thead>
                    <tr>
                        <th scope="col">
                            Cookie
                        </th>
                        <th scope="col">
                            Duración
                        </th>
                        <th scope="col">
                            Descripción
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>IDE</td>
                        <td>1 year 24 days</td>
                        <td>
                            Google DoubleClick IDE cookies store information
                            about how the user uses the website to present them
                            with relevant ads according to the user profile.
                        </td>
                    </tr>
                    <tr>
                        <td>test_cookie</td>
                        <td>15 minutes</td>
                        <td>
                            doubleclick.net sets this cookie to determine if the
                            user's browser supports cookies.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            VISITOR_INFO1_LIVE
                        </td>
                        <td>5 months 27 days</td>
                        <td>
                            YouTube sets this cookie to measure bandwidth,
                            determining whether the user gets the new or old
                            player interface.
                        </td>
                    </tr>
                    <tr>
                        <td>YSC</td>
                        <td>session</td>
                        <td>
                            Youtube sets this cookie to track the views of
                            embedded videos on Youtube pages.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            yt-remote-connected-devices
                        </td>
                        <td>never</td>
                        <td>
                            YouTube sets this cookie to store the user's video
                            preferences using embedded YouTube videos.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            yt-remote-device-id
                        </td>
                        <td>never</td>
                        <td>
                            YouTube sets this cookie to store the user's video
                            preferences using embedded YouTube videos.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            yt.innertube::nextId
                        </td>
                        <td>never</td>
                        <td>
                            YouTube sets this cookie to register a unique ID to
                            store data on what videos from YouTube the user has
                            seen.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            yt.innertube::requests
                        </td>
                        <td>never</td>
                        <td>
                            YouTube sets this cookie to register a unique ID to
                            store data on what videos from YouTube the user has
                            seen.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="cookie-config-modal">
            <div className="cookie-config-modal__wrapper">
                <div className="cookie-config-modal__wrapper__content">
                    <div className="cookie-config-modal__title">Configuración de Cookies</div>
                    <div className="cookie-config-modal__message">Usamos cookies en nuestro sitio web para brindarle la experiencia más relevante recordando sus preferencias y visitas repetidas. Al hacer clic en "GUARDAR Y ACEPTAR", acepta el uso de las cookies activas.</div>
                    <AccordionComponent>
                        <AccordionItemsDirective>
                            <AccordionItemDirective
                                header={Panel1Header}
                                content={Panel1Content}
                            />
                            <AccordionItemDirective
                                header={Panel2Header}
                                content={Panel2Content}
                            />
                            <AccordionItemDirective
                                header={Panel3Header}
                                content={Panel3Content}
                            />
                        </AccordionItemsDirective>
                    </AccordionComponent>
                </div>
                <div className="cookie-config-modal__wrapper__buttons">
                    <button className="btn-base btn-base_normal" onClick={onClose}>Guardar y aceptar</button>
                </div>
            </div>
        </div>
    );
};

export default CookiesConfigModal;
