import React from 'react';
import './HeaderPages.scss';

const HeaderPages = ({ title, userLogedName }) => {
  return (
    <>
        <div className='row'>
            <div className='col-lg-12'>
                <h2 className="title-header-page">{title}</h2>
            </div>
        </div>
        <div className='user-container'>
            <div className='help-wrapper' >
                <a className='return-wrapper' href='https://www.madridexcelente.com/' role='button'>
                    <span className='material-icons' aria-hidden={true}>arrow_back</span> 
                    <span className='return-link'>
                        Volver a la web de Madrid Excelente
                    </span>
                </a>
            </div>            
            <div className='user-wrapper'>
                <span className='user-text'>{userLogedName}</span>
            </div>
        </div>
    </>
  )
}

export default HeaderPages;