import React, { useEffect, useRef, useState } from 'react';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { useDispatch } from 'react-redux';
import { getQuestionnaireGetAllParams, postCreateQuestionnaire } from './QuestionnairesCalls/QuestionnairesCalls';
import { startRequest } from '@backoffice/actions/apiCall';
import { useTranslation } from 'react-i18next';
import { GetSessionStorage } from '../../../common/utils/storageManager';
import HeaderPages from '../../../shared/HeaderPages';
import { Button, Combo } from '../../../components/FormComponents';
import './Questionnaires.scss';
import { openModal } from '../../../actions/popup';
import { showFixedLabel } from '../../../actions/fixedLabel';
import QuestionnaireAddModal from './QuestionnaireAddModal/QuestionnaireAddModal';
import { ROUTE } from '../../../common/constants/routes';
import { useHistory } from 'react-router-dom';

const Questionnaires = () => {
    const pageSettings = { pageSize: 10 };
	const grid = useRef(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();
    const history = useHistory();

	const optionsIsPublic = [
		{
			id: 1,
			value: false,
			label: t('Private')
		},
		{
			id: 2,
			value: true,
			label: t('Public')
		}
	];

    const [userLogedName, setUserLogedName] = useState('')
	const [questionnairesData, setQuestionnairesData] = useState([])
    const [isPublic, setIsPublic] = useState(false)
    const [optionsIsPublicSelected, setOptionsIsPublicSelected] = useState(optionsIsPublic[0])

    useEffect(() => {
		getUserLoged()
	}, []);

    useEffect(() => {
		setIsPublic(optionsIsPublicSelected.value)
	}, [optionsIsPublicSelected]);

    useEffect(() => {
		getQuestionnaireGetAll()
	}, [isPublic]);	

	const getUserLoged = () =>{
		const storage = GetSessionStorage('PROFILE_')
		setUserLogedName(storage.name)
	}

    const getQuestionnaireGetAll = () => {
        dispatch(startRequest(getQuestionnaireGetAllParams(isPublic, getQuestionnaireGetAllParamsSuccess)))
    }

    const getQuestionnaireGetAllParamsSuccess = (data) => {
        setQuestionnairesData(data);
    }

    const dateTemplate = (rowData) => {
		return new Date(rowData.creationDate).toLocaleDateString()
	}

    const fileTemplate = (rowData) => {
		const haveDocument = rowData.fileUrl === '' ? false : true;

		return (
			<div className="document-template">
				{
					haveDocument 
						? <a title={rowData.fileName} href={rowData.fileUrl} className="file-link material-icons-round" target="_blank" rel="noreferrer">
							<Button 
								dataTestID={rowData.id}
								id={rowData.id}
								btnText={ t('DownloadFile')}
								btnType="button"
								btnClass={ 'btn-base_outline-success' }
								onHandleBtnClick={ () => {} }
								icon={ 'download' }
							/>
						</a>
						: null
				}
			</div>
		);
	}

    const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnSee_${rowData.index}`}
					role='navigation'
					aria-hidden={true}
					id={`btnSee_${rowData.index}`}
                    onClick={() => history.push({
                        pathname: ROUTE.QUESTIONNAIRESDETAIL.replace(':id', rowData.id),
                        state: {
                            id: rowData.id
                        }
                    })}
					
					type="button"
				>
					visibility
				</span>
			</div>
		);
	};

    const addQuestionnaireModal = () => {
        const optionValues = {
            id: 'addQuestionnaireModal',
            dataTestId: 'addQuestionnaireModal',
            cssClass: 'modal-header__transparent',
            title: t('AddQuestionnaire')
        };
    
        const options = {
            ...optionValues,
            body: (
                <QuestionnaireAddModal
                    handleSubmit={handleOnSubmitFile}
                    showFixedLabel={showFixedLabel}
                />
            )
        };
        dispatch(openModal(options));
    }

    const handleOnSubmitFile = (file) => {
        const fileUpload = {
            File : file
        }
        dispatch(startRequest(postCreateQuestionnaire(fileUpload, isPublic, postCreateQuestionnaireSuccess, postCreateQuestionnaireError)))
    }

    const postCreateQuestionnaireSuccess = () => {
        dispatch(showFixedLabel(t('UploadQuestionnaireSuccess'), 'success'))
        getQuestionnaireGetAll()
    }

    const postCreateQuestionnaireError = () => {
        dispatch(showFixedLabel(t('UploadQuestionnaireError'), 'error'))
    }
    
    return (
        <div className="questionnaires">
			<HeaderPages 
				title={t('Questionnaires')}
				userLogedName={userLogedName}
			/>			
            <div className='row'>
				<div className='add-questionnaire-wrapper col-lg-12'>
					<Combo 
						id="IsPublic"
						data-testid="IsPublic"
						defaultInputValue=""
						options={optionsIsPublic}
						isSearchable={false}
						isClearable={false}
						className="comboBox"
						handleChange={(e) => setOptionsIsPublicSelected(e)}
						inputValue={optionsIsPublicSelected}
						isMultiple={false}
						comboLabel={t('Public/Private')}
						comboPlaceholder={t('Public/Private')}
					/>
					<Button
						btnText={t('AddQuestionnaire')}
						btnType="button"
						btnClass="btn-base_normal "
						dataTestID="addUserBtn"
						id="addQuestionnaireBtn"
						onHandleBtnClick={addQuestionnaireModal}
					/>
				</div>
			</div>
			<div className='request-grid row'>
				<div className="col-md-12">
					<div className='grid-wrapper' style={{ flex: "1", width: "100%", height: "100%" }}>
						<GridComponent
							className="gridTable"
							dataSource={questionnairesData}
							width="100%"
							toolbar={false}
							ref={grid}
							//allowExcelExport={true}
							allowPaging={true}
							enableAdaptiveUI={true}
							allowSorting={true}
							rowRenderingMode={'Vertical'}
							pageSettings={pageSettings}
							allowResizing={true}
							locale="es"
							// rowDataBound={colorRow}
							sortSettings={
								{
									columns:[{
										field: 'version',
										direction: 'Descending'
									}]
								}
							}
						>
							<ColumnsDirective>
								<ColumnDirective field="id" width="0" visible={false} />
                                <ColumnDirective 
                                    field="version" 
                                    headerText={t('Version')}
                                    textAlign="Left" 
                                    width="40"
									sortComparer={(reference,
										comparer) => {
											if (parseInt(reference) < parseInt(comparer)) {
												return -1;
											}
											if (parseInt(reference) > parseInt(comparer)) {
												return 1;
											}
											return 0;
										}
									}
                                />							
								<ColumnDirective 
									field="creationDate" 
									headerText={t('CreationDate')} 
									template={dateTemplate} 
									textAlign="Left"
									width="120"
								/>														
								<ColumnDirective 
									field="fileName" 
									headerText={t('FileName')}
									textAlign="Left"
									width="120"
								/>														
								<ColumnDirective 
									field="fileUrl" 
									headerText={t('DownloadDocument')}
                                    template={fileTemplate} 
									textAlign="Left"
									width="120"
								/>														
								<ColumnDirective
									field="actions"
									headerText=''
									template={actionsTemplate}
									textAlign="Right"
									width="30"
								/>
							</ColumnsDirective>
							<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
						</GridComponent>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Questionnaires