const GET_MENU = 'GET_MENU';
const UPDATE_MENU = 'UPDATE_MENU';

const defaultConst = {
    GET_MENU,
    UPDATE_MENU,
};

export default defaultConst;
export const getMenu = () => ({ type: GET_MENU });
export const updateMenu = (items) => ({ type: UPDATE_MENU, items: items });
