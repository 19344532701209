import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/FormComponents';

const RequestInformEvaluatorForm = ({userRol, formValues, filesData, uploadFile, disableDataBlocks, colorEvaluationStates, handleApproveEvaluation}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='title-wrapper'>
                <h2 className='title'>{t('EvaluationReport')}</h2>
                <div className='state'>
                    <span
                        className='material-icons-round'
                        style={{
                            color: colorEvaluationStates(formValues.EvaluationState)
                        }}
                        aria-hidden={true}
                    >assignment</span>
                    <span>{formValues.EvaluationState}</span>
                </div>
            </div>
            <div className='content-wrapper'>
                <div className='data'>
                    <div className='label'>{t('Date')}</div>
                    <div className='data'>
                        {formValues.EvaluationDate ? new Date(formValues.EvaluationDate).toLocaleDateString() : '-'}
                    </div>
                </div>
                <div className='actions flex-direction-column'>
                    {
                        userRol === 'Admin' && filesData.evaluationMedia.url !== '' && filesData.evaluationMedia2.url !== '' ? (
                            <>
                            {
                                formValues.EvaluationState !== 'Completado' ? (
                                    <div className='btn-wrapper'>
                                        <Button
                                            btnText={t('Authorize')}
                                            btnType="button"
                                            btnClass="btn-base_normal "
                                            dataTestID="AuthorizeEvaluationBtn"
                                            id="AuthorizeEvaluationBtn"
                                            onHandleBtnClick={(e) => handleApproveEvaluation(e)}
                                            isDisabled={disableDataBlocks}
                                        />

                                    </div>

                                ) : null
                            }
                            </>

                        ): null
                    }
                    {
                        (userRol === 'Auditor' || userRol === 'Admin') && (formValues.IsAuditorComplete || filesData.evaluationMedia2.url !== '') ? (
                            <>
                                <div className="upload-wrapper">
                                    <div className="button-upload ml-3">
                                        {
                                            filesData.evaluationMedia.url !== '' ? (
                                                <div className='file-wrapper'>
                                                    {/* <span className='file-name'>{filesData.evaluationMedia.name}</span> */}
                                                    <a title={filesData.evaluationMedia.name} href={filesData.evaluationMedia.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                                </div>
                                            ) : null
                                        }
                                        <input
                                            id="btnEvaluationFile"
                                            data-testid="btnEvaluationFile"
                                            type="file"
                                            onChange={(e) => uploadFile(e, 'evaluationFile')}
                                            name="btnEvaluationFile"
                                            accept=".pdf"
                                            className="inputfile"
                                            disabled={
                                                disableDataBlocks || 
                                                formValues.QuestionnaireCompletePercent < 100 ||
                                                formValues.QuestionnaireState !== "Validado"
                                            }
                                        />
                                        {
                                            userRol === 'Auditor' &&
                                                <label
                                                    htmlFor="btnEvaluationFile"
                                                    role={'button'}
                                                    className={`btn-base_normal ${formValues.EvaluationFileName ? 'btn-base_outline' : null}`}
                                                >
                                                    {
                                                        !formValues.EvaluationFileName ? (
                                                            <i className="material-icons-round">file_upload</i>
                                                        ) : <i className="material-icons-round">refresh</i>
                                                    }

                                                    <span>{!formValues.EvaluationFileName ? `${t('Importar informe')}` : 'Reemplazar'}</span>
                                                </label>
                                        }
                                        <span className='file-name'>{formValues.EvaluationFileName}</span>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                    {
                        (userRol === 'Auditor' || userRol === 'Admin') && (formValues.IsAuditorComplete || filesData.evaluationMedia2.url !== '') ? (
                            <>
                                <div className="upload-wrapper">
                                    <div className="button-upload ml-3">
                                        {
                                            filesData.evaluationMedia2.url !== '' ? (
                                                <div className='file-wrapper'>
                                                    {/* <span className='file-name'>{filesData.evaluationMedia2.name}</span> */}
                                                    <a title={filesData.evaluationMedia2.name} href={filesData.evaluationMedia2.url} className="file-link material-icons-round" target="_blank" rel="noreferrer">file_download</a>
                                                </div>
                                            ) : null
                                        }
                                        <input
                                            id="btnEvaluationFile2"
                                            data-testid="btnEvaluationFile2"
                                            type="file"
                                            onChange={(e) => uploadFile(e, 'evaluationFile2')}
                                            name="btnEvaluationFile2"
                                            accept=".pdf, .docx, .doc, .xls, .xlsx"
                                            className="inputfile"
                                            disabled={
                                                disableDataBlocks || 
                                                formValues.QuestionnaireCompletePercent < 100 ||
                                                formValues.QuestionnaireState !== "Validado"
                                            }
                                        />
                                        {
                                            userRol === 'Auditor' &&
                                                <label
                                                    htmlFor="btnEvaluationFile2"
                                                    role={'button'}
                                                    className={`btn-base_normal ${formValues.EvaluationFileName2 ? 'btn-base_outline' : null}`}
                                                >
                                                    {
                                                        !formValues.EvaluationFileName2 ? (
                                                            <i className="material-icons-round">file_upload</i>
                                                        ) : <i className="material-icons-round">refresh</i>
                                                    }

                                                    <span>{!formValues.EvaluationFileName2 ? `${t('Importar hoja de puntuación')}` : 'Reemplazar'}</span>
                                                </label>
                                        }
                                        <span className='file-name'>{formValues.EvaluationFileName2}</span>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                </div>
            </div>
        </>
    )
}

export default RequestInformEvaluatorForm;