import React, { useEffect, useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useHistory } from 'react-router-dom';
import LoginForm from './LoginForm';
import logo from '../../../assets/logos/me_azul2.png'
import FixedLabel from '../../../shared/FixedLabel';
import { DeleteSessionStorage, GetSessionStorage } from '../../../common/utils/storageManager';
import { DeleteAllCookies } from '../../../common/utils/cookiesManager';

import './Login.scss';

export default function Login() {
	const [ forgotPassword, setForgotPassword ] = useState(false);
	const history = useHistory();
	const isMaintenance = false;


	useEffect(() => {
		let isSubscribed = true;
		checkIfLoged()
		return () => (isSubscribed = false)
	}, [])
	
	const checkIfLoged = () => {
		if(!isMaintenance){
			const storage = GetSessionStorage('PROFILE_')
			if(storage){
				//  storage.userRoles.map(el => {
				// 	 if(el.name === 'Admin'){
				// 		 history.push('/empresas')
				// 	 } else {
				// 		 // TODO change home by role permission
						
				// 		 history.push('/home')
				// 	 }
				//  })
				storage.userRoles.map(el => {
					if(el.name === 'Admin' || el.name === 'Auditor'){
						history.push('/empresas')
					} else {
						history.push('/solicitudes')
					}
				})
			}
		} else {
			DeleteAllCookies();
			DeleteSessionStorage();
			history.push('/login')
		}
	}

	return (
		<div className='login'>
	
			<div className="login-wrapper">
				<h1 className="img-wrapper">
					<img className='logo' src={logo} alt="logo madrid excelente" />
				</h1>
			</div>
			<div className='separator'></div>
			<div className="login-wrapper ">
				{
					!isMaintenance
						? <>
							<FixedLabel parentType="general" />
							{forgotPassword ? (
								<ForgotPasswordForm setForgotPassword={setForgotPassword} />
							) : (
								<LoginForm
									setForgotPassword={setForgotPassword}
									redirect={true}
									hideRegister={false}
									fromBooking={false}
								/>
							)}
							</>
						: <div style={{width: 300, padding: 10}}>Estamos de mantenimiento, en breve volverá a estar la plataforma disponible. Disculpe las molestias.</div>
				}
				
			</div>
				
		</div>
	);
}
