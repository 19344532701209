import appearance from '../../actions/appearance';
import { SaveSessionStorage, GetSessionStorage } from '@backoffice/common/utils/storageManager';
import { SESSION_STORAGE_CONSTANTS } from '../../common/constants/sessionStorage';

/* Redux state init */
const defaultAppearance = {
	imgUrl: process.env.PUBLIC_URL + '/pasiona.png',
	faviconImgUrl: process.env.PUBLIC_URL + '/favicon.ico',
	colorPrimary: '#748781',
	colorPrimaryVariant: '#a4aaa6',
	colorPrimaryVariant2: '#eae9e3',
	colorPrimaryHover: '#919b99',
	colorPrimaryPressed: '#474e4c',
	colorPrimaryDisabled: '#d2d6d6',
	colorSecondary: '#9a817c',
	colorSecondaryVariant: '#706f70',
	colorSecondaryVariant2: '#d7d4ce',
	colorText: '#000000',
	colorError: '#95260e',
	colorErrorBG: '#ccaca6',
	colorWarning: '#d9d2aa',
	colorWarningBG: '#dad3a8',
	colorSuccess: '#b6c3be',
	colorSuccessBG: '#b2c4be',
	colorMenuBG: '#f1f1f1',
	colorMenu: '#000',
	colorHeaderBG: '#ffffff',
	colorHeader: '#000',
	colorMainBG: '#ffffff'
};

const initialState = {
	isDefault: true,
	appearance: GetSessionStorage(SESSION_STORAGE_CONSTANTS.APPEARANCE)
		? GetSessionStorage(SESSION_STORAGE_CONSTANTS.APPEARANCE)
		: defaultAppearance
};

export default function appearanceReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case appearance.CHANGE_APPEARANCE:
				SaveSessionStorage(SESSION_STORAGE_CONSTANTS.APPEARANCE, action.appearance);
				setProperties(action.appearance);
				return {
					...state,
					isDefault: false,
					appearance: action.appearance
				};
			case appearance.RESET_APPEARANCE:
				setProperties(defaultAppearance);
				return {
					...state,
					appearance: defaultAppearance
				};
			case appearance.REFRESH_APPEARANCE:
				setProperties(initialState.appearance);
				return {
					...state,
					appearance: initialState.appearance
				};
			default:
				return { ...state };
		}
	}
	return state;
}

const setProperties = (appearance) => {
	document.getElementById('favicon').href = appearance.faviconImgUrl;
	document.documentElement.style.setProperty('--color-primary', appearance.colorPrimary);
	document.documentElement.style.setProperty('--color-primary-variant', appearance.colorPrimaryVariant);
	document.documentElement.style.setProperty('--color-primary-variant2', appearance.colorPrimaryVariant2);
	document.documentElement.style.setProperty('--color-primary-hover', appearance.colorPrimaryHover);
	document.documentElement.style.setProperty('--color-primary-pressed', appearance.colorPrimaryPressed);
	document.documentElement.style.setProperty('--color-primary-disabled', appearance.colorPrimaryDisabled);
	document.documentElement.style.setProperty('--color-secondary', appearance.colorSecondary);
	document.documentElement.style.setProperty('--color-secondary-variant', appearance.colorSecondaryVariant);
	document.documentElement.style.setProperty('--color-secondary-variant2', appearance.colorSecondaryVariant2);
	document.documentElement.style.setProperty('--color-text', appearance.colorText);
	document.documentElement.style.setProperty('--color-error', appearance.colorError);
	document.documentElement.style.setProperty('--color-error-bg', appearance.colorErrorBG);
	document.documentElement.style.setProperty('--color-warning', appearance.colorWarning);
	document.documentElement.style.setProperty('--color-warning-bg', appearance.colorWarningBG);
	document.documentElement.style.setProperty('--color-success', appearance.colorSuccess);
	document.documentElement.style.setProperty('--color-success-bg', appearance.colorSuccessBG);
	document.documentElement.style.setProperty('--color-menu-bg', appearance.colorMenuBG);
	document.documentElement.style.setProperty('--color-menu', appearance.colorMenu);
	document.documentElement.style.setProperty('--color-header-bg', appearance.colorHeaderBG);
	document.documentElement.style.setProperty('--color-header', appearance.colorHeader);
	document.documentElement.style.setProperty('--color-main-bg', appearance.colorMainBG);
};
