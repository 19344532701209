const getSectorTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/SectorType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};
const getEntitiesTypeParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/EntityType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postCreateRequestParams = (data, SuccessFn, ErrorFn) => {
	const options = {
		method: 'POST',
		endpoint: '/PublicRequest/CreateRequest',
		data,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
		onError: (error) => {
			ErrorFn(error)
		},
	};
	return options;
};

const putCertificateEntityParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/PublicRequest/UploadCertificateEntity',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};
const putCertificateAllPaidTaxesParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/PublicRequest/UploadCertificateAllPaidTaxes',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};
const putCertificateSocialSecurityParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/PublicRequest/UploadCertificateSocialSecurity',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getPublicRequestDetailByID = (id, funcSucc) => {
    const options = {
        method: 'GET',
        endpoint: `/PublicRequest/Detail/${id}`,
        onSuccess: (response) => {
            funcSucc(response.data.data);
        }
    };
    return options;
}

const getBasicClientCompaniesParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Company/getBasicClientCompanies',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getNotCombinedClientCompanies = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Company/GetNotCombinedClientCompanies',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postUpdateEvaluationBasicData = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/PublicRequest/UpdateEvaluationBasicData',
		onSuccess: (response) => {
			SuccessFn(response.data.data); 
		}
	};
	return options;
};
const getUserLoggedParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetUserLogged',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};
const getExcelAutoEvaluationDataParams = (requestId, isAuditor, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetExcelAutoEvaluationData/${requestId}${isAuditor && '?canSeeAuditor=true'}`,
		onSuccess: (response) => {
			SuccessFn(response.data);
		}
	};
	return options;
};
const getExcelAutoEvaluationDataBase64Params = (requestId, isAuditor, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetExcelAutoEvaluationDataBase64/${requestId}${'?canSeeAuditor=' + isAuditor}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
    getSectorTypeParams,
    getEntitiesTypeParams,
    postCreateRequestParams,
	putCertificateEntityParams,
	putCertificateAllPaidTaxesParams,
	putCertificateSocialSecurityParams,
	getPublicRequestDetailByID,
	getBasicClientCompaniesParams,
	postUpdateEvaluationBasicData,
	getUserLoggedParams,
	getNotCombinedClientCompanies,
	getExcelAutoEvaluationDataParams,
	getExcelAutoEvaluationDataBase64Params
	//getCompanies
}