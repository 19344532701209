import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Provider } from 'react-redux';
import { GetCookie_culture } from '@backoffice/common/utils/cookiesManager';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadLanguage } from '../../locales/SyncfusionTranslationService';
import { getPrivateRoutes, getPublicRoutes } from '../service.js';
import { createStore } from 'redux';
import rootReducer from '../../reducers';
import ErrorPage from '../../views/Pages/ErrorPage';
import Loading from '../../shared/Loading';
import { ApiCall } from '../../shared/index';
import CustomPopUp from '../../shared/CustomPopUp';
import Footer from '../../components/Footer';
import CookiesConsent from '../../components/CookiesConsent/CookiesConsent';
import './Root.css';

const store = createStore(rootReducer);

const Root = () => {

	
	useEffect(() => {
		i18n.changeLanguage(GetCookie_culture());
		loadLanguage();
	}, []);

	return (
		<Provider store={store}>
			<Loading />
			<ApiCall />
			<Router>
				<CustomPopUp key={'popUp' + Math.floor(Math.random() * (5000 - 1)) + Math.random()} />
				<Switch>
					{getPublicRoutes(store)}
					{getPrivateRoutes(store)}
					<Route component={() => <ErrorPage pageNotFound={true} />} />
				</Switch>
			</Router>
			<Footer />
			<CookiesConsent />
		</Provider>
	);
};

export default Root;
