import cookies from '@backoffice/actions/cookies';
import { SaveCookies, DeleteCookies } from '@backoffice/common/utils/cookiesManager';
import { COOKIES_CONSTANTS } from '@backoffice/common/constants/cookies';
import { GetCookie_token, GetCookie_token_exp, GetCookie_refreshToken } from '@backoffice/common/utils/cookiesManager';

/* Redux state init */
const initialState = {
	token: GetCookie_token() ? GetCookie_token() : '',
	tokenExp: GetCookie_token_exp() ? GetCookie_token_exp() : '',
	refreshToken: GetCookie_refreshToken() ? GetCookie_refreshToken() : ''
};

export default function tokenReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case cookies.CREATETOKEN:
				SaveCookies(COOKIES_CONSTANTS.TOKEN, action.token);
				return {
					...state,
					token: action.token
				};
			case cookies.CREATEREFRESHTOKEN:
				SaveCookies(COOKIES_CONSTANTS.REFRESHTOKEN, action.refreshToken);
				return {
					...state,
					refreshToken: action.refreshToken
				};
			case cookies.DELETETOKEN:
				DeleteCookies(COOKIES_CONSTANTS.TOKEN);
				return {
					...state,
					token: action.token
				};
			default:
				return { ...state };
		}
	}
	return state;
}
