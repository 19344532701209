import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import { postResetPasswordParams } from './ResetPasswordCalls/ResetPasswordCalls';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import { startRequest } from '../../../../actions/apiCall';
import { ROUTE } from '../../../../common/constants/routes';
import FixedLabel from '../../../../shared/FixedLabel';
import { Input, Button } from '../../../../components/FormComponents/index';
import logo from '../../../../assets/logos/me_azul2.png'
import './ResetPasswordForm.scss';

const ResetPasswordForm = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [ error, setError ] = useState(false);
	const [ errorResponse, setErrorResponse ] = useState({});
	const [ token, setToken ] = useState('');
	//const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ passwordHidden, setPasswordHidden ] = useState(true);
	const [ confirmPasswordHidden, setConfirmPasswordHidden ] = useState(true);

	useEffect(() => {
		
		const search = history.location.search;
		const searchFirstSplit = search.split('?token=')[1];
		const params = searchFirstSplit.split('&email=');
		setToken(params[0]);
		//setEmail(params[1]);
		document.title = t('ResetPassword');
	}, []);

	const submitResetPassword = (e) => {
		const data = {
			token,
			confirmPassword,
			password
		};
		if (password === confirmPassword) {
			dispatch(
				startRequest(
					postResetPasswordParams(
						data,
						() => {
							dispatch(showFixedLabel(t('PasswordSaved'), 'success'));
							history.push(ROUTE.ROOT);
						},
						(error) => {
							setError(true);
							setErrorResponse(error);
						}
					)
				)
			);
		} else {
			setError(true);
			setErrorResponse({
				data: { errors: [ { errorMessageKey: 'PasswordsNotMatch' } ] }
			});
		}
	};

	const testPassword = (password) => {
		if (
			password.match(/[a-z]/g) &&
			password.match(/[A-Z]/g) &&
			password.match(/[0-9]/g) &&
			password.match(/[^a-zA-Z\d]/g) &&
			password.length >= 8
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (password !== '' && confirmPassword !== '') {
				submitResetPassword(event);
			}
		}
	};

	const [notEquals, setNotEquals] = useState(false)

	useEffect(() => {
	  if (password !== '' && confirmPassword !== '') {
		
		if(password !== confirmPassword){
			setNotEquals(true)
		}else{
			setNotEquals(false)
		}
	  }
	}, [password, confirmPassword])
	

	return (
		<div className='resetPasswordForm'>
			<div className="login-wrapper">
				<h1 className="img-wrapper">
					<img className='logo' src={logo} alt="logo madrid excelente" />
				</h1>
			</div>
			<div className='separator'></div>
			<div className="login-wrapper">
				<FixedLabel parentType="general" />
				<div className="resetpassword-form">
					<form
						className="form-signin"
						name="resetPasswordForm"
						title={t('ResetPassword')}
						aria-label={t('ResetPassword')}
						// onSubmit={submitResetPassword}
					>
						<p className="resetpassword-title">{t('ResetPassword')}</p>
						<div className="form-wrapper">
							<Input
								dataTestID="txtPassword"
								inputName="txtPassword"
								inputType={passwordHidden ? 'password' : 'text'}
								inputLabel={t('Password')}
								inputID="txtPassword"
								isDisabled={false}
								onHandleChange={(e) => setPassword(e.currentTarget.value)}
								value={password}
								icon={passwordHidden ? 'visibility' : 'visibility_off'}
								isRequired={true}
								onHandleKeyPress={handleKeyPress}
								inputPlaceholder={passwordHidden ? 'Nueva contraseña' : ''}
								onHandleClick={() => setPasswordHidden(!passwordHidden)}
							/>
						</div>
						<div className="form-wrapper">
							<Input
								dataTestID="txtConfirmPassword"
								inputName="txtConfirmPassword"
								inputType={confirmPasswordHidden ? 'password' : 'text'}
								inputLabel={t('ConfirmPassword')}
								inputID="txtConfirmPassword"
								isDisabled={false}
								onHandleChange={(e) => setConfirmPassword(e.currentTarget.value)}
								value={confirmPassword}
								onHandleKeyPress={handleKeyPress}
								icon={confirmPasswordHidden ? 'visibility' : 'visibility_off'}
								isRequired={true}
								inputPlaceholder={confirmPasswordHidden ? 'Repite contraseña' : ''}
								onHandleClick={() => setConfirmPasswordHidden(!confirmPasswordHidden)} 
							/>
						</div>
						{
							notEquals ? (
								<div className='not-equals-message'>{t('PasswordNotEquals')}</div>
							) : null
						}
						<div className='info-password'>La contraseña ha de tener al menos 8 caracteres, incluyendo una mayúscula, una minúscula y un número.</div>
						<div className="link-forgotPass">
							<Link
								type="text"
								className='link'
								onClick={(e) => {
									e.preventDefault();
									history.push(ROUTE.LOGIN);
								}}
							>
								<span>{t('DoYouHaveAnAccount')}</span>
							</Link>
						</div>
						<div className="button-container">
							<Button
								btnText={t('Send')}
								btnType="button"
								onHandleBtnClick={submitResetPassword}
								isDisabled={password === '' || confirmPassword === ''}
								btnClass="btn-base_normal"
								dataTestID="btnResetPasswordButton"
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPasswordForm;
