const getImprovementPlanDetailParams = (improvementPlanId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetImprovementPlanDetail/${improvementPlanId}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAutoevaluationBaisisesParams = (autoevaluationId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetAutoevaluationBaisises/${autoevaluationId}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAutoevaluationAreasFromBasisParams = (autoevaluationId, basisId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetAutoevaluationAreasFromBasis/${autoevaluationId}/${basisId}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAutoevaluationSubAreasFromBasisAreaParams = (autoevaluationId, basisId, areaId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: `/Request/GetAutoevaluationSubAreasFromBasisArea/${autoevaluationId}/${basisId}/${areaId}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postSaveImprovementPlanParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data: data,
		endpoint: `/Request/SaveImprovementPlan/`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const putSendImprovementPlanParams = (data, SuccessFn) => {
	const options = {
		method: 'PUT',
		data: data,
		endpoint: `/Request/SendImprovementPlan/`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
	getImprovementPlanDetailParams,
    getAutoevaluationBaisisesParams,
    getAutoevaluationAreasFromBasisParams,
    getAutoevaluationSubAreasFromBasisAreaParams,
	postSaveImprovementPlanParams,
	putSendImprovementPlanParams
}