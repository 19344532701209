import { combineReducers } from 'redux';
import language from './languageReducer';
import token from './tokenReducer';
import menu from './menuReducer';
import loading from './loadingReducer/loading';
import apicall from './apiCallReducer';
import error from './errorReducer';
import profile from './profileReducer';
import popup from './popupReducer';
import customCallback from './customCallbackReducer';
import fixedLabel from './fixedLabelReducer';
import appearance from './appearanceReducer';

const rootReducer = combineReducers({
	/** Add more reducers here */
	appearance,
	language,
	token,
	menu,
	loading,
	apicall,
	error,
	popup,
	profile,
	customCallback,
	fixedLabel
});

export default rootReducer;
