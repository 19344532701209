import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import './Paginator.scss';

const Paginator = ({
    totalItems,
    page,
    defaultPageSize,
    showPagesText = true,
    blockNumber = 2,
    onPageChange,
}) => {
    const { t } = useTranslation()

    const blockNumberCorrect = blockNumber <= 0 ? 1 : blockNumber + 1
    const numberTotalPages = totalItems % defaultPageSize === 0 ? Math.floor(totalItems / defaultPageSize) : Math.floor(totalItems / defaultPageSize) + 1
    const [pages, setPages] = useState([])

    useEffect(() => {
        createArrayPages()
    }, [numberTotalPages])

    const createArrayPages = () => {
        setPages(Array.from({ length: numberTotalPages }, (_, i) => i + 1))
    }

    const handleOnChangePage = (page) => {
        onPageChange(page)
    }

    const handleOnPrevPage = () => {
        onPageChange(page - 1)
    }

    const handleOnNextPage = () => {
        onPageChange(page + 1)
    }

    const drawPages = () => {
        const hasDots = numberTotalPages > (7 + blockNumber);
        const middlePage = Math.floor(numberTotalPages / 2);
        return (
            <>
                {
                    hasDots ?
                        <>
                            {
                                pages.map((page) => (
                                    page < blockNumberCorrect && drawPageItem(page)
                                ))
                            }
                            <div>...</div>
                            {
                                page === blockNumberCorrect
                                    ? <>
                                        {drawPageItem(page)}
                                        {drawPageItem(page + 1)}
                                        {drawPageItem(page + 2)}
                                    </>
                                    : page === (numberTotalPages - (blockNumberCorrect - 1))
                                        ? <>
                                            {drawPageItem(page - 2)}
                                            {drawPageItem(page - 1)}
                                            {drawPageItem(page)}
                                        </>
                                        : page < blockNumberCorrect || page > (numberTotalPages - (blockNumberCorrect - 1))
                                            ? <>
                                                {drawPageItem(middlePage - 1)}
                                                {drawPageItem(middlePage)}
                                                {drawPageItem(middlePage + 1)}
                                            </>
                                            : <>
                                                {drawPageItem(page - 1)}
                                                {drawPageItem(page)}
                                                {drawPageItem(page + 1)}
                                            </>
                            }
                            <div>...</div>
                            {
                                pages.map((page) => (
                                    page > (numberTotalPages - (blockNumberCorrect - 1)) && drawPageItem(page)
                                ))
                            }
                        </>
                        : pages.map((page) => (
                            drawPageItem(page)
                        ))
                }
            </>
        )
    }

    const drawPageItem = (pageNumber) => (
        <li
            key={`PaginatorIndex${pageNumber}`}
            className={page === pageNumber ? 'active-page' : 'no-active-page'}
            onClick={page === pageNumber ? () => { } : () => handleOnChangePage(pageNumber)}>
            <span>{pageNumber}</span>
        </li>
    )

    const [paginator, setPaginator] = useState(drawPages)

    useEffect(() => {
        setPaginator(drawPages)
    }, [page, pages])

    return (
        <div className={'paginator'}>
            <div className={'paginator-btns-wrapper'}>
                <div className={'paginator-prev-btns'}>
                    {
                        numberTotalPages > 1 &&
                        <>
                            <div
                                className={`${'paginator-prev-btns-start'} ${page !== 1 ? '' : 'btn-disable'}`}
                                onClick={() => page !== 1 ? onPageChange(1) : null}
                            >
                                <span className="material-icons-round" data-testid={'btn-goback'} id={'btn-goback'}>
                                    keyboard_double_arrow_left
                                </span>
                            </div>
                            <div
                                className={`${'paginator-prev-btns-prev'} ${page !== 1 ? '' : 'btn-disable'}`}
                                onClick={page !== 1 ? handleOnPrevPage : null}
                            >
                                <span className="material-icons-round" data-testid={'btn-goback'} id={'btn-goback'}>
                                    navigate_before
                                </span>
                            </div>
                        </>
                    }
                </div>
                <div className={'paginator-pages-btn'}>
                    <ul>
                        {paginator}
                    </ul>
                </div>
                <div className={'paginator-next-btns'}>
                    {
                        numberTotalPages > 1 &&
                        <>
                            <div
                                className={`${'paginator-next-btns-next'} ${page !== numberTotalPages ? '' : 'btn-disable'}`}
                                onClick={page !== numberTotalPages ? handleOnNextPage : null}
                            >
                                <span className="material-icons-round" data-testid={'btn-goback'} id={'btn-goback'}>
                                    navigate_next
                                </span>
                            </div>
                            <div
                                className={`${'paginator-next-btns-end'} ${page !== numberTotalPages ? '' : 'btn-disable'}`}
                                onClick={page !== numberTotalPages ? () => onPageChange(numberTotalPages) : null}
                            >
                                <span className="material-icons-round" data-testid={'btn-goback'} id={'btn-goback'}>
                                    keyboard_double_arrow_right
                                </span>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                showPagesText &&
                <div className={'items-count'}>{`${page ? page : 1} ${t("PaginatorOf")} ${isNaN(numberTotalPages) ? 1 : numberTotalPages} ${t("PaginatorPages")} (${totalItems ? totalItems : 0}  ${t("PaginatorTotal")})`}</div>
            }
        </div>
    )
}

export default Paginator