import React from 'react'
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components/FormComponents';

const CompaniesInfoDetailGrid = ({
    companieInfoData,
    grid,
    pageSettings,
	companySizeType,
	companyDataSectorTypology,
	colorRow
}) => {

    const {t} = useTranslation();

	const sizeTemplate = (rowData) => {
		let name = '';
		if( rowData ) {
			name = companySizeType.filter(el => el.id === rowData.companySizeTypeId)[0].label
		}

		return (
			<span>{name}</span>
		)
	}

	const sectorTemplate = (rowData) => {
		let name = '';
		if( rowData ) {
			name = companyDataSectorTypology.filter(el => el.id === rowData.sectorTypeId)[0].label
		}

		return (
			<span>{name}</span>
		)
	}
	
	const publicTemplate = (rowData) => {
		return (
			rowData
				? rowData.isPublicCompany
					? <span>{t('Public')}</span>
					: <span>{t('Private')}</span>
				: <span></span>
		)
	}

	const dateTemplate = (rowData) => {
		return (
			rowData && <span>{new Date(rowData.creationDate).toLocaleDateString()}</span>
		)
	}

    return (
        <div className='request-grid row'>
			<div className="col-md-12">
				<div className='grid-wrapper' style={{ flex: "1", width: "100%", height: "100%" }}>
					<GridComponent
						className="gridTable"
						dataSource={companieInfoData}
						width="100%"
						toolbar={false}
						ref={grid}
						allowExcelExport={true}
						allowPaging={true}
						enableAdaptiveUI={true}
						allowSorting={true}
						rowRenderingMode={'Vertical'}
						pageSettings={pageSettings}
						allowResizing={true}
						locale="es"
						rowDataBound={colorRow}
					>
						<ColumnsDirective>
							<ColumnDirective field="id" width="0" visible={false} />
							<ColumnDirective 
								field="creationDate" 
								headerText={t('DataDate')} 
								template={dateTemplate} 
								textAlign="Left"
								width="90" 
								visible={true}
							/>
							<ColumnDirective 
								field="numEmployees" 
								headerText={t('Employees')} 
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="incomeQuantity" 
								headerText={t('Revenue')}  
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="companySizeTypeId" 
								headerText={t('Size')} 
								template={sizeTemplate} 
								textAlign="Left"
								// width="150" 
								visible={true}
							/>
							<ColumnDirective 
								field="sectorTypeId" 
								headerText={t('CompanySector')} 
								template={sectorTemplate} 
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="town" 
								headerText={t('Town')} 
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="region" 
								headerText={t('Province')}  
								textAlign="Left"
								width="80" 
								visible={true}
							/>
							<ColumnDirective 
								field="isPublicCompany" 
								headerText={t('Public')} 
								template={publicTemplate} 
								textAlign="Left"
								width="80" 
								visible={true}
							/>							
						</ColumnsDirective>
						<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
					</GridComponent>
					{/* <div className="export-excel-wrapper">
						<div className="col-lg-12">
							<Button
								btnText={t('ExportExcel')}
								btnType="button"
								btnClass="btn-base_normal export-excel-button"
								onHandleBtnClick={() => {
									grid.current.excelExport();
								}}
							/>
						</div>
					</div> */}
				</div>
			</div>
		</div>
    )
}

export default CompaniesInfoDetailGrid