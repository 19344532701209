const ROUTE = {
	ROOT: '/',
	LOGIN: '/login',
	USERS: '/usuarios',
	USERDETAIL: '/users/:id',
	COMPANIES: '/empresas',
	REQUESTDETAIL: '/empresas/:id',
	SOLICITUDES: '/solicitudes',
	SOLICITUDESID: '/solicitudes/:id',
	RESETPASSWORD: '/resetpassword',
	LOGOUT: '/logout',
	REQUEST: '/solicitud',
	REQUESTID: '/solicitud/:id',
	SELFASSESSMENTID: '/formularioautoevaluacion/:id',
	REQUESTRECIEVED: '/solicitud-recibida',
	AUDITORCOMPANIES: '/empresasauditoras',
	IMPROVEMENTPLANS: '/planesmejora/:id',
	QUESTIONNAIRES: '/cuestionarios',
	QUESTIONNAIRESDETAIL: '/cuestionarios/:id',
	COMPANIESINFO: '/empresasinfo',
	COMPANIESINFODETAIL: '/empresasinfo/:id'
};
export { ROUTE }; 
