const getQuestionnaireGetAllParams = ( isPublic, SuccessFn ) => {
	const options = {
		method: 'GET',
		endpoint: `/Questionnaire/GetAll?isPublic=${isPublic}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getQuestionnaireDetailsParams = ( id, SuccessFn ) => {
	const options = {
		method: 'GET',
		endpoint: `/Questionnaire/GetDetail/${id}`,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postCreateQuestionnaire = ( data, isPublic, SuccessFn, ErrorFn ) => {
	const options = {
		method: 'POST',
        data: data,
        contentType: 'multipart/form-data',
		endpoint: `/Questionnaire/Create?isPublic=${isPublic}`,
		onSuccess: () => {
			SuccessFn();
		},
		onError: (response) => {
            ErrorFn(response);
        }
	};
	return options;
};

const getUserLoggedParams = ( SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/UserManagement/GetUserLogged',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
    getQuestionnaireGetAllParams,
    getQuestionnaireDetailsParams,
    postCreateQuestionnaire,
    getUserLoggedParams
}