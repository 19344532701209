import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Fetch from 'i18next-fetch-backend';
import { APIBASEURL } from '../services/apiCall';
import { ApiVersion } from '../services/config';
import translationES from './translations/es.json';
import translationEN from './translations/en.json';
import translationCA from './translations/ca.json';

const urlTranslations = APIBASEURL + ApiVersion + '/Translations/Get/';

i18n.use(initReactI18next).use(Fetch).init({
	whitelist: [ 'es', 'ca', 'en', 'de' ],
	initImmediate: false,
	resources: {
		es: {
			translation: translationES
		},
		en: {
			translation: translationEN
		},
		ca: {
			translation: translationCA
		}
	}
});

export default i18n;
