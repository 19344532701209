import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { startRequest } from '@backoffice/actions/apiCall';
import { getCompanieInfoParams, getCompanySizeTypeParams, putUpdateCompaniesInfoParams } from '../CompaniesInfoCalls.js/CompaniesInfoCalls';
import { getSectorTypeParams } from '../../../Pages/Request/RequestCalls/RequestCalls';
import { Button, Combo, Input, InputAutoSuggest } from '../../../../components/FormComponents';
import { MUNICIPIOS } from '../../../../common/constants/municipios';
import { PROVINCIAS } from '../../../../common/constants/provincias';
import CompaniesInfoDetailGrid from './CompaniesInfoDetailGrid';
import './CompaniesInfoDetail.scss';
import { row } from '@syncfusion/ej2-react-grids';

const CompaniesInfoDetail = () => {

    const pageSettings = { pageSize: 10 };
	const grid = useRef(null);
	const history = useHistory();
    const companyId = history.location.pathname.split('/')[2];
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [companiesData, setCompaniesData] = useState([])
	const [error, setError] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

    // input num employees
	const [companyNumEmployees, setCompanyNumEmployees] = useState()

	// input vol incomings
	const [companyVolIncomings, setCompanyVolIncomings] = useState()

	// Combo company size
	const [companySizeType, setCompanySizeType] = useState([])
	const [companySizeSelectedType, setCompanySizeSelectedType] = useState(null)

	// Combo sector type
	const [companyDataSectorTypology, setCompanyDataSectorTypology] = useState([])
	const [companyDataSectorTypologySelected, setCompanyDataSectorTypologySelected] = useState(null)

	// Combo public/private
	const [companyPublicSelected, setCompanyPublicSelected] = useState(null)
	
	// Autosuggest towns
	const [autoSuggestTown, setAutoSuggestTown] = useState('')

	// Autosuggest province
	const [autoSuggestProvince, setAutoSuggestProvince] = useState('')

	const optionsPublic = [
		{
			id: 1,
			value: true,
			label: t('Public')
		},
		{
			id: 2,
			value: false,
			label: t('Private')
		}
	]

	useEffect(() => {
		getCompanieInfo()
	}, [])

	useEffect(() => {
		if(companiesData.currentCompanyInfoHistory) {
			setCompanyNumEmployees(companiesData.currentCompanyInfoHistory.numEmployees);
			setCompanyVolIncomings(companiesData.currentCompanyInfoHistory.incomeQuantity);
			setCompanySizeSelectedType(companySizeType.filter(el => el.id === companiesData.currentCompanyInfoHistory.companySizeTypeId)[0]);
			setCompanyDataSectorTypologySelected(companyDataSectorTypology.filter(el => el.id === companiesData.currentCompanyInfoHistory.sectorTypeId)[0]);
			setCompanyPublicSelected(optionsPublic.filter(el => el.value === companiesData.currentCompanyInfoHistory.isPublicCompany)[0]);
			setAutoSuggestTown(companiesData.currentCompanyInfoHistory.town);
			setAutoSuggestProvince(companiesData.currentCompanyInfoHistory.region);
		}
	}, [companySizeType, companyDataSectorTypology])

	const getCompanieInfo = () => {
        dispatch(startRequest(getCompanieInfoParams(companyId, getCompanieInfoSuccess)))
    }

	const getCompanieInfoSuccess = (data) => {
		setCompaniesData(data);
		getCompanySizeType();		
	}

	const getCompanySizeType = () => {
		dispatch(startRequest(getCompanySizeTypeParams(getCompanySizeTypeParamsSuccess)))
	}

	const getCompanySizeTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
			let label = "";
			switch (el.name) {
				case "Grupo 1":
					label = `${el.name}: < 10 empleados y ≤ 2 millones`
					break;

				case "Grupo 2":
					label = `${el.name}: < 50 empleados y/o < 10 millones`
					break;

				case "Grupo 3":
					label = `${el.name}: < 250 empleados y/o < 50 millones`
					break;

				case "Grupo 4":
					label = `${el.name}: ≥ 250 empleados y ≥ 50 millones`
					break;

				case "Grupo 5":
					label = `${el.name}: > 500 empleados y/o > 200 millones`
					break;

				case "Grupo 6":
					label = `${el.name}: > 5000 empleados y/o > 1.000 millones`
					break;
			
				default:
					break;
			}

            options.push({
                id: el.id,
                value: el.name,
                label: label
            });
        });
		setCompanySizeType(options)		
		getSectorType()
	}

	const getSectorType = () => {
		dispatch(startRequest(getSectorTypeParams(getSectorTypeParamsSuccess)))
	}

	const getSectorTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name,
                description: el.description
            });
        });
        setCompanyDataSectorTypology(options)
		setIsLoading(false)
	}

	const onHandleChangeCompanySize = (e) => {
		setCompanySizeSelectedType(e)
	}

	const onHandleChangeCompanySector = (e) => {
		setCompanyDataSectorTypologySelected(e)
	}

	const onHandleChangeCompanyPublic = (e) => {
		setCompanyPublicSelected(e)
	}

	const onHandleChangeCompanyTown = (e) => {
		setAutoSuggestTown(e)
	}

	const onHandleChangeCompanyProvince = (e) => {
		setAutoSuggestProvince(e)
	}

	const checkData = () => {
		let check = true;

		let invalidRevenueChar = ['.', ',']
		let revenueIsNotValid = Array.from(companyVolIncomings).some(char => invalidRevenueChar.includes(char))

		if( companyNumEmployees < 1
			|| companyVolIncomings < 1
			|| !companySizeSelectedType
			|| !companyDataSectorTypologySelected
			|| !companyPublicSelected
			|| !autoSuggestTown
			|| !autoSuggestProvince
		) {
			setError(true);
			check = false;
		} else if(revenueIsNotValid) {
			setError(true)
			check = false;
			setCompanyVolIncomings('');
		} else {
			setError(false);
		}

		return check;
	}

	const saveCompanyData = () => {

		const check = checkData();

		if( check ) {
			const data = {
				companyId: companyId,
				numEmployees: companyNumEmployees,
				incomeQuantity: companyVolIncomings,
				companySizeTypeId: companySizeSelectedType.id,
				sectorTypeId: companyDataSectorTypologySelected.id,
				town: autoSuggestTown,
				region: autoSuggestProvince,
				isPublicCompany: companyPublicSelected.value,
				creationDate: new Date()
			}
	
			dispatch(startRequest(putUpdateCompaniesInfoParams(data, saveCompanyDataSuccess)))
		}

	}
    
    const saveCompanyDataSuccess = () => {
        getCompanieInfo();
    }

	const colorRow = (rowData) => {
		if(rowData.data.id == companiesData.currentCompanyInfoHistory.id) {
			rowData.row.classList.add('recent-detail');
		}
	}

    return (
        <div className='companies-info-detail'>

			{
				!isLoading &&
					<h2>{companiesData.infoData.socialName}</h2>
			}
			<div className='companies-info-detail-filters'>


                <div className="input-wrapper">
                    <Input
                        inputType="number"
                        inputmode="number"
                        inputLabel={t('NumberEmployees')}
                        inputID="inputNumberEmployees"
                        dataTestID="inputNumberEmployees"
                        isDisabled={false}
                        isRequired={true}
						on
                        onHandleChange={(e) => {
                            setCompanyNumEmployees(e.currentTarget.value);
                        }}
                        value={companyNumEmployees}
                        showLabel
                        autoComplete="off"
                        inputPlaceholder={t('NumberEmployees')}   
						avoidPoint
                    />
                </div>

                <div className="input-wrapper">
                    <Input
                        inputType="number"
                        inputmode="number"
                        inputLabel={t('RevenueVolumen')}
                        inputID="inputRevenueVolumen"
                        dataTestID="inputRevenueVolumen"
                        isDisabled={false}
                        isRequired={true}
                        onHandleChange={(e) => {
                            setCompanyVolIncomings(e.currentTarget.value.replace(/[^0-9]/g, ""));
                        }}
                        value={companyVolIncomings}
                        showLabel
                        autoComplete="off"
                        inputPlaceholder={t('RevenueVolumen')}
						avoidPoint
                    />
                </div>

				<Combo 
					id="CompanySize"
					data-testid="CompanySize"
					defaultInputValue=""
					options={companySizeType}
					isSearchable={false}
					isClearable={true}
					className="comboBox"
					handleChange={onHandleChangeCompanySize}
					inputValue={companySizeSelectedType}
					isMultiple={false}
					comboLabel={t('Size')}
					comboPlaceholder={t('Size')}
					isRequired
				/> 

				<Combo 
					id="CompanySector"
					data-testid="CompanySector"
					defaultInputValue=""
					options={companyDataSectorTypology}
					isSearchable={false}
					isClearable={true}
					className="comboBox"
					handleChange={onHandleChangeCompanySector}
					inputValue={companyDataSectorTypologySelected}
					isMultiple={false}
					comboLabel={t('CompanySector')}
					comboPlaceholder={t('CompanySector')}
					isRequired
				/> 

				<Combo 
					id="CompanyPublic"
					data-testid="CompanyPublic"
					defaultInputValue=""
					options={optionsPublic}
					isSearchable={false}
					isClearable={true}
					className="comboBox"
					handleChange={onHandleChangeCompanyPublic}
					inputValue={companyPublicSelected}
					isMultiple={false}
					comboLabel={t('Public/Private')}
					comboPlaceholder={t('Public/Private')}
					isRequired
				/>

				<InputAutoSuggest 
					inputLabel={t('Town')}
					defaultValue={autoSuggestTown}
					placeholderInput={t('Town')}
					onHandleChange={(value) => onHandleChangeCompanyTown(value)}
					filterFunction={(inputValue) => MUNICIPIOS.filter((el) => el.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))}
					isRequired
				/>

				<InputAutoSuggest 
					inputLabel={t('Province')}
					defaultValue={autoSuggestProvince}
					placeholderInput={t('Province')}
					onHandleChange={(value) => onHandleChangeCompanyProvince(value)}
					filterFunction={(inputValue) => PROVINCIAS.filter((el) => el.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))}
					isRequired
				/>

				<Button
					dataTestID="btnSaveCompanyData"
					btnText={t('SaveRecent')}
					btnType="button"
					btnClass={'btn-base_normal'}
					onHandleBtnClick={saveCompanyData}
				/>
			</div>
			
			{
				error && 
					<div className="error-message">Faltan campos por rellenar o hay campos inválidos</div>
			}

			{
				<p className='companies-info-detail-table-title'>
					<h2>
						{t('InfoHistoryTable')}
					</h2>
				</p>
			}

			{
				!isLoading &&
					<CompaniesInfoDetailGrid
						companieInfoData={companiesData.companyInfoHistoryList}
						grid={grid}
						pageSettings={pageSettings}
						companySizeType={companySizeType}
						companyDataSectorTypology={companyDataSectorTypology}
						colorRow={colorRow}
					/>
			}
		</div>
    )
}

export default CompaniesInfoDetail