import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import './Combo.scss';

export default function Combo(props) {
    const {
        id,
        dataTestID,
        inputValue,
        defaultInputValue,
        options,
        isSearchable,
        comboLabel,
        handleChange,
        isDisabled,
        getOptionValue,
        getOptionLabel,
        isMultiple,
        isRequired,
        onHandleClose,
        isClearable,
        comboPlaceholder
    } = props;
    const { t } = useTranslation();

    const handleOpen = () => {
        const target = document.getElementById(dataTestID);
        if (target) {
            target.classList.add('isFocus');
        }
        
    };
    const handleClose = () => {
        const target = document.getElementById(dataTestID);
        if (target) {
            target.classList.remove('isFocus');
        }
        if (onHandleClose) {
            onHandleClose();
        }
    };

    return (
        <div
            data-testid={dataTestID}
            className={`combo-wrapper ${isDisabled ? 'isDisabled' : ''}`}
            id={dataTestID}
        >
            <label htmlFor={id} className={comboLabel ? 'label-combo' : 'no-label-combo'}>
                {comboLabel}
                {isRequired ? <span className='combo-required'> *</span> : ''}
            </label>
            <Select
                id={id}
                isDisabled={isDisabled}
                data-testid={dataTestID}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                //placeholder={t('Select') + '...'}
                placeholder={comboPlaceholder}
                defaultValue={defaultInputValue}
                options={options}
                isSearchable={isSearchable}
                noOptionsMessage={() => t('Empty')}
                className='comboBox'
                onChange={handleChange}
                value={inputValue}
                isMulti={isMultiple}
                isClearable={isClearable}
                onMenuOpen={handleOpen}
                onMenuClose={handleClose}
            />
        </div>
    );
}
